import React from "react";
import {
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import ContactVerily from "assets/contact-verily.svg";
import { A11yLineBreak } from "./A11yLineBreak";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "./A11yDialogTitle";

interface ContactVerilyDialogProps {
  open: boolean;
  onClose: () => void;
}

const ContactVerilyDialog: React.FC<ContactVerilyDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">
          Please contact Verily to make this change
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">
            We want to make sure the watch data gets attributed to the right
            participant.
            <A11yLineBreak />
            Email <b>numetric-portal@verily.com</b>
          </Typography>
          <Box
            sx={{
              alignSelf: "center",
              paddingTop: "24px",
            }}
          >
            <Box
              component="img"
              src={ContactVerily}
              sx={{
                alignSelf: "center",
                justifySelf: "center",
              }}
              alt=""
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button variant="filled" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactVerilyDialog;
