import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { ReactComponent as PriorityHighIcon } from "assets/priority-high.svg";
import { ReactComponent as CheckIcon } from "assets/check-icon-emphasized.svg";

export const successBackgroundColor = "#D5F1DA";
export const failureBackgroundColor = "#F9E3C7";

export const HighlightedIcon: React.FC<{
  icon: React.ReactNode;
  success: boolean;
  ariaLabel: string;
  showFocus: boolean;
}> = ({ icon, success, ariaLabel, showFocus }) => {
  const color = success ? "#087A6A" : "#5D3108";
  const annotation = success ? (
    <Box arial-hidden="true" position="absolute" top={-8} right={-4}>
      <CheckIcon width={14} />
    </Box>
  ) : (
    <Box arial-hidden="true" position="absolute" top={-4} right={-4}>
      <PriorityHighIcon width={14} />
    </Box>
  );

  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (boxRef.current != null) {
      if (showFocus) {
        boxRef.current.focus();
      } else {
        boxRef.current.blur();
      }
    }
  }, [showFocus]);

  return (
    <Box
      aria-label={ariaLabel}
      tabIndex={showFocus ? 0 : -1}
      role="img"
      ref={boxRef}
      bgcolor={success ? successBackgroundColor : failureBackgroundColor}
      sx={{ "& path": { fill: color } }}
      // Copied from the Figma. Specifying the width and height prevents the
      // circle from expanding if the calendar cell is wide.
      width="36px"
      height="36px"
      padding="6px"
      borderRadius="40px"
      position="relative"
    >
      {annotation}
      {icon}
    </Box>
  );
};
