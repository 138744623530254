import React from "react";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { sxVisuallyHidden } from "common/Accessibility";

// TableHeaderCell for the manage user details column, which contains a hidden label
// for a11y support.
export const ManageUserDetailsTableHeaderCell: React.FC = () => {
  return (
    <TableCell width="64px">
      <Box sx={sxVisuallyHidden}>Manage user details</Box>
    </TableCell>
  );
};
