/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.participantcompliancetask.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: participantcompliancetask/participantcompliancetask.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.participantcompliancetask = {};
proto.verily.sdp.participantcompliancetask.v1alpha1 = require('./participantcompliancetask_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest,
 *   !proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>}
 */
const methodDescriptor_ParticipantComplianceTaskService_CreateParticipantComplianceTask = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/CreateParticipantComplianceTask',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest,
  proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask,
  /**
   * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServiceClient.prototype.createParticipantComplianceTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/CreateParticipantComplianceTask',
      request,
      metadata || {},
      methodDescriptor_ParticipantComplianceTaskService_CreateParticipantComplianceTask,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServicePromiseClient.prototype.createParticipantComplianceTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/CreateParticipantComplianceTask',
      request,
      metadata || {},
      methodDescriptor_ParticipantComplianceTaskService_CreateParticipantComplianceTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest,
 *   !proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse>}
 */
const methodDescriptor_ParticipantComplianceTaskService_FilterParticipantComplianceTasks = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/FilterParticipantComplianceTasks',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest,
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse,
  /**
   * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServiceClient.prototype.filterParticipantComplianceTasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/FilterParticipantComplianceTasks',
      request,
      metadata || {},
      methodDescriptor_ParticipantComplianceTaskService_FilterParticipantComplianceTasks,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskServicePromiseClient.prototype.filterParticipantComplianceTasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTaskService/FilterParticipantComplianceTasks',
      request,
      metadata || {},
      methodDescriptor_ParticipantComplianceTaskService_FilterParticipantComplianceTasks);
};


module.exports = proto.verily.sdp.participantcompliancetask.v1alpha1;

