/**
 * Defines a default value for previous week offset.
 *
 * -1 means next week. We do not allow users to go to next week in the UI.
 */
export const defaultPreviousWeekOffset = -1;

/**
 * Caches the latest setting of previous week offset.
 */
let previousWeekOffsetCache = defaultPreviousWeekOffset;

export function getPreviousWeekOffsetCache() {
  return previousWeekOffsetCache;
}

export function setPreviousWeekOffsetCache(offset: number) {
  previousWeekOffsetCache = offset;
}

/**
 * Caches the selected participant id for calendar view.
 */
let studyParticipantIdCache = "";

export function getStudyParticipantIdCache() {
  return studyParticipantIdCache;
}

export function setStudyParticipantIdCache(participantId: string) {
  studyParticipantIdCache = participantId;
}

// Uses a none month start date as default so it will be
// different from any valid month start date.
export const defaultMonthStartDate = "2024-03-26";

/**
 * Caches the selected calendar month.
 */
let monthStartDateCache = defaultMonthStartDate;

export function getMonthStartDateCache() {
  return monthStartDateCache;
}

export function setMonthStartDateCache(startDate: string) {
  monthStartDateCache = startDate;
}

/**
 * Clears all the caches related to Looker settings.
 */
export function clearLookerSettingsCache() {
  previousWeekOffsetCache = defaultPreviousWeekOffset;
  studyParticipantIdCache = "";
  monthStartDateCache = defaultMonthStartDate;
}
