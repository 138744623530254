import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  MenuList,
  MenuItem,
  Popover,
  TablePagination,
  Typography,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Search,
  Snackbar,
  Tag,
  MultipleSelectFilterChip,
  Tooltip,
} from "@verily-src/react-design-system";
import useStudySitesDataLoader from "./StudySitesDataLoader";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { StudySite } from "generated/studysite/studysite_pb";
import { useAppSelector } from "redux/hooks";
import { hasAnyRole, selectCurrentStudyRoles } from "core/UserConfigSlice";
import CreateStudySiteDialog from "./CreateStudySiteDialog";
import DeleteStudySiteDialog from "./DeleteStudySiteDialog";
import EditStudySiteDialog from "./EditStudySiteDialog";
import ArchiveStudySiteDialog from "./ArchiveStudySiteDialog";
import { Role } from "generated/studyauth/studyauth_pb";
import { tablePaginationSxProps } from "components/Tables";
import { usePageTitle } from "components/PageTitle";
import { countries, toCountry } from "common/CountryCode";
import { fromDateProto } from "common/Dates";
import pluralize from "pluralize";
import A11yStatusMessage from "components/A11yStatusMessage";
import { sxFocusWithin, sxVisuallyHidden } from "common/Accessibility";

interface StudySitesTableHeaderProps {
  isEditable: boolean;
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const StudySitesTableHeader: React.FC<StudySitesTableHeaderProps> = ({
  isEditable,
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="site name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Site name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Site Name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="site number"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Site number"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Site Number</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="principal investigator"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Principal investigator"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Principal Investigator</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="country"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Country"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Country</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Status"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Status</Typography>
        </SortableTableHeaderCell>
        {isEditable && (
          <TableCell width="64px">
            <Box sx={sxVisuallyHidden}>Manage site details</Box>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

// Define a component to render study site information.
interface StudySiteRowProps {
  isEditable: boolean;
  studySite: StudySite;
  isSiteDeletable: boolean;
  isSiteOnFocus: boolean;
  onClickEditButton(studySite: StudySite): void;
  onClickDeleteButton(studySite: StudySite): void;
  onClickArchiveButton(studySite: StudySite): void;
}

const StudySiteRow: React.FC<StudySiteRowProps> = ({
  isEditable,
  studySite,
  isSiteDeletable,
  isSiteOnFocus,
  onClickEditButton,
  onClickDeleteButton,
  onClickArchiveButton,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [optionMenuHasFocus, setOptionMenuHasFocus] = useState(false);

  const handleClickMoreVertButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOptionMenuHasFocus(false);
    event.stopPropagation();
  };

  const handleMoreVertMenuClose = (event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const iconButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isSiteOnFocus && iconButtonRef.current) {
      iconButtonRef.current.focus();
    }
  }, [isSiteOnFocus]);

  // Allow users to use 'TAB' key to exit the option menu popup
  const handlePopoverKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (optionMenuHasFocus && e.key === "Tab") {
        e.preventDefault();
        setAnchorEl(null);
      }
    },
    [optionMenuHasFocus]
  );

  return (
    <TableRow>
      <TableCell width="20%" sx={{ paddingLeft: "16px" }}>
        {studySite.getSiteName()}
      </TableCell>
      <TableCell width="20%" sx={{ paddingLeft: "16px" }}>
        {studySite.getSiteNumber() ? studySite.getSiteNumber() : "--"}
      </TableCell>
      <TableCell width="20%" sx={{ paddingLeft: "16px" }}>
        {studySite.getPrincipalInvestigator()
          ? studySite.getPrincipalInvestigator()
          : "--"}
      </TableCell>
      <TableCell width="20%" sx={{ paddingLeft: "16px" }}>
        {studySite.getCountryCode()
          ? toCountry(studySite.getCountryCode())
          : "--"}
      </TableCell>
      <TableCell width="20%" sx={{ paddingLeft: "16px" }}>
        {studySite.getStatus() === 1 /* Archived */ && (
          <Box>
            <Tag color="neutral" label="Archived" />
            <Typography variant="caption" sx={{ marginTop: "12px" }}>
              As of{" "}
              {fromDateProto(studySite.getArchivedDate()!!).toLocaleDateString(
                "en-US",
                { year: "numeric", month: "2-digit", day: "2-digit" }
              )}
            </Typography>
          </Box>
        )}
        {studySite.getStatus() === 0 && <Tag color="info" label="Active" />}
      </TableCell>
      {isEditable && (
        <TableCell width="64px">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              ...sxFocusWithin,
            }}
          >
            {studySite.getStatus() === 0 /* active */ && (
              <Tooltip title="More actions">
                <IconButton
                  onClick={handleClickMoreVertButton}
                  ref={iconButtonRef}
                >
                  <MoreVertIcon
                    titleAccess={`More site management tasks for ${studySite.getSiteName()}`}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Popover
              id="task-status-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMoreVertMenuClose}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onKeyDown={handlePopoverKeyDown}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: theme.shadows,
                },
              }}
            >
              <MenuList>
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "white",
                    },
                  }}
                  onFocus={() => {
                    setOptionMenuHasFocus(true);
                  }}
                  onClick={(event) => {
                    handleMoreVertMenuClose(event);
                    onClickEditButton(studySite);
                  }}
                >
                  Edit site details
                </MenuItem>
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "white",
                    },
                  }}
                  onFocus={() => {
                    setOptionMenuHasFocus(true);
                  }}
                  onClick={(event) => {
                    handleMoreVertMenuClose(event);
                    isSiteDeletable
                      ? onClickDeleteButton(studySite)
                      : onClickArchiveButton(studySite);
                  }}
                >
                  {isSiteDeletable ? "Delete site" : "Archive site"}
                </MenuItem>
              </MenuList>
            </Popover>
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

interface StudySitesTableContentProps {
  isEditable: boolean;
  studySites: Array<StudySite>;
  nonDeletableStudySites: Array<StudySite>;
  siteOnFocus: String;
  onClickEditButton(studySite: StudySite): void;
  onClickDeleteButton(studySite: StudySite): void;
  onClickArchiveButton(studySite: StudySite): void;
}

const StudySitesTableContent: React.FC<StudySitesTableContentProps> = ({
  isEditable,
  studySites,
  nonDeletableStudySites,
  siteOnFocus,
  onClickEditButton,
  onClickDeleteButton,
  onClickArchiveButton,
}) => {
  return (
    <TableBody>
      {studySites.map((studySite) => (
        <StudySiteRow
          key={studySite.getName()}
          isEditable={isEditable}
          studySite={studySite}
          isSiteDeletable={
            /* Not in the nonDeletableStudySites */
            nonDeletableStudySites.filter(
              (s) => s.getName() === studySite.getName()
            ).length === 0
          }
          isSiteOnFocus={studySite.getName() === siteOnFocus}
          onClickEditButton={onClickEditButton}
          onClickDeleteButton={onClickDeleteButton}
          onClickArchiveButton={onClickArchiveButton}
        />
      ))}
    </TableBody>
  );
};

interface StudySitesTableControlProps {
  isEditable: boolean;
  focusOnAddStudySiteButton: boolean;
  searchText: string;
  selectedCountry: string[];
  selectedStatus: string[];

  onRequestSearch(searchText: string): void;
  onClickAddStudySiteButton(): void;
  onCountrySelected(selectedCountry: string[]): void;
  onStatusSelected(selectedStatus: string[]): void;
}

const StudySitesTableControl: React.FC<StudySitesTableControlProps> = ({
  isEditable,
  focusOnAddStudySiteButton,
  searchText,
  selectedCountry,
  selectedStatus,
  onRequestSearch,
  onClickAddStudySiteButton,
  onCountrySelected,
  onStatusSelected,
}) => {
  const countryOptions = useMemo<Array<{ label: string; value: string }>>(
    () =>
      countries.map((country) => ({
        label: country,
        value: country,
      })),
    []
  );

  const statusOptions = useMemo<Array<{ label: string; value: string }>>(
    () => [
      { label: "Active", value: "Active" },
      { label: "Archived", value: "Archived" },
    ],
    []
  );

  const handleCountrysSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    onCountrySelected(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  const handleStatusSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    onStatusSelected(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  const addSiteButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnAddStudySiteButton && addSiteButtonRef.current) {
      addSiteButtonRef.current.focus();
    }
  }, [focusOnAddStudySiteButton]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingLeft: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Search
          placeHolder="Search"
          aria-label={searchText}
          textFieldProps={{
            defaultValue: searchText,
            showClearInputButton: false,
            onChange: (e) => {
              onRequestSearch(e.target.value);
            },
          }}
          fullWidth={false}
          sx={{ width: "300px" }}
        />
        <Box sx={{ marginLeft: "16px" }}>
          <MultipleSelectFilterChip
            placeholder="Country"
            value={selectedCountry}
            onChange={handleCountrysSelected}
            options={countryOptions}
            ariaLabel={"Filter by country"}
          />
        </Box>
        <Box sx={{ marginLeft: "16px" }}>
          <MultipleSelectFilterChip
            placeholder="Status"
            value={selectedStatus}
            onChange={handleStatusSelected}
            options={statusOptions}
            ariaLabel={"Filter by status"}
          />
        </Box>
      </Box>

      {isEditable && (
        <Button
          label="Add Site"
          ref={addSiteButtonRef}
          variant="filled"
          icon={<AddIcon />}
          iconPosition="start"
          onClick={onClickAddStudySiteButton}
          sx={{
            marginLeft: "16px",
            width: "120px",
          }}
        />
      )}
    </Box>
  );
};

function getStudySiteIndex(
  studySites: Array<StudySite>,
  studySiteName: string
) {
  for (let i = 0; i < studySites.length; i++) {
    if (studySites[i].getName() === studySiteName) {
      return i;
    }
  }
  return -1;
}

export function restoreStudySiteRecordPosition(
  studySites: Array<StudySite>,
  studySiteName: string,
  originalIndex: number
) {
  const index = getStudySiteIndex(studySites, studySiteName);
  if (index >= 0) {
    // Remove study site from its current sorted position
    const removedStudySites = studySites.splice(index, 1);

    // Add study site to its original position.
    studySites.splice(originalIndex, 0, ...removedStudySites);
  }
}

const StudySites: React.FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const currentUserRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );
  const hasCdmRole = hasAnyRole(currentUserRoles, Role.CDM);

  const {
    isLoading,
    hasError,
    studySites,
    nonDeleteableStudySites,
    loadStudySites,
  } = useStudySitesDataLoader();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchText, setSearchText] = React.useState("");

  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const [showCreateStudySiteDialog, setShowCreateStudySiteDialog] =
    useState(false);
  const [focusOnCreateStudySiteButton, setFocusOnCreateStudySiteButton] =
    useState(false);
  const [fromCreateStudySiteButton, setFromCreateStudySiteButton] =
    useState(false);

  const studySiteToEdit = useRef(new StudySite());

  const [showEditStudySiteDialog, setShowEditStudySiteDialog] = useState(false);
  const [fromEditStudySiteDialog, setFromEditStudySiteDialog] = useState(false);

  // Stored the edited study site index in the filteredAndSortedRecords in order
  // to keep its current position after editing.
  const editedStudySiteIndex = useRef(-1);

  const studySiteToDelete = useRef(new StudySite());
  const [showDeleteStudySiteDialog, setShowDeleteStudySiteDialog] =
    useState(false);
  const [fromDeleteStudySiteDialog, setFromDeleteStudySiteDialog] =
    useState(false);

  const [showArchiveStudySiteDialog, setShowArchiveStudySiteDialog] =
    useState(false);
  const [fromArchiveStudySiteDialog, setFromArchiveStudySiteDialog] =
    useState(false);

  const [siteOnFocus, setSiteOnFocus] = useState("");

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");

  const [filteredAndSortedRecords, setFilteredAndSortedRecords] = useState(
    new Array<StudySite>()
  );

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  // Stores previous search string.
  const previousSearch = useRef("");

  const resetEditedStudySiteIndex = () => {
    editedStudySiteIndex.current = -1;
  };

  useEffect(() => {
    resetEditedStudySiteIndex();
  }, []);

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate filtered and sorted records.
  useEffect(() => {
    const records = getFilteredAndSortedRecords(
      studySites,
      searchText,
      selectedCountry,
      selectedStatus,
      orderBy,
      order,
      editedStudySiteIndex.current,
      studySiteToEdit.current.getName()
    );
    setFilteredAndSortedRecords(records);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(pluralize("search result", records.length, true));
      previousSearch.current = searchText;
    }
  }, [order, orderBy, searchText, selectedCountry, selectedStatus, studySites]);

  // Set page title.
  usePageTitle("Manage sites");

  useEffect(() => {
    loadStudySites(currentRegistryId);
  }, [currentRegistryId, loadStudySites]);

  // Set the focus on the add site button if the screen reloads after a successful submit.
  useEffect(() => {
    if (
      !isLoading &&
      (fromCreateStudySiteButton ||
        fromEditStudySiteDialog ||
        fromDeleteStudySiteDialog ||
        fromArchiveStudySiteDialog)
    ) {
      // Add 500ms delay so that the success message is announced first.
      const timeoutId = setTimeout(() => {
        if (
          fromCreateStudySiteButton ||
          fromEditStudySiteDialog ||
          fromDeleteStudySiteDialog ||
          fromArchiveStudySiteDialog
        ) {
          setShowSnackBar(true);
          // Don't show the snackbar again if the page reloads for some reason.
          setFromCreateStudySiteButton(false);
          setFromEditStudySiteDialog(false);
          setFromDeleteStudySiteDialog(false);
          setFromArchiveStudySiteDialog(false);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [
    isLoading,
    fromCreateStudySiteButton,
    fromEditStudySiteDialog,
    fromDeleteStudySiteDialog,
    fromArchiveStudySiteDialog,
  ]);

  return (
    <>
      <div className="main-content-page">
        <Typography variant="display5" component="h1">
          Manage sites
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "left",
            width: "100%",
            marginTop: "32px",
          }}
        >
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <>
              <A11yStatusMessage message={searchResultMessage} />
              <StudySitesTableControl
                isEditable={hasCdmRole}
                focusOnAddStudySiteButton={focusOnCreateStudySiteButton}
                searchText={searchText}
                selectedCountry={selectedCountry}
                selectedStatus={selectedStatus}
                onRequestSearch={(searchText) => {
                  resetEditedStudySiteIndex();
                  setSearchText(searchText);
                  setPage(0);
                }}
                onClickAddStudySiteButton={() => {
                  resetEditedStudySiteIndex();
                  setShowCreateStudySiteDialog(true);
                }}
                onCountrySelected={(selectedCountry) => {
                  resetEditedStudySiteIndex();
                  setSelectedCountry(selectedCountry);
                  setPage(0);
                }}
                onStatusSelected={(selectedStatus) => {
                  resetEditedStudySiteIndex();
                  setSelectedStatus(selectedStatus);
                  setPage(0);
                }}
              />
              <TableContainer sx={{ marginY: "8px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="Study sites table">
                  <StudySitesTableHeader
                    isEditable={hasCdmRole}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(orderBy) => {
                      resetEditedStudySiteIndex();
                      handleRequestSort(orderBy);
                    }}
                  />
                  {!hasError && (
                    <StudySitesTableContent
                      isEditable={hasCdmRole}
                      studySites={filteredAndSortedRecords.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )}
                      nonDeletableStudySites={nonDeleteableStudySites}
                      onClickEditButton={(studySite) => {
                        resetEditedStudySiteIndex();
                        studySiteToEdit.current = studySite;
                        setShowEditStudySiteDialog(true);
                      }}
                      onClickDeleteButton={(studySite) => {
                        resetEditedStudySiteIndex();
                        studySiteToDelete.current = studySite;
                        setShowDeleteStudySiteDialog(true);
                      }}
                      onClickArchiveButton={(studySite) => {
                        resetEditedStudySiteIndex();
                        // Store the value in the same studySiteToEdit ref to keep
                        // the archived record sort position.
                        studySiteToEdit.current = studySite;
                        setShowArchiveStudySiteDialog(true);
                      }}
                      siteOnFocus={siteOnFocus}
                    />
                  )}
                  {hasError && (
                    <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                      Failed to load study sites
                    </Alert>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredAndSortedRecords.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ marginRight: "96px", ...tablePaginationSxProps }}
              />
            </>
          )}
        </Box>
      </div>
      <A11yStatusMessage message={sortDirectiontMessage} />
      <CreateStudySiteDialog
        open={showCreateStudySiteDialog}
        registryId={currentRegistryId}
        studySites={studySites}
        onClose={() => {
          setShowCreateStudySiteDialog(false);
        }}
        onSuccess={() => {
          setShowCreateStudySiteDialog(false);
          setFromCreateStudySiteButton(true);
          setFocusOnCreateStudySiteButton(true);
          loadStudySites(currentRegistryId);
          setSnackBarText("Site added");
        }}
      />
      <EditStudySiteDialog
        open={showEditStudySiteDialog}
        studySite={studySiteToEdit.current}
        studySites={studySites}
        onClose={() => {
          setShowEditStudySiteDialog(false);
        }}
        onSuccess={() => {
          setShowEditStudySiteDialog(false);
          setFromEditStudySiteDialog(true);
          editedStudySiteIndex.current = getStudySiteIndex(
            filteredAndSortedRecords,
            studySiteToEdit.current.getName()
          );
          loadStudySites(currentRegistryId);
          setSnackBarText("Site details updated");
          setSiteOnFocus(studySiteToEdit.current.getName());
        }}
      />
      <DeleteStudySiteDialog
        open={showDeleteStudySiteDialog}
        studySite={studySiteToDelete.current}
        onClose={() => {
          setShowDeleteStudySiteDialog(false);
        }}
        onSuccess={() => {
          setShowDeleteStudySiteDialog(false);
          setFromDeleteStudySiteDialog(true);
          setFocusOnCreateStudySiteButton(true);
          loadStudySites(currentRegistryId);
          setSnackBarText("Site deleted");
        }}
      />
      <ArchiveStudySiteDialog
        open={showArchiveStudySiteDialog}
        studySite={studySiteToEdit.current}
        onClose={() => {
          setShowArchiveStudySiteDialog(false);
        }}
        onSuccess={() => {
          setShowArchiveStudySiteDialog(false);
          setFromArchiveStudySiteDialog(true);
          editedStudySiteIndex.current = getStudySiteIndex(
            filteredAndSortedRecords,
            studySiteToEdit.current.getName()
          );
          // Need to focus on create site button since the more options button is hidden
          // after a site is archived.
          setFocusOnCreateStudySiteButton(true);
          loadStudySites(currentRegistryId);
          setSnackBarText("Site archived");
        }}
      />
      <Snackbar
        open={showSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        color="success"
        role="alert"
        onClose={() => {
          setShowSnackBar(false);
          setSnackBarText("");
        }}
        text={snackBarText}
        withIcon={true}
      />
    </>
  );
};

function getFilteredAndSortedRecords(
  studySites: Array<StudySite>,
  searchText: string,
  selectedCountry: string[],
  selectedStatus: string[],
  orderBy: string,
  order: string,
  editedStudySiteIndex: number,
  editedStudySiteName: string
) {
  const studySiteList = studySites
    .filter((studySite) => {
      // Always keep the edited study site.
      if (
        editedStudySiteIndex >= 0 &&
        editedStudySiteName === studySite.getName()
      ) {
        return true;
      }

      if (searchText !== "") {
        const searchTextLowerCase = searchText.toLowerCase();
        if (
          studySite.getSiteName().toLowerCase().indexOf(searchTextLowerCase) ===
            -1 &&
          studySite
            .getSiteNumber()
            .toLowerCase()
            .indexOf(searchTextLowerCase) === -1 &&
          studySite
            .getPrincipalInvestigator()
            .toLowerCase()
            .indexOf(searchTextLowerCase) === -1 &&
          (studySite.getCountryCode() === "" ||
            toCountry(studySite.getCountryCode())
              .toLowerCase()
              .indexOf(searchTextLowerCase) === -1)
        ) {
          return false;
        }
      }

      if (selectedCountry.length !== 0) {
        if (studySite.getCountryCode() == null) {
          return false;
        }
        if (
          selectedCountry.filter(
            (country) => country === toCountry(studySite.getCountryCode())
          ).length === 0
        )
          return false;
      }

      if (selectedStatus.length === 1) {
        if (selectedStatus[0] === "Active" && studySite.getStatus() === 1) {
          return false;
        }
        if (selectedStatus[0] === "Archived" && studySite.getStatus() === 0) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "site name":
          result = a.getSiteName().localeCompare(b.getSiteName());
          break;
        case "site number":
          result = a.getSiteNumber().localeCompare(b.getSiteNumber());
          break;
        case "principal investigator":
          result = a
            .getPrincipalInvestigator()
            .localeCompare(b.getPrincipalInvestigator());
          break;
        case "country":
          result = a.getCountryCode().localeCompare(b.getCountryCode());
          break;
        case "status":
          result = a.getStatus() - b.getStatus();
          break;
      }
      return order === "asc" ? result : -result;
    });

  // Keep position of an edited study site
  if (editedStudySiteIndex >= 0) {
    restoreStudySiteRecordPosition(
      studySiteList,
      editedStudySiteName,
      editedStudySiteIndex
    );

    // Do not reset the editedStudySiteIndex here since this function is called
    // multiple times before the list of study sites are rendered.
  }

  return studySiteList;
}

export default StudySites;
