/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.participantsession.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: participantsession/participantsession.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_type_interval_pb = require('../google/type/interval_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.participantsession = {};
proto.verily.sdp.participantsession.v1alpha1 = require('./participantsession_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.CreateParticipantSessionRequest,
 *   !proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>}
 */
const methodDescriptor_ParticipantSessionService_CreateParticipantSession = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/CreateParticipantSession',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.CreateParticipantSessionRequest,
  proto.verily.sdp.participantsession.v1alpha1.ParticipantSession,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.CreateParticipantSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.participantsession.v1alpha1.ParticipantSession.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.CreateParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.participantsession.v1alpha1.ParticipantSession)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.createParticipantSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/CreateParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_CreateParticipantSession,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.CreateParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.createParticipantSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/CreateParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_CreateParticipantSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.GetParticipantSessionRequest,
 *   !proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>}
 */
const methodDescriptor_ParticipantSessionService_GetParticipantSession = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/GetParticipantSession',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.GetParticipantSessionRequest,
  proto.verily.sdp.participantsession.v1alpha1.ParticipantSession,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.GetParticipantSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.participantsession.v1alpha1.ParticipantSession.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.GetParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.participantsession.v1alpha1.ParticipantSession)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.getParticipantSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/GetParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_GetParticipantSession,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.GetParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.participantsession.v1alpha1.ParticipantSession>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.getParticipantSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/GetParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_GetParticipantSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.DeleteParticipantSessionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ParticipantSessionService_DeleteParticipantSession = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/DeleteParticipantSession',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.DeleteParticipantSessionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.DeleteParticipantSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.DeleteParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.deleteParticipantSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/DeleteParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_DeleteParticipantSession,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.DeleteParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.deleteParticipantSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/DeleteParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_DeleteParticipantSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.EndParticipantSessionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ParticipantSessionService_EndParticipantSession = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/EndParticipantSession',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.EndParticipantSessionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.EndParticipantSessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.EndParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.endParticipantSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/EndParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_EndParticipantSession,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.EndParticipantSessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.endParticipantSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/EndParticipantSession',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_EndParticipantSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsRequest,
 *   !proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse>}
 */
const methodDescriptor_ParticipantSessionService_ListParticipantSessions = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/ListParticipantSessions',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsRequest,
  proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.listParticipantSessions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/ListParticipantSessions',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_ListParticipantSessions,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.participantsession.v1alpha1.ListParticipantSessionsResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.listParticipantSessions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/ListParticipantSessions',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_ListParticipantSessions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.participantsession.v1alpha1.SwapParticipantSessionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ParticipantSessionService_SwapParticipantSessions = new grpc.web.MethodDescriptor(
  '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/SwapParticipantSessions',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.participantsession.v1alpha1.SwapParticipantSessionsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.participantsession.v1alpha1.SwapParticipantSessionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.SwapParticipantSessionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServiceClient.prototype.swapParticipantSessions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/SwapParticipantSessions',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_SwapParticipantSessions,
      callback);
};


/**
 * @param {!proto.verily.sdp.participantsession.v1alpha1.SwapParticipantSessionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.participantsession.v1alpha1.ParticipantSessionServicePromiseClient.prototype.swapParticipantSessions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.participantsession.v1alpha1.ParticipantSessionService/SwapParticipantSessions',
      request,
      metadata || {},
      methodDescriptor_ParticipantSessionService_SwapParticipantSessions);
};


module.exports = proto.verily.sdp.participantsession.v1alpha1;

