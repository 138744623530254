import { Duration } from "date-fns";
import { WearTimeRange } from "looker/CalendarViewQueryResultsCache";
import { CSSProperties } from "react";

// Choose an earliest enough date as the initial value
// to find latest sync date.
export const earliestSyncDate = new Date(2000, 1, 1);

export const tableBorderWidth = 1;

export const tableCellStyle: CSSProperties = {
  borderWidth: tableBorderWidth,
  width: "calc(100%/7)",
  borderCollapse: "collapse",
};

export interface WatchData {
  date: string;
  wearHrs?: number;
  wearTimeRanges?: Array<WearTimeRange>;
  syncs?: number;
  syncTime?: Date;
  surveysPrompted?: number;
  surveysAnswered?: number;
  medicationTags?: number;
}

export interface CalendarDay {
  date: Date;
  outOfMonth?: boolean;
  today?: boolean;
  noData?: boolean;
  awaitingData?: boolean;
  watchWearDuration?: Duration;
  watchWearCompliant?: boolean;
  watchWearTimeRanges?: Array<WearTimeRange>;
  watchSync?: boolean;
  syncTime?: Date;
  surveysPrompted?: number;
  surveysAnswered?: number;
  checkMedicationTag?: boolean;
  medicationTags?: number;
}

export interface MedicationTagSetting {
  frequency: "daily" | "weekly" | undefined;
  requiredCount: number;
}
