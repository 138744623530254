import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookerSdk } from "looker/LookerSdk";
import { updateReportTimePeriod } from "looker/UpdateReportTimePeriod";
import { getParticipants } from "looker/GetParticipants";
import { ComplianceType } from "looker/QueryResultsCache";
import { getParticipantsComplianceRecords } from "looker/GetParticipantsComplianceRecords";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { find } from "lodash";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export enum DeviceStatus {
  Active,
  NoData,
  NoDevice,
}

export enum ComplianceStatus {
  Compliant,
  NonCompliant,
  Unknown,
}

export interface ParticipantComplianceRecord {
  participantId: string;
  subjectId: string;
  deviceId: string;
  deviceStatus: DeviceStatus;
  complianceStatus: ComplianceStatus;
}

export interface AllParticipantsSummary {
  totalEnrolled: number;
  totalActive: number;
  totalNoData: number;
  totalNoDevice: number;
  records: Array<ParticipantComplianceRecord>;
}

// Defines a custom effect to load CRC all participants compliance data.
function useCrcAllParticipantsTabDataLoader() {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<AllParticipantsSummary>({
    totalEnrolled: 0,
    totalActive: 0,
    totalNoData: 0,
    totalNoDevice: 0,
    records: new Array<ParticipantComplianceRecord>(),
  });

  const loadAllParticipantsData = useCallback(
    (currentRegistryId: string, previousWeekOffset: number) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const weekStart = getWeekStartDate(previousWeekOffset);
            const weekEnd = getWeekEndDate(previousWeekOffset);

            // Set report time period.
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            await updateReportTimePeriod(
              auth0Config.audience!,
              token,
              weekStart,
              weekEnd,
              previousWeekOffset
            );

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              currentRegistryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalCompliance: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalCompliance.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalCompliance.push(ComplianceType.MedLogWeekly);
            }

            // Get all participants and compliance records in parallel.
            const [participants, complianceRecords] = await Promise.all([
              getParticipants(sdk, previousWeekOffset, lookerQueryIdsMap),
              getParticipantsComplianceRecords(
                sdk,
                previousWeekOffset,
                optionalCompliance,
                lookerQueryIdsMap
              ),
            ]);

            if (participants.hasError || complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            let totalNoData = 0;
            let totalNoDevice = 0;
            const records = participants.records.map((record) => {
              if (record.deviceId === null) {
                totalNoDevice++;
                return {
                  ...record,
                  deviceStatus: DeviceStatus.NoDevice,
                  complianceStatus: ComplianceStatus.Unknown,
                };
              }

              const compliantRecord = find(
                complianceRecords.compliantRecords,
                record
              );
              if (compliantRecord) {
                return {
                  ...record,
                  deviceStatus: DeviceStatus.Active,
                  complianceStatus: ComplianceStatus.Compliant,
                };
              }

              const nonCompliantRecord = find(
                complianceRecords.noncompliantRecords,
                record
              );
              if (nonCompliantRecord) {
                return {
                  ...record,
                  deviceStatus: DeviceStatus.Active,
                  complianceStatus: ComplianceStatus.NonCompliant,
                };
              }

              totalNoData++;
              return {
                ...record,
                deviceStatus: DeviceStatus.NoData,
                complianceStatus: ComplianceStatus.Unknown,
              };
            });

            let summary = {
              totalEnrolled: participants.records.length,
              totalActive: complianceRecords.activeParticipantsCount,
              totalNoData: totalNoData,
              totalNoDevice: totalNoDevice,
              records: records,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      auth0Config,
      getAccessTokenSilently,
      isAuthenticated,
      lookerQueryIdsMap,
      sdk,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadAllParticipantsData,
  };
}

export default useCrcAllParticipantsTabDataLoader;
