import {
  Box,
  Stack,
  TableCell,
  Tooltip,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import { tableCellStyle } from "./CalendarTypes";
import { getDateAriaLabel } from "./CalendarUtils";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ReactComponent as CheckIcon } from "assets/check-icon-blue.svg";

interface ExitStudyDayCalendarCellProps {
  date: Date;
  participantStatus: StudyParticipant.ParticipantStatus;
  highlight: boolean;
  grey: boolean;
  weekIndex: number;
}

export const ExitStudyDayCalendarCell: React.FC<
  ExitStudyDayCalendarCellProps
> = ({ date, participantStatus, grey, highlight, weekIndex }) => {
  const theme = useTheme();

  // The ref to the TableCell element.
  const cellRef = useRef<HTMLTableCellElement>(null);

  // handleKeyDown and handleClick are stable for the lifetime of the component:
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    cellRef,
    false,
    weekIndex
  );

  // Set focus on the TableCell when needed.
  useFocusEffect(focused, cellRef);

  let dayCircleColor: string | undefined;
  let dayNumberColor = theme.palette.text.emphasized;
  if (highlight) {
    dayCircleColor = theme.palette.primary.main;
    dayNumberColor = theme.palette.common.white;
  }
  if (grey) {
    if (dayCircleColor) {
      dayCircleColor = lighten(dayCircleColor, 0.5);
    }
    dayNumberColor = theme.palette.text.default;
  }

  // Handle navigation to ExitStudyBar
  const [showExitStudyBarFocus, setShowExitStudyBarFocus] = useState(false);
  const [showExitStudyBarTooltip, setShowExitStudyBarTooltip] = useState(false);

  useEffect(() => {
    setShowExitStudyBarFocus(false);
    setShowExitStudyBarTooltip(false);
  }, [date, focused]);

  // Returns whether the keyboard event is handled and the focus should still be
  // inside the table cell.
  const handleIconNavigation = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "ArrowRight") {
      if (!showExitStudyBarFocus) {
        setShowExitStudyBarFocus(true);
        return true;
      }

      if (!showExitStudyBarTooltip) {
        setShowExitStudyBarTooltip(true);
        return true;
      }

      setShowExitStudyBarTooltip(false);
      return false;
    }

    setShowExitStudyBarFocus(false);
    setShowExitStudyBarTooltip(false);
    return false;
  };

  // Do not show the compliance icons and colors for exit study date.
  // Otherwise, these icons will overlap with exit study icon.
  return (
    <TableCell
      role="gridcell"
      ref={cellRef}
      tabIndex={tabIndex}
      onKeyDown={(event) => {
        const handled = handleIconNavigation(event);
        if (handled) {
          return;
        }
        handleKeyDown(event);
      }}
      onClick={handleClick}
      sx={{
        ...tableCellStyle,
        padding: "0px",
        borderColor: theme.palette.background.separator,
      }}
    >
      <Stack
        width="100%"
        height="100%"
        minHeight="100px"
        justifyContent="space-between"
        sx={{ padding: "8px" }}
      >
        <Box
          alignSelf="end"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={theme.spacing(3)}
          height={theme.spacing(3)}
          borderRadius="100%"
          bgcolor={dayCircleColor}
          sx={{
            marginBottom: "16px",
          }}
        >
          <Typography
            aria-label={getDateAriaLabel(date)}
            lineHeight="100%"
            color={dayNumberColor}
            fontSize={12}
            variant={grey ? "overline" : "overlineem"}
          >
            {date.getDate()}
          </Typography>
        </Box>
        <ExitStudyBar
          participantStatus={participantStatus}
          showFocus={showExitStudyBarFocus}
          showTooltip={showExitStudyBarTooltip}
        />
      </Stack>
    </TableCell>
  );
};

interface ExitStudyBarProps {
  participantStatus: StudyParticipant.ParticipantStatus;
  showFocus: boolean;
  showTooltip: boolean;
}

const ExitStudyBar: React.FC<ExitStudyBarProps> = ({
  participantStatus,
  showFocus,
  showTooltip,
}) => {
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (boxRef.current != null) {
      if (showFocus) {
        boxRef.current.focus();
      } else {
        boxRef.current.blur();
      }
    }
  }, [showFocus]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    setTooltipOpen(showTooltip);
  }, [showTooltip]);

  const theme = useTheme();
  return (
    <Tooltip
      open={tooltipOpen}
      aria-live="polite"
      title={
        <Stack>
          <Typography color="white" variant="body2">
            {participantStatus ===
            StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED
              ? "Participant completed the study"
              : "Participant withdrew from the study"}
          </Typography>
        </Stack>
      }
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.background.contrast,
            width: "200px",
          },
        },
      }}
      placement="top"
    >
      <Box
        ref={boxRef}
        tabIndex={showFocus ? 0 : -1}
        width="100%"
        borderRadius="4px"
        bgcolor={theme.palette.info.background}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingY: "4px",
          "&:hover": {
            boxShadow: theme.shadows,
            border: "2px solid",
            borderColor: theme.palette.info.divider,
          },
        }}
        onMouseEnter={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
      >
        {
          /* Show study completed icon */
          participantStatus ===
            StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED && (
            <Box
              arial-hidden="true"
              sx={{
                position: "relative",
                marginLeft: "8px",
                marginRight: "12px",
              }}
            >
              <Box position="absolute" top={-8} right={-8}>
                <CheckIcon width={12} />
              </Box>
              <PersonIcon
                sx={{
                  fontSize: "16px",
                  color: theme.palette.info.textOnBackground,
                }}
              />
            </Box>
          )
        }
        {
          /* Show study withdrawn icon */
          participantStatus ===
            StudyParticipant.ParticipantStatus.EXIT_STUDY_WITHDRAWN && (
            <PersonOffIcon
              arial-hidden="true"
              sx={{
                fontSize: "16px",
                marginLeft: "8px",
                marginRight: "8px",
                color: theme.palette.info.textOnBackground,
              }}
            />
          )
        }
        <Typography
          variant="captionem"
          sx={{
            color: theme.palette.info.textOnBackground,
            width: "120px",
          }}
        >
          {participantStatus ===
          StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED
            ? "Participant completed"
            : "Participant withdrawn"}
        </Typography>
      </Box>
    </Tooltip>
  );
};
