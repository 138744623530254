// source: studyparticipant/studyparticipant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus', null, global);
goog.exportSymbol('proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.displayName = 'proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    registryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sponsorParticipantId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    studySiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enrollmentStartTime: (f = msg.getEnrollmentStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enrollmentStartDate: (f = msg.getEnrollmentStartDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    enrollmentEndTime: (f = msg.getEnrollmentEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enrollmentEndDate: (f = msg.getEnrollmentEndDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    verilyMeUserId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deviceStartTime: (f = msg.getDeviceStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 14, 0),
    exitStudyDate: (f = msg.getExitStudyDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant;
  return proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsorParticipantId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudySiteId(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnrollmentStartTime(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEnrollmentStartDate(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnrollmentEndTime(value);
      break;
    case 17:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEnrollmentEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerilyMeUserId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeviceStartTime(value);
      break;
    case 14:
      var value = /** @type {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 15:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExitStudyDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSponsorParticipantId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStudySiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnrollmentStartTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnrollmentStartDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEnrollmentEndTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnrollmentEndDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVerilyMeUserId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeviceStartTime();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getExitStudyDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus = {
  UNKNOWN: 0,
  ENROLLED: 1,
  EXIT_STUDY_COMPLETED: 2,
  EXIT_STUDY_WITHDRAWN: 3
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string registry_id = 2;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sponsor_participant_id = 3;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getSponsorParticipantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setSponsorParticipantId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string study_site_id = 4;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getStudySiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setStudySiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp enrollment_start_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getEnrollmentStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setEnrollmentStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearEnrollmentStartTime = function() {
  return this.setEnrollmentStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasEnrollmentStartTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.type.Date enrollment_start_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getEnrollmentStartDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setEnrollmentStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearEnrollmentStartDate = function() {
  return this.setEnrollmentStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasEnrollmentStartDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp enrollment_end_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getEnrollmentEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setEnrollmentEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearEnrollmentEndTime = function() {
  return this.setEnrollmentEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasEnrollmentEndTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.type.Date enrollment_end_date = 17;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getEnrollmentEndDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 17));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setEnrollmentEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearEnrollmentEndDate = function() {
  return this.setEnrollmentEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasEnrollmentEndDate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string middle_name = 6;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string last_name = 7;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email_address = 8;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string verily_me_user_id = 10;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getVerilyMeUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setVerilyMeUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string device_id = 13;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp device_start_time = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getDeviceStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setDeviceStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearDeviceStartTime = function() {
  return this.setDeviceStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasDeviceStartTime = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ParticipantStatus status = 14;
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getStatus = function() {
  return /** @type {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.ParticipantStatus} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional google.type.Date exit_study_date = 15;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.getExitStudyDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 15));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.setExitStudyDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.clearExitStudyDate = function() {
  return this.setExitStudyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.prototype.hasExitStudyDate = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyParticipant: (f = msg.getStudyParticipant()) && proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant;
      reader.readMessage(value,proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinaryFromReader);
      msg.setStudyParticipant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyParticipant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudyParticipant study_participant = 1;
 * @return {?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.getStudyParticipant = function() {
  return /** @type{?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant, 1));
};


/**
 * @param {?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.setStudyParticipant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.clearStudyParticipant = function() {
  return this.setStudyParticipant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest.prototype.hasStudyParticipant = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyParticipant: (f = msg.getStudyParticipant()) && proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant;
      reader.readMessage(value,proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinaryFromReader);
      msg.setStudyParticipant(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyParticipant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional StudyParticipant study_participant = 1;
 * @return {?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.getStudyParticipant = function() {
  return /** @type{?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant, 1));
};


/**
 * @param {?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.setStudyParticipant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.clearStudyParticipant = function() {
  return this.setStudyParticipant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.hasStudyParticipant = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string update_reason = 3;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataDeletionRequested: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDataDeletionRequested(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataDeletionRequested();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool data_deletion_requested = 3;
 * @return {boolean}
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.getDataDeletionRequested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest.prototype.setDataDeletionRequested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sponsorParticipantId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsorParticipantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSponsorParticipantId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sponsor_participant_id = 2;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.getSponsorParticipantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest.prototype.setSponsorParticipantId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studySiteId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest;
  return proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudySiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudySiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string study_site_id = 1;
 * @return {string}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.prototype.getStudySiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest.prototype.setStudySiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyParticipantsList: jspb.Message.toObjectList(msg.getStudyParticipantsList(),
    proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse;
  return proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant;
      reader.readMessage(value,proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinaryFromReader);
      msg.addStudyParticipants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudyParticipant study_participants = 1;
 * @return {!Array<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.getStudyParticipantsList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>} value
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse} returns this
*/
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.setStudyParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant}
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.addStudyParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse} returns this
 */
proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.prototype.clearStudyParticipantsList = function() {
  return this.setStudyParticipantsList([]);
};


goog.object.extend(exports, proto.verily.sdp.studyparticipant.v1alpha1);
