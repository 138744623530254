import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";
import { toDateProto } from "common/Dates";
import { UpdateReportTimePeriodRequest } from "generated/studydata/studydata_pb";
import {
  defaultPreviousWeekOffset,
  getPreviousWeekOffsetCache,
  setPreviousWeekOffsetCache,
} from "./SettingsCache";

export async function updateReportTimePeriod(
  apiEndpoint: string,
  auth0AccessToken: string,
  weekStart: Date,
  weekEnd: Date,
  previousWeekOffset: number
) {
  // Bypass the update if we already set the time period for
  // for the previousWeekOffset.
  if (getPreviousWeekOffsetCache() === previousWeekOffset) {
    return;
  }

  const studyDataClient = new StudyDataServiceApiClient(
    apiEndpoint,
    auth0AccessToken
  );

  const updateTimePeriodRequest = new UpdateReportTimePeriodRequest()
    .setStartDate(toDateProto(weekStart))
    .setEndDate(toDateProto(weekEnd));

  try {
    await studyDataClient.updateReportTimePeriod(updateTimePeriodRequest);
  } catch (error) {
    // There is an error. We don't know whether the report time period is
    // updated or now. So reset the cached value to allow any update to
    // go through.
    setPreviousWeekOffsetCache(defaultPreviousWeekOffset);
    throw error;
  }

  setPreviousWeekOffsetCache(previousWeekOffset);
}
