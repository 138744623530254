import { DialogTitle, SxProps } from "@mui/material";
import React, { useCallback } from "react";

interface A11yDialogTitleProps {
  children: React.ReactNode;
  component?: React.ElementType<any>;
  sx?: SxProps;
}

// DialogTitle which focuses on itself when the parent dialog is opened.
const A11yDialogTitle: React.FC<A11yDialogTitleProps> = ({
  children,
  component,
  sx,
}) => {
  // Use a callback ref to access the DialogTitle.
  // MUI dialog is rendered in a portal component and may not exist when its parent
  // component is first rendered.
  // A callback ref provide a function that React will call when the DialogTitle actually
  // appears in the DOM. This gives a reliable way to access the DialogTitle element.
  const handleRef = useCallback((node) => {
    // Node is not null when the dialog is opened.
    if (node !== null) {
      node.focus();
    }
  }, []);

  // Set tabIndex={-1} so that DialogTitle can receive focus programmatically
  // but also removed from tab order.
  return (
    <DialogTitle
      ref={handleRef}
      component={component === undefined ? "h1" : component}
      tabIndex={-1}
      sx={sx}
    >
      {children}
    </DialogTitle>
  );
};

export default A11yDialogTitle;
