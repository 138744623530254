import { Metadata } from "grpc-web";
import { StudyParticipantServicePromiseClient } from "generated/studyparticipant/studyparticipant_grpc_web_pb";
import {
  CreateStudyParticipantRequest,
  DeleteStudyParticipantRequest,
  GetStudyParticipantRequest,
  ListStudyParticipantsRequest,
  LookupSponsorParticipantRequest,
  StudyParticipant,
  UpdateStudyParticipantRequest,
} from "generated/studyparticipant/studyparticipant_pb";

/**
 * Defines helper methods to call StudyParticipantService.
 */
export class StudyParticipantServiceApiClient {
  private gRpcClient: StudyParticipantServicePromiseClient;

  /**
   * Creates an instance of StudyParticipantServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new StudyParticipantServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createStudyParticipant(request: CreateStudyParticipantRequest) {
    return this.gRpcClient.createStudyParticipant(
      request,
      this.getGrpcMetaData()
    );
  }

  updateStudyParticipant(request: UpdateStudyParticipantRequest) {
    return this.gRpcClient.updateStudyParticipant(
      request,
      this.getGrpcMetaData()
    );
  }

  getStudyParticipant(request: GetStudyParticipantRequest) {
    return this.gRpcClient.getStudyParticipant(request, this.getGrpcMetaData());
  }

  lookupSponsorParticipant(request: LookupSponsorParticipantRequest) {
    return this.gRpcClient.lookupSponsorParticipant(
      request,
      this.getGrpcMetaData()
    );
  }

  deleteStudyParticipant(request: DeleteStudyParticipantRequest) {
    return this.gRpcClient.deleteStudyParticipant(
      request,
      this.getGrpcMetaData()
    );
  }

  listStudyParticipants(studySiteId: string) {
    const request = new ListStudyParticipantsRequest().setStudySiteId(
      studySiteId
    );
    return this.gRpcClient.listStudyParticipants(
      request,
      this.getGrpcMetaData()
    );
  }
}

export function isStudyParticipantEmpty(studyParticipant: StudyParticipant) {
  return studyParticipant.toArray().length === 0;
}
