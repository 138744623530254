import { FC, useEffect } from "react";
import { Alert, Box, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import WeekNavigation from "core/WeekNavigation";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  StudySummaryRecord,
  useStudySummaryTabDataLoader,
} from "./StudySummaryTabDataLoader";
import A11yInfoTooltip from "components/A11yInfoTooltip";

export const compliantColor = "#1A468F";
export const noncompliantColor = "#A6C9DE";

interface ComplianceMetricCardProps {
  total: number;
  description: string;
  tooltipLabel: string;
  tooltipLine1: string;
  tooltipLine2?: string;
}

const ComplianceMetricCard: React.FC<ComplianceMetricCardProps> = ({
  total,
  description,
  tooltipLabel,
  tooltipLine1,
  tooltipLine2,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "24px",
          justifyContent: "space-between",
          alignItems: "top",
        }}
      >
        <Box
          aria-label={description + " " + total}
          sx={{ display: "flex", flexDirection: "column", marginRight: "16px" }}
        >
          <Typography aria-hidden variant="body1">
            {description}
          </Typography>
          <Typography aria-hidden variant="display4" sx={{ marginTop: "12px" }}>
            {total}
          </Typography>
        </Box>
        <A11yInfoTooltip
          ariaLabel={tooltipLabel}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "220px",
                padding: "4px",
              }}
            >
              <Typography
                variant="caption"
                color={theme.palette.primary.textOnMain}
                sx={{ opacity: 0.87 }}
              >
                {tooltipLine1}
              </Typography>
              {tooltipLine2 && (
                <Typography
                  variant="caption"
                  color={theme.palette.primary.textOnMain}
                  sx={{ opacity: 0.87, marginTop: "16px" }}
                >
                  {tooltipLine2}
                </Typography>
              )}
            </Box>
          }
        />
      </Box>
    </>
  );
};

interface ParticipantOverviewCardProps {
  summary: StudySummaryRecord;
}

const ParticipantOverviewCard: React.FC<ParticipantOverviewCardProps> = ({
  summary,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "400px",
        height: "100%",
        padding: "24px",
        background: theme.palette.background.canvas,
        borderRadius: "16px",
      }}
    >
      <Typography component="h2" variant="overlineem">
        PARTICIPANT OVERVIEW
      </Typography>
      <ComplianceMetricCard
        total={summary.totalEnrolled}
        description="Total enrolled participants"
        tooltipLabel="Total enrolled participants tooltip"
        tooltipLine1="Total enrolled participants across all sites"
      />
      <ComplianceMetricCard
        total={summary.totalActive}
        description="Participants with active watches"
        tooltipLabel="Participants with active watches tooltip"
        tooltipLine1="Active watches are collecting data from participants"
      />
      <ComplianceMetricCard
        total={summary.totalNoData}
        description="Watches pending first sync"
        tooltipLabel="Watches pending first sync tooltip"
        tooltipLine1="Watches have been assigned to participants, but are awaiting first sync to begin collecting data."
        tooltipLine2="These may be new or replacement watches given to participants."
      />
      <ComplianceMetricCard
        total={summary.totalNoDevice}
        description="No watch assigned"
        tooltipLabel="No watch assigned tooltip"
        tooltipLine1="Participants are enrolled but have not been assigned watches."
        tooltipLine2="These may be newly enrolled participants awaiting watches, or participants who are awaiting watch replacements."
      />
    </Box>
  );
};

function getCompliantPercentage(summary: StudySummaryRecord) {
  let percentage = 0;
  if (summary.totalActive > 0) {
    percentage = Math.round(
      ((summary.totalCompliant * 1.0) / summary.totalActive) * 100
    );
  }
  return `${percentage}%`;
}

function getComplianceSummary(summary: StudySummaryRecord) {
  return `${getCompliantPercentage(summary)} (${summary.totalCompliant}/${
    summary.totalActive
  }) of participants with active watches were compliant`;
}

function getComplianceSummaryA11yLabel(summary: StudySummaryRecord) {
  return `${summary.totalCompliant} of ${
    summary.totalActive
  } participants were compliant and ${
    summary.totalActive - summary.totalCompliant
  } of ${summary.totalActive} participants were noncompliant`;
}

interface ComplianceChartLegendProps {
  summary: StudySummaryRecord;
}

const ComplianceChartLegend: React.FC<ComplianceChartLegendProps> = ({
  summary,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <Typography variant="overline">LEGEND</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle cx="8" cy="8" r="8" fill={compliantColor} />
        </svg>
        <Typography component="h4" variant="body1" sx={{ marginLeft: "12px" }}>
          {`Compliant participants (${summary.totalCompliant})`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          marginTop: "12px",
        }}
      >
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle
            cx="8"
            cy="8"
            r="7"
            fill={noncompliantColor}
            stroke={compliantColor}
            stroke-width="2"
          />
        </svg>
        <Typography component="h4" variant="body1" sx={{ marginLeft: "12px" }}>
          {`Non-compliant participants (${
            summary.totalActive - summary.totalCompliant
          })`}
        </Typography>
      </Box>
    </Box>
  );
};

interface AverageComplianceCardProps {
  summary: StudySummaryRecord;
}

const ComplianceSummaryCard: React.FC<AverageComplianceCardProps> = ({
  summary,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        minWidth: "600px",
        padding: "24px",
        background: theme.palette.background.canvas,
        borderRadius: "16px",
      }}
    >
      <Typography component="h2" variant="overlineem">
        Compliance summary
      </Typography>
      <Typography component="h3" variant="display6" sx={{ marginTop: "16px" }}>
        {getComplianceSummary(summary)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "start",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box
            aria-label={getComplianceSummaryA11yLabel(summary)}
            role="img"
            sx={{
              width: "400px",
              height: "400px",
            }}
          >
            <PieChart
              aria-hidden="true"
              colors={[compliantColor, noncompliantColor]}
              series={[
                {
                  data: [
                    { id: 0, value: summary.totalCompliant },
                    {
                      id: 1,
                      value: summary.totalActive - summary.totalCompliant,
                    },
                  ],
                  innerRadius: "100px",
                  outerRadius: "140px",
                  paddingAngle: 0,
                  cornerRadius: 0,
                  startAngle: 0,
                  endAngle: 360,
                  cx: "200px",
                  cy: "200px",
                },
              ]}
            />
          </Box>
          <Box
            aria-hidden="true"
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="display3" sx={{ fontFamily: "Poppins" }}>
              {getCompliantPercentage(summary)}
            </Typography>
          </Box>
        </Box>
        <ComplianceChartLegend summary={summary} />
      </Box>
    </Box>
  );
};

const StudySummaryTab: FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );
  const dispatch = useAppDispatch();

  const { isLoading, hasError, summary, loadStudySummary } =
    useStudySummaryTabDataLoader();

  // Reload data if currently selected study or week changes.
  useEffect(() => {
    loadStudySummary(currentRegistryId, previousWeekOffset);
  }, [currentRegistryId, previousWeekOffset, loadStudySummary]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <WeekNavigation
        enabled={!isLoading}
        previousWeekOffset={previousWeekOffset}
        onClickPreviousWeek={() => {
          dispatch(setPreviousWeekOffset(previousWeekOffset + 1));
        }}
        onClickNextWeek={() => {
          dispatch(setPreviousWeekOffset(previousWeekOffset - 1));
        }}
      />
      {isLoading && <SlowLoadingIndicator />}
      {!isLoading && hasError && (
        <Alert severity="error" sx={{ paddingLeft: "16px" }}>
          Failed to study summary data
        </Alert>
      )}
      {!isLoading && !hasError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              marginRight: "24px",
              flexGrow: 1,
            }}
          >
            <ComplianceSummaryCard summary={summary} />
          </Box>
          <ParticipantOverviewCard summary={summary} />
        </Box>
      )}
    </Box>
  );
};

export default StudySummaryTab;
