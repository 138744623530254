import {
  Box,
  FormControl,
  Chip,
  Popover,
  useTheme,
  Typography,
  svgIconClasses,
  Theme,
  chipClasses,
  tooltipClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TextField, Tooltip } from "@verily-src/react-design-system";
import { useCallback, useRef, useState } from "react";
import { MinClickableAreaWrapper } from "./MinClickableArea";

export const BASE_POPPER_CLASS = `${tooltipClasses.popper}:not(.${tooltipClasses.popperArrow}) .${tooltipClasses.tooltip}`;

// The styles are copied from https://github.com/verily-src/verily1/blob/main/phaf/rds/src/components/Chip/Chip.tsx
const sxNoFilter = {
  borderRadius: "2.5rem",
  minHeight: "2.5rem",
  maxWidth: "20rem",
  padding: ".5rem .5rem .5rem 1rem",
  border: "1px solid",
  borderColor: "neutral.divider",
  backgroundColor: "background.paper",
  [`&.${chipClasses.disabled}`]: {
    opacity: 1,
    borderColor: "icon.disabled",
    [` .${svgIconClasses.root} path`]: {
      fill: (theme: Theme) => theme.palette.icon.disabled,
    },
  },
  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: "state.hoverOnSurface",
  },
  [`&.${chipClasses.clickable}:active`]: {
    backgroundColor: "state.pressOnSurface",
    boxShadow: 0,
  },
  [`&.${chipClasses.focusVisible}`]: {
    outline: "2px solid",
    outlineColor: (theme: Theme) => theme.palette.primary.textOnBackground,
    backgroundColor: "background.paper",
  },
  [`.${chipClasses.icon}`]: {
    fontSize: "1.5rem",
    margin: "0 .25rem 0 -.5rem",
  },
  [`.${chipClasses.deleteIcon} > div`]: {
    minHeight: "2rem",
    minWidth: "2rem",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "state.hoverOnSurface",
    },
    "&:focus-visible": {
      outline: "2px solid",
      outlineColor: (theme: Theme) => theme.palette.primary.textOnBackground,
    },
  },
  [`.${chipClasses.label}`]: {
    padding: 0,
    color: "text.muted",
  },
  [` .${svgIconClasses.root} path`]: {
    fill: (theme: Theme) => theme.palette.icon.muted,
  },
  "& .MuiChip-icon": {
    order: "1", // The label has a default order of 0, so this icon goes after the label.
  },
};

const sxHasFilter = {
  paddingRight: "0px",

  // Use transparent border instead of 0px border to prevent
  // the Chip component shifting when selected/unselected
  border: "1px solid transparent",
  backgroundColor: "primary.background",
  [`&.${chipClasses.focusVisible}`]: {
    outlineOffset: "2px",
    outlineColor: "primary.textOnBackground",
    backgroundColor: "primary.background",
  },
  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: "state.hoverPrimary",
  },
  [`&.${chipClasses.clickable}:active`]: {
    backgroundColor: "state.pressPrimary",
  },
  [`&.${chipClasses.disabled}`]: {
    border: "1px solid transparent",
    opacity: 1,
    backgroundColor: "state.disabled",
    [` .${svgIconClasses.root} path`]: {
      fill: (theme: Theme) => theme.palette.icon.disabled,
    },
  },
  [`.${chipClasses.deleteIcon} > div`]: {
    "&:hover": {
      backgroundColor: "state.hoverPrimary",
    },
  },
  [`.${chipClasses.label}`]: {
    color: "primary.main",
  },
  [` .${svgIconClasses.root} path`]: {
    fill: (theme: Theme) => theme.palette.primary.main,
  },
};

export interface ComplianceRange {
  minPercentage: number;
  maxPercentage: number;
}

interface ComplianceRangeFilterProps {
  complianceRange: ComplianceRange;
  onRequestFilter(complianceRange: ComplianceRange): void;
}

// A component to set compliance range filter condition
const ComplianceRangeFilter: React.FC<ComplianceRangeFilterProps> = ({
  complianceRange,
  onRequestFilter,
}) => {
  // Min and max filter values shown in the chip button
  const [minFilterValue, setMinFilterValue] = useState(
    complianceRange.minPercentage
  );
  const [maxFilterValue, setMaxFilterValue] = useState(
    complianceRange.maxPercentage
  );

  // Min and max values shown in the input text fields
  const [minValue, setMinValue] = useState(complianceRange.minPercentage);
  const [maxValue, setMaxValue] = useState(complianceRange.maxPercentage);

  const [minValueError, setMinValueError] = useState("");
  const [maxValueError, setMaxValueError] = useState("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const chipRef = useRef<HTMLDivElement | null>(null);

  const [isLastTabStopReached, setIsLastTabStopReached] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setIsLastTabStopReached(false);
    }
  };

  const focusOnChipButton = () => {
    setTimeout(() => {
      if (chipRef.current) {
        chipRef.current.focus();
      }
    }, 500); // add 500ms deplay so that focus works after filtered results are displayed.
  };

  const handleDelete = useCallback(() => {
    setMinValue(0);
    setMaxValue(100);
    setMinFilterValue(0);
    setMaxFilterValue(100);
    onRequestFilter({ minPercentage: 0, maxPercentage: 100 });
    focusOnChipButton();
  }, [onRequestFilter]);

  const handleClose = useCallback(() => {
    if (minValue > maxValue) {
      return;
    }

    setAnchorEl(null);
    setMinFilterValue(minValue);
    setMaxFilterValue(maxValue);
    onRequestFilter({ minPercentage: minValue, maxPercentage: maxValue });
    focusOnChipButton();
  }, [maxValue, minValue, onRequestFilter]);

  const handleMinValueChange = (newValue: string) => {
    const numberValue = parseInt(newValue === "" ? "0" : newValue);
    setMinValue(isNaN(numberValue) ? 0 : numberValue);

    if (numberValue <= maxValue) {
      setMinValueError("");
    } else {
      setMinValueError("Value needs to be less than or equal to " + maxValue);
    }

    setMaxValueError("");
  };

  const handleMaxValueChange = (newValue: string) => {
    const numberValue = parseInt(newValue === "" ? "0" : newValue);
    setMaxValue(isNaN(numberValue) ? 0 : numberValue);

    if (numberValue >= minValue) {
      setMaxValueError("");
    } else {
      setMaxValueError(
        "Value needs to be greater than or equal to " + minValue
      );
    }

    setMinValueError("");
  };

  const handleDeleteFocus = (e: React.FocusEvent) => {
    // Need to avoid the focus event on the delete button from propagating
    // to the parent `Chip` element which would result in a double focus visual.
    e.stopPropagation();
  };

  const handleKeyUpDelete = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " ") {
        // If the user is focused on the delete icon and presses one of these
        // keys, then the onDelete should be called and nothing else.
        e.preventDefault();
        handleDelete();
      }
    },
    [handleDelete]
  );

  // Allow users to use 'TAB' key to exit the popup
  const handlePopoverKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Tab" && !e.shiftKey && isLastTabStopReached) {
        e.preventDefault();
        handleClose();
      }
    },
    [handleClose, isLastTabStopReached]
  );

  const hasFilterValue = minFilterValue > 0 || maxFilterValue < 100;

  const theme = useTheme();
  return (
    <Box>
      <Chip
        ref={chipRef}
        variant={hasFilterValue ? "filled" : "outlined"}
        aria-haspopup="true"
        aria-controls="compliance-range-filter-popover"
        label={
          <Typography
            variant="button1"
            sx={{
              color: hasFilterValue
                ? theme.palette.primary.main
                : theme.palette.text.muted,
              paddingRight: hasFilterValue ? "0px" : "16px",
            }}
          >
            {`Compliance range: ${minFilterValue} - ${maxFilterValue}%`}
          </Typography>
        }
        onClick={handleClick}
        onDelete={
          hasFilterValue
            ? () => {
                handleDelete();
              }
            : undefined
        }
        icon={
          hasFilterValue ? undefined : open ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        }
        deleteIcon={
          <Tooltip
            title="Remove"
            placement="bottom"
            slotProps={{
              popper: {
                sx: {
                  [`&.${BASE_POPPER_CLASS}.${tooltipClasses.tooltipPlacementBottom}`]:
                    {
                      marginTop: ".5rem",
                    },
                },
              },
            }}
            onFocus={handleDeleteFocus}
          >
            <div style={{ marginLeft: ".375rem" }}>
              <MinClickableAreaWrapper
                a11yClickableAreaStyling={{
                  justifyContent: "space-around",
                }}
                onKeyUp={handleKeyUpDelete}
                role="button"
                aria-label="Remove"
                tabIndex={0}
              >
                <CloseIcon />
              </MinClickableAreaWrapper>
            </div>
          </Tooltip>
        }
        clickable={true}
        sx={hasFilterValue ? sxHasFilter : sxNoFilter}
      />
      <Popover
        id="compliance-range-filter-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onKeyDown={handlePopoverKeyDown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: theme.shadows,
          },
        }}
      >
        <FormControl sx={{ padding: "8px" }}>
          <TextField
            label="Greater than or equal to"
            autoComplete="off"
            autoFocus
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
            suffixText="%"
            value={minValue === 0 ? "" : minValue.toString()}
            placeholder="0"
            helperText={minValueError}
            error={minValueError !== ""}
            onChange={(event) => {
              handleMinValueChange(event.target.value);
            }}
            onFocus={() => {
              setIsLastTabStopReached(false);
            }}
            sx={{ marginTop: "8px", marginBottom: "22px" }}
          />
          <TextField
            label="Less than or equal to"
            autoComplete="off"
            inputProps={{
              style: {
                fontSize: "16px",
              },
            }}
            suffixText="%"
            value={maxValue === 100 ? "" : maxValue.toString()}
            placeholder="100"
            helperText={maxValueError}
            error={maxValueError !== ""}
            onChange={(event) => {
              handleMaxValueChange(event.target.value);
            }}
            onFocus={() => {
              setIsLastTabStopReached(true);
            }}
            sx={{ marginTop: "8px", marginBottom: "22px" }}
          />
        </FormControl>
      </Popover>
    </Box>
  );
};

export default ComplianceRangeFilter;
