import { studyDetailsCache } from "studydetails/StudyDetailsCache";

export function getStudyUsersAlert(registryId: string) {
  const prefix = "To add, remove, or modify users, please contact ";
  if (
    studyDetailsCache.has(registryId) &&
    studyDetailsCache.get(registryId)!.getVerilyCdm().length > 0
  ) {
    return prefix + studyDetailsCache.get(registryId)?.getVerilyCdm() + ".";
  } else {
    return prefix + "Verily CDMs.";
  }
}
