import { AuthToken, IAccessToken } from "@looker/sdk-rtl";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";

type GetAuth0AccessTokenCallback =
  | ((opts?: GetTokenSilentlyOptions) => Promise<string>)
  | undefined;

/**
 * Cache the Looker auth token used by Looker SDK.
 */
export class LookerAuthTokenCache {
  // The API endpoint to call StudyData service, such as "https://sdp-autopush.api.verily.com"
  private apiEndpoint: string = "";

  private getAuth0AccessTokenCb: GetAuth0AccessTokenCallback;

  // The active Looker token for a user session.
  private lookerToken = new AuthToken();

  init(
    apiEndpoint: string,
    getAuth0AccessTokenCb: GetAuth0AccessTokenCallback
  ) {
    this.apiEndpoint = apiEndpoint;
    this.getAuth0AccessTokenCb = getAuth0AccessTokenCb;
  }

  /**
   * @returns The Looker access token
   */
  public async getToken() {
    if (!this.isAuthenticated()) {
      await this.refreshAuthToken();
    }

    return this.lookerToken;
  }

  /**
   * Calls AuthorizeUser API to get a Looker auth token.
   */
  public async refreshAuthToken() {
    if (this.apiEndpoint === "") {
      throw new Error("apiEndpoint is not initialized");
    }

    if (!this.getAuth0AccessTokenCb) {
      throw new Error("getAuth0AccessTokenCb is not initialized");
    }

    const auth0Token = await this.getAuth0AccessTokenCb();
    const client = new StudyDataServiceApiClient(this.apiEndpoint, auth0Token);
    const response = await client.authorizeUser();

    let lookerToken: IAccessToken = {
      access_token: response.getAccessToken(),
      expires_in: response.getExpiresIn(),
      refresh_token: response.getRefreshToken(),
      token_type: response.getTokenType(),
    };

    this.lookerToken.setToken(lookerToken);
  }

  public isAuthenticated() {
    const token = this.lookerToken;
    if (!(token && token.access_token)) return false;
    return token.isActive();
  }
}

export const lookerAuthTokenCache = new LookerAuthTokenCache();
