import { LRUCache } from "lru-cache";
import { StudyDetail } from "generated/studydetail/studydetail_pb";

/**
 * Sets up options to cache study details for 3 studies.
 */
const cacheOptions = {
  max: 3,
};

/**
 * Cache for study details.
 */
export const studyDetailsCache = new LRUCache<string, StudyDetail>(
  cacheOptions
);

export function clearStudyDetailsCache() {
  studyDetailsCache.clear();
}
