import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import {
  GetStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import {
  GetParticipantSessionRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { ParseIdFromName } from "common/ResourceName";
import { RpcError, StatusCode } from "grpc-web";

// Defines a custom effect to load data for the study participant detail screen.
function useStudyParticipantDataLoader(
  studyParticipantName: string,
  reloadData: boolean
) {
  const auth0Config = useAppSelector((state) => state.auth0Config);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [studyParticipant, setStudyParticipant] = useState(
    new StudyParticipant()
  );
  const [participantSession, setParticipantSession] = useState(
    new ParticipantSession()
  );

  const loadStudyParticipant = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        setHasError(false);

        if (studyParticipantName === "") {
          return;
        }

        // load study participants
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        try {
          const studyParticipantServiceClient =
            new StudyParticipantServiceApiClient(auth0Config.audience!, token);

          const studyParticipantRequest =
            new GetStudyParticipantRequest().setName(studyParticipantName);
          const studyParticipantResponse =
            await studyParticipantServiceClient.getStudyParticipant(
              studyParticipantRequest
            );

          setStudyParticipant(studyParticipantResponse);
        } catch (error) {
          console.log("error reason %s", error);
          setHasError(true);
        }

        try {
          const participantSessionServiceClient =
            new ParticipantSessionServiceApiClient(
              auth0Config.audience!,
              token
            );

          const participantSessionRequest =
            new GetParticipantSessionRequest().setName(
              `participantSessions/${ParseIdFromName(studyParticipantName)}`
            );
          const participantSessionResponse =
            await participantSessionServiceClient.getParticipantSession(
              participantSessionRequest
            );

          setParticipantSession(participantSessionResponse);
        } catch (error) {
          if (
            error instanceof RpcError &&
            error.code === StatusCode.NOT_FOUND
          ) {
            // set participant session to empty
            setParticipantSession(new ParticipantSession());
          } else {
            console.log("error reason %s", error);
            setHasError(true);
          }
        }

        setIsLoading(false);
      })();
    }
  }, [
    isAuthenticated,
    auth0Config,
    studyParticipantName,
    getAccessTokenSilently,
  ]);

  // load data used in the study participants screen.
  useEffect(() => {
    if (reloadData) {
      setIsLoading(true);
      loadStudyParticipant();
    }
  }, [reloadData, loadStudyParticipant]);

  return {
    isLoading,
    hasError,
    studyParticipant,
    participantSession,
  };
}

export default useStudyParticipantDataLoader;
