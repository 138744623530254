import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import {
  DeleteUserRequest,
  DeleteUserRolesRequest,
  Role,
  StudyRole,
  User,
} from "generated/studyauth/studyauth_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { dialog_border_radius } from "styles/Dimensions";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ConfirmDeleteStudySiteUserDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  user: User;
  registryId: string;
  userRoles: StudyRole[] | undefined;
  onClose(): void;
  onSuccess(): void;
}

// The confirmation dialog for deleting a study site user
const ConfirmDeleteStudySiteUserDialog: React.FC<
  ConfirmDeleteStudySiteUserDialogProps
> = ({ open, user, registryId, userRoles, onClose, onSuccess }) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const testConfig = useAppSelector((state) => state.testConfig);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [disableRemoveButton, setDisableRemoveButton] = useState(false);

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setErrorMessage("");
      setDisableRemoveButton(false);
    }
  }, [open]);

  const currentSiteId = useMemo(() => {
    if (userRoles) {
      for (var role of userRoles) {
        if (
          role.getRegistryId() === registryId &&
          role.getRole() === Role.CRC
        ) {
          return role.getStudySiteId();
        }
      }
    }
    return "";
  }, [registryId, userRoles]);

  const handleClickRemoveButton = () => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showDeleteUserError) {
            throw new Error("failed to delete user");
          }

          if (
            !updateReasonFormRef.current!.validateInput(true /* focusOnError */)
          ) {
            return;
          }

          setDisableRemoveButton(true);

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          // Remove user roles
          const studyRoles = new Array<StudyRole>();
          studyRoles.push(
            new StudyRole()
              .setRegistryId(registryId)
              .setStudySiteId(currentSiteId)
              .setRole(Role.CRC)
          );

          const deleteRolesRequest = new DeleteUserRolesRequest()
            .setUserName(user.getName())
            .setStudyRolesList(studyRoles)
            .setUpdateReason(updateReason);
          await client.deleteUserRoles(deleteRolesRequest);

          // Delete the user.
          // The call deletes the user if the user does not have any other roles.
          const deleteUserRequest = new DeleteUserRequest()
            .setName(user.getName())
            .setUpdateReason(updateReason);
          await client.deleteUser(deleteUserRequest);

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to delete the user. Please try again.");
        }

        setDisableRemoveButton(false);
      })();
    } else {
      setErrorMessage(
        "Current user is not authenticated. Please logout and login again."
      );
    }
  };

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "500px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px", paddingBottom: "4px" }}>
        <Typography variant="display6">Remove user from study</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingBottom: "16px" }}>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Remove user failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography
          variant="body1"
          sx={{ marginTop: "16px", marginBottom: "24px" }}
        >
          This user won't be able to access this study anymore. Any pending
          invitations will be cancelled.
        </Typography>
        <UpdateReasonForm
          ref={updateReasonFormRef}
          predefinedReasons={["Error correction", "Access no longer required"]}
          reasonSelectionLabel="Reason for removing user (required)"
          reasonRequiredMessage="Reason for removing user required"
          otherReasonHint="Please provide reason this user is being removed"
          otherReasonRequiredMessage="Other reason for removing user required"
        />
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Remove"
          variant="filled"
          disabled={disableRemoveButton}
          onClick={handleClickRemoveButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteStudySiteUserDialog;
