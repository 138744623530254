import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as WatchIcon } from "assets/watch-icon.svg";
import { ReactComponent as SyncIcon } from "assets/sync-icon.svg";
import { ReactComponent as PollIcon } from "assets/poll-icon.svg";
import { ReactComponent as MedicationPillIcon } from "assets/medication-pill-icon.svg";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { useAppSelector } from "redux/hooks";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import pluralize from "pluralize";

interface SettingSummaryProps {
  complianceSetting: ComplianceSetting;
  showCompactMode: boolean;
}

const SettingSummary: React.FC<SettingSummaryProps> = ({
  complianceSetting,
  showCompactMode,
}) => {
  const appConfig = useAppSelector((state) => state.appConfig);

  const fontVariant = showCompactMode ? "body2" : "body1";
  const cellPadding = showCompactMode ? "16px" : "24px";
  const tableWidth = showCompactMode ? 552 : 752;

  const IconCellSxProp = {
    verticalAlign: "top",
    paddingLeft: "0px",
    paddingTop: cellPadding,
    paddingBottom: cellPadding,
  };

  const ParamNameCellSxProp = {
    verticalAlign: "top",
    paddingLeft: "0px",
    paddingTop: cellPadding,
    paddingBottom: cellPadding,
  };

  const ParamValueCellSxProp = {
    verticalAlign: "top",
    padding: cellPadding,
  };

  return (
    <Table sx={{ minWidth: tableWidth }} aria-label="compliance setting">
      <TableBody>
        {/* Show wear time compliance parameter */}
        <TableRow>
          <TableCell aria-hidden="true" width="40px" sx={IconCellSxProp}>
            <WatchIcon />
          </TableCell>
          <TableCell width="30%" sx={ParamNameCellSxProp}>
            <Typography component="h2" variant={fontVariant} fontWeight="600">
              Watch wear time
            </Typography>
          </TableCell>
          <TableCell width="70%" sx={ParamValueCellSxProp}>
            <Typography variant={fontVariant}>
              {`At least ${pluralize(
                "hour",
                complianceSetting.getWearTimeHoursPerDay(),
                true
              )} per day required`}
            </Typography>
            <Typography variant={fontVariant}>
              {`At least ${pluralize(
                "day",
                complianceSetting.getWearTimeDaysPerWeek(),
                true
              )} a week required (Sunday through Saturday)`}
            </Typography>
          </TableCell>
        </TableRow>
        {/* Show sync time compliance parameter */}
        <TableRow>
          <TableCell aria-hidden="true" width="40px" sx={IconCellSxProp}>
            <SyncIcon />
          </TableCell>
          <TableCell width="30%" sx={ParamNameCellSxProp}>
            <Typography component="h2" variant={fontVariant} fontWeight="600">
              Watch sync
            </Typography>
          </TableCell>
          <TableCell width="70%" sx={ParamValueCellSxProp}>
            <Typography variant={fontVariant}>
              {`Sync required at least ${pluralize(
                "day",
                complianceSetting.getSyncDaysPerWeek(),
                true
              )} per week (Sunday through Saturday)`}
            </Typography>
          </TableCell>
        </TableRow>
        {/* Show survey submission compliance parameter if any */}
        {featureFlagProvider.isSurveyEnabled(appConfig.environment) &&
          complianceSetting.getSurveySubmissionPercentage() > 0 && (
            <TableRow>
              <TableCell aria-hidden="true" width="40px" sx={IconCellSxProp}>
                <PollIcon />
              </TableCell>
              <TableCell width="30%" sx={ParamNameCellSxProp}>
                <Typography
                  component="h2"
                  variant={fontVariant}
                  fontWeight="600"
                >
                  Survey submission
                </Typography>
              </TableCell>
              <TableCell width="70%" sx={ParamValueCellSxProp}>
                <Typography variant={fontVariant}>
                  {`At least ${complianceSetting.getSurveySubmissionPercentage()}% of all surveys prompted are required per week`}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        {/* Show meds logging compliance parameter if any */}
        {(complianceSetting.getMedsLoggedPerDay() > 0 ||
          complianceSetting.getMedsLoggedPerWeek() > 0) && (
          <TableRow>
            <TableCell aria-hidden="true" width="40px" sx={IconCellSxProp}>
              <MedicationPillIcon />
            </TableCell>
            <TableCell width="30%" sx={ParamNameCellSxProp}>
              <Typography component="h2" variant={fontVariant} fontWeight="600">
                Medication logging
              </Typography>
            </TableCell>
            <TableCell width="70%" sx={ParamValueCellSxProp}>
              {complianceSetting.getMedsLoggedPerDay() > 0 && (
                <Typography variant={fontVariant}>
                  {`At least ${pluralize(
                    "dose",
                    complianceSetting.getMedsLoggedPerDay(),
                    true
                  )} of medication logged per day`}
                </Typography>
              )}
              {complianceSetting.getMedsLoggedPerWeek() > 0 && (
                <Typography variant={fontVariant}>
                  {`At least ${pluralize(
                    "dose",
                    complianceSetting.getMedsLoggedPerWeek(),
                    true
                  )} of medication logged per week`}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SettingSummary;
