export interface Option {
  id: number;
  label: string;
  nextStepId: number;
}

export class RadioOptions {
  options: Map<number, Option>;
  default: Option;

  constructor(options: Option[], defaultId?: number) {
    if (options.length === 0) {
      throw new Error("Options must not be empty");
    }

    this.options = new Map<number, Option>();
    for (const option of options) {
      this.options.set(option.id, option);
    }

    this.default = {
      id: -1,
      label: "genericDefault_DoNotShow",
      nextStepId: -1,
    };
    if (defaultId) {
      this.default = this.get(defaultId) ?? this.default;
    }
  }

  get(id: number): Option | undefined {
    return this.options.get(id);
  }
}

export enum EditParticipantIdReasonOptions {
  Typo = 0,
  Error = 1,
  Other = 2,
}

export enum TaskOptions {
  Return = 0,
  Lost = 1,
  Error = 2,
  Other = 3,
}

export enum ReturnReasonOptions {
  ExitStudy = 0,
  WatchIssue = 1,
  Other = 2,
}

export enum WatchOwnerOptions {
  StudyStaff = 0,
  AnotherParticpant = 1,
  Other = 2,
}
