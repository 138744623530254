import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { SingleSelectDropdown } from "@verily-src/react-design-system";
import { InputTextField, InputTextFieldRef } from "components/InputText";

interface UpdateReasonFormProps {
  predefinedReasons: Array<string>;
  reasonSelectionLabel: string;
  reasonRequiredMessage: string;
  otherReasonHint?: string;
  otherReasonAllowed?: boolean;
  otherReasonRequiredMessage?: string;
}

// Defines an interface for the functions exposed from UpdateReasonForm
export interface UpdateReasonFormRef {
  clearErrorMessage: () => void;
  validateInput: (focusOnError?: boolean) => boolean;
  getUpdateReason: () => string;
}

// A form to collect UpdateReason input
const UpdateReasonForm = forwardRef<UpdateReasonFormRef, UpdateReasonFormProps>(
  (
    {
      predefinedReasons,
      reasonSelectionLabel,
      reasonRequiredMessage,
      otherReasonHint,
      otherReasonAllowed,
      otherReasonRequiredMessage,
    },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState(-1);
    const [selectReasonError, setSelectReasonError] = useState("");

    const [otherReason, setOtherReason] = useState("");
    const [otherReasonError, setOtherReasonError] = useState("");

    const selectedReasonRef = useRef<HTMLInputElement>(null);
    const otherReasonRef = useRef<InputTextFieldRef>(null);

    const dropdownOptions = useMemo<
      Array<{ label: string; value: string }>
    >(() => {
      const options = new Array<{ label: string; value: string }>();
      for (let i = 0; i < predefinedReasons.length; i++) {
        options.push({ label: predefinedReasons[i], value: i.toString() });
      }

      if (!otherReasonAllowed) {
        options.push({
          label: "Other",
          value: predefinedReasons.length.toString(),
        });
      }

      return options;
    }, [otherReasonAllowed, predefinedReasons]);

    const clearErrorMessage = useCallback(() => {
      setSelectReasonError("");
      setOtherReasonError("");
    }, []);

    // Set initial state when the form is opened.
    useEffect(() => {
      setSelectedOption(-1);
      setOtherReason("");
      clearErrorMessage();
    }, [clearErrorMessage]);

    const handleSelectReasonChange = (event: SelectChangeEvent<unknown>) => {
      const index = parseInt(event.target.value as string);
      setSelectedOption(index);
      setSelectReasonError("");
    };

    const validateInput = useCallback(
      (focusOnError) => {
        if (selectedOption < 0) {
          setSelectReasonError(reasonRequiredMessage);

          if (focusOnError) {
            selectedReasonRef.current?.focus();
          }

          return false;
        }

        if (
          selectedOption === predefinedReasons.length &&
          otherReason.trim().length < 1
        ) {
          setOtherReasonError(
            otherReasonRequiredMessage
              ? otherReasonRequiredMessage
              : "Other reason required"
          );

          if (focusOnError) {
            otherReasonRef.current?.focus();
          }

          return false;
        }

        return true;
      },
      [
        otherReason,
        otherReasonRequiredMessage,
        predefinedReasons.length,
        reasonRequiredMessage,
        selectedOption,
      ]
    );

    const getUpdateReason = useCallback(() => {
      if (selectedOption === predefinedReasons.length) {
        return otherReason;
      }
      return predefinedReasons[selectedOption];
    }, [otherReason, predefinedReasons, selectedOption]);

    // Expose functions to parent components.
    useImperativeHandle(
      ref,
      () => ({
        clearErrorMessage,
        validateInput,
        getUpdateReason,
      }),
      [validateInput, getUpdateReason, clearErrorMessage]
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SingleSelectDropdown
          value={selectedOption > -1 ? selectedOption.toString() : ""}
          options={dropdownOptions}
          label={reasonSelectionLabel}
          inputRef={selectedReasonRef}
          labelId="selectReasonId"
          placeholder="Select reason"
          helperText={selectReasonError}
          error={selectReasonError.length > 0}
          fullWidth
          onChange={handleSelectReasonChange}
        />
        {selectedOption === predefinedReasons.length && (
          <InputTextField
            id="otherReason"
            label="Other reason (required)"
            ref={otherReasonRef}
            placeholder={otherReasonHint}
            helperText={otherReasonError}
            showError={otherReasonError.length > 0}
            value={otherReason}
            onChange={(newValue) => {
              setOtherReasonError("");
              setOtherReason(newValue);
            }}
            sx={{ marginTop: "30px " }}
          />
        )}
      </Box>
    );
  }
);

export default UpdateReasonForm;
