import { Looker40SDK } from "@looker/sdk";
import { format, parseISO } from "date-fns";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get the last sync time for all participants in a given study site.
 * The last sync time will be for each participant-device association during their tenure at that site.
 *
 * The function assumes that the Looker user attribute for site id is set-up properly
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantsLastSyncTime(
  sdk: Looker40SDK,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  // Create nested map to store last sync for each participant and device
  // The key is a concatenation of the participant id and device id with an underscore
  // (e.g. "<participant_id>_<device_id>_<association_start_time>")
  let lastSyncTimeMap = new Map<string, Date>();
  let hasError = false;

  try {
    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_LAST_SYNC_TIME
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        const participantId = row["participants_last_sync_time.participant_id"];
        const deviceId = row["participants_last_sync_time.device_id"];
        // timestamps are in UTC
        const associationStartTime = parseISO(
          row["participants_last_sync_time.association_start_time"]
        );
        const lastSyncTime = parseISO(
          row["participants_last_sync_time.last_sync_time"]
        );

        lastSyncTimeMap.set(
          // key on participantId, deviceId, and associationStartTime (in epoch time)
          `${participantId}_${deviceId}_${format(associationStartTime, "t")}`,
          lastSyncTime
        );
      }
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { lastSyncTimeMap, hasError };
}
