import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getLookerSdk } from "looker/LookerSdk";
import { ComplianceType, NonCompliantReason } from "looker/QueryResultsCache";
import { Role } from "generated/studyauth/studyauth_pb";
import { selectCurrentStudyRoles } from "core/UserConfigSlice";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ParticipantComplianceTask,
  FilterParticipantComplianceTasksRequest,
} from "generated/participantcompliancetask/participantcompliancetask_pb";
import { ParticipantComplianceTaskServiceApiClient } from "apiclient/ParticipantComplianceTaskServiceApiClient";
import { toDateProto } from "common/Dates";
import { getParticipantsComplianceRecords } from "looker/GetParticipantsComplianceRecords";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { ComplianceScope } from "./NonCompliantTabDataLoader";
import { getStudyParticipantsComplianceRecords } from "looker/GetStudyParticipantsComplianceRecords";
import { StudySite } from "generated/studysite/studysite_pb";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import { ParseSiteIdFromName } from "common/ResourceName";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export enum TaskStatus {
  NeedsContacting,
  Resolved,
}

export interface NonCompliantDetailRecord {
  participantId: string;
  subjectId: string;
  deviceId: string;
  siteId: string;
  status: TaskStatus;
  reasons: Set<NonCompliantReason>;
}

export interface NonCompliantDetailSummary {
  /** number of enrolled participants */
  total: number;

  /** number of non-compliant participants */
  nonCompliant: number;
  records: Array<NonCompliantDetailRecord>;
  complianceSetting: ComplianceSetting;
  sites: Map<string, StudySite>;
}

// Defines a custom effect to load non-compliant detail data.
function useNonCompliantDetailDataLoader(scope: ComplianceScope) {
  const appConfig = useAppSelector((state) => state.appConfig);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<NonCompliantDetailSummary>({
    total: 0,
    nonCompliant: 0,
    records: new Array<NonCompliantDetailRecord>(),
    complianceSetting: getDefaultComplianceSetting(),
    sites: new Map<string, StudySite>(),
  });
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  const loadNonCompliantDetailData = useCallback(
    (
      previousWeekOffset: number,
      reason: NonCompliantReason,
      registryId: string
    ) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const startDate = getWeekStartDate(previousWeekOffset);
            const endDate = getWeekEndDate(previousWeekOffset);

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              currentRegistryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalCompliance: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalCompliance.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalCompliance.push(ComplianceType.MedLogWeekly);
            }

            const complianceRecords =
              scope === ComplianceScope.Site
                ? await getParticipantsComplianceRecords(
                    sdk,
                    previousWeekOffset,
                    optionalCompliance,
                    lookerQueryIdsMap
                  )
                : await getStudyParticipantsComplianceRecords(
                    sdk,
                    previousWeekOffset,
                    optionalCompliance,
                    lookerQueryIdsMap
                  );

            if (complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            const taskServiceClient =
              new ParticipantComplianceTaskServiceApiClient(
                auth0Config.audience!,
                token
              );

            const nonCompliantParticipantNames =
              complianceRecords.noncompliantRecords.map((record) => {
                return "studyParticipants/" + record.participantId;
              });

            const selectedCrcRole = selectedRoles.find((role) => {
              return role.role === Role.CRC;
            });

            // Assume CRC will contact non-compliance participant within 1 week.
            const newStartDate = new Date(
              startDate.getTime() + 7 * 24 * 60 * 60 * 1000
            );
            const newEndDate = new Date(
              endDate.getTime() + 7 * 24 * 60 * 60 * 1000
            );

            const filterTaskRequest =
              new FilterParticipantComplianceTasksRequest()
                .setParticipantsList(nonCompliantParticipantNames)
                .setStartDate(toDateProto(newStartDate))
                .setEndDate(toDateProto(newEndDate));

            if (scope === ComplianceScope.Site) {
              filterTaskRequest.setSiteId(selectedCrcRole?.studySiteId!);
            } else {
              filterTaskRequest.setRegistryId(registryId);
            }

            const response =
              await taskServiceClient.filterParticipantComplianceTask(
                filterTaskRequest
              );

            const participantIdToStatusMap = new Map(
              response
                .getTasksList()
                .map((task) => [
                  task.getName().split("/")[1],
                  task.getTaskStatus(),
                ])
            );

            const detailRecords = complianceRecords.noncompliantRecords
              .filter((record) => {
                if (reason === NonCompliantReason.Multiple) {
                  return record.reasons.size > 1;
                } else {
                  return (
                    record.reasons.size === 1 && record.reasons.has(reason)
                  );
                }
              })
              .map((record) => {
                let taskStatus = TaskStatus.NeedsContacting;
                if (
                  participantIdToStatusMap.get(record.participantId) ===
                  ParticipantComplianceTask.TaskStatus.RESOLVED
                ) {
                  taskStatus = TaskStatus.Resolved;
                }

                return {
                  ...record,
                  status: taskStatus,
                };
              });

            // Load study sites info if needed
            const sites = new Map<string, StudySite>();
            if (scope === ComplianceScope.Study) {
              const client = new StudySiteServiceApiClient(
                auth0Config.audience!,
                token
              );

              const response = await client.listStudySites(registryId);
              for (const site of response.getStudySitesList()) {
                const siteId = ParseSiteIdFromName(site.getName());
                sites.set(siteId, site);
              }
            }

            let summary = {
              total: complianceRecords.activeParticipantsCount,
              nonCompliant: detailRecords.length,
              records: detailRecords,
              complianceSetting: complianceSetting,
              sites: sites,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        //TODO(STUDYDVICE-4628) - add error dialog for other errors
        console.log(
          "User is not authenticated. Please logout and login again."
        );

        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      isAuthenticated,
      auth0Config,
      getAccessTokenSilently,
      currentRegistryId,
      scope,
      sdk,
      lookerQueryIdsMap,
      selectedRoles,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadNonCompliantDetailData,
  };
}

export default useNonCompliantDetailDataLoader;
