/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.compliancesetting.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: compliancesetting/compliancesetting.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.compliancesetting = {};
proto.verily.sdp.compliancesetting.v1alpha1 = require('./compliancesetting_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest,
 *   !proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 */
const methodDescriptor_ComplianceSettingService_CreateComplianceSetting = new grpc.web.MethodDescriptor(
  '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/CreateComplianceSetting',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest,
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting,
  /**
   * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServiceClient.prototype.createComplianceSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/CreateComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_CreateComplianceSetting,
      callback);
};


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServicePromiseClient.prototype.createComplianceSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/CreateComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_CreateComplianceSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest,
 *   !proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 */
const methodDescriptor_ComplianceSettingService_UpdateComplianceSetting = new grpc.web.MethodDescriptor(
  '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/UpdateComplianceSetting',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest,
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting,
  /**
   * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServiceClient.prototype.updateComplianceSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/UpdateComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_UpdateComplianceSetting,
      callback);
};


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServicePromiseClient.prototype.updateComplianceSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/UpdateComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_UpdateComplianceSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest,
 *   !proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 */
const methodDescriptor_ComplianceSettingService_GetComplianceSetting = new grpc.web.MethodDescriptor(
  '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/GetComplianceSetting',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest,
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting,
  /**
   * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServiceClient.prototype.getComplianceSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/GetComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_GetComplianceSetting,
      callback);
};


/**
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSettingServicePromiseClient.prototype.getComplianceSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.compliancesetting.v1alpha1.ComplianceSettingService/GetComplianceSetting',
      request,
      metadata || {},
      methodDescriptor_ComplianceSettingService_GetComplianceSetting);
};


module.exports = proto.verily.sdp.compliancesetting.v1alpha1;

