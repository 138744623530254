import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button } from "@verily-src/react-design-system";
import pluralize from "pluralize";
import A11yDialogTitle from "components/A11yDialogTitle";

// Set 15-minute idle timeout.
const timeout = 1000 * 60 * 15;

// Show the warning dialog 2 minutes before idle timeout.
const promptBeforeIdle = 1000 * 60 * 2;

// Monitor user activity and show a warning dialog before idle timout.
const SessionController: React.FC = () => {
  const { isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const [remainingTimeSeconds, setRemainingTimeSeconds] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const closeSession = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  const onIdle = () => {
    closeSession();
  };

  const onPrompt = () => {
    if (isAuthenticated) {
      setOpen(true);
    } else {
      closeSession();
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const stayLoggedIn = useCallback(async () => {
    if (isAuthenticated) {
      try {
        // Reactivate the idle timer
        activate();

        // Make a request to keep Auth0 session active
        await getAccessTokenSilently();

        setOpen(false);
      } catch (error) {
        closeSession();
      }
    } else {
      closeSession();
    }
  }, [activate, closeSession, getAccessTokenSilently, isAuthenticated]);

  // Update the remaining time in seconds.
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTimeSeconds(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "500px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6">Are you still there?</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          We log you out when you've been inactive for more than 15 minutes.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          You have{" "}
          <b>
            {pluralize("minute", Math.floor(remainingTimeSeconds / 60), true)}{" "}
            and {pluralize("second", remainingTimeSeconds % 60, true)}
          </b>{" "}
          before logout.
        </Typography>
        <Typography variant="body1" sx={{ paddingBottom: "4px" }}>
          If you're still working, stay logged in.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Log out"
          onClick={() => {
            closeSession();
          }}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Stay logged in"
          variant="filled"
          onClick={() => {
            stayLoggedIn();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SessionController;
