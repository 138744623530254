import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "components/SortableTableHeaderCell";
import { WritableDraft } from "immer/dist/internal";

export enum CrcComplianceTabIndex {
  NonCompliant = 0,
  Compliant = 1,
  Participants = 2,
}

export interface CrcComplianceState {
  selectedTab: CrcComplianceTabIndex;

  compliantTabSortOrder: Order;
  compliantTabOrderBy: string;
  compliantTabPageNumber: number;
  compliantTabRowsPerPage: number;

  participantsTabSortOrder: Order;
  participantsTabOrderBy: string;
  participantsTabPageNumber: number;
  participantsTabRowsPerPage: number;
  participantsTabSearchText: string;
}

const initialState: CrcComplianceState = {
  selectedTab: CrcComplianceTabIndex.NonCompliant,

  compliantTabSortOrder: "asc",
  compliantTabOrderBy: "subjectId",
  compliantTabPageNumber: 0,
  compliantTabRowsPerPage: 5,

  participantsTabSortOrder: "asc",
  participantsTabOrderBy: "subjectId",
  participantsTabPageNumber: 0,
  participantsTabRowsPerPage: 5,
  participantsTabSearchText: "",
};

function resetCompliantTabStateInternal(
  state: WritableDraft<CrcComplianceState>
) {
  state.compliantTabSortOrder = "asc";
  state.compliantTabOrderBy = "subjectId";
  state.compliantTabPageNumber = 0;
  state.compliantTabRowsPerPage = 5;
}

function resetParticipantsTabStateInternal(
  state: WritableDraft<CrcComplianceState>
) {
  state.participantsTabSortOrder = "asc";
  state.participantsTabOrderBy = "subjectId";
  state.participantsTabPageNumber = 0;
  state.participantsTabRowsPerPage = 5;
  state.participantsTabSearchText = "";
}

const crcComplianceStateSlice = createSlice({
  name: "crcComplianceState",
  initialState,
  reducers: {
    setSelectedComplianceTab: (
      state,
      action: PayloadAction<CrcComplianceTabIndex>
    ) => {
      state.selectedTab = action.payload;
    },
    resetCrcComplianceScreenState: (state) => {
      state.selectedTab = CrcComplianceTabIndex.NonCompliant;
      resetCompliantTabStateInternal(state);
      resetParticipantsTabStateInternal(state);
    },
    setCompliantTabSortOrder: (state, action: PayloadAction<Order>) => {
      state.compliantTabSortOrder = action.payload;
    },
    setCompliantTabOrderBy: (state, action: PayloadAction<string>) => {
      state.compliantTabOrderBy = action.payload;
    },
    setCompliantTabPageNumber: (state, action: PayloadAction<number>) => {
      state.compliantTabPageNumber = action.payload;
    },
    setCompliantTabRowsPerPage: (state, action: PayloadAction<number>) => {
      state.compliantTabRowsPerPage = action.payload;
    },
    resetCompliantTabState: (state) => {
      resetCompliantTabStateInternal(state);
    },
    setParticipantsTabSortOrder: (state, action: PayloadAction<Order>) => {
      state.participantsTabSortOrder = action.payload;
    },
    setParticipantsTabOrderBy: (state, action: PayloadAction<string>) => {
      state.participantsTabOrderBy = action.payload;
    },
    setParticipantsTabPageNumber: (state, action: PayloadAction<number>) => {
      state.participantsTabPageNumber = action.payload;
    },
    setParticipantsTabRowsPerPage: (state, action: PayloadAction<number>) => {
      state.participantsTabRowsPerPage = action.payload;
    },
    setParticipantsTabSearchText: (state, action: PayloadAction<string>) => {
      state.participantsTabSearchText = action.payload;
    },
    resetParticipantsTabState: (state) => {
      resetParticipantsTabStateInternal(state);
    },
  },
});

export const {
  setSelectedComplianceTab,
  resetCrcComplianceScreenState,
  setCompliantTabSortOrder,
  setCompliantTabOrderBy,
  setCompliantTabPageNumber,
  setCompliantTabRowsPerPage,
  resetCompliantTabState,
  setParticipantsTabSortOrder,
  setParticipantsTabOrderBy,
  setParticipantsTabPageNumber,
  setParticipantsTabRowsPerPage,
  setParticipantsTabSearchText,
  resetParticipantsTabState,
} = crcComplianceStateSlice.actions;
export default crcComplianceStateSlice.reducer;
