export enum AppRoutes {
  ROOT = "/",
  HOME = "/home",
  STUDY_DETAIL = "home/study_detail",
  STUDY_USERS_HOME = "/home/study_users",
  STUDY_SITES = "/home/study_sites",
  STUDY_PARTICIPANTS = "/home/study_participants",
  STUDY_PARTICIPANT = "/home/study_participant",
  COMPLIANCE_SETTING = "/home/compliance_setting",
  EDIT_COMPLIANCE_SETTING = "/home/edit_compliance_setting",
  TEST_TOOL = "/home/test_tool",
  ADMIN = "/admin",
  SUPER_ADMINS = "/admin/superadmins",
  ADMIN_TEST_TOOL = "/admin/test_tool",
  STUDIES = "/studies",
  ACCESS_DENIED = "/accessdenied",
  LOGOUT = "/logout",
  PARTICIPANT_COMPLIANCE = "/home/participant_compliance",
  NON_COMPLIANT_DETAIL = "/non_compliant",
}
