import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth0ProviderOptions } from "@auth0/auth0-react";

const initialConfig: Auth0ProviderOptions = {
  domain: "",
  clientId: "",
  redirectUri: window.location.origin,
  audience: "",
};

const auth0ConfigSlice = createSlice({
  name: "auth0Config",
  initialState: initialConfig,
  reducers: {
    setAuth0Config: (state, action: PayloadAction<Auth0ProviderOptions>) => {
      state.domain = action.payload.domain;
      state.clientId = action.payload.clientId;
      state.redirectUri = action.payload.redirectUri;
      state.audience = action.payload.audience;
    },
  },
});

export const { setAuth0Config } = auth0ConfigSlice.actions;
export default auth0ConfigSlice.reducer;
