import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";
import { UpdateParticipantRequest } from "generated/studydata/studydata_pb";
import {
  getStudyParticipantIdCache,
  setStudyParticipantIdCache,
} from "./SettingsCache";

export async function updateParticipantId(
  apiEndpoint: string,
  auth0AccessToken: string,
  studyParticipantId: string
) {
  // Bypass the update if we already set the study participant id.
  if (getStudyParticipantIdCache() === studyParticipantId) {
    return;
  }

  const studyDataClient = new StudyDataServiceApiClient(
    apiEndpoint,
    auth0AccessToken
  );

  try {
    await studyDataClient.updateParticipant(
      new UpdateParticipantRequest().setParticipantId(studyParticipantId)
    );
  } catch (error) {
    // There is an error. We don't know whether the participant ID is
    // updated or now. So reset the cached value to allow any update to
    // go through.
    setStudyParticipantIdCache("");
    throw error;
  }

  setStudyParticipantIdCache(studyParticipantId);
}
