import { Button } from "@verily-src/react-design-system";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ChangeStatusRedirectDialogProps {
  open: boolean;
  onExit: () => void;
  onCancel: () => void;
}

export const ChangeStatusRedirectDialog: React.FC<
  ChangeStatusRedirectDialogProps
> = ({ open, onExit, onCancel }) => {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">
          For participant status changes, go to “Change status” instead.
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Typography variant="body1">
          For any participant status changes, you do not need to unassign a
          watch. Please exit this page and go to “Change status”.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button variant="filled" onClick={onExit}>
          Exit this page
        </Button>
      </DialogActions>
    </Dialog>
  );
};
