import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppRoutes } from "./core/AppRoutes";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "redux/hooks";
import Loading from "components/Loading";
import Error from "components/Error";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginController from "core/LoginController";
import AccessDeniedScreen from "home/AccessDenied";
import { InitialSetup } from "core/InitialSetup";
import AdminHome from "admin/home/AdminHome";
import { Main } from "home/Main";
import AdminMain from "admin/home/AdminMain";
import TestTools from "test_tools/TestTools";
import StudyUsersHome from "admin/studyusers/StudyUsersHome";
import ComplianceHome from "home/ComplianceHome";
import EditComplianceSetting from "compliancesetting/EditComplianceSetting";
import Studies from "home/Studies";
import StudyDetail from "studydetails/StudyDetailsHome";
import StudyParticipants from "studyparticipant/StudyParticipants";
import ParticipantCompliance from "participantcompliance/ParticipantCompliance";
import StudySites from "studysite/StudySites";
import NonCompliantDetail from "home/NonCompliantDetail";
import ComplianceSetting from "compliancesetting/ComplianceSetting";
import SessionController from "core/SessionController";

function App() {
  const appState = useAppSelector((state) => state.appState);
  const auth0Config = useAppSelector((state) => state.auth0Config);

  if (!appState.initialized) {
    return <Loading />;
  }

  // Do not load the Auth0Provider until auth0Config is ready, because even though auth0Config
  // is globally state-managed, it does not update its props after initialization.
  return (
    <Auth0Provider {...auth0Config}>
      <Auth0AuthenticatedApp />
    </Auth0Provider>
  );
}

// The root app component under Auth0Provider.
function Auth0AuthenticatedApp() {
  const { error } = useAuth0();
  if (error) {
    return (
      <Error message={error.message ? error.message : "Authentication error"} />
    );
  }

  return (
    <BrowserRouter>
      <>
        <LoginController />
        <SessionController />
        <Routes>
          <Route path={AppRoutes.ROOT} element={<InitialSetup />} />
          <Route path={AppRoutes.STUDIES} element={<Studies />} />
          <Route path={AppRoutes.HOME} element={<Main />}>
            <Route index element={<ComplianceHome />} />
            <Route
              path={AppRoutes.COMPLIANCE_SETTING}
              element={<ComplianceSetting />}
            />
            <Route path={AppRoutes.STUDY_SITES} element={<StudySites />} />
            <Route
              path={AppRoutes.STUDY_PARTICIPANTS}
              element={<StudyParticipants />}
            />
            <Route path={AppRoutes.STUDY_DETAIL} element={<StudyDetail />} />
            <Route
              path={AppRoutes.STUDY_USERS_HOME}
              element={<StudyUsersHome />}
            />
            <Route path={AppRoutes.TEST_TOOL} element={<TestTools />} />
          </Route>
          <Route path={AppRoutes.ADMIN} element={<AdminMain />}>
            <Route index={true} element={<AdminHome />} />
            <Route path={AppRoutes.ADMIN_TEST_TOOL} element={<TestTools />} />
          </Route>
          <Route
            path={AppRoutes.ACCESS_DENIED}
            element={<AccessDeniedScreen />}
          />
          <Route
            path={AppRoutes.EDIT_COMPLIANCE_SETTING}
            element={<EditComplianceSetting />}
          />
          <Route
            path={AppRoutes.PARTICIPANT_COMPLIANCE}
            element={<ParticipantCompliance />}
          />
          <Route
            path={AppRoutes.NON_COMPLIANT_DETAIL}
            element={<NonCompliantDetail />}
          />
          <Route path="*" element={<Navigate to={AppRoutes.HOME} />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
