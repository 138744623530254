import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const AccessDeniedScreen: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="body1">
        Can't find user account. Please ask your study admin to set up your
        account first.
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          logout({
            returnTo: window.location.origin,
          });
        }}
        sx={{ marginTop: "32px" }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default AccessDeniedScreen;
