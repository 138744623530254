import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { getDatesSurroundingMonth } from "./Month";
import { WatchData } from "./CalendarTypes";
import { Box, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useMemo } from "react";
import { formatISO, isAfter, isSameDay, isSameMonth, subDays } from "date-fns";
import { DeviceAssignment } from "looker/CalendarViewQueryResultsCache";
import { RovingTabIndexProvider } from "react-roving-tabindex";
import { CalendarDay, MedicationTagSetting } from "./CalendarTypes";
import { parseDuration } from "./CalendarUtils";
import { GreyBarType, scanGreyBars } from "./NoDataBars";
import { WatchStartDayCalendarCell } from "./WatchStartDayCalendarCell";
import { NoDataCalendarCell } from "./NoDataCalendarCell";
import { CalendarCell } from "./CalendarCell";
import { HeaderCell } from "./CalendarHeaderCell";
import { ComplianceScope } from "home/NonCompliantTabDataLoader";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { fromDateProto } from "common/Dates";
import { ExitStudyDayCalendarCell } from "./ExitStudyDayCalendarCell";

interface CalendarProps {
  /**
   * The first of the month in the local timezone,
   * e.g. `new Date('2024-02-01 00:00').
   */
  monthStart: Date;
  today: Date;
  /** Should be sorted by date. */
  watchData: WatchData[];
  complianceSetting: ComplianceSetting;
  latestSync: Date;
  devices: DeviceAssignment[];
  scope?: ComplianceScope;
  participant: StudyParticipant;
}

interface WatchDataByDate {
  [date: string]: WatchData | undefined;
}

const Calendar: React.FC<CalendarProps> = ({
  monthStart,
  today,
  watchData,
  complianceSetting,
  latestSync,
  devices,
  scope,
  participant,
}) => {
  const dataByDate: WatchDataByDate = useMemo(() => {
    const map: WatchDataByDate = {};
    for (const data of watchData) {
      map[data.date] = data;
    }
    return map;
  }, [watchData]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const headerCells = days.map((day) => <HeaderCell key={day} day={day} />);
  const headerRow = <tr>{headerCells}</tr>;

  let medTagSetting: MedicationTagSetting = {
    frequency: undefined,
    requiredCount: 0,
  };

  if (complianceSetting.getMedsLoggedPerDay() > 0) {
    medTagSetting.frequency = "daily";
    medTagSetting.requiredCount = complianceSetting.getMedsLoggedPerDay();
  } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
    medTagSetting.frequency = "weekly";
    medTagSetting.requiredCount = complianceSetting.getMedsLoggedPerWeek();
  }

  const participantExitStudyDate =
    participant.getExitStudyDate() != null
      ? fromDateProto(participant.getExitStudyDate()!)
      : null;

  const datesToRender = getDatesSurroundingMonth(monthStart);
  const calendarDays = datesToRender.map((date) => {
    const day: CalendarDay = {
      date,
      today: isSameDay(date, today),
      outOfMonth: !isSameMonth(date, monthStart),
    };

    const afterToday = isAfter(date, today);
    if (afterToday) {
      return day;
    }

    const isoDate = formatISO(date, { representation: "date" });
    const data = dataByDate[isoDate];
    if (data == null || isEmpty(data)) {
      const afterLastSync = isAfter(date, latestSync);
      if (afterLastSync) {
        day.awaitingData = true;
      } else {
        day.noData = true;
      }

      return day;
    }

    if (data.wearHrs) {
      day.watchWearDuration = parseDuration(data.wearHrs);
      day.watchWearCompliant =
        data.wearHrs >= complianceSetting.getWearTimeHoursPerDay();
      day.watchWearTimeRanges = data.wearTimeRanges;
    }
    if (data.syncs) {
      day.watchSync = true;
    }
    day.syncTime = data.syncTime;
    day.surveysPrompted = data.surveysPrompted;
    day.surveysAnswered = data.surveysAnswered;

    if (medTagSetting.frequency !== undefined) {
      day.checkMedicationTag = true;
      day.medicationTags = data.medicationTags;
    }

    return day;
  });

  const daysInWeek = 7;
  const numRows = calendarDays.length / daysInWeek;
  const rows = [];

  for (let i = 0; i < numRows; i++) {
    const startIndex = i * daysInWeek;
    const endIndex = startIndex + daysInWeek;
    const week = calendarDays.slice(startIndex, endIndex);

    rows.push(
      <CalendarRow
        key={i}
        week={week}
        medicationTagSetting={medTagSetting}
        SurveySubmissionPercentage={complianceSetting.getSurveySubmissionPercentage()}
        latestSync={latestSync}
        devices={devices}
        weekIndex={i + 1}
        participantStatus={participant.getStatus()}
        participantExitStudyDate={participantExitStudyDate}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height:
          scope === ComplianceScope.Study
            ? "calc(100vh - 266px)"
            : "calc(100vh - 240px)",
        overflowY: "auto",
      }}
    >
      <RovingTabIndexProvider options={{ focusOnClick: true }}>
        <Table
          aria-label="monthly calendar"
          role="grid"
          style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
        >
          <TableHead>{headerRow}</TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </RovingTabIndexProvider>
    </Box>
  );
};

interface CalendarRowProps {
  week: CalendarDay[];
  weekIndex: number;
  medicationTagSetting: MedicationTagSetting;
  SurveySubmissionPercentage: number;
  latestSync: Date;
  devices: DeviceAssignment[];
  participantStatus: StudyParticipant.ParticipantStatus;
  participantExitStudyDate: Date | null;
}

const CalendarRow: React.FC<CalendarRowProps> = ({
  week,
  weekIndex,
  medicationTagSetting,
  SurveySubmissionPercentage,
  latestSync,
  devices,
  participantStatus,
  participantExitStudyDate,
}) => {
  // Map between watch start day index and watch ID
  const watchStartDateIndexes = new Map<number, string>();
  for (let device of devices) {
    for (let i = 0; i < week.length; i++) {
      const day = week[i];
      if (isSameDay(day.date, device.startTime)) {
        watchStartDateIndexes.set(i, device.deviceId);
      }
    }
  }

  // Find the first watch start date, which is used by scanGreyBars to
  // hide the "No data" bar before the first watch start date.
  let firstWatchStart = subDays(week[0].date, 1);
  if (devices.length > 0) {
    // The device assignments are sorted by start time in ascending order.
    firstWatchStart = devices[0].startTime;
  }

  const bars = scanGreyBars(
    week,
    firstWatchStart,
    participantExitStudyDate,
    latestSync,
    watchStartDateIndexes
  );

  const boxes = week.map((day, i) => {
    if (watchStartDateIndexes.has(i)) {
      return (
        <WatchStartDayCalendarCell
          key={i}
          date={day.date}
          deviceId={watchStartDateIndexes.get(i)!}
          highlight={!!day.today}
          grey={!!day.outOfMonth}
          weekIndex={weekIndex}
        />
      );
    } else if (
      (participantStatus ===
        StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED ||
        participantStatus ===
          StudyParticipant.ParticipantStatus.EXIT_STUDY_WITHDRAWN) &&
      participantExitStudyDate != null &&
      isSameDay(day.date, participantExitStudyDate)
    ) {
      return (
        <ExitStudyDayCalendarCell
          key={i}
          date={day.date}
          participantStatus={participantStatus}
          highlight={!!day.today}
          grey={!!day.outOfMonth}
          weekIndex={weekIndex}
        />
      );
    } else if (
      day.date >= firstWatchStart &&
      (participantExitStudyDate === null ||
        day.date < participantExitStudyDate) &&
      (day.noData || day.awaitingData)
    ) {
      return (
        <NoDataCalendarCell
          key={i}
          date={day.date}
          highlight={!!day.today}
          grey={!!day.outOfMonth}
          type={day.noData ? GreyBarType.NO_DATA : GreyBarType.AWAITING_DATA}
          lastestSync={latestSync}
          weekIndex={weekIndex}
        />
      );
    } else {
      return (
        <CalendarCell
          key={i}
          date={day.date}
          highlight={!!day.today}
          grey={!!day.outOfMonth}
          watchWearDuration={day.watchWearDuration}
          watchWearCompliant={day.watchWearCompliant}
          watchWearTimeRanges={day.watchWearTimeRanges}
          watchSync={day.watchSync}
          syncTime={day.syncTime}
          surveysPrompted={day.surveysPrompted}
          surveysAnswered={day.surveysAnswered}
          SurveySubmissionPercentage={SurveySubmissionPercentage}
          medicationTags={day.medicationTags}
          checkMedicationTag={day.checkMedicationTag}
          medicationTagSetting={medicationTagSetting}
          weekIndex={weekIndex}
        />
      );
    }
  });

  return (
    <TableRow style={{ position: "relative" }}>
      {boxes}
      {bars}
    </TableRow>
  );
};

function isEmpty(data: WatchData): boolean {
  return (
    data.wearHrs === undefined &&
    data.syncs === undefined &&
    data.surveysPrompted === undefined &&
    data.surveysAnswered === undefined &&
    data.medicationTags === undefined
  );
}

export default Calendar;
