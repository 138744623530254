import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { SxProps, Theme, useTheme } from "@mui/material";
import { TextField } from "@verily-src/react-design-system";

// Define a common input text field used in the dialog.
interface InputTextFieldProps {
  id: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  showError?: boolean;
  disabled?: boolean;
  value: string;
  onChange(newValue: string): void;
  sx?: SxProps<Theme>;
}

// Defines an interface for the functions exposed from InputTextField
export interface InputTextFieldRef {
  focus: () => void;
}

export const InputTextField = forwardRef<
  InputTextFieldRef,
  InputTextFieldProps
>(
  (
    {
      id,
      label,
      placeholder,
      helperText,
      showError,
      disabled,
      value,
      onChange,
      sx,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const focus = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    // Expose functions to parent components.
    useImperativeHandle(
      ref,
      () => ({
        focus,
      }),
      [focus]
    );

    const theme = useTheme();
    return (
      <TextField
        id={id}
        label={label}
        inputRef={inputRef}
        placeholder={placeholder}
        autoComplete="off"
        fullWidth
        inputProps={{ style: { fontSize: "16px" } }}
        InputLabelProps={{
          style: { color: theme.palette.text.muted },
        }}
        helperText={helperText}
        error={showError}
        value={value}
        disabled={disabled === undefined ? false : disabled}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        sx={sx !== undefined ? sx : { marginTop: "12px" }}
      />
    );
  }
);
