import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ComplianceSettingState {
  showSuccessMessage: boolean;
  fromScreen: string;
}

const initialState: ComplianceSettingState = {
  showSuccessMessage: false,
  fromScreen: "",
};

const complianceSettingState = createSlice({
  name: "complianceSettingState",
  initialState,
  reducers: {
    setShowSuccessMessage: (state, action: PayloadAction<boolean>) => {
      state.showSuccessMessage = action.payload;
    },
    setFromScreen: (state, action: PayloadAction<string>) => {
      state.fromScreen = action.payload;
    },
  },
});

export const { setShowSuccessMessage, setFromScreen } =
  complianceSettingState.actions;
export default complianceSettingState.reducer;
