import React from "react";
import { Typography, TypographyProps } from "@mui/material";

interface HeaderValuePairProps {
  component: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  title: string;
  value: string;
  fontFamily?: string;
  paragraph?: boolean;
  variant_title?: TypographyProps["variant"];
  variant_value?: TypographyProps["variant"];
}

const HeaderValuePair: React.FC<HeaderValuePairProps> = ({
  component,
  title,
  value,
  fontFamily,
  paragraph,
  variant_title = "body1em",
  variant_value = "body1",
}) => {
  return (
    <Typography
      component={component}
      variant={variant_title}
      aria-label={`${title} ${value}`}
    >
      {title}
      <Typography
        variant={variant_value}
        fontFamily={fontFamily}
        paragraph={paragraph}
      >
        {value}
      </Typography>
    </Typography>
  );
};

export default HeaderValuePair;
