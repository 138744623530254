import { useCallback, useState, useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import { getStudyDetail } from "./GetStudyDetail";

// Defines a custom effect to load data for the study detail screen.
function useStudyDetailDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const userConfig = useAppSelector((state) => state.userConfig);

  const selectedStudy = useMemo(() => {
    const selectedStudies = userConfig.studies.filter(
      (study) => study.registryId === userConfig.selectedRegistryId
    );
    return selectedStudies.length > 0 ? selectedStudies[0] : null;
  }, [userConfig.selectedRegistryId, userConfig.studies]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasExistingStudyDetail, setExisitingStudyDetail] = useState(false);
  const [studyDetail, setStudyDetail] = useState<StudyDetail | null>(null);

  const loadStudyDetail = useCallback(
    (registryId: string) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setIsLoading(true);
          setHasError(false);

          try {
            if (registryId === "") {
              throw new Error("missing selected study");
            }

            // load study detail
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const studyDetail = await getStudyDetail(
              auth0Config.audience!,
              token,
              registryId
            );

            if (studyDetail === null) {
              const defaultDetail = new StudyDetail();
              defaultDetail.setStudyName(selectedStudy?.displayName!);
              setStudyDetail(defaultDetail);
            } else {
              setStudyDetail(studyDetail);
              setExisitingStudyDetail(true);
            }
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      }
    },
    [isAuthenticated, auth0Config, getAccessTokenSilently, selectedStudy]
  );

  return {
    isLoading,
    hasError,
    studyDetail,
    loadStudyDetail,
    hasExistingStudyDetail,
  };
}

export default useStudyDetailDataLoader;
