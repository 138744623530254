import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookerSdk } from "looker/LookerSdk";
import { ComplianceType } from "looker/QueryResultsCache";
import { getParticipantsComplianceRecords } from "looker/GetParticipantsComplianceRecords";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { ParticipantRecord } from "looker/QueryResultsCache";
import { updateReportTimePeriod } from "looker/UpdateReportTimePeriod";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export interface CompliantSummary {
  /** total number of active participants */
  total: number;

  /** number of compliant participants */
  compliant: number;

  records: Array<ParticipantRecord>;
}

// Defines a custom effect to load CRC compliant data.
function useCrcCompliantTabDataLoader() {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<CompliantSummary>({
    total: 0,
    compliant: 0,
    records: new Array<ParticipantRecord>(),
  });

  const loadCompliantData = useCallback(
    (currentRegistryId: string, previousWeekOffset: number) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const weekStart = getWeekStartDate(previousWeekOffset);
            const weekEnd = getWeekEndDate(previousWeekOffset);

            // Set report time period.
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            await updateReportTimePeriod(
              auth0Config.audience!,
              token,
              weekStart,
              weekEnd,
              previousWeekOffset
            );

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              currentRegistryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalCompliance: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalCompliance.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalCompliance.push(ComplianceType.MedLogWeekly);
            }

            const complianceRecords = await getParticipantsComplianceRecords(
              sdk,
              previousWeekOffset,
              optionalCompliance,
              lookerQueryIdsMap
            );

            if (complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            let summary = {
              total: complianceRecords.activeParticipantsCount,
              compliant: complianceRecords.compliantRecords.length,
              records: complianceRecords.compliantRecords,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      auth0Config,
      getAccessTokenSilently,
      isAuthenticated,
      lookerQueryIdsMap,
      sdk,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadCompliantData,
  };
}

export default useCrcCompliantTabDataLoader;
