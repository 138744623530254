import { Box } from "@mui/material";

// Used to create line breaks that aren't interpreted by screen readers
export const A11yLineBreak = () => {
  return (
    <Box aria-hidden="true">
      <br />
    </Box>
  );
};
