import React from "react";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { sxVisuallyHidden } from "common/Accessibility";

// TableHeaderCell for the view details column, which contains a hidden label
// for a11y support.
export interface ViewDetailsTableHeaderCellProps {
  // Optional item name to be used in the hidden label.
  ItemName?: string;
}

export const ViewDetailsTableHeaderCell: React.FC<
  ViewDetailsTableHeaderCellProps
> = ({ ItemName }) => {
  return (
    <TableCell width="48px">
      <Box sx={sxVisuallyHidden}>View {(ItemName ?? "") + " "} details</Box>
    </TableCell>
  );
};
