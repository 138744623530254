// Must be the same value in Country/CountryCode
export const countries = [
  "United States",
  "United Kingdom",
  "Canada",
  "Spain",
  "Germany",
  "Israel",
];

export enum CountryCode {
  US = countries[0] as any,
  GB = countries[1] as any,
  CA = countries[2] as any,
  ES = countries[3] as any,
  DE = countries[4] as any,
  IL = countries[5] as any,
}

export function toCountryCode(country: string) {
  return Object.keys(CountryCode)[Object.values(CountryCode).indexOf(country)];
}

export function toCountry(countryCode: string) {
  return Object.values(CountryCode)[
    Object.keys(CountryCode).indexOf(countryCode)
  ] as String;
}
