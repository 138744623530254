import { a11yOutlineBorder } from "common/Accessibility";

// TablePagination's style to align its UI elements vertically.
export const tablePaginationSxProps = {
  "& > div > .MuiTablePagination-selectLabel": {
    marginBottom: "4px",
  },
  "& > div > .MuiTablePagination-displayedRows": {
    marginBottom: "4px",
  },
  "& > div > .MuiTablePagination-input:focus-within": {
    outline: a11yOutlineBorder,
  },
  "& > div > .MuiTablePagination-actions > .MuiIconButton-root:focus-within": {
    outline: a11yOutlineBorder,
  },
};
