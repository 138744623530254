import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  card_border_radius,
  card_padding,
  compliance_setting_card_min_width,
} from "styles/Dimensions";
import { NumberInput } from "./NumberInput";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";

// Define a component to set survery submission compliance parameters.
interface SurveySubmissionSettingCardProps {
  initValue: ComplianceSetting | null;
}

// Defines an interface for the functions exposed from WearTimeSettingCard
export interface SurveySubmissionSettingCardRef {
  validateInput: () => boolean;
  getSurveySubmissionPercentage: () => number;
}

export const SurveySubmissionSettingCard = forwardRef<
  SurveySubmissionSettingCardRef,
  SurveySubmissionSettingCardProps
>(({ initValue }, ref) => {
  const [surveySubmissionPercentage, setSurveySubmissionPercentage] =
    useState(0);
  const [showError, setShowError] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (initValue) {
      setSurveySubmissionPercentage(initValue.getSurveySubmissionPercentage());
    } else {
      setSurveySubmissionPercentage(0);
    }

    setShowError(false);
  }, [initValue]);

  const validateSurveySubmissionPercentage = useCallback(() => {
    // 0 is a valid value meaning no survey submission percentage being set.
    const isValid =
      surveySubmissionPercentage >= 0 && surveySubmissionPercentage <= 100;
    setShowError(!isValid);
    return isValid;
  }, [surveySubmissionPercentage]);

  const validateInput = useCallback(() => {
    return validateSurveySubmissionPercentage();
  }, [validateSurveySubmissionPercentage]);

  const getSurveySubmissionPercentage = useCallback(() => {
    return surveySubmissionPercentage;
  }, [surveySubmissionPercentage]);

  // Expose functions to parent components.
  useImperativeHandle(
    ref,
    () => ({
      validateInput,
      getSurveySubmissionPercentage,
    }),
    [getSurveySubmissionPercentage, validateInput]
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        minWidth: compliance_setting_card_min_width,
        marginTop: "24px",
        padding: card_padding,
        borderRadius: card_border_radius,
        background: theme.palette.background.canvas,
      }}
    >
      <Typography component="h2" variant="display6" fontWeight="600">
        Survey submission (optional)
      </Typography>
      <Typography variant="caption" sx={{ margin: "8px 0px" }}>
        A survey is only included towards the submission percentage if it is
        entirely complete and submitted.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "top",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <NumberInput
          id="surveySubmission"
          value={surveySubmissionPercentage}
          label="Percentage"
          placeholderText="###"
          helperText={
            showError ? "Enter a number between 1 to 100" : "Between 1 to 100"
          }
          showError={showError}
          onChange={(newValue) => {
            setSurveySubmissionPercentage(newValue);
          }}
          onMouseLeave={() => {
            validateSurveySubmissionPercentage();
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          % of all surveys prompted per week
        </Typography>
      </Box>
    </Box>
  );
});
