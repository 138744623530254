import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import {
  Alert,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TablePagination,
  LinearProgress,
  SelectChangeEvent,
} from "@mui/material";
import {
  Search,
  MultipleSelectFilterChip,
} from "@verily-src/react-design-system";
import SortableTableHeaderCell, {
  Order,
  orderByNumberAriaDescription,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ComplianceSettingPanel from "./ComplianceSettingPanel";
import WeekNavigation from "core/WeekNavigation";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import { tablePaginationSxProps } from "components/Tables";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";
import useStudySiteComplianceTabDataLoader, {
  SiteComplianceRecord,
} from "./StudySiteComplianceTabDataLoader";
import { countries, toCountry } from "common/CountryCode";
import {
  resetStudySiteComplianceTabState,
  setStudySiteComplianceTabComplianceRange,
  setStudySiteComplianceTabOrderBy,
  setStudySiteComplianceTabPageNumber,
  setStudySiteComplianceTabRowsPerPage,
  setStudySiteComplianceTabSearchText,
  setStudySiteComplianceTabSortOrder,
  setStudySiteComplianceTabSelectedCountry,
} from "./StudyComplianceStateSlice";
import { StudySite } from "generated/studysite/studysite_pb";
import { compliantColor, noncompliantColor } from "./StudySummaryTab";
import ComplianceRangeFilter, {
  ComplianceRange,
} from "components/ComplianceRangeFilter";

interface SiteComplianceTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const SiteComplianceTableHeader: React.FC<SiteComplianceTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="site name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Site name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Site name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="site number"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Site number"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Site number</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="location"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Location"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Location</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="active watches"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Active watches"
          orderByAriaDescription={orderByNumberAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Active watches</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="compliance percentage"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Compliance percentage"
          orderByAriaDescription={orderByNumberAriaDescription}
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Compliance percentage</Typography>
        </SortableTableHeaderCell>
      </TableRow>
    </TableHead>
  );
};

function getSiteNumber(site: StudySite) {
  return site.getSiteNumber() ? site.getSiteNumber() : "--";
}

function getCountry(site: StudySite) {
  return site.getCountryCode()
    ? (toCountry(site.getCountryCode()) as string)
    : "--";
}

// Define a component to render site compliance record
interface SiteComplianceRowProps {
  record: SiteComplianceRecord;
}

const SiteComplianceRow: React.FC<SiteComplianceRowProps> = ({ record }) => {
  return (
    <TableRow>
      <TableCell width="20%">{record.site.getSiteName()}</TableCell>
      <TableCell width="20%">{getSiteNumber(record.site)}</TableCell>
      <TableCell width="20%">{getCountry(record.site)}</TableCell>
      <TableCell width="20%">{record.totalActive}</TableCell>
      <TableCell width="20%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ width: "36px" }}>
            {`${record.compliancePercentage}%`}
          </Typography>
          <LinearProgress
            aria-hidden="true"
            variant="determinate"
            value={record.compliancePercentage}
            sx={{
              marginLeft: "16px",
              width: "120px",
              height: "8px",
              backgroundColor: noncompliantColor,
              borderRadius: "4px",
              "& .MuiLinearProgress-bar": {
                backgroundColor: compliantColor,
                borderRadius: "4px",
              },
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

interface SiteComplianceTableContentProps {
  records: Array<SiteComplianceRecord>;
}

const SiteComplianceTableContent: React.FC<SiteComplianceTableContentProps> = ({
  records,
}) => {
  return (
    <TableBody>
      {records.map((record) => (
        <SiteComplianceRow key={record.site.getName()} record={record} />
      ))}
    </TableBody>
  );
};

interface SiteComplianceTableControlProps {
  searchText: string;
  selectedCountry: string[];
  complianceRange: ComplianceRange;
  onRequestSearch(searchText: string): void;
  onCountrySelected(selectedCountry: string[]): void;
  onRequestFilterComplianceRange(range: ComplianceRange): void;
}

const SiteComplianceTableControl: React.FC<SiteComplianceTableControlProps> = ({
  searchText,
  selectedCountry,
  complianceRange,
  onRequestSearch,
  onCountrySelected,
  onRequestFilterComplianceRange,
}) => {
  const countryOptions = useMemo<Array<{ label: string; value: string }>>(
    () =>
      countries.map((country) => ({
        label: country,
        value: country,
      })),
    []
  );

  const handleCountrysSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    onCountrySelected(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        padding: "16px 0px 8px 0px",
      }}
    >
      <Search
        placeHolder="Search for site name, etc"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
      <Box sx={{ marginLeft: "16px" }}>
        <MultipleSelectFilterChip
          placeholder="Country"
          value={selectedCountry}
          onChange={handleCountrysSelected}
          options={countryOptions}
          ariaLabel={"Select Country"}
        />
      </Box>
      <Box sx={{ marginLeft: "16px" }}>
        <ComplianceRangeFilter
          complianceRange={complianceRange}
          onRequestFilter={onRequestFilterComplianceRange}
        />
      </Box>
    </Box>
  );
};

const StudySiteComplianceTab: FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );
  const dispatch = useAppDispatch();

  const { isLoading, hasError, summary, loadStudySiteComplianceData } =
    useStudySiteComplianceTabDataLoader();

  const [filteredAndSortedRecords, setFilteredAndSortedRecords] = useState(
    new Array<SiteComplianceRecord>()
  );

  // Reload data if currently selected study or week changes.
  useEffect(() => {
    loadStudySiteComplianceData(currentRegistryId, previousWeekOffset);
  }, [currentRegistryId, previousWeekOffset, loadStudySiteComplianceData]);

  const {
    studySiteComplianceTabSortOrder: sortOrder,
    studySiteComplianceTabOrderBy: orderBy,
    studySiteComplianceTabPageNumber: page,
    studySiteComplianceTabRowsPerPage: rowsPerPage,
    studySiteComplianceTabSearchText: searchText,
    studySiteComplianceTabSelectedCountry: selectedCountry,
    studySiteComplianceTabComplianceRange: complianceRange,
  } = useAppSelector((state) => state.studyComplianceState);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    dispatch(setStudySiteComplianceTabSortOrder(newSortOrder));
    dispatch(setStudySiteComplianceTabOrderBy(orderByColumn));
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setStudySiteComplianceTabPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setStudySiteComplianceTabRowsPerPage(parseInt(event.target.value, 10))
    );
    dispatch(setStudySiteComplianceTabPageNumber(0));
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  // Calculate filtered and sorted records.
  useEffect(() => {
    const records = getFilteredAndSortedRecords(
      summary.records,
      searchText,
      selectedCountry,
      complianceRange,
      orderBy,
      sortOrder
    );
    setFilteredAndSortedRecords(records);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(records.length));
      previousSearch.current = searchText;
    }
  }, [
    summary.records,
    searchText,
    orderBy,
    sortOrder,
    selectedCountry,
    complianceRange,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <A11yStatusMessage message={searchResultMessage} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginRight: "64px",
          flexGrow: 1,
        }}
      >
        <WeekNavigation
          enabled={!isLoading}
          previousWeekOffset={previousWeekOffset}
          onClickPreviousWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset + 1));
            dispatch(resetStudySiteComplianceTabState());
          }}
          onClickNextWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset - 1));
            dispatch(resetStudySiteComplianceTabState());
          }}
        />
        {isLoading && <SlowLoadingIndicator />}
        {!isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography
              component="h2"
              variant="display6"
              sx={{ marginBottom: "8px" }}
            >
              Compliance data per site
            </Typography>
            <SiteComplianceTableControl
              searchText={searchText}
              selectedCountry={selectedCountry}
              complianceRange={complianceRange}
              onRequestSearch={(searchText) => {
                dispatch(setStudySiteComplianceTabSearchText(searchText));
                dispatch(setStudySiteComplianceTabPageNumber(0));
              }}
              onCountrySelected={(selectedCountry) => {
                dispatch(
                  setStudySiteComplianceTabSelectedCountry(selectedCountry)
                );
                dispatch(setStudySiteComplianceTabPageNumber(0));
              }}
              onRequestFilterComplianceRange={(range) => {
                dispatch(setStudySiteComplianceTabComplianceRange(range));
              }}
            />
            <TableContainer>
              <Table
                sx={{
                  minWidth: 700,
                  marginBottom: "8px",
                }}
                aria-label="compliance data per site"
              >
                <SiteComplianceTableHeader
                  order={sortOrder}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {!hasError && (
                  <SiteComplianceTableContent
                    records={filteredAndSortedRecords.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )}
                  />
                )}
                {hasError && (
                  <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                    Failed to load site compliance data
                  </Alert>
                )}
              </Table>
              <A11yStatusMessage message={sortDirectiontMessage} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredAndSortedRecords.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={tablePaginationSxProps}
            />
          </Box>
        )}
      </Box>
      <Box
        role="complementary"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
        }}
      >
        <ComplianceSettingPanel registryId={currentRegistryId} />
      </Box>
    </Box>
  );
};

function getFilteredAndSortedRecords(
  records: Array<SiteComplianceRecord>,
  searchText: string,
  selectedCountry: string[],
  complianceRange: ComplianceRange,
  orderBy: string,
  sortOrder: string
) {
  return records
    .filter((record) => {
      if (
        record.compliancePercentage < complianceRange.minPercentage ||
        record.compliancePercentage > complianceRange.maxPercentage
      ) {
        return false;
      }

      if (searchText !== "") {
        if (
          record.site
            .getSiteName()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1 &&
          getSiteNumber(record.site)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1 &&
          getCountry(record.site)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1 &&
          record.totalActive
            .toString()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1 &&
          `${record.compliancePercentage}%`
            .toString()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (selectedCountry.length !== 0) {
        if (record.site.getCountryCode() == null) {
          return false;
        }
        if (
          selectedCountry.filter(
            (country) => country === toCountry(record.site.getCountryCode())
          ).length === 0
        )
          return false;
      }

      return true;
    })
    .sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "site name":
          result = a.site.getSiteName().localeCompare(b.site.getSiteName());
          break;
        case "site number":
          const siteNumberA = getSiteNumber(a.site);
          const siteNumberB = getSiteNumber(b.site);
          result = siteNumberA.localeCompare(siteNumberB);
          break;
        case "location":
          const countryA = getCountry(a.site);
          const countryB = getCountry(b.site);
          result = countryA.localeCompare(countryB);
          break;
        case "active watches":
          result = a.totalActive - b.totalActive;
          break;
        case "compliance percentage":
          result = a.compliancePercentage - b.compliancePercentage;
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });
}

export default StudySiteComplianceTab;
