import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Dialog as MUIDialog } from "@mui/material";
import { Footer } from "components/Footer";
import { StepInfo, Steps } from "common/Steps";
import WatchSwapper, { WatchSwapperRef } from "components/WatchSwapper";
import WatchSwapperConfirm, {
  WatchSwapperConfirmRef,
} from "components/WatchSwapperConfirm";
import StepsHeader from "components/StepsHeader";

enum WatchSwapSteps {
  Swap = 0,
  Confirm = 1,
}

interface ParticipantsWatchSwapProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const ParticipantsWatchSwap: React.FC<ParticipantsWatchSwapProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
}) => {
  const watchSwapperRef = useRef<WatchSwapperRef>(null);
  const watchSwapperConfirmRef = useRef<WatchSwapperConfirmRef>(null);

  // Store next button text in a state so that we can set it with
  // a delay to prevent the next button text is announced first when
  // navigate to a new screen.
  const [nextButtonText, setNextButtonText] = useState("Next");

  const [swaps, setSwaps] = useState<Map<string, string>>(new Map([["", ""]]));

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [prevStepId, setPrevStepId] = useState<number>(-1);
  const [stepId, setStepId] = useState<number>(WatchSwapSteps.Swap);

  const stepInfo: Array<StepInfo> = useMemo(() => {
    return [
      {
        id: WatchSwapSteps.Swap,
        page: (
          <WatchSwapper
            ref={watchSwapperRef}
            swaps={swaps}
            setSwaps={setSwaps}
          />
        ),
        onPrevious: () => {
          onClose();
        },
        isPreviousDisabled: false,
        nextButtonText: "Next",
        isNextButtonDisabled: false,
        onNext: () => {
          if (watchSwapperRef.current?.validateSwaps()) {
            setPrevStepId(WatchSwapSteps.Swap);
            setStepId(WatchSwapSteps.Confirm);
          }
        },
      },
      {
        id: WatchSwapSteps.Confirm,
        page: (
          <WatchSwapperConfirm
            ref={watchSwapperConfirmRef}
            swaps={swaps}
            onSuccess={onSuccess}
            onError={(msg: string) => {
              onError(msg);
            }}
          />
        ),
        onPrevious: () => {
          setStepId(prevStepId);
          setPrevStepId(WatchSwapSteps.Swap);
        },
        isPreviousDisabled: false,
        nextButtonText: "Save",
        isNextButtonDisabled: false,
        isNextButtonLoading: confirmLoading,
        onNext: async () => {
          setConfirmLoading(true);
          await watchSwapperConfirmRef.current?.swapWatches();
          setConfirmLoading(false);
        },
      },
    ];
  }, [confirmLoading, onClose, onError, onSuccess, prevStepId, swaps]);

  const steps: Steps = useMemo(() => {
    return new Steps(stepInfo, WatchSwapSteps.Swap);
  }, [stepInfo]);

  const resetFields = useCallback(() => {
    setPrevStepId(-1);
    setStepId(steps.default.id);
    setSwaps(new Map([["", ""]]));
    setNextButtonText("Next");
  }, [steps.default.id]);

  useEffect(() => {
    if (open) {
      resetFields();
    }
  }, [open, resetFields]);

  // focus on form title after transition to previous or next screen
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (stepId === WatchSwapSteps.Swap) {
        watchSwapperRef.current?.focusOnTitle();
        setNextButtonText("Next");
      } else if (stepId === WatchSwapSteps.Confirm) {
        watchSwapperConfirmRef.current?.focusOnTitle();
        setNextButtonText("Save");
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [stepId]);

  return (
    <>
      <MUIDialog open={open} fullScreen={true}>
        <StepsHeader
          title="Swap watches"
          stepId={stepId}
          steps={steps}
          onClose={onClose}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "24px 0px",
          }}
        >
          <Box width="40%">
            {steps.get(stepId)!.page}
            <Footer
              leftButtonText="Previous"
              isLeftButtonDisabled={steps.get(stepId)!.isPreviousDisabled}
              handleClickLeftButton={steps.get(stepId)!.onPrevious}
              rightButtonText={nextButtonText}
              isRightButtonDisabled={steps.get(stepId)!.isNextButtonDisabled}
              isRightButtonLoading={steps.get(stepId)!.isNextButtonLoading}
              handleClickRightButton={steps.get(stepId)!.onNext}
            />
          </Box>
        </Box>
      </MUIDialog>
    </>
  );
};

export default ParticipantsWatchSwap;
