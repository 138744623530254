// source: studydata/studydata.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var compliancesetting_compliancesetting_pb = require('../compliancesetting/compliancesetting_pb.js');
goog.object.extend(proto, compliancesetting_compliancesetting_pb);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.LookerDashboard', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.LookerDashboardId', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.LookerDashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.LookerDashboard.displayName = 'proto.verily.sdp.studydata.v1alpha1.LookerDashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.displayName = 'proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.displayName = 'proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest;
  return proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tokenType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiresIn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse;
  return proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTokenType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiresIn();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token_type = 2;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.getTokenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.setTokenType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 expires_in = 3;
 * @return {number}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.getExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.setExpiresIn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string refresh_token = 4;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string scope = 5;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bqProject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bqDataset: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complianceSetting: (f = msg.getComplianceSetting()) && compliancesetting_compliancesetting_pb.ComplianceSetting.toObject(includeInstance, f),
    registryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest;
  return proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBqProject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBqDataset(value);
      break;
    case 3:
      var value = new compliancesetting_compliancesetting_pb.ComplianceSetting;
      reader.readMessage(value,compliancesetting_compliancesetting_pb.ComplianceSetting.deserializeBinaryFromReader);
      msg.setComplianceSetting(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBqProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBqDataset();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplianceSetting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      compliancesetting_compliancesetting_pb.ComplianceSetting.serializeBinaryToWriter
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string bq_project = 1;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.getBqProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.setBqProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bq_dataset = 2;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.getBqDataset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.setBqDataset = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional verily.sdp.compliancesetting.v1alpha1.ComplianceSetting compliance_setting = 3;
 * @return {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.getComplianceSetting = function() {
  return /** @type{?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} */ (
    jspb.Message.getWrapperField(this, compliancesetting_compliancesetting_pb.ComplianceSetting, 3));
};


/**
 * @param {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting|undefined} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
*/
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.setComplianceSetting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.clearComplianceSetting = function() {
  return this.setComplianceSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.hasComplianceSetting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string registry_id = 4;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string site_id = 5;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse;
  return proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest;
  return proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse;
  return proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    participantId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest;
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticipantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string participant_id = 1;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.prototype.getParticipantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest.prototype.setParticipantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse;
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: (f = msg.getStartDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest;
  return proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Date start_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} returns this
*/
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date end_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} returns this
*/
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse;
  return proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    monthStartDate: (f = msg.getMonthStartDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    monthEndDate: (f = msg.getMonthEndDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest;
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setMonthStartDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setMonthEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonthStartDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMonthEndDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Date month_start_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.getMonthStartDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} returns this
*/
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.setMonthStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.clearMonthStartDate = function() {
  return this.setMonthStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.hasMonthStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date month_end_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.getMonthEndDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} returns this
*/
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.setMonthEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.clearMonthEndDate = function() {
  return this.setMonthEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest.prototype.hasMonthEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse;
  return proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.LookerDashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    queryId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.LookerDashboard;
  return proto.verily.sdp.studydata.v1alpha1.LookerDashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.verily.sdp.studydata.v1alpha1.LookerDashboardId} */ (reader.readEnum());
      msg.setDashboardId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.LookerDashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQueryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional LookerDashboardId dashboard_id = 1;
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboardId}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.getDashboardId = function() {
  return /** @type {!proto.verily.sdp.studydata.v1alpha1.LookerDashboardId} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.LookerDashboardId} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard} returns this
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.setDashboardId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string query_id = 2;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.getQueryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard} returns this
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboard.prototype.setQueryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest;
  return proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} returns this
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dashboardsList: jspb.Message.toObjectList(msg.getDashboardsList(),
    proto.verily.sdp.studydata.v1alpha1.LookerDashboard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse;
  return proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studydata.v1alpha1.LookerDashboard;
      reader.readMessage(value,proto.verily.sdp.studydata.v1alpha1.LookerDashboard.deserializeBinaryFromReader);
      msg.addDashboards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDashboardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studydata.v1alpha1.LookerDashboard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LookerDashboard dashboards = 1;
 * @return {!Array<!proto.verily.sdp.studydata.v1alpha1.LookerDashboard>}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.getDashboardsList = function() {
  return /** @type{!Array<!proto.verily.sdp.studydata.v1alpha1.LookerDashboard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studydata.v1alpha1.LookerDashboard, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studydata.v1alpha1.LookerDashboard>} value
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse} returns this
*/
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.setDashboardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studydata.v1alpha1.LookerDashboard}
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.addDashboards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studydata.v1alpha1.LookerDashboard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse} returns this
 */
proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.prototype.clearDashboardsList = function() {
  return this.setDashboardsList([]);
};


/**
 * @enum {number}
 */
proto.verily.sdp.studydata.v1alpha1.LookerDashboardId = {
  LOOKER_DASHBORD_ID_UNSPECIFIED: 0,
  PARTICIPANTS_DEVICES_ASSOCIATIONS: 1,
  PARTICIPANTS_WEAR_COMPLIANCE: 2,
  PARTICIPANTS_SYNC_COMPLIANCE: 3,
  PARTICIPANTS_MED_LOGGING_DAILY_COMPLIANCE: 4,
  PARTICIPANTS_MED_LOGGING_WEEKLY_COMPLIANCE: 5,
  PARTICIPANTS_LAST_SYNC_TIME: 6,
  PARTICIPANT_WEAR_MINUTES: 100,
  PARTICIPANT_SYNC_TIME: 101,
  PARTICIPANT_DEVICES: 102,
  PARTICIPANT_WEAR_TIME_RANGES: 103,
  PARTICIPANT_MED_LOGGING_DAILY_COUNT: 104,
  PARTICIPANTS_STUDY_DEVICES: 200,
  PARTICIPANTS_STUDY_WEAR_COMPLIANCE: 201,
  PARTICIPANTS_STUDY_SYNC_COMPLIANCE: 202,
  PARTICIPANTS_STUDY_MED_LOGGING_DAILY_COMPLIANCE: 203,
  PARTICIPANTS_STUDY_MED_LOGGING_WEEKLY_COMPLIANCE: 204
};

goog.object.extend(exports, proto.verily.sdp.studydata.v1alpha1);
