import { studyDetailsCache } from "./StudyDetailsCache";
import { StudyDetailServiceApiClient } from "apiclient/StudyDetailServiceApiClient";
import { RpcError, StatusCode } from "grpc-web";

export async function getStudyDetail(
  apiEndpoint: string,
  auth0AccessToken: string,
  registryId: string
) {
  if (studyDetailsCache.has(registryId)) {
    return studyDetailsCache.get(registryId)!;
  }

  try {
    const client = new StudyDetailServiceApiClient(
      apiEndpoint,
      auth0AccessToken
    );

    const setting = await client.getStudyDetail(registryId);

    // Store the result in cache.
    studyDetailsCache.set(registryId, setting);

    return setting;
  } catch (error) {
    if (error instanceof RpcError && error.code === StatusCode.NOT_FOUND) {
      return null;
    } else {
      throw error;
    }
  }
}
