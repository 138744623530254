import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const SlowLoadingIndicator: React.FC = () => {
  const [showLoadingSlowMessage, setShowLoadingSlowMessage] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoadingSlowMessage(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress size={40} />
      <Typography variant="body1em" sx={{ marginTop: "20px" }}>
        {showLoadingSlowMessage
          ? "Page loading is taking longer than usual"
          : "Gathering data"}
      </Typography>
    </Box>
  );
};

export default SlowLoadingIndicator;
