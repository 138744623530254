import React, { useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as WatchIcon } from "assets/watch-icon-black-white.svg";
import { ReactComponent as SyncIcon } from "assets/sync-icon-black-white.svg";
import { ReactComponent as PollIcon } from "assets/poll-icon-black-white.svg";
import { ReactComponent as MedicationPillIcon } from "assets/medication-pill-icon-black-white.svg";
import useComplianceSettingDataLoader from "compliancesetting/ComplianceSettingDataLoader";
import { Tag } from "@verily-src/react-design-system";
import { format } from "date-fns";
import { useAppSelector } from "redux/hooks";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import pluralize from "pluralize";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";

interface ComplianceSettingPanelProps {
  registryId: string;
}

const ComplianceSettingPanel: React.FC<ComplianceSettingPanelProps> = ({
  registryId,
}) => {
  const appConfig = useAppSelector((state) => state.appConfig);

  const {
    isLoading,
    hasError,
    complianceSetting: complianceSettingLoaded,
    loadComplianceSetting,
  } = useComplianceSettingDataLoader();

  // Reload data if currently selected study changes.
  useEffect(() => {
    loadComplianceSetting(registryId);
  }, [registryId, loadComplianceSetting]);

  // Use default compliance setting if compliance setting is not set yet.
  const complianceSetting = useMemo(() => {
    return complianceSettingLoaded === null
      ? getDefaultComplianceSetting()
      : complianceSettingLoaded;
  }, [complianceSettingLoaded]);

  const fontVariant = "body2";
  const cellPadding = "8px";

  const IconCellSxProp = {
    verticalAlign: "top",
    paddingLeft: "0px",
    paddingTop: cellPadding,
    paddingBottom: cellPadding,
  };

  const SettingCellSxProp = {
    verticalAlign: "top",
    paddingLeft: "0px",
    paddingTop: cellPadding,
    paddingBottom: cellPadding,
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: theme.palette.background.canvas,
        borderRadius: "16px",
      }}
    >
      <Typography component="h3" variant="body1em">
        Compliance requirements
      </Typography>
      {complianceSetting !== null && (
        <Typography variant="captionem" sx={{ marginTop: "8px" }}>
          <Tag
            color="primary"
            label={
              "Last updated " +
              format(complianceSetting.getUpdatedTime()!.toDate(), "M/d/yy")
            }
          />
        </Typography>
      )}
      <Divider
        sx={{
          width: "100%",
          marginTop: "16px",
          marginBottom: "8px",
          opacity: 1,
          borderColor: theme.palette.background.separator,
        }}
      />
      {!isLoading && (hasError || complianceSetting === null) && (
        <Alert severity="error">Failed to load compliance requirements</Alert>
      )}
      {!isLoading && !hasError && complianceSetting !== null && (
        <Table sx={{ minWidth: "250px" }} aria-label="compliance setting">
          <TableBody>
            {/* Show wear time compliance parameter */}
            <TableRow>
              <TableCell
                aria-hidden="true"
                width="40px"
                sx={IconCellSxProp}
                style={{ borderBottom: "none" }}
              >
                <WatchIcon />
              </TableCell>
              <TableCell
                width="100%"
                sx={SettingCellSxProp}
                style={{ borderBottom: "none" }}
              >
                <Typography
                  component="h4"
                  variant={fontVariant}
                  fontWeight="600"
                >
                  Watch wear time
                </Typography>
                <Typography variant={fontVariant}>
                  {pluralize(
                    "hour",
                    complianceSetting.getWearTimeHoursPerDay(),
                    true
                  )}{" "}
                  per day
                </Typography>
                <Typography variant={fontVariant}>
                  {pluralize(
                    "day",
                    complianceSetting.getWearTimeDaysPerWeek(),
                    true
                  )}{" "}
                  per week (Sunday through Saturday)
                </Typography>
              </TableCell>
            </TableRow>
            {/* Show sync time compliance parameter */}
            <TableRow>
              <TableCell
                aria-hidden="true"
                width="40px"
                sx={IconCellSxProp}
                style={{ borderBottom: "none" }}
              >
                <SyncIcon />
              </TableCell>
              <TableCell
                width="100%"
                sx={SettingCellSxProp}
                style={{ borderBottom: "none" }}
              >
                <Typography
                  component="h4"
                  variant={fontVariant}
                  fontWeight="600"
                >
                  Watch sync
                </Typography>
                <Typography variant={fontVariant}>
                  {pluralize(
                    "day",
                    complianceSetting.getSyncDaysPerWeek(),
                    true
                  )}{" "}
                  per week (Sunday through Saturday)
                </Typography>
              </TableCell>
            </TableRow>
            {/* Show survey submission compliance parameter if any */}
            {featureFlagProvider.isSurveyEnabled(appConfig.environment) &&
              complianceSetting.getSurveySubmissionPercentage() > 0 && (
                <TableRow>
                  <TableCell
                    aria-hidden="true"
                    width="40px"
                    sx={IconCellSxProp}
                    style={{ borderBottom: "none" }}
                  >
                    <PollIcon />
                  </TableCell>
                  <TableCell
                    width="100%"
                    sx={SettingCellSxProp}
                    style={{ borderBottom: "none" }}
                  >
                    <Typography
                      component="h4"
                      variant={fontVariant}
                      fontWeight="600"
                    >
                      Survey submission
                    </Typography>
                    <Typography variant={fontVariant}>
                      At least{" "}
                      {complianceSetting.getSurveySubmissionPercentage()}% of
                      all surveys prompted
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            {/* Show meds logging compliance parameter if any */}
            {(complianceSetting.getMedsLoggedPerDay() > 0 ||
              complianceSetting.getMedsLoggedPerWeek() > 0) && (
              <TableRow>
                <TableCell
                  aria-hidden="true"
                  width="40px"
                  sx={IconCellSxProp}
                  style={{ borderBottom: "none" }}
                >
                  <MedicationPillIcon />
                </TableCell>
                <TableCell
                  width="30%"
                  sx={SettingCellSxProp}
                  style={{ borderBottom: "none" }}
                >
                  <Typography
                    component="h4"
                    variant={fontVariant}
                    fontWeight="600"
                  >
                    Medication logs
                  </Typography>
                  {complianceSetting.getMedsLoggedPerDay() > 0 && (
                    <Typography variant={fontVariant}>
                      At least{" "}
                      {pluralize(
                        "dose",
                        complianceSetting.getMedsLoggedPerDay(),
                        true
                      )}{" "}
                      logged per day
                    </Typography>
                  )}
                  {complianceSetting.getMedsLoggedPerWeek() > 0 && (
                    <Typography variant={fontVariant}>
                      At least{" "}
                      {pluralize(
                        "dose",
                        complianceSetting.getMedsLoggedPerWeek(),
                        true
                      )}{" "}
                      logged per week
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default ComplianceSettingPanel;
