import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { getParticipantsLastSyncTime } from "looker/GetParticipanstLastSyncTime";
import { getLookerSdk } from "looker/LookerSdk";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

// Defines a custom effect to load data for the study participant screen.
function useStudyParticipantsDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const appConfig = useAppSelector((state) => state.appConfig);

  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoadingParticipants, setIsLoadingParticipants] = useState(false);
  const [isLoadingLastSyncTimes, setIsLoadingLastSyncTimes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const [studyParticipants, setStudyParticipants] = useState(
    new Array<StudyParticipant>()
  );
  const [lastSyncTimes, setLastSyncTimes] = useState(new Map<string, Date>());

  const loadStudyParticipants = useCallback(
    (studySiteId: string) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setIsLoadingParticipants(true);
          setHasError(false);

          try {
            if (studySiteId === "") {
              throw new Error("missing selected study");
            }

            // load study participants
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const client = new StudyParticipantServiceApiClient(
              auth0Config.audience!,
              token
            );

            const response = await client.listStudyParticipants(studySiteId);
            const sortedStudyParticipants = response
              .getStudyParticipantsList()
              .sort((a, b) => {
                return a
                  .getSponsorParticipantId()
                  .localeCompare(b.getSponsorParticipantId());
              });
            setStudyParticipants(sortedStudyParticipants);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          } finally {
            setIsLoadingParticipants(false);
          }
        })();
      }
    },
    [isAuthenticated, auth0Config, getAccessTokenSilently]
  );

  const loadParticipantsLastSyncTimes = useCallback(
    async (studySiteId: string) => {
      if (!isAuthenticated) {
        setHasError(true);
        setIsLoadingLastSyncTimes(false);
        return;
      }

      (async () => {
        setIsLoadingLastSyncTimes(true);
        setHasError(false);

        try {
          if (studySiteId === "") {
            throw new Error("missing selected study");
          }

          // Get compliance metrics simultaneously
          const resp = await getParticipantsLastSyncTime(
            sdk,
            lookerQueryIdsMap
          );

          if (resp.hasError) {
            throw new Error("error getting last sync times");
          }

          setLastSyncTimes(resp.lastSyncTimeMap);
        } catch (error) {
          console.log("error reason %s", error);
          setHasError(true);
        } finally {
          setIsLoadingLastSyncTimes(false);
        }
      })();
    },
    [isAuthenticated, sdk, lookerQueryIdsMap]
  );

  useEffect(() => {
    setIsLoading(isLoadingParticipants || isLoadingLastSyncTimes);
  }, [isLoadingParticipants, isLoadingLastSyncTimes]);

  return {
    isLoading,
    hasError,
    studyParticipants,
    lastSyncTimes,
    loadStudyParticipants,
    loadParticipantsLastSyncTimes,
  };
}

export default useStudyParticipantsDataLoader;
