import {
  Box,
  Stack,
  TableCell,
  Tooltip,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StartIcon } from "@verily-src/react-design-system";
import { useRef } from "react";
import { useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import { tableCellStyle } from "./CalendarTypes";
import { getDateAriaLabel } from "./CalendarUtils";
import { RemovePicardPrefix } from "common/ResourceName";

interface WatchStartDayCalendarCellProps {
  date: Date;
  deviceId: string;
  highlight: boolean;
  grey: boolean;
  weekIndex: number;
}

export const WatchStartDayCalendarCell: React.FC<
  WatchStartDayCalendarCellProps
> = ({ date, deviceId, grey, highlight, weekIndex }) => {
  const theme = useTheme();

  // The ref to the TableCell element.
  const cellRef = useRef<HTMLTableCellElement>(null);

  // handleKeyDown and handleClick are stable for the lifetime of the component:
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    cellRef,
    false,
    weekIndex
  );

  // Set focus on the TableCell when needed.
  useFocusEffect(focused, cellRef);

  let dayCircleColor: string | undefined;
  let dayNumberColor = theme.palette.text.emphasized;
  if (highlight) {
    dayCircleColor = theme.palette.primary.main;
    dayNumberColor = theme.palette.common.white;
  }
  if (grey) {
    if (dayCircleColor) {
      dayCircleColor = lighten(dayCircleColor, 0.5);
    }
    dayNumberColor = theme.palette.text.default;
  }

  // Handle navigation to WatchStartBar
  const [showStartBarFocus, setShowStartBarFocus] = useState(false);
  const [showStartBarTooltip, setShowStartBarTooltip] = useState(false);

  useEffect(() => {
    setShowStartBarFocus(false);
    setShowStartBarTooltip(false);
  }, [date, focused]);

  // Returns whether the keyboard event is handled and the focus should still be
  // inside the table cell.
  const handleIconNavigation = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "ArrowRight") {
      if (!showStartBarFocus) {
        setShowStartBarFocus(true);
        return true;
      }

      if (!showStartBarTooltip) {
        setShowStartBarTooltip(true);
        return true;
      }

      setShowStartBarTooltip(false);
      return false;
    }

    setShowStartBarFocus(false);
    setShowStartBarTooltip(false);
    return false;
  };

  // Hide the compliance icons and colors for watch start date.
  // Otherwise, these icons will overlap with watch start icon.
  return (
    <TableCell
      role="gridcell"
      ref={cellRef}
      tabIndex={tabIndex}
      onKeyDown={(event) => {
        const handled = handleIconNavigation(event);
        if (handled) {
          return;
        }
        handleKeyDown(event);
      }}
      onClick={handleClick}
      sx={{
        ...tableCellStyle,
        padding: "0px",
        borderColor: theme.palette.background.separator,
      }}
    >
      <Stack
        width="100%"
        height="100%"
        minHeight="100px"
        justifyContent="space-between"
        sx={{ padding: "8px" }}
      >
        <Box
          alignSelf="end"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={theme.spacing(3)}
          height={theme.spacing(3)}
          borderRadius="100%"
          bgcolor={dayCircleColor}
          sx={{
            marginBottom: "16px",
          }}
        >
          <Typography
            aria-label={getDateAriaLabel(date)}
            lineHeight="100%"
            color={dayNumberColor}
            fontSize={12}
            variant={grey ? "overline" : "overlineem"}
          >
            {date.getDate()}
          </Typography>
        </Box>
        <WatchStartBar
          deviceId={RemovePicardPrefix(deviceId)}
          showFocus={showStartBarFocus}
          showTooltip={showStartBarTooltip}
        />
      </Stack>
    </TableCell>
  );
};

interface WatchStartBarProps {
  deviceId: string;
  showFocus: boolean;
  showTooltip: boolean;
}

const WatchStartBar: React.FC<WatchStartBarProps> = ({
  deviceId,
  showFocus,
  showTooltip,
}) => {
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (boxRef.current != null) {
      if (showFocus) {
        boxRef.current.focus();
      } else {
        boxRef.current.blur();
      }
    }
  }, [showFocus]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    setTooltipOpen(showTooltip);
  }, [showTooltip]);

  const theme = useTheme();
  return (
    <Tooltip
      open={tooltipOpen}
      aria-live="polite"
      title={
        <Stack>
          <Typography color="white" variant="body2">
            New watch
          </Typography>
          <Typography color="white" variant="captionem">
            Device ID
          </Typography>
          <Typography color="white" variant="caption">
            {deviceId}
          </Typography>
        </Stack>
      }
      slotProps={{
        tooltip: {
          sx: { backgroundColor: theme.palette.background.contrast },
        },
      }}
    >
      <Box
        ref={boxRef}
        tabIndex={showFocus ? 0 : -1}
        width="100%"
        borderRadius="4px"
        bgcolor={theme.palette.info.background}
        flexDirection={"row"}
        display={"flex"}
        sx={{
          paddingY: "4px",
          "&:hover": {
            boxShadow: theme.shadows,
            border: "2px solid",
            borderColor: theme.palette.info.divider,
          },
        }}
        onMouseEnter={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
      >
        <StartIcon
          sx={{
            fontSize: "16px",
            marginLeft: "8px",
            marginRight: "8px",
            color: theme.palette.info.textOnBackground,
          }}
        />
        <Typography
          sx={{
            color: theme.palette.info.textOnBackground,
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Watch start
        </Typography>
      </Box>
    </Tooltip>
  );
};
