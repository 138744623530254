import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "../core/AppRoutes";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { Nav, NavItem, NavItemGroup } from "@verily-src/react-design-system";
import { useEffect, useState } from "react";
import { Role } from "generated/studyauth/studyauth_pb";
import { hasAnyRole, selectCurrentStudyRoles } from "core/UserConfigSlice";
import { Box, boxClasses, drawerClasses, paperClasses } from "@mui/material";
import { header_nav_height } from "styles/Dimensions";
import { setFromScreen } from "compliancesetting/ComplianceSettingSlice";

const Sidebar = () => {
  const { user } = useAuth0();
  const appConfig = useAppSelector((state) => state.appConfig);

  const [bodyMenuItems, setBodyMenuItems] = useState(new Array<NavItem>());
  const [bodyMenuGroups, setBodyMenuGroups] = useState(
    new Array<NavItemGroup>()
  );
  const [footerMenuItems, setFooterMenuItems] = useState(new Array<NavItem>());

  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Set up body menu items.
  useEffect(() => {
    const menuGroups = new Array<NavItemGroup>();

    const complianceMenuGroup = new Array<NavItem>();
    if (hasAnyRole(selectedRoles, Role.CDM)) {
      complianceMenuGroup.push({
        name: "Set parameters",
        path: AppRoutes.COMPLIANCE_SETTING,
        icon: <EditNoteIcon />,
      });
    }

    complianceMenuGroup.push({
      name: "Monitor compliance",
      path: AppRoutes.HOME,
      icon: <ChecklistIcon />,
    });

    if (complianceMenuGroup.length > 1) {
      menuGroups.push({
        name: "Compliance",
        items: complianceMenuGroup,
      });
    } else {
      setBodyMenuItems(complianceMenuGroup);
    }

    const manageMenuGroup = new Array<NavItem>();

    if (hasAnyRole(selectedRoles, Role.CDM)) {
      manageMenuGroup.push({
        name: "Study Details",
        path: AppRoutes.STUDY_DETAIL,
        icon: <AssignmentIcon />,
      });
    }

    if (
      hasAnyRole(
        selectedRoles,
        Role.SPONSOR_OPS,
        Role.CDM,
        Role.COMPLIANCE_ADMIN
      )
    ) {
      manageMenuGroup.push({
        name: "Sites",
        path: AppRoutes.STUDY_SITES,
        icon: <MapsHomeWorkIcon />,
      });
    }

    if (hasAnyRole(selectedRoles, Role.CRC)) {
      manageMenuGroup.push({
        name: "Study participants",
        path: AppRoutes.STUDY_PARTICIPANTS,
        icon: <GroupsIcon />,
      });
    }

    if (
      hasAnyRole(
        selectedRoles,
        Role.SPONSOR_OPS,
        Role.CDM,
        Role.COMPLIANCE_ADMIN,
        Role.CRC
      )
    ) {
      manageMenuGroup.push({
        name: "Users",
        path: AppRoutes.STUDY_USERS_HOME,
        icon: <ManageAccountsIcon />,
      });
    }

    if (manageMenuGroup.length > 0) {
      menuGroups.push({
        name: "Manage",
        items: manageMenuGroup,
      });
    }

    setBodyMenuGroups(menuGroups);
  }, [selectedRoles]);

  // Set up footer menu items.
  useEffect(() => {
    const menuItems = new Array<NavItem>();

    if (appConfig.environment !== "prod") {
      menuItems.push({
        name: "Testing",
        path: AppRoutes.TEST_TOOL,
        icon: <AutoAwesomeIcon />,
      });
    }

    setFooterMenuItems(menuItems);
  }, [appConfig.environment, selectedRoles, user]);

  const navigateToUrl = (path: string) => {
    if (path === AppRoutes.COMPLIANCE_SETTING) {
      dispatch(setFromScreen(""));
    }
    navigate(path);
  };

  return (
    <Box
      sx={{
        [`& .${drawerClasses.paperAnchorLeft}.${paperClasses.root}.${drawerClasses.paper}`]:
          {
            top: header_nav_height,
            height: `calc(100% - ${header_nav_height})`,
          },
        [`& a[pendo-id="nav-verily-logo"]`]: {
          display: "none",
        },
        [`& .${boxClasses.root}:has(a[pendo-id="nav-verily-logo"])`]: {
          height: "4em",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Nav
        homePath={AppRoutes.HOME}
        homeLogo={<></>}
        navigateToUrl={navigateToUrl}
        navItems={bodyMenuItems}
        navItemGroups={bodyMenuGroups}
        navFooterItems={footerMenuItems}
        showDividers={true}
      />
    </Box>
  );
};

export default Sidebar;
