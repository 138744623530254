import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import {
  User,
  StudyInfo,
  StudyRole,
  GetUsersRolesRequest,
} from "generated/studyauth/studyauth_pb";

// Defines a custom effect to load data for the admin home screen.
function useAdminHomeDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState(new Array<User>());

  // Map between user name and user roles.
  const [userRolesMap, setUserRolesMap] = useState(
    new Map<string, StudyRole[]>()
  );

  const [studies, setStudies] = useState(new Array<StudyInfo>());

  // Map between registryId and study names.
  const [studyNamesMap, setStudyNamesMap] = useState(new Map<string, string>());

  const loadUsers = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showLoadUserError) {
            throw new Error("failed to load user");
          }

          setIsLoading(true);
          setHasError(false);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const response = await client.listUsers();
          setUsers(response.getUsersList());

          // Get user roles.
          if (response.getUsersList().length > 0) {
            const userNames = response
              .getUsersList()
              .map((user) => user.getName());
            const getRolesRequest = new GetUsersRolesRequest()
              .setUserNamesList(userNames)
              .setWithStudyInfo(false);

            const getRolesReponse = await client.getUsersRoles(getRolesRequest);

            // Build a map between user name and user roles.
            const userRolesMap = new Map(
              getRolesReponse
                .getUserRolesList()
                .map((userRoles) => [
                  userRoles.getUserName(),
                  userRoles.getStudyRolesList(),
                ])
            );
            setUserRolesMap(userRolesMap);
          }

          // load all available studies.
          const listStudiesResponse = await client.listStudies();
          const sortedStudies = listStudiesResponse
            .getStudiesList()
            .sort((a: StudyInfo, b: StudyInfo) => {
              return a.getDisplayName().localeCompare(b.getDisplayName());
            });
          setStudies(sortedStudies);

          // Build a map between registry id and study name.
          const studyNamesMap = new Map(
            sortedStudies.map((study) => [
              study.getRegistryId(),
              study.getDisplayName(),
            ])
          );
          setStudyNamesMap(studyNamesMap);
        } catch (error) {
          console.log("error reason %s", error);
          setHasError(true);
        }

        setIsLoading(false);
      })();
    }
  }, [
    isAuthenticated,
    auth0Config,
    testConfig.showLoadUserError,
    getAccessTokenSilently,
  ]);

  return {
    isLoading,
    hasError,
    users,
    userRolesMap,
    studies,
    studyNamesMap,
    loadUsers,
  };
}

export default useAdminHomeDataLoader;
