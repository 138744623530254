import { Metadata } from "grpc-web";
import { StudyDataServicePromiseClient } from "generated/studydata/studydata_grpc_web_pb";
import {
  AuthorizeUserRequest,
  ListDashboardsRequest,
  UpdateParticipantReportTimePeriodRequest,
  UpdateParticipantRequest,
  UpdateReportTimePeriodRequest,
  UpdateSiteRequest,
  UpdateStudyRequest,
} from "generated/studydata/studydata_pb";

/**
 * Defines helper methods to call StudyDataService.
 */
export class StudyDataServiceApiClient {
  private gRpcClient: StudyDataServicePromiseClient;

  /**
   * Creates an instance of StudyDataServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndpoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndpoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new StudyDataServicePromiseClient(
      apiEndpoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  authorizeUser() {
    return this.gRpcClient.authorizeUser(
      new AuthorizeUserRequest(),
      this.getGrpcMetaData()
    );
  }

  updateStudy(request: UpdateStudyRequest) {
    return this.gRpcClient.updateStudy(request, this.getGrpcMetaData());
  }

  updateSite(request: UpdateSiteRequest) {
    return this.gRpcClient.updateSite(request, this.getGrpcMetaData());
  }

  updateReportTimePeriod(request: UpdateReportTimePeriodRequest) {
    return this.gRpcClient.updateReportTimePeriod(
      request,
      this.getGrpcMetaData()
    );
  }

  updateParticipant(request: UpdateParticipantRequest) {
    return this.gRpcClient.updateParticipant(request, this.getGrpcMetaData());
  }

  updateParticipantReportTimePeriod(
    request: UpdateParticipantReportTimePeriodRequest
  ) {
    return this.gRpcClient.updateParticipantReportTimePeriod(
      request,
      this.getGrpcMetaData()
    );
  }

  listDashboards(request: ListDashboardsRequest) {
    return this.gRpcClient.listDashboards(request, this.getGrpcMetaData());
  }
}
