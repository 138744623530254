import { Looker40SDK } from "@looker/sdk";

import {
  DeviceAssignment,
  getParticipantMonthCacheKey,
  participantDevicesCache,
} from "./CalendarViewQueryResultsCache";
import { parseISO } from "date-fns";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get a participant's device assignments for a given month.
 *
 * The function assumes that the Looker user attributes for participant id,
 * month start date and month end date are set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantDevices(
  sdk: Looker40SDK,
  studyParticipantId: string,
  monthStartDate: Date,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<DeviceAssignment>();
  let hasError = false;

  try {
    const cacheKey = getParticipantMonthCacheKey(
      studyParticipantId,
      monthStartDate
    );

    if (participantDevicesCache.has(cacheKey)) {
      return {
        records: participantDevicesCache.get(cacheKey)!!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(LookerDashboardId.PARTICIPANT_DEVICES)!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        /**
         * Both start time and end time are in ISO 8601 format for UTC time.
         * For example: 2024-02-12T16:30:50Z.
         *
         * They both contain none-null values.
         */
        const startTime = parseISO(row["participant_devices.start_time"]);
        const endTime = parseISO(row["participant_devices.end_time"]);

        records.push({
          deviceId: row["participant_devices.device_id"],
          startTime: startTime,
          endTime: endTime,
        });
      }

      records.sort((a, b) => {
        if (a.startTime < b.startTime) {
          return -1;
        } else if (a.startTime > b.startTime) {
          return 1;
        } else {
          return 0;
        }
      });

      // Store the results in cache.
      participantDevicesCache.set(cacheKey, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { records: records, hasError: hasError };
}
