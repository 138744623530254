import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  SelectChangeEvent,
  Alert,
  AlertTitle,
  useTheme,
  Box,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import {
  MultipleSelectDropdown,
  Button,
} from "@verily-src/react-design-system";
import {
  AddUserRolesRequest,
  CreateUserRequest,
  EmailType,
  GetUserByEmailRequest,
  GetUsersRolesRequest,
  Role,
  SendEmailRequest,
  StudyInfo,
  StudyRole,
  User,
} from "generated/studyauth/studyauth_pb";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "redux/hooks";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { RpcError, StatusCode } from "grpc-web";
import UserInfoForm, { UserInfoFormRef } from "components/UserInfoForm";
import {
  getAddUserDialogTitle,
  getUserRoleDescription,
} from "components/UserRenderers";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface InviteStudyAdminDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  /** Assign user the role specified in userRole */
  userRole: Role;
  studies: StudyInfo[];
  onClose(): void;
  onSuccess(): void;
}

// The dialog box for inviting a study admin.
const InviteStudyAdminDialog: React.FC<InviteStudyAdminDialogProps> = ({
  open,
  userRole,
  studies,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const userInfoFormRef = useRef<UserInfoFormRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [selectedRegistryIds, setSelectedRegistryIds] = React.useState<
    string[]
  >([]);
  const [selectedRegistryIdsErrorMessage, setSelectedRegistryIdsErrorMessage] =
    useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [disableAddUserButton, setDisableAddUserButton] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setSelectedRegistryIds([]);
      setSelectedRegistryIdsErrorMessage("");
      setErrorMessage("");
    }
  }, [open]);

  const handleRegistryIdsSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setSelectedRegistryIdsErrorMessage("");
    setSelectedRegistryIds(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  const registryIdsRef = useRef<HTMLInputElement>(null);

  const validateInputs = useCallback(async () => {
    let isInputsValid = await userInfoFormRef.current!.validateInput(
      true /* focusOnError */
    );

    if (selectedRegistryIds.length < 1) {
      setSelectedRegistryIdsErrorMessage("Study required");

      if (isInputsValid) {
        registryIdsRef.current?.focus();
      }

      isInputsValid = false;
    }

    if (
      !updateReasonFormRef.current!.validateInput(
        isInputsValid /* focusOnError */
      )
    ) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, [selectedRegistryIds.length]);

  const handleClickAddUserButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showInviteUserError) {
            throw new Error("failed to invite user");
          }

          setDisableAddUserButton(true);

          const isInputsValid = await validateInputs();
          if (!isInputsValid) {
            setDisableAddUserButton(false);
            return;
          }

          const user = userInfoFormRef.current!.getUserInfo();

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          // Check if the user already exists.
          let existingUser: User | undefined;
          try {
            const requst = new GetUserByEmailRequest().setEmail(
              user.getEmailAddress()
            );
            existingUser = await client.getUserByEmail(requst);
          } catch (error) {
            if (
              !(
                error instanceof RpcError && error.code === StatusCode.NOT_FOUND
              )
            ) {
              throw error;
            }
          }

          const selectedRegistryIdsSet = new Set(selectedRegistryIds);

          let userName: string;
          if (existingUser === undefined) {
            // Create the user if it does not exist.
            const request = new CreateUserRequest()
              .setUser(user)
              .setUpdateReason(updateReason);
            const newUser = await client.createUser(request);
            userName = newUser.getName();
          } else {
            userName = existingUser.getName();

            const getRolesRequest = new GetUsersRolesRequest()
              .setUserNamesList([userName])
              .setWithStudyInfo(false);

            const getRolesReponse = await client.getUsersRoles(getRolesRequest);

            // Make sure that new user roles do not conflict with existing ones.
            const userRolesList = getRolesReponse.getUserRolesList();
            if (userRolesList.length > 0) {
              for (let studyRole of userRolesList[0].getStudyRolesList()) {
                if (selectedRegistryIdsSet.has(studyRole.getRegistryId())) {
                  if (studyRole.getRole() === userRole) {
                    // No need to add the role for the study if the user already has the role.
                    selectedRegistryIdsSet.delete(studyRole.getRegistryId());
                  } else {
                    // Show an error message if the user has a different role for the study.
                    setErrorMessage(
                      "This user was invited to one of these studies in a different role. " +
                        "Please remove them from that role. Then reassign them to the new role."
                    );
                    setDisableAddUserButton(false);
                    return;
                  }
                }
              }
            }
          }

          // Add user roles.
          if (selectedRegistryIdsSet.size > 0) {
            const studyRoles = Array.from(selectedRegistryIdsSet).map(
              (registryId) => {
                return new StudyRole()
                  .setRegistryId(registryId)
                  .setRole(userRole);
              }
            );

            const addRolesRequest = new AddUserRolesRequest()
              .setUserName(userName)
              .setStudyRolesList(studyRoles)
              .setUpdateReason(updateReason);

            await client.addUserRoles(addRolesRequest);
          }

          // Send invitation email for a new user
          if (existingUser === undefined) {
            const sendEmailRequest = new SendEmailRequest()
              .setName(userName)
              .setEmailType(EmailType.INVITATION)
              .setUserRole(userRole)
              .setUpdateReason(updateReason);

            await client.sendEmail(sendEmailRequest);
          }

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to invite the user. Please try again.");
        }

        setDisableAddUserButton(false);
      })();
    } else {
      setErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    selectedRegistryIds,
    testConfig.showInviteUserError,
    userRole,
    validateInputs,
  ]);

  const selectStudyOptions = useMemo(() => {
    const options = studies.map((study) => {
      return { value: study.getRegistryId(), label: study.getDisplayName() };
    });
    return options;
  }, [studies]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
      data-testid="invite-study-admin-dialog"
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6">
          {getAddUserDialogTitle(userRole)}
        </Typography>
      </A11yDialogTitle>
      <DialogContent>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Invitation failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography variant="body1" sx={{ padding: "4px 0px 16px 0px" }}>
          {getUserRoleDescription(userRole)}
        </Typography>
        <UserInfoForm ref={userInfoFormRef} verilyEmailRequired={true} />
        <Typography variant="body1em">Study</Typography>
        <MultipleSelectDropdown
          labelId="SelectStudy"
          label="Selected studies"
          inputRef={registryIdsRef}
          placeholder="Select (required)"
          helperText={selectedRegistryIdsErrorMessage}
          error={selectedRegistryIdsErrorMessage.length > 0}
          value={selectedRegistryIds}
          onChange={handleRegistryIdsSelected}
          options={selectStudyOptions}
          sx={{ width: "600px" }}
          formControlSx={{ marginTop: "12px" }}
        />
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Access required to perform job responsibilities",
              "Temporary access required to perform troubleshooting",
            ]}
            reasonSelectionLabel="Reason for adding user (required)"
            reasonRequiredMessage="Reason for adding user required"
            otherReasonHint="Please provide reason this user is being added"
            otherReasonRequiredMessage="Other reason for adding user required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
          data-testid="cancel-button"
        />
        <Button
          label="Add user"
          variant="filled"
          onClick={handleClickAddUserButton}
          disabled={disableAddUserButton}
          data-testid="continue-button"
        />
      </DialogActions>
    </Dialog>
  );
};

export default InviteStudyAdminDialog;
