import React from "react";
import { Box } from "@mui/material";

interface ErrorProps {
  message: string;
}

const ErrorScreen: React.FC<ErrorProps> = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {message}
    </Box>
  );
};

export default ErrorScreen;
