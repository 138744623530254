import React, { useEffect } from "react";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@verily-src/react-design-system";
import AdminUsers from "./AdminUsers";
import { TabPanel, tabA11yProps } from "components/TabPanel";
import useAdminHomeDataLoader from "./AdminHomeDataLoader";
import { Role } from "generated/studyauth/studyauth_pb";
import SuperAdmins from "admin/superadmins/SuperAdmins";
import useSuperAdminsDataLoader from "admin/superadmins/SuperAdminsDataLoader";
import { usePageTitle } from "components/PageTitle";

const AdminHome: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const {
    isLoading: isLoadingAdminUsers,
    hasError: hasLoadAdminUsersError,
    users,
    userRolesMap,
    studies,
    studyNamesMap,
    loadUsers,
  } = useAdminHomeDataLoader();

  const {
    isLoadingSuperAdmins,
    hasLoadSuperAdminError,
    superAdmins,
    loadSuperAdmins,
  } = useSuperAdminsDataLoader();

  // Set page title.
  usePageTitle("Manage users");

  useEffect(() => {
    loadUsers();
    loadSuperAdmins();
  }, [loadSuperAdmins, loadUsers]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 40px 24px 40px",
        }}
      >
        <Box sx={{ marginBottom: "8px" }}>
          <Typography component="h1" variant="display5">
            Manage users
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTabIndex} onChange={handleChange}>
              <Tab label="Dev Ops (internal)" {...tabA11yProps(0)} />
              <Tab label="Sponsor Ops (internal)" {...tabA11yProps(1)} />
              <Tab label="CDMs (internal)" {...tabA11yProps(2)} />
            </Tabs>
          </Box>
          {(hasLoadAdminUsersError || hasLoadSuperAdminError) && (
            <Alert
              severity="error"
              sx={{ paddingLeft: "16px", marginTop: "8px" }}
            >
              Failed to load users
            </Alert>
          )}
          {!hasLoadAdminUsersError && !hasLoadSuperAdminError && (
            <>
              <TabPanel selectedTabIndex={selectedTabIndex} tabIndex={0}>
                <SuperAdmins
                  users={superAdmins}
                  reloadData={() => {
                    loadSuperAdmins();
                  }}
                />
              </TabPanel>
              <TabPanel selectedTabIndex={selectedTabIndex} tabIndex={1}>
                <AdminUsers
                  users={users}
                  userRolesMap={userRolesMap}
                  userRoleFilter={Role.SPONSOR_OPS}
                  studies={studies}
                  studyNamesMap={studyNamesMap}
                  reloadData={() => {
                    loadUsers();
                  }}
                />
              </TabPanel>
              <TabPanel selectedTabIndex={selectedTabIndex} tabIndex={2}>
                <AdminUsers
                  users={users}
                  userRolesMap={userRolesMap}
                  userRoleFilter={Role.CDM}
                  studies={studies}
                  studyNamesMap={studyNamesMap}
                  reloadData={() => {
                    loadUsers();
                  }}
                />
              </TabPanel>
            </>
          )}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme: { zIndex: { drawer: number } }) =>
                theme.zIndex.drawer + 1,
            }}
            open={isLoadingAdminUsers || isLoadingSuperAdmins}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </Box>
    </>
  );
};

export default AdminHome;
