import { getDate, getMonth, getYear } from "date-fns";
import { Date as DateProto } from "generated/google/type/date_pb";

export function toDateProto(date: Date) {
  const year = getYear(date);
  const month = getMonth(date) + 1; // Months are 0-indexed
  const day = getDate(date);
  return new DateProto().setYear(year).setMonth(month).setDay(day);
}

export function fromDateProto(date: DateProto) {
  // JS dates are 0-indexed; Proto dates are 1-indexed
  return new Date(date.getYear(), date.getMonth() - 1, date.getDay());
}

export function parseDate(date: Date) {
  return new Date(date.toDateString());
}
