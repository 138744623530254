import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog as MUIDialog,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, ClearIcon, TextField } from "@verily-src/react-design-system";
import {
  CreateStudyParticipantRequest,
  LookupSponsorParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import {
  CreateParticipantSessionRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import { RpcError, StatusCode } from "grpc-web";
import { SDPDatePicker } from "components/SDPDatePicker";
import WatchAssignmentField, {
  WatchAssignmentFieldRef,
} from "./WatchAssignmentField";
import DuplicateParticipantFoundDialog from "studyparticipant/DuplicateParticipantFoundDialog";
import { ParseIdFromName } from "common/ResourceName";
import { Interval } from "generated/google/type/interval_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { isValid } from "date-fns";
import { parseDate, toDateProto } from "common/Dates";
import { focusOnRef, sxFocusWithin, sxFullScreen } from "common/Accessibility";
import SDPErrorSnackbar from "components/SDPErrorSnackbar";
import ConfirmAddParticipantDialog from "./ConfirmAddParticipantDialog";

interface AddStudyParticipantDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  onSuccess(msg: string): void;
  registryId: string;
  studySiteId: string;
}

// The dialog box for adding a Study Participant
const AddStudyParticipantDialog: React.FC<AddStudyParticipantDialogProps> = ({
  open,
  onClose,
  onSuccess,
  registryId,
  studySiteId,
}) => {
  const theme = useTheme();

  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [participantId, setParticipantId] = useState("");
  const [participantIdHasError, setParticipantIdHasError] = useState(false);
  const [participantIdErrorMessage, setParticipantIdErrorMessage] =
    useState("");

  const [enrollmentStartDate, setEnrollmentStartDate] = useState<Date>(
    parseDate(new Date())
  );

  const [datePickerValue, setDatePickerValue] = useState<
    Date | null | undefined
  >(parseDate(new Date()));
  const [datePickerError, setDatePickerError] = useState<boolean>(false);
  const [datePickerErrorText, setDatePickerErrorText] = useState<string>("");

  const [showConfirmAddParticipantDialog, setShowConfirmAddParticipantDialog] =
    useState(false);

  const [duplicateParticipantDialogOpen, setDuplicateParticipantDialogOpen] =
    useState(false);

  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const watchAssignmentRef = useRef<WatchAssignmentFieldRef>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setParticipantId("");
      setEnrollmentStartDate(parseDate(new Date()));
      setSelectedDeviceId("");
      setParticipantIdHasError(false);
      setParticipantIdErrorMessage("");
      setDatePickerValue(parseDate(new Date()));
      setDatePickerError(false);
      setDatePickerErrorText("");
      setSaveLoading(false);
      setConfirmLoading(false);
      setShowConfirmAddParticipantDialog(false);
      setTimeout(() => {
        focusOnRef(titleRef);
      }, 0);
    }
  }, [open]);

  const addNewParticipant = useCallback(async () => {
    if (!isAuthenticated || !auth0Config) {
      setIsErrorSnackbarOpen(true);
      setErrorSnackbarMessage(
        "User not authenticated. Please logout and login again."
      );
      return;
    }

    await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const studyParticipantClient = new StudyParticipantServiceApiClient(
          auth0Config.audience!,
          token
        );

        const participantSessionApiClient =
          new ParticipantSessionServiceApiClient(auth0Config.audience!, token);

        const response = await studyParticipantClient.createStudyParticipant(
          new CreateStudyParticipantRequest().setStudyParticipant(
            new StudyParticipant()
              .setRegistryId(registryId)
              .setStudySiteId(studySiteId)
              .setSponsorParticipantId(participantId)
              .setEnrollmentStartTime(Timestamp.fromDate(enrollmentStartDate))
              .setEnrollmentStartDate(toDateProto(enrollmentStartDate))
              .setStatus(StudyParticipant.ParticipantStatus.ENROLLED)
          )
        );

        // Use current day as enrollment date for now.
        const studyParticipantId = ParseIdFromName(response.getName());
        await participantSessionApiClient.createParticipantSession(
          new CreateParticipantSessionRequest().setParticipantSession(
            new ParticipantSession()
              .setName(`participantSessions/${studyParticipantId}`)
              .setDeviceId(selectedDeviceId)
              .setInterval(
                new Interval().setStartTime(
                  Timestamp.fromDate(enrollmentStartDate)
                )
              )
          )
        );

        setSelectedDeviceId("");
        onSuccess("Participant added");
      } catch (error) {
        let errMsg = "Failed to add the study participant. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.ALREADY_EXISTS
        ) {
          setDuplicateParticipantDialogOpen(true);
          return;
        } else if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to add a new participant.";
        }

        setIsErrorSnackbarOpen(true);
        setErrorSnackbarMessage(errMsg);
      }
    })();
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    enrollmentStartDate,
    participantId,
    registryId,
    selectedDeviceId,
    studySiteId,
  ]);

  const validateParticipantIdTextField = useCallback(async () => {
    setParticipantIdHasError(false);
    setParticipantIdErrorMessage("");

    if (participantId.length === 0) {
      setParticipantIdHasError(true);
      setParticipantIdErrorMessage("Required");
      return false;
    }

    if (!isAuthenticated || !auth0Config) {
      setIsErrorSnackbarOpen(true);
      setErrorSnackbarMessage(
        "User not authenticated. Please logout and login again."
      );
      return false;
    }

    const isAvailableParticipantId = await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new StudyParticipantServiceApiClient(
          auth0Config.audience!,
          token
        );

        await client.lookupSponsorParticipant(
          new LookupSponsorParticipantRequest()
            .setRegistryId(registryId)
            .setSponsorParticipantId(participantId)
        );

        // If lookupSponsorParticipant doesn't throw a NOT_FOUND error,
        // then the participant ID is already in use
        setDuplicateParticipantDialogOpen(true);
        return false;
      } catch (error) {
        let errMsg = "Failed to update participant ID. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to update participant.";
        } else if (
          error instanceof RpcError &&
          error.code === StatusCode.NOT_FOUND
        ) {
          // We want the participant to be missing
          return true;
        }

        setIsErrorSnackbarOpen(true);
        setErrorSnackbarMessage(errMsg);
        return false;
      }
    })();

    return isAvailableParticipantId;
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    registryId,
    participantId,
  ]);

  const validateDatePickerField = useCallback(() => {
    // Reset error fields
    setDatePickerError(false);
    setDatePickerErrorText("");

    // If selected date is invalid, throw an error
    if (!datePickerValue || !isValid(datePickerValue)) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a valid date.");
      return false;
    }

    // If selected date is in the future, throw an error
    const currentDate = parseDate(new Date());
    if (parseDate(datePickerValue) > currentDate) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a date that is today or earlier.");
      return false;
    }

    return true;
  }, [datePickerValue, setDatePickerError, setDatePickerErrorText]);

  return (
    <>
      <MUIDialog open={open} fullScreen={true} fullWidth={true}>
        <Box
          role="navigation"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "24px 40px",
            background: theme.palette.background.canvas,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginRight: "12px", ...sxFocusWithin }}>
              <IconButton onClick={onClose} aria-label="Exit">
                <ClearIcon />
              </IconButton>
            </Box>
            <Typography
              component="h1"
              variant="display5"
              ref={titleRef}
              tabIndex={-1}
            >
              New Participant
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              label="Cancel"
              color="primary"
              variant="outlined"
              onClick={onClose}
              sx={{
                marginRight: "8px",
              }}
              aria-label="Cancel"
            />
            <Button
              label="Save"
              variant="filled"
              onClick={async () => {
                setSaveLoading(true);
                const isParticipantValid =
                  await validateParticipantIdTextField();
                const isDateValid = validateDatePickerField();
                const isDeviceValid =
                  watchAssignmentRef.current!.validateDeviceTextField();
                if (isParticipantValid && isDateValid && isDeviceValid) {
                  setEnrollmentStartDate(datePickerValue!);
                  await watchAssignmentRef.current!.searchDevices();
                }
                setSaveLoading(false);
              }}
              loading={saveLoading}
              aria-label="Save"
            />
          </Box>
        </Box>
        <Box role="main" sx={sxFullScreen}>
          <Box
            margin="normal"
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0 30%",
              padding: "40px 0px",
            }}
          >
            <Box paddingBottom="42px">
              <Typography component="h2" variant="display6">
                Input participant ID
              </Typography>
              <TextField
                id="participantId"
                label="Participant ID (required)"
                placeholder="XXXXXXXXXXX"
                multiline={false}
                error={participantIdHasError}
                errorText={participantIdErrorMessage}
                value={participantId}
                sx={{ marginTop: "8px" }}
                inputProps={{
                  style: { fontFamily: "Roboto Mono" },
                }}
                onChange={(event) => {
                  setParticipantId(event.target.value);
                }}
              />
            </Box>
            <Box paddingBottom="42px">
              <Typography component="h2" variant="display6">
                Enrollment date
              </Typography>
              <SDPDatePicker
                label="Date (required)"
                value={datePickerValue}
                disableFuture={true}
                error={datePickerError}
                errorText={datePickerErrorText}
                onChange={(selectedDate: Date) => {
                  setDatePickerError(false);
                  setDatePickerErrorText("");

                  setDatePickerValue(selectedDate);
                }}
                sx={{ marginTop: "8px" }}
              ></SDPDatePicker>
            </Box>
            <Box paddingBottom="42px">
              <WatchAssignmentField
                ref={watchAssignmentRef}
                registryId={registryId}
                onSuccess={async () => {
                  const deviceId = watchAssignmentRef.current!.fetchDevice();
                  setSelectedDeviceId(deviceId);
                  setShowConfirmAddParticipantDialog(true);
                }}
              ></WatchAssignmentField>
              <ConfirmAddParticipantDialog
                showConfirmAddParticipantDialog={
                  showConfirmAddParticipantDialog
                }
                setShowConfirmAddParticipantDialog={
                  setShowConfirmAddParticipantDialog
                }
                participantId={participantId}
                enrollmentStartDate={enrollmentStartDate}
                selectedDeviceId={selectedDeviceId}
                confirmLoading={confirmLoading}
                setConfirmLoading={setConfirmLoading}
                addNewParticipant={addNewParticipant}
                watchAssignmentRef={watchAssignmentRef}
              />
            </Box>
          </Box>
        </Box>
        <SDPErrorSnackbar
          showSnackbar={isErrorSnackbarOpen}
          setShowSnackbar={setIsErrorSnackbarOpen}
          errorMessage={errorSnackbarMessage}
        />
      </MUIDialog>
      <DuplicateParticipantFoundDialog
        open={duplicateParticipantDialogOpen}
        onClose={() => {
          setDuplicateParticipantDialogOpen(false);
        }}
      ></DuplicateParticipantFoundDialog>
    </>
  );
};

export default AddStudyParticipantDialog;
