import React, { FC, useEffect, useState, useRef } from "react";
import {
  Alert,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TablePagination,
  useTheme,
  Link,
} from "@mui/material";
import { Search, Tag, Tooltip } from "@verily-src/react-design-system";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import { ReactComponent as ZeroParticipantIllustration } from "assets/zero-participant-illustration.svg";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ComplianceSettingPanel from "./ComplianceSettingPanel";
import WeekNavigation from "core/WeekNavigation";
import useCrcAllParticipantsTabDataLoader, {
  AllParticipantsSummary,
  ComplianceStatus,
  DeviceStatus,
  ParticipantComplianceRecord,
} from "./CrcAllParticipantsTabDataLoader";
import { useNavigate } from "react-router";
import { AppRoutes } from "core/AppRoutes";
import {
  resetParticipantsTabState,
  setParticipantsTabOrderBy,
  setParticipantsTabPageNumber,
  setParticipantsTabRowsPerPage,
  setParticipantsTabSearchText,
  setParticipantsTabSortOrder,
} from "./CrcComplianceStateSlice";
import { setParticipantComplianceState } from "participantcompliance/ParticipantComplianceStateSlice";
import { clearCalendarViewCache } from "looker/CalendarViewQueryResultsCache";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import { tablePaginationSxProps } from "components/Tables";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import { isActivationKey } from "common/Accessibility";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import A11yInfoTooltip from "components/A11yInfoTooltip";
import { RemovePicardPrefix } from "common/ResourceName";

interface AllParticipantsTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const AllParticipantsTableHeader: React.FC<AllParticipantsTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="participant ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Participant ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="30%"
        >
          <Typography variant="body2em">Participant ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="30%"
        >
          <Typography variant="body2em">Device ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device status"
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Device Status</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="compliance status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Compliance status"
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Compliance Status</Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

interface DeviceStatusTagProps {
  status: DeviceStatus;
}

const DeviceStatusTag: React.FC<DeviceStatusTagProps> = ({ status }) => {
  switch (status) {
    case DeviceStatus.Active:
      return <Tag color="info" label="Active" />;
    case DeviceStatus.NoData:
      return <Tag color="neutral" label="Waiting for data" />;
    case DeviceStatus.NoDevice:
      return <Tag color="neutral" label="No watch asssigned" />;
  }
};

interface ComplianceStatusTagProps {
  status: ComplianceStatus;
}

const ComplianceStatusTag: React.FC<ComplianceStatusTagProps> = ({
  status,
}) => {
  switch (status) {
    case ComplianceStatus.Compliant:
      return <Tag color="success" label="Compliant" />;
    case ComplianceStatus.NonCompliant:
      return <Tag color="warning" label="Non-Compliant" />;
    case ComplianceStatus.Unknown:
      return <Typography variant="body1">--</Typography>;
  }
};

function getShowCalendarLinkAriaLabel(record: ParticipantComplianceRecord) {
  let deviceStatus = "No watch asssigned";
  switch (record.deviceStatus) {
    case DeviceStatus.Active:
      deviceStatus = "Active";
      break;
    case DeviceStatus.NoData:
      deviceStatus = "Waiting for data";
      break;
  }

  let complianceStatus = "Unknown";
  switch (record.complianceStatus) {
    case ComplianceStatus.Compliant:
      complianceStatus = "Compliant";
      break;
    case ComplianceStatus.NonCompliant:
      complianceStatus = "Non-Compliant";
      break;
  }

  return `View Participant ID ${record.subjectId}, Device ID ${
    record.deviceId === null ? "--" : RemovePicardPrefix(record.deviceId)
  }, Device Status ${deviceStatus}, Compliance status ${complianceStatus}`;
}

// Define a component to render participant compliant record
interface ParticipantRowProps {
  record: ParticipantComplianceRecord;
  onClickNextButton(record: ParticipantComplianceRecord): void;
}

const ParticipantRow: React.FC<ParticipantRowProps> = ({
  record,
  onClickNextButton,
}) => {
  return (
    <TableRow
      hover
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClickNextButton(record);
      }}
    >
      <TableCell width="30%">{record.subjectId}</TableCell>
      <TableCell width="30%">
        {record.deviceId === null ? "--" : RemovePicardPrefix(record.deviceId)}
      </TableCell>
      <TableCell width="20%">
        <DeviceStatusTag status={record.deviceStatus} />
      </TableCell>
      <TableCell width="20%">
        <ComplianceStatusTag status={record.complianceStatus} />
      </TableCell>
      <TableCell width="48px" sx={{ paddingRight: "12px" }}>
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickNextButton(record);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickNextButton(record);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getShowCalendarLinkAriaLabel(record)}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface AllParticipantsTableContentProps {
  records: Array<ParticipantComplianceRecord>;
  onClickNextButton(record: ParticipantComplianceRecord): void;
}

const AllParticipantsTableContent: React.FC<
  AllParticipantsTableContentProps
> = ({ records, onClickNextButton }) => {
  return (
    <TableBody>
      {records.map((record) => (
        <ParticipantRow
          key={record.subjectId}
          record={record}
          onClickNextButton={onClickNextButton}
        />
      ))}
    </TableBody>
  );
};

const ZeroParticipantView: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <ZeroParticipantIllustration />
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        No enrolled participants
      </Typography>
    </Box>
  );
};

interface SummaryViewProps {
  summary: AllParticipantsSummary;
}

const SummaryView: React.FC<SummaryViewProps> = ({ summary }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        padding: "16px 24px",
        borderRadius: "16px",
        backgroundColor: theme.palette.background.canvas,
      }}
    >
      <Typography variant="body2em">{summary.totalActive}</Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "4px", marginRight: "32px" }}
      >
        active watches
      </Typography>
      <Typography variant="body2em">{summary.totalNoData}</Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "4px", marginRight: "32px" }}
      >
        waiting for data
      </Typography>
      <Typography variant="body2em">{summary.totalNoDevice}</Typography>
      <Typography
        variant="body2"
        sx={{ marginLeft: "4px", marginRight: "32px", flexGrow: 1 }}
      >
        no watch assigned
      </Typography>
      <A11yInfoTooltip
        ariaLabel="Watch status definitions tooltip"
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "220px",
              padding: "4px",
            }}
          >
            <Typography
              variant="body2"
              color={theme.palette.primary.textOnMain}
              sx={{ opacity: 0.87 }}
            >
              Active watches
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.primary.textOnMain}
              sx={{ opacity: 0.6 }}
            >
              Watches are collecting participant data
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.primary.textOnMain}
              sx={{ marginTop: "16px", opacity: 0.87 }}
            >
              Waiting for data
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.primary.textOnMain}
              sx={{ opacity: 0.6 }}
            >
              Watches have been assigned to participants, but aren't collecting
              data yet.
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.primary.textOnMain}
              sx={{ marginTop: "16px", opacity: 0.87 }}
            >
              No watch assigned
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.primary.textOnMain}
              sx={{ opacity: 0.6 }}
            >
              No watch has been assigned to these participants
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

interface AllParticipantsTableControlProps {
  searchText: string;
  onRequestSearch(searchText: string): void;
}

const AllParticipantsTableControl: React.FC<
  AllParticipantsTableControlProps
> = ({ searchText, onRequestSearch }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        padding: "24px 0px 0px 0px",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
    </Box>
  );
};

const CrcAllParticipantsTab: FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );
  const dispatch = useAppDispatch();

  const { isLoading, hasError, summary, loadAllParticipantsData } =
    useCrcAllParticipantsTabDataLoader();

  const [filteredAndSortedRecords, setFilteredAndSortedRecords] = useState(
    new Array<ParticipantComplianceRecord>()
  );

  // Reload data if currently selected study or week changes.
  useEffect(() => {
    loadAllParticipantsData(currentRegistryId, previousWeekOffset);
  }, [currentRegistryId, previousWeekOffset, loadAllParticipantsData]);

  const {
    participantsTabSortOrder: sortOrder,
    participantsTabOrderBy: orderBy,
    participantsTabPageNumber: page,
    participantsTabRowsPerPage: rowsPerPage,
    participantsTabSearchText: searchText,
  } = useAppSelector((state) => state.crcComplianceState);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    dispatch(setParticipantsTabSortOrder(newSortOrder));
    dispatch(setParticipantsTabOrderBy(orderByColumn));
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setParticipantsTabPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setParticipantsTabRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setParticipantsTabPageNumber(0));
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  // Calculate filtered and sorted records.
  useEffect(() => {
    const records = getFilteredAndSortedRecords(
      summary.records,
      searchText,
      orderBy,
      sortOrder
    );
    setFilteredAndSortedRecords(records);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(records.length));
      previousSearch.current = searchText;
    }
  }, [summary.records, searchText, orderBy, sortOrder]);

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <A11yStatusMessage message={searchResultMessage} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginRight: "64px",
          flexGrow: 1,
        }}
      >
        <WeekNavigation
          enabled={!isLoading}
          previousWeekOffset={previousWeekOffset}
          onClickPreviousWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset + 1));
            dispatch(resetParticipantsTabState());
          }}
          onClickNextWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset - 1));
            dispatch(resetParticipantsTabState());
          }}
        />
        {isLoading && <SlowLoadingIndicator />}
        {!isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography
              component="h2"
              variant="display6"
              sx={{ marginBottom: "8px" }}
            >
              {summary.totalEnrolled > 0
                ? `${summary.totalEnrolled} total enrolled participants `
                : "0 enrolled participants"}
            </Typography>
            {!hasError && summary.totalEnrolled === 0 && (
              <ZeroParticipantView />
            )}
            {(hasError || summary.totalEnrolled > 0) && (
              <>
                {!hasError && <SummaryView summary={summary} />}
                <AllParticipantsTableControl
                  searchText={searchText}
                  onRequestSearch={(searchText) => {
                    dispatch(setParticipantsTabSearchText(searchText));
                    dispatch(setParticipantsTabPageNumber(0));
                  }}
                />
                <TableContainer>
                  <Table
                    sx={{
                      minWidth: 700,
                      marginBottom: "8px",
                    }}
                    aria-label="all participants"
                  >
                    <AllParticipantsTableHeader
                      order={sortOrder}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    {!hasError && (
                      <AllParticipantsTableContent
                        records={filteredAndSortedRecords.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )}
                        onClickNextButton={(record) => {
                          dispatch(
                            setParticipantComplianceState({
                              participantId: record.participantId,
                              subjectId: record.subjectId,
                              deviceId: record.deviceId,
                              returnRoute: AppRoutes.HOME,
                            })
                          );
                          clearCalendarViewCache();
                          navigate(AppRoutes.PARTICIPANT_COMPLIANCE);
                        }}
                      />
                    )}
                    {hasError && (
                      <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                        Failed to load participants' data
                      </Alert>
                    )}
                  </Table>
                  <A11yStatusMessage message={sortDirectiontMessage} />
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredAndSortedRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={tablePaginationSxProps}
                />
              </>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
        }}
      >
        <ComplianceSettingPanel registryId={currentRegistryId} />
      </Box>
    </Box>
  );
};

function getFilteredAndSortedRecords(
  records: Array<ParticipantComplianceRecord>,
  searchText: string,
  orderBy: string,
  sortOrder: string
) {
  return records
    .filter((record) => {
      if (searchText !== "") {
        if (
          record.subjectId.toLowerCase().indexOf(searchText.toLowerCase()) ===
            -1 &&
          (record.deviceId == null ||
            RemovePicardPrefix(record.deviceId)
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1)
        ) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "participant ID":
          result = a.subjectId.localeCompare(b.subjectId);
          break;
        case "device ID":
          if (a.deviceId === null && b.deviceId === null) {
            result = 0;
          } else if (a.deviceId === null) {
            result = -1;
          } else if (b.deviceId === null) {
            result = 1;
          } else {
            result = a.deviceId.localeCompare(b.deviceId);
          }
          break;
        case "device status":
          result = a.deviceStatus - b.deviceStatus;
          break;
        case "compliance status":
          result = a.complianceStatus - b.complianceStatus;
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });
}

export default CrcAllParticipantsTab;
