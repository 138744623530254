import { studyDetailsCache } from "./StudyDetailsCache";
import { StudyDetailServiceApiClient } from "apiclient/StudyDetailServiceApiClient";
import {
  StudyDetail,
  CreateStudyDetailRequest,
  UpdateStudyDetailRequest,
} from "generated/studydetail/studydetail_pb";

export async function updateStudyDetail(
  apiEndpoint: string,
  auth0AccessToken: string,
  registryId: string,
  studyDetail: StudyDetail,
  hasExistingStudyDetail: boolean,
  updateReason: string
) {
  // Remove cached value so that cache will be populated again.
  studyDetailsCache.delete(registryId);

  const client = new StudyDetailServiceApiClient(apiEndpoint, auth0AccessToken);

  if (hasExistingStudyDetail) {
    const request = new UpdateStudyDetailRequest()
      .setStudyDetail(studyDetail)
      .setUpdateReason(updateReason);
    await client.updateStudyDetail(request);
  } else {
    const request = new CreateStudyDetailRequest()
      .setStudyDetail(studyDetail)
      .setUpdateReason(updateReason);
    await client.createStudyDetail(request);
  }
}
