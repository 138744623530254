import { FC } from "react";
import { startOfWeek, endOfWeek, sub, format } from "date-fns";
import { Box, Typography, IconButton } from "@mui/material";
import { ReactComponent as KeyboardArrowLeftIcon } from "assets/keyboard-arrow-left.svg";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import { icon_opacity_disabled, icon_opacity_enabled } from "styles/colors";
import { sxFocusWithin } from "common/Accessibility";

export function getWeekStartDate(previousWeekOffset: number) {
  const startOfWeekDate = startOfWeek(new Date());
  return sub(startOfWeekDate, { weeks: previousWeekOffset });
}

export function getWeekEndDate(previousWeekOffset: number) {
  const endOfWeekDate = endOfWeek(new Date());
  return sub(endOfWeekDate, { weeks: previousWeekOffset });
}

export function getWeekDisplay(previousWeekOffset: number) {
  const weekStart = getWeekStartDate(previousWeekOffset);
  const weekEnd = getWeekEndDate(previousWeekOffset);

  return (
    (previousWeekOffset === 1 ? "Last week" : "Week") +
    " (" +
    format(weekStart, "MM/dd/yyyy") +
    " - " +
    format(weekEnd, "MM/dd/yyyy") +
    ")"
  );
}

interface WeekNavigationProps {
  enabled: boolean;
  previousWeekOffset: number;
  onClickPreviousWeek: () => void;
  onClickNextWeek: () => void;
}

const WeekNavigation: FC<WeekNavigationProps> = ({
  enabled,
  previousWeekOffset,
  onClickPreviousWeek,
  onClickNextWeek,
}) => {
  const nextButtonEnabled = enabled && previousWeekOffset > 1;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "8px",
      }}
    >
      <Box sx={sxFocusWithin}>
        <IconButton
          disabled={!enabled}
          onClick={() => {
            if (enabled) {
              onClickPreviousWeek();
            }
          }}
          sx={{ padding: "8px" }}
        >
          <KeyboardArrowLeftIcon
            title="Back (previous week)"
            style={{
              opacity: enabled ? icon_opacity_enabled : icon_opacity_disabled,
            }}
          />
        </IconButton>
      </Box>
      <Box sx={sxFocusWithin}>
        <IconButton
          disabled={!nextButtonEnabled}
          onClick={() => {
            if (nextButtonEnabled) {
              onClickNextWeek();
            }
          }}
          sx={{ padding: "8px", marginRight: "2px" }}
        >
          <KeyboardArrowRightIcon
            title="Next (next week)"
            style={{
              opacity: nextButtonEnabled
                ? icon_opacity_enabled
                : icon_opacity_disabled,
            }}
          />
        </IconButton>
      </Box>
      <Typography variant="body2em">
        {getWeekDisplay(previousWeekOffset)}
      </Typography>
    </Box>
  );
};

export default WeekNavigation;
