import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button, SingleSelectDropdown } from "@verily-src/react-design-system";
import {
  AddUserRolesRequest,
  DeleteUserRolesRequest,
  Role,
  StudyRole,
  UpdateUserRequest,
  User,
} from "generated/studyauth/studyauth_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import UserInfoForm, { UserInfoFormRef } from "components/UserInfoForm";
import {
  getEditUserDialogTitle,
  getUserRoleDescription,
} from "components/UserRenderers";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { StudySite } from "generated/studysite/studysite_pb";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface EditStudySiteUserDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  user: User;
  userRoles: StudyRole[] | undefined;
  registryId: string;
  studySites: StudySite[];
  onSuccess(): void;
}

// The dialog box for editing a study site user
const EditStudySiteUserDialog: React.FC<EditStudySiteUserDialogProps> = ({
  open,
  onClose,
  user,
  userRoles,
  registryId,
  studySites,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const userInfoFormRef = useRef<UserInfoFormRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [selectedStudySiteName, setSelectedStudySiteName] = React.useState("");
  const [showSelectSiteError, setShowSelectSiteError] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const currentSiteId = useMemo(() => {
    if (userRoles) {
      for (var role of userRoles) {
        if (
          role.getRegistryId() === registryId &&
          role.getRole() === Role.CRC
        ) {
          return role.getStudySiteId();
        }
      }
    }
    return "";
  }, [registryId, userRoles]);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      if (currentSiteId !== "") {
        setSelectedStudySiteName("studySites/" + currentSiteId);
      } else {
        setSelectedStudySiteName("");
      }

      setErrorMessage("");
      setShowSelectSiteError(false);
    }
  }, [currentSiteId, open, registryId, userRoles]);

  const handleSiteSelected = (event: SelectChangeEvent<unknown>) => {
    setSelectedStudySiteName(event.target.value as string);
    setShowSelectSiteError(false);
  };

  const siteDropdownOptions = useMemo<Array<{ label: string; value: string }>>(
    () =>
      studySites.map((studySite) => ({
        label: studySite.getSiteName(),
        value: studySite.getName(),
      })),
    [studySites]
  );

  const studySiteRef = useRef<HTMLInputElement>(null);

  const validateInputs = useCallback(async () => {
    let isInputsValid = await userInfoFormRef.current!.validateInput(
      true /* focusOnError */
    );

    if (selectedStudySiteName === "") {
      setShowSelectSiteError(true);

      if (isInputsValid) {
        studySiteRef.current?.focus();
      }

      isInputsValid = false;
    }

    if (
      !updateReasonFormRef.current!.validateInput(
        isInputsValid /* focusOnError */
      )
    ) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, [selectedStudySiteName]);

  const handleClickSaveButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showEditUserError) {
            throw new Error("Failed to edit user. Please try again.");
          }

          setDisableSaveButton(true);

          const isInputsValid = await validateInputs();
          if (!isInputsValid) {
            setDisableSaveButton(false);
            return;
          }

          // Update user information.
          const userInfo = userInfoFormRef.current!.getUserInfo();
          const userToUpdate = user.clone();
          userToUpdate.setFirstName(userInfo.getFirstName());
          userToUpdate.setLastName(userInfo.getLastName());

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const request = new UpdateUserRequest()
            .setUser(userToUpdate)
            .setUpdateReason(updateReason);
          await client.updateUser(request);

          // Get selected site Id
          // selectedStudySiteName is in the format "studySites/{study_site_id}"
          const index = selectedStudySiteName.indexOf("/");
          const selectedSiteId = selectedStudySiteName.substring(index + 1);

          if (currentSiteId !== selectedSiteId) {
            // Remove CRC role from current site.
            const rolesToDelete = new Array<StudyRole>();
            rolesToDelete.push(
              new StudyRole()
                .setRegistryId(registryId)
                .setStudySiteId(currentSiteId)
                .setRole(Role.CRC)
            );

            const deleteRolesRequest = new DeleteUserRolesRequest()
              .setUserName(user.getName())
              .setStudyRolesList(rolesToDelete)
              .setUpdateReason(updateReason);
            await client.deleteUserRoles(deleteRolesRequest);

            // Add CRC role to the selecte site.
            const rolesToAdd = new Array<StudyRole>();
            rolesToAdd.push(
              new StudyRole()
                .setRegistryId(registryId)
                .setStudySiteId(selectedSiteId)
                .setRole(Role.CRC)
            );

            const addRolesRequest = new AddUserRolesRequest()
              .setUserName(user.getName())
              .setStudyRolesList(rolesToAdd)
              .setUpdateReason(updateReason);
            await client.addUserRoles(addRolesRequest);
          }

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to edit user. Please try again.");
        }

        setDisableSaveButton(false);
      })();
    } else {
      setErrorMessage(
        "Current user is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    currentSiteId,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    registryId,
    selectedStudySiteName,
    testConfig.showEditUserError,
    user,
    validateInputs,
  ]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6">
          {getEditUserDialogTitle(Role.CRC)}
        </Typography>
      </A11yDialogTitle>
      <DialogContent>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Invitation failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography variant="body1" sx={{ padding: "4px 0px 16px 0px" }}>
          {getUserRoleDescription(Role.CRC)}
        </Typography>
        <UserInfoForm
          initValue={user}
          emailDisabled={true}
          ref={userInfoFormRef}
        />
        <Typography variant="body1em">Site</Typography>
        <Box sx={{ marginTop: "12px" }}>
          <SingleSelectDropdown
            value={selectedStudySiteName}
            options={siteDropdownOptions}
            inputRef={studySiteRef}
            ariaLabel="Select site"
            placeholder="Select a site (required)"
            helperText={showSelectSiteError ? "Site required" : ""}
            error={showSelectSiteError}
            fullWidth
            onChange={handleSiteSelected}
          />
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Error correction",
              "Change in role required to perform job responsibilities",
            ]}
            reasonSelectionLabel="Reason for editing user (required)"
            reasonRequiredMessage="Reason for editing user required"
            otherReasonHint="Please provide reason this user is being edited"
            otherReasonRequiredMessage="Other reason for editing user required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Save"
          variant="filled"
          onClick={handleClickSaveButton}
          disabled={disableSaveButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditStudySiteUserDialog;
