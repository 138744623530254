import {
  ProxySession,
  BrowserTransport,
  IRequestProps,
  IApiSettings,
  DefaultSettings,
} from "@looker/sdk-rtl";
import { Looker40SDK } from "@looker/sdk";
import { AppConfig } from "../core/AppConfigSlice";
import {
  LookerAuthTokenCache,
  lookerAuthTokenCache,
} from "./LookerAuthTokenCache";

/**
 * A browser session managing the credentials (tokens) for the Looker SDK.
 * Two types of tokens are referenced in this class:
 * 1. A Looker token for accessing the Looker API.
 * 2. An Auth0 token for accessing the Study Sponsor Service to request a Looker token.
 *
 * If a Looker token does not exist or is expired, this session will attempt to retrieve a
 * Looker token from the Study Sponsor Service. Access to the Study Sponsor Service requires
 * an Auth0 token.
 *
 * For both APIs, the access token is inserted in the Authorization header of the corresponding
 * API requests.
 */
export class LookerSdkSession extends ProxySession {
  /**
   * @param settings Looker API settings
   * @param authTokenCache cache for Looker auth token.
   */
  constructor(
    settings: IApiSettings,
    private authTokenCache: LookerAuthTokenCache
  ) {
    super(settings, "" /* proxyUrl */, new BrowserTransport(settings));
  }

  override async authenticate(props: IRequestProps) {
    const token = await this.getToken();
    if (token && token.access_token) {
      // Enable cors.
      props.mode = "cors";

      // Clear any credentials and insert active token in auth header.
      delete props.credentials;
      props.headers = {
        ...props.headers,
        Authorization: `Bearer ${token.access_token}`,
      };
    }
    return props;
  }

  /**
   * @returns The Looker access token
   */
  override async getToken() {
    return await this.authTokenCache.getToken();
  }

  override isAuthenticated() {
    return this.authTokenCache.isAuthenticated();
  }
}

export const getLookerSdk = (appConfig: AppConfig) => {
  const lookerSdkSession = new LookerSdkSession(
    {
      ...DefaultSettings(),

      base_url: appConfig.lookerProxyUrl,
    },
    lookerAuthTokenCache
  );
  return new Looker40SDK(lookerSdkSession);
};
