import { Looker40SDK } from "@looker/sdk";
import { parseISO } from "date-fns";
import {
  getParticipantMonthCacheKey,
  participantSyncTimeCache as participantSyncTimesCache,
} from "./CalendarViewQueryResultsCache";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get a participant's watch sync time in a given month.
 *
 * The function assumes that the Looker user attributes for participant id,
 * month start date and month end date are set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantSyncTime(
  sdk: Looker40SDK,
  studyParticipantId: string,
  monthStartDate: Date,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let syncTimesMap = new Map<string, Date>();
  let hasError = false;

  try {
    const cacheKey = getParticipantMonthCacheKey(
      studyParticipantId,
      monthStartDate
    );

    if (participantSyncTimesCache.has(cacheKey)) {
      const syncTimesCached = participantSyncTimesCache.get(cacheKey)!!;
      return { syncTimesMap: syncTimesCached, hasError: false };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANT_SYNC_TIME
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        /**
         * Both event date and last sync time are in ISO 8601 format.
         * For example: 2024-02-12 and 2024-02-12T16:30:50 for event date
         * and last sync time, respectively.
         */
        const syncDate = row["participant_sync_time.event_date"];
        const lastSyncTime = parseISO(
          row["participant_sync_time.last_sync_time"]
        );
        syncTimesMap.set(syncDate, lastSyncTime);
      }

      // Put results in cache
      participantSyncTimesCache.set(cacheKey, syncTimesMap);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { syncTimesMap: syncTimesMap, hasError: hasError };
}
