/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.studyauth.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: studyauth/studyauth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.studyauth = {};
proto.verily.sdp.studyauth.v1alpha1 = require('./studyauth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.User>}
 */
const methodDescriptor_StudyAuthService_CreateUser = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest,
  proto.verily.sdp.studyauth.v1alpha1.User,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_CreateUser,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.User>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.User>}
 */
const methodDescriptor_StudyAuthService_UpdateUser = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest,
  proto.verily.sdp.studyauth.v1alpha1.User,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_UpdateUser,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.User>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_StudyAuthService_DeleteUser = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_DeleteUser,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.GetUserRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.User>}
 */
const methodDescriptor_StudyAuthService_GetUser = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.GetUserRequest,
  proto.verily.sdp.studyauth.v1alpha1.User,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUser,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.User>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUser',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.User>}
 */
const methodDescriptor_StudyAuthService_GetUserByEmail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUserByEmail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest,
  proto.verily.sdp.studyauth.v1alpha1.User,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.getUserByEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUserByEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUserByEmail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.User>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.getUserByEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUserByEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUserByEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse>}
 */
const methodDescriptor_StudyAuthService_ListUsers = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListUsers',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest,
  proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ListUsers,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListUsers',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ListUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_StudyAuthService_SyncUserAccount = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SyncUserAccount',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.syncUserAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SyncUserAccount',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_SyncUserAccount,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.syncUserAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SyncUserAccount',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_SyncUserAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_StudyAuthService_AddUserRoles = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/AddUserRoles',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.addUserRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/AddUserRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_AddUserRoles,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.addUserRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/AddUserRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_AddUserRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_StudyAuthService_DeleteUserRoles = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUserRoles',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.deleteUserRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUserRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_DeleteUserRoles,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.deleteUserRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/DeleteUserRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_DeleteUserRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse>}
 */
const methodDescriptor_StudyAuthService_GetUsersRoles = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUsersRoles',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest,
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.getUsersRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUsersRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUsersRoles,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.getUsersRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/GetUsersRoles',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_GetUsersRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse>}
 */
const methodDescriptor_StudyAuthService_ListStudies = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListStudies',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest,
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.listStudies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListStudies',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ListStudies,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.listStudies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ListStudies',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ListStudies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse>}
 */
const methodDescriptor_StudyAuthService_SendEmail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SendEmail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest,
  proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.sendEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SendEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_SendEmail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.sendEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/SendEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_SendEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest,
 *   !proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse>}
 */
const methodDescriptor_StudyAuthService_ValidateEmail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ValidateEmail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest,
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse,
  /**
   * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServiceClient.prototype.validateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ValidateEmail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyauth.v1alpha1.StudyAuthServicePromiseClient.prototype.validateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyauth.v1alpha1.StudyAuthService/ValidateEmail',
      request,
      metadata || {},
      methodDescriptor_StudyAuthService_ValidateEmail);
};


module.exports = proto.verily.sdp.studyauth.v1alpha1;

