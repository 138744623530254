import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { getComplianceSetting } from "./GetComplianceSetting";

// Defines a custom effect to load data for the compliance setting screen.
function useComplianceSettingDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [complianceSetting, setComplianceSetting] =
    useState<ComplianceSetting | null>(null);

  const loadComplianceSetting = useCallback(
    (registryId: string) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setIsLoading(true);
          setHasError(false);

          try {
            if (registryId === "") {
              throw new Error("missing selected study");
            }

            // load compliance setting
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const setting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              registryId
            );

            setComplianceSetting(setting);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      }
    },
    [isAuthenticated, auth0Config, getAccessTokenSilently]
  );

  return {
    isLoading,
    hasError,
    complianceSetting,
    loadComplianceSetting,
  };
}

export default useComplianceSettingDataLoader;
