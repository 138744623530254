import { SxProps, Theme, Tooltip, TooltipProps, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { isActivationKey, isEscapeKey } from "common/Accessibility";

interface A11yInfoTooltipProps {
  title: React.ReactNode;
  ariaLabel: string;
  arrow?: boolean;
  placement?: TooltipProps["placement"];
  sx?: SxProps<Theme>;
}

// A component to show an information tooltip.
const A11yInfoTooltip: React.FC<A11yInfoTooltipProps> = ({
  ariaLabel,
  title,
  arrow,
  placement,
  sx,
}) => {
  // Manage tooltip visibility to prevent it from automatically showing up
  // when receiving keyboard focus
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();
  return (
    <Tooltip
      open={showTooltip}
      title={title}
      arrow={arrow}
      slotProps={{
        tooltip: {
          sx: { backgroundColor: theme.palette.background.contrast },
        },
      }}
      placement={placement}
      sx={sx}
    >
      <InfoOutlinedIcon
        tabIndex={0}
        titleAccess={ariaLabel}
        sx={{ color: theme.palette.icon.default }}
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onKeyDown={(event) => {
          if (isActivationKey(event)) {
            setShowTooltip(true);
            return;
          }

          if (isEscapeKey(event)) {
            setShowTooltip(false);
            return;
          }
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        onBlur={() => {
          setShowTooltip(false);
        }}
      />
    </Tooltip>
  );
};

export default A11yInfoTooltip;
