import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@verily-src/react-design-system";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useComplianceSettingDataLoader from "./ComplianceSettingDataLoader";
import { AppRoutes } from "core/AppRoutes";
import { useNavigate } from "react-router-dom";
import {
  WearTimeSettingCard,
  WearTimeSettingCardRef,
} from "./WearTimeSettingCard";
import { SyncTimeSettingCard } from "./SyncTimeSettingCard";
import {
  SurveySubmissionSettingCard,
  SurveySubmissionSettingCardRef,
} from "./SurveySubmissionSettingCard";
import {
  MedsLoggingSettingCard,
  MedsLoggingSettingCardRef,
} from "./MedsLoggingSettingCard";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import ConfirmComplianceSettingDialog from "./ConfirmComplianceSettingDialog";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import { setFromScreen, setShowSuccessMessage } from "./ComplianceSettingSlice";
import { sxFocusWithin } from "common/Accessibility";
import { usePageTitle } from "components/PageTitle";

interface TitleBarProps {
  disableSaveButton: boolean;
  onClickSave(): void;
}

const TitleBar: React.FC<TitleBarProps> = ({
  disableSaveButton,
  onClickSave,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <Box
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        padding: "24px 40px",
        background: theme.palette.background.canvas,
        minHeight: "96px",
      }}
    >
      <Box sx={sxFocusWithin}>
        <IconButton
          onClick={() => {
            navigate(AppRoutes.COMPLIANCE_SETTING);
          }}
        >
          <CloseIcon titleAccess="Close" />
        </IconButton>
      </Box>
      <Typography component="h1" variant="display5" sx={{ marginLeft: "12px" }}>
        Set compliance parameters
      </Typography>
      <Box sx={{ flexGrow: "1" }}></Box>
      <Button
        variant="outlined"
        label="Cancel"
        onClick={() => {
          dispatch(setFromScreen(AppRoutes.EDIT_COMPLIANCE_SETTING));
          navigate(AppRoutes.COMPLIANCE_SETTING);
        }}
        sx={{
          marginX: "12px",
        }}
      />
      <Button
        variant="filled"
        label="Save"
        disabled={disableSaveButton}
        onClick={onClickSave}
      />
    </Box>
  );
};

const EditComplianceSetting: React.FC = () => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const { isLoading, hasError, complianceSetting, loadComplianceSetting } =
    useComplianceSettingDataLoader();

  // Set page title.
  usePageTitle("Set compliance parameters");

  // Reload data if currently selected study changes.
  useEffect(() => {
    loadComplianceSetting(currentRegistryId);
  }, [currentRegistryId, loadComplianceSetting]);

  // Set up refs to setting cards.
  const wearTimeSettingCardRef = useRef<WearTimeSettingCardRef>(null);
  const syncTimeSettingCardRef = useRef<WearTimeSettingCardRef>(null);
  const surveySubmissionSettingCardRef =
    useRef<SurveySubmissionSettingCardRef>(null);
  const medsLoggingSettingCardRef = useRef<MedsLoggingSettingCardRef>(null);

  const [newSetting, setNewSetting] = useState(new ComplianceSetting());
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleClickSaveButton = useCallback(() => {
    let isInputsValid = true;
    if (!wearTimeSettingCardRef.current!.validateInput()) {
      isInputsValid = false;
    }

    if (!syncTimeSettingCardRef.current!.validateInput()) {
      isInputsValid = false;
    }

    if (featureFlagProvider.isSurveyEnabled(appConfig.environment)) {
      if (!surveySubmissionSettingCardRef.current!.validateInput()) {
        isInputsValid = false;
      }
    }

    if (!medsLoggingSettingCardRef.current!.validateInput()) {
      isInputsValid = false;
    }

    if (!isInputsValid) {
      return;
    }

    const setting = new ComplianceSetting();
    setting
      .setName("complianceSettings/" + currentRegistryId)
      .setWearTimeDaysPerWeek(wearTimeSettingCardRef.current!.getDaysPerWeek())
      .setWearTimeHoursPerDay(wearTimeSettingCardRef.current!.getHoursPerDay())
      .setSyncDaysPerWeek(syncTimeSettingCardRef.current!.getDaysPerWeek())
      .setSurveySubmissionPercentage(
        featureFlagProvider.isSurveyEnabled(appConfig.environment)
          ? surveySubmissionSettingCardRef.current!.getSurveySubmissionPercentage()
          : 0
      )
      .setMedsLoggedPerDay(medsLoggingSettingCardRef.current!.getDosesPerDay())
      .setMedsLoggedPerWeek(
        medsLoggingSettingCardRef.current!.getDosesPerWeek()
      );

    setNewSetting(setting);
    setShowConfirmDialog(true);
  }, [currentRegistryId, appConfig.environment]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <TitleBar
        disableSaveButton={isLoading || hasError}
        onClickSave={() => {
          handleClickSaveButton();
        }}
      />
      <Box
        role="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          paddingTop: "40px",
          paddingBottom: "32px",
        }}
      >
        {/* Show loading indicator while loading is in progress */}
        {isLoading && (
          <Box
            sx={{
              marginTop: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* Show error screen if there is a loading error */}
        {!isLoading && hasError && (
          <Box
            sx={{
              marginTop: "100px",
            }}
          >
            <Alert severity="error">
              Failed to load compliance parameters. Please try again.
            </Alert>
          </Box>
        )}
        {/* Show compliance setting screen */}
        {!isLoading && !hasError && (
          <Box
            role="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <WearTimeSettingCard
              initValue={complianceSetting}
              ref={wearTimeSettingCardRef}
            />
            <SyncTimeSettingCard
              initValue={complianceSetting}
              ref={syncTimeSettingCardRef}
            />
            {featureFlagProvider.isSurveyEnabled(appConfig.environment) && (
              <SurveySubmissionSettingCard
                initValue={complianceSetting}
                ref={surveySubmissionSettingCardRef}
              />
            )}
            <MedsLoggingSettingCard
              initValue={complianceSetting}
              ref={medsLoggingSettingCardRef}
            />
          </Box>
        )}
      </Box>
      <ConfirmComplianceSettingDialog
        open={showConfirmDialog}
        registryId={currentRegistryId}
        setting={newSetting}
        hasExistingSetting={complianceSetting !== null}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
        onSuccess={() => {
          dispatch(setFromScreen(AppRoutes.EDIT_COMPLIANCE_SETTING));
          dispatch(setShowSuccessMessage(true));
          navigate(AppRoutes.COMPLIANCE_SETTING);
        }}
      />
    </Box>
  );
};

export default EditComplianceSetting;
