import React from "react";

// Copied from https://github.com/verily-src/verily1/blob/main/phaf/rds/src/shared/lib/constants.ts
export const A11Y_44PX = "44px";
export const MIN_A11Y_AREA = "rds-min-a11y-area";
export const MIN_A11Y_AREA_WRAPPER = "rds-min-a11y-area-wrapper";

// Copied from https://github.com/verily-src/verily1/blob/main/phaf/rds/src/components/Button/MinClickableArea.tsx
type MinClickableAreaProps = {
  fullWidth?: boolean;
  style?: React.CSSProperties;
};

/**
 * Adds a 44x44px empty area that can be used to set minimum clickable areas for the parent wrappers.
 * If @param fullWidth is true, the box will take up the full width (100%) of its container,
 * otherwise uses a fixed width of 44px.
 * @param style is used for adjusting the minimum clickable area css styles.
 */
export const MinClickableArea = React.memo(
  ({ fullWidth, style: styleProp }: MinClickableAreaProps) => {
    const [style, setStyle] = React.useState<React.CSSProperties>(
      styleProp ?? {}
    );
    const ref = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
      if (
        ref.current?.parentElement?.offsetWidth &&
        ref.current.parentElement?.offsetWidth >= 44 &&
        style.left === undefined
      ) {
        setStyle((s) => ({ left: 0, ...s }));
      }
    }, [ref, style]);

    return (
      <div
        style={{
          // This makes the min-clickable area 44x44px.
          minHeight: A11Y_44PX,
          minWidth: A11Y_44PX,
          position: "absolute",
          // If the parent is wider than 44px, it will match the width.
          width: fullWidth ? "100%" : undefined,
          ...style,
        }}
        data-testid={MIN_A11Y_AREA}
        ref={ref}
      />
    );
  }
);

type MinClickableAreaWrapperProps = {
  a11yClickableAreaStyling?: React.CSSProperties;
  children?: React.ReactNode;
} & MinClickableAreaProps &
  React.HTMLAttributes<HTMLDivElement>;

/**
 * Adds a parent wrapper with a minimum clickable area for the content (children prop).
 */
export const MinClickableAreaWrapper = React.memo(
  ({
    fullWidth,
    a11yClickableAreaStyling,
    children,
    ...additionalProps
  }: MinClickableAreaWrapperProps) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        ...a11yClickableAreaStyling,
      }}
      data-testid={MIN_A11Y_AREA_WRAPPER}
      {...additionalProps}
    >
      <MinClickableArea fullWidth={fullWidth} />
      {children}
    </div>
  )
);
