import { Metadata } from "grpc-web";
import { ParticipantComplianceTaskServicePromiseClient } from "generated/participantcompliancetask/participantcompliancetask_grpc_web_pb";
import {
  CreateParticipantComplianceTaskRequest,
  FilterParticipantComplianceTasksRequest,
} from "generated/participantcompliancetask/participantcompliancetask_pb";

/**
 * Defines helper methods to call ParticipantComplianceTaskService.
 */
export class ParticipantComplianceTaskServiceApiClient {
  private gRpcClient: ParticipantComplianceTaskServicePromiseClient;

  /**
   * Creates an instance of ParticipantComplianceTaskServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-dev.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new ParticipantComplianceTaskServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createParticipantComplianceTask(
    request: CreateParticipantComplianceTaskRequest
  ) {
    return this.gRpcClient.createParticipantComplianceTask(
      request,
      this.getGrpcMetaData()
    );
  }

  filterParticipantComplianceTask(
    request: FilterParticipantComplianceTasksRequest
  ) {
    return this.gRpcClient.filterParticipantComplianceTasks(
      request,
      this.getGrpcMetaData()
    );
  }
}
