import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { InputTextField, InputTextFieldRef } from "components/InputText";
import { dialog_border_radius } from "styles/Dimensions";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import { updateStudyDetail } from "./UpdateStudyDetail";
import validator from "validator";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface EditVerilyCdmDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  registryId: string;
  detail: StudyDetail;
  hasExistingStudyDetail: boolean;
  verilyEmailRequired?: boolean;
  onClose(): void;
  onSuccess(): void;
}

// The edit dialog for editing study detail for a study.
const EditVerilyCdmDialog: React.FC<EditVerilyCdmDialogProps> = ({
  open,
  registryId,
  detail,
  hasExistingStudyDetail,
  verilyEmailRequired,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [showError, setShowError] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [email, setEmail] = useState(detail.getVerilyCdm());
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const emailRef = useRef<InputTextFieldRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const theme = useTheme();

  const handleClickSaveButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          setDisableSaveButton(true);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          let isInputsValid = true;

          if (email.trim().length < 1) {
            setEmailErrorMessage("Email required");
            isInputsValid = false;
          } else {
            if (!validator.isEmail(email)) {
              setEmailErrorMessage(
                "Email has invalid format. (Correct format: name@verily.com)"
              );
              isInputsValid = false;
            }

            // Verify it's verily.com address
            if (
              isInputsValid &&
              verilyEmailRequired &&
              !email.endsWith("@verily.com")
            ) {
              setEmailErrorMessage("Please use a verily.com email address");
              isInputsValid = false;
            }
          }

          if (!isInputsValid) {
            emailRef.current?.focus();
          }

          if (
            !updateReasonFormRef.current!.validateInput(
              isInputsValid /* focusOnError */
            )
          ) {
            isInputsValid = false;
          }

          if (isInputsValid) {
            detail.setVerilyCdm(email);
            detail.setName("studyDetails/" + registryId);

            await updateStudyDetail(
              auth0Config.audience!,
              token,
              registryId,
              detail,
              hasExistingStudyDetail,
              updateReasonFormRef.current!.getUpdateReason()
            );

            onSuccess();
          }
        } catch (error) {
          console.log("error reason %s", error);
          setShowError(true);
        }
        setDisableSaveButton(false);
      })();
    } else {
      setShowError(true);
    }
  }, [
    isAuthenticated,
    auth0Config,
    getAccessTokenSilently,
    email,
    verilyEmailRequired,
    detail,
    registryId,
    hasExistingStudyDetail,
    onSuccess,
  ]);

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setShowError(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "16px" }}
      >
        <Typography variant="display6">
          Edit Verily CDM contact person
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {showError && (
          <Alert severity="error">
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Failed to edit the email of the Verily CDM
              </Typography>
            </AlertTitle>
          </Alert>
        )}
        <InputTextField
          id="verilyCdm"
          label="Email"
          ref={emailRef}
          value={email}
          placeholder="name@verily.com"
          helperText={emailErrorMessage}
          showError={emailErrorMessage.length > 0}
          onChange={(newValue) => {
            setEmailErrorMessage("");
            setEmail(newValue);
          }}
        />
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Error correction",
              "Change in role required to perform job responsibilities",
            ]}
            reasonSelectionLabel="Reason for change (required)"
            reasonRequiredMessage="Reason for change required"
            otherReasonHint="Please provide reason"
            otherReasonRequiredMessage="Other reason for change required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          disabled={disableSaveButton}
          onClick={handleClickSaveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditVerilyCdmDialog;
