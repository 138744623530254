// source: compliancesetting/compliancesetting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting', null, global);
goog.exportSymbol('proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest', null, global);
goog.exportSymbol('proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest', null, global);
goog.exportSymbol('proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.displayName = 'proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.displayName = 'proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.displayName = 'proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.displayName = 'proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wearTimeDaysPerWeek: jspb.Message.getFieldWithDefault(msg, 2, 0),
    wearTimeHoursPerDay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    syncDaysPerWeek: jspb.Message.getFieldWithDefault(msg, 4, 0),
    surveySubmissionPercentage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    medsLoggedPerDay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    medsLoggedPerWeek: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedTime: (f = msg.getUpdatedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting;
  return proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWearTimeDaysPerWeek(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWearTimeHoursPerDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSyncDaysPerWeek(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSurveySubmissionPercentage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMedsLoggedPerDay(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMedsLoggedPerWeek(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWearTimeDaysPerWeek();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWearTimeHoursPerDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSyncDaysPerWeek();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSurveySubmissionPercentage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMedsLoggedPerDay();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMedsLoggedPerWeek();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 wear_time_days_per_week = 2;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getWearTimeDaysPerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setWearTimeDaysPerWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 wear_time_hours_per_day = 3;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getWearTimeHoursPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setWearTimeHoursPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sync_days_per_week = 4;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getSyncDaysPerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setSyncDaysPerWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 survey_submission_percentage = 5;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getSurveySubmissionPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setSurveySubmissionPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 meds_logged_per_day = 6;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getMedsLoggedPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setMedsLoggedPerDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 meds_logged_per_week = 7;
 * @return {number}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getMedsLoggedPerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setMedsLoggedPerWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp updated_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.getUpdatedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
*/
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.clearUpdatedTime = function() {
  return this.setUpdatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.prototype.hasUpdatedTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceSetting: (f = msg.getComplianceSetting()) && proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest;
  return proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting;
      reader.readMessage(value,proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinaryFromReader);
      msg.setComplianceSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComplianceSetting compliance_setting = 1;
 * @return {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.getComplianceSetting = function() {
  return /** @type{?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting, 1));
};


/**
 * @param {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting|undefined} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} returns this
*/
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.setComplianceSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.clearComplianceSetting = function() {
  return this.setComplianceSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.compliancesetting.v1alpha1.CreateComplianceSettingRequest.prototype.hasComplianceSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceSetting: (f = msg.getComplianceSetting()) && proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest;
  return proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting;
      reader.readMessage(value,proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.deserializeBinaryFromReader);
      msg.setComplianceSetting(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ComplianceSetting compliance_setting = 1;
 * @return {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.getComplianceSetting = function() {
  return /** @type{?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting, 1));
};


/**
 * @param {?proto.verily.sdp.compliancesetting.v1alpha1.ComplianceSetting|undefined} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} returns this
*/
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.setComplianceSetting = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.clearComplianceSetting = function() {
  return this.setComplianceSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.hasComplianceSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.UpdateComplianceSettingRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest;
  return proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest}
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest} returns this
 */
proto.verily.sdp.compliancesetting.v1alpha1.GetComplianceSettingRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.verily.sdp.compliancesetting.v1alpha1);
