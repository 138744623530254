import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import {
  User,
  StudyRole,
  GetUsersRolesRequest,
} from "generated/studyauth/studyauth_pb";

// Defines a custom effect to load data for the study users screen.
function useStudyUsersDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState(new Array<User>());

  // Map between user name and user roles.
  const [userRolesMap, setUserRolesMap] = useState(
    new Map<string, StudyRole[]>()
  );

  const loadUsers = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showLoadUserError) {
            throw new Error("failed to load user");
          }

          setHasError(false);
          setIsLoading(true);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const listUsersResponse = await client.listUsers();

          // Get user roles.
          const userNames = listUsersResponse
            .getUsersList()
            .map((user) => user.getName());
          const getRolesRequest = new GetUsersRolesRequest()
            .setUserNamesList(userNames)
            .setWithStudyInfo(true);

          const getUserRolesReponse = await client.getUsersRoles(
            getRolesRequest
          );

          // Build a map between user name and user roles.
          const userRolesMap = new Map(
            getUserRolesReponse
              .getUserRolesList()
              .map((userRole) => [
                userRole.getUserName(),
                userRole.getStudyRolesList(),
              ])
          );
          setUserRolesMap(userRolesMap);

          setUsers(listUsersResponse.getUsersList());
        } catch (error) {
          console.log("error reason %s", error);
          setHasError(true);
        }

        setIsLoading(false);
      })();
    }
  }, [
    isAuthenticated,
    auth0Config,
    testConfig.showLoadUserError,
    getAccessTokenSilently,
  ]);

  return {
    isLoading,
    hasError,
    users,
    userRolesMap,
    loadUsers,
  };
}

export default useStudyUsersDataLoader;
