import { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { AppRoutes } from "core/AppRoutes";
import { Box } from "@mui/material";
import HeaderNav from "core/HeaderNav";

/**
 * Main view of the app after it is initialized (from app.config.json).
 */
export const Main = () => {
  const appState = useAppSelector((state) => state.appState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!appState.userConfigured) {
      navigate(AppRoutes.ROOT);
    }
  }, [appState.userConfigured, navigate]);

  if (!appState.userConfigured) {
    return <></>;
  }

  // flex-grow: 1;
  // overflow-y: scroll;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <HeaderNav />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <Box
          role="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflowY: "auto",
            backgroundColor: "#ffffff",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
