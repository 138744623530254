import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "components/SortableTableHeaderCell";
import { NonCompliantReason } from "looker/QueryResultsCache";
import { ComplianceScope } from "./NonCompliantTabDataLoader";

export interface NonCompliantDetailState {
  scope: ComplianceScope;
  reason: NonCompliantReason;
  sortOrder: Order;
  orderBy: string;
  pageNumber: number;
  rowsPerPage: number;
}

const initialState: NonCompliantDetailState = {
  scope: ComplianceScope.Site,
  reason: NonCompliantReason.UNKNOWN,
  sortOrder: "asc",
  orderBy: "subjectId",
  pageNumber: 0,
  rowsPerPage: 5,
};

const nonCompliantDetailStateSlice = createSlice({
  name: "nonCompliantDetailState",
  initialState,
  reducers: {
    setNonCompliantScope: (state, action: PayloadAction<ComplianceScope>) => {
      state.scope = action.payload;
    },
    setNonCompliantReason: (
      state,
      action: PayloadAction<NonCompliantReason>
    ) => {
      state.reason = action.payload;

      // Reset the sort and paginiation state.
      state.sortOrder = "asc";
      state.orderBy = "subjectId";
      state.pageNumber = 0;
      state.rowsPerPage = 5;
    },
    setNonCompliantDetailSortOrder: (state, action: PayloadAction<Order>) => {
      state.sortOrder = action.payload;
    },
    setNonCompliantDetailOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setNonCompliantDetailPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    setNonCompliantDetailRowsPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.rowsPerPage = action.payload;
    },
  },
});

export const {
  setNonCompliantScope,
  setNonCompliantReason,
  setNonCompliantDetailSortOrder,
  setNonCompliantDetailOrderBy,
  setNonCompliantDetailPageNumber,
  setNonCompliantDetailRowsPerPage,
} = nonCompliantDetailStateSlice.actions;

export default nonCompliantDetailStateSlice.reducer;
