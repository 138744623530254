// source: studysite/studysite.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.StudySite', null, global);
goog.exportSymbol('proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.StudySite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.StudySite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.StudySite.displayName = 'proto.verily.sdp.studysite.v1alpha1.StudySite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.displayName = 'proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.displayName = 'proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.displayName = 'proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.StudySite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.StudySite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    registryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    principalInvestigator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    archivedDate: (f = msg.getArchivedDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.StudySite;
  return proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.StudySite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipalInvestigator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatus(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setArchivedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.StudySite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrincipalInvestigator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getArchivedDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string registry_id = 2;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_number = 4;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getSiteNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setSiteNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string principal_investigator = 5;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getPrincipalInvestigator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setPrincipalInvestigator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 status = 7;
 * @return {number}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.type.Date archived_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.getArchivedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
*/
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.setArchivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite} returns this
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.clearArchivedDate = function() {
  return this.setArchivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studysite.v1alpha1.StudySite.prototype.hasArchivedDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studySite: (f = msg.getStudySite()) && proto.verily.sdp.studysite.v1alpha1.StudySite.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest;
  return proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studysite.v1alpha1.StudySite;
      reader.readMessage(value,proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader);
      msg.setStudySite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudySite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StudySite study_site = 1;
 * @return {?proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.getStudySite = function() {
  return /** @type{?proto.verily.sdp.studysite.v1alpha1.StudySite} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studysite.v1alpha1.StudySite, 1));
};


/**
 * @param {?proto.verily.sdp.studysite.v1alpha1.StudySite|undefined} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} returns this
*/
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.setStudySite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.clearStudySite = function() {
  return this.setStudySite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.hasStudySite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.CreateStudySiteRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studySite: (f = msg.getStudySite()) && proto.verily.sdp.studysite.v1alpha1.StudySite.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest;
  return proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studysite.v1alpha1.StudySite;
      reader.readMessage(value,proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader);
      msg.setStudySite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudySite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StudySite study_site = 1;
 * @return {?proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.getStudySite = function() {
  return /** @type{?proto.verily.sdp.studysite.v1alpha1.StudySite} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studysite.v1alpha1.StudySite, 1));
};


/**
 * @param {?proto.verily.sdp.studysite.v1alpha1.StudySite|undefined} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} returns this
*/
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.setStudySite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.clearStudySite = function() {
  return this.setStudySite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.hasStudySite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.UpdateStudySiteRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest;
  return proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.DeleteStudySiteRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest;
  return proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest}
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.GetStudySiteRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest;
  return proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studySitesList: jspb.Message.toObjectList(msg.getStudySitesList(),
    proto.verily.sdp.studysite.v1alpha1.StudySite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse;
  return proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studysite.v1alpha1.StudySite;
      reader.readMessage(value,proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader);
      msg.addStudySites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudySitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudySite study_sites = 1;
 * @return {!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.getStudySitesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studysite.v1alpha1.StudySite, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse} returns this
*/
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.setStudySitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studysite.v1alpha1.StudySite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.addStudySites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studysite.v1alpha1.StudySite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse} returns this
 */
proto.verily.sdp.studysite.v1alpha1.ListStudySitesResponse.prototype.clearStudySitesList = function() {
  return this.setStudySitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest;
  return proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest} returns this
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studySitesList: jspb.Message.toObjectList(msg.getStudySitesList(),
    proto.verily.sdp.studysite.v1alpha1.StudySite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse;
  return proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studysite.v1alpha1.StudySite;
      reader.readMessage(value,proto.verily.sdp.studysite.v1alpha1.StudySite.deserializeBinaryFromReader);
      msg.addStudySites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudySitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studysite.v1alpha1.StudySite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudySite study_sites = 1;
 * @return {!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.getStudySitesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studysite.v1alpha1.StudySite, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studysite.v1alpha1.StudySite>} value
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse} returns this
*/
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.setStudySitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studysite.v1alpha1.StudySite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studysite.v1alpha1.StudySite}
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.addStudySites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studysite.v1alpha1.StudySite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse} returns this
 */
proto.verily.sdp.studysite.v1alpha1.ListNonEmptyStudySitesResponse.prototype.clearStudySitesList = function() {
  return this.setStudySitesList([]);
};


goog.object.extend(exports, proto.verily.sdp.studysite.v1alpha1);
