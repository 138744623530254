import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { User } from "generated/studyauth/studyauth_pb";

// Defines a custom effect to load data for the super admin screen.
function useSuperAdminsDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState(new Array<User>());

  const loadUsers = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showLoadUserError) {
            throw new Error("failed to load user");
          }

          setIsLoading(true);
          setHasError(false);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          // load super admins.
          const response = await client.listUsers(true);
          setUsers(response.getUsersList());
        } catch (error) {
          console.log("error reason %s", error);
          setHasError(true);
        }

        setIsLoading(false);
      })();
    }
  }, [
    isAuthenticated,
    auth0Config,
    testConfig.showLoadUserError,
    getAccessTokenSilently,
  ]);

  return {
    isLoadingSuperAdmins: isLoading,
    hasLoadSuperAdminError: hasError,
    superAdmins: users,
    loadSuperAdmins: loadUsers,
  };
}

export default useSuperAdminsDataLoader;
