import { Metadata } from "grpc-web";
import { StudySiteServicePromiseClient } from "generated/studysite/studysite_grpc_web_pb";
import {
  CreateStudySiteRequest,
  DeleteStudySiteRequest,
  ListStudySitesRequest,
  ListNonEmptyStudySitesRequest,
  UpdateStudySiteRequest,
} from "generated/studysite/studysite_pb";

/**
 * Defines helper methods to call StudySiteService.
 */
export class StudySiteServiceApiClient {
  private gRpcClient: StudySiteServicePromiseClient;

  /**
   * Creates an instance of StudySiteServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new StudySiteServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createStudySite(request: CreateStudySiteRequest) {
    return this.gRpcClient.createStudySite(request, this.getGrpcMetaData());
  }

  updateStudySite(request: UpdateStudySiteRequest) {
    return this.gRpcClient.updateStudySite(request, this.getGrpcMetaData());
  }

  deleteStudySite(request: DeleteStudySiteRequest) {
    return this.gRpcClient.deleteStudySite(request, this.getGrpcMetaData());
  }

  listStudySites(registryId: string) {
    const request = new ListStudySitesRequest().setRegistryId(registryId);
    return this.gRpcClient.listStudySites(request, this.getGrpcMetaData());
  }

  listNonDeletableStudySites(registryId: string) {
    const request = new ListNonEmptyStudySitesRequest().setRegistryId(
      registryId
    );
    return this.gRpcClient.listNonEmptyStudySites(
      request,
      this.getGrpcMetaData()
    );
  }
}
