import { Looker40SDK } from "@looker/sdk";
import { ParticipantRecord, participantsCache } from "./QueryResultsCache";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get enrolled participants for a week and site.
 *
 * The function assumes that the Looker user attributes for week and site are
 * set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipants(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<ParticipantRecord>();
  let hasError = false;

  try {
    if (participantsCache.has(previousWeekOffset)) {
      return {
        records: participantsCache.get(previousWeekOffset)!!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_DEVICES_ASSOCIATIONS
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        records.push({
          participantId: row["participants_devices.participant_id"],
          subjectId: row["participants_devices.subject_id"],
          deviceId: row["participants_devices.device_id"],
          siteId: "",
        });
      }

      // Store the results in cache.
      participantsCache.set(previousWeekOffset, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { records: records, hasError: hasError };
}
