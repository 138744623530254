import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { StudyInfo } from "common/StudyInfo";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import HeaderNav from "core/HeaderNav";
import { useAuth0 } from "@auth0/auth0-react";
import { hasAnyRole, setSelectedRegistryId } from "core/UserConfigSlice";
import { Role } from "generated/studyauth/studyauth_pb";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import {
  ListDashboardsRequest,
  UpdateStudyRequest,
} from "generated/studydata/studydata_pb";
import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "core/AppRoutes";
import { Snackbar, Tooltip } from "@verily-src/react-design-system";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import { resetCrcComplianceScreenState } from "./CrcComplianceStateSlice";
import { clearLookerQueryResultsCache } from "looker/QueryResultsCache";
import { clearLookerSettingsCache } from "looker/SettingsCache";
import { clearComplianceSettingsCache } from "compliancesetting/ComplianceSettingsCache";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import useAllStudyDetailDataLoader from "studydetails/AllStudyDetailsDataLoader";
import { isActivationKey } from "common/Accessibility";
import { usePageTitle } from "components/PageTitle";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import { resetNonCompliantTabState } from "./NonCompliantTabStateSlice";
import { resetStudyComplianceScreenState } from "./StudyComplianceStateSlice";
import { resetParticipantsViewState } from "studyparticipant/StudyParticipantsStateSlice";
import {
  LookerDashboardConfig,
  setLookerConfig,
} from "looker/LookerConfigSlice";
import A11yStatusMessage from "components/A11yStatusMessage";

interface StudiesTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const StudiesTableHeader: React.FC<StudiesTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="study name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Study name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="33%"
        >
          <Typography variant="body2em">Study name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="sponsor"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Sponsor"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="33%"
        >
          <Typography variant="body2em">Sponsor</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="study ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Study ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="34%"
        >
          <Typography variant="body2em">Study ID</Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

function getStudyLinkAriaLabel(
  studyName: string,
  sponsorName: string,
  studyId: string
) {
  return `View Study name ${studyName}, Sponsor ${
    sponsorName === "" ? "unknown" : sponsorName
  }, Study ID ${studyId === "" ? "unknown" : studyId}`;
}

// Define a component to render study information.
interface StudyRowProps {
  study: StudyInfo;
  studyDetails: Map<string, StudyDetail>;
  onClickNextButton(study: StudyInfo): void;
}

const StudyRow: React.FC<StudyRowProps> = ({
  study,
  studyDetails,
  onClickNextButton,
}) => {
  let studyName = study.displayName;
  let sponsorName = "";
  let studyId = "";
  if (studyDetails.has(study.registryId)) {
    const studyDetail = studyDetails.get(study.registryId)!;
    studyName = studyDetail.getStudyName();
    sponsorName = studyDetail.getSponsorName();
    studyId = studyDetail.getStudyId();
  }

  return (
    <TableRow
      hover
      onClick={() => {
        onClickNextButton(study);
      }}
      style={{ cursor: "pointer" }}
    >
      <TableCell width="33%">{studyName}</TableCell>
      <TableCell width="33%">{sponsorName}</TableCell>
      <TableCell width="34%">{studyId}</TableCell>
      <TableCell width="48px" sx={{ paddingRight: "12px" }}>
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickNextButton(study);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickNextButton(study);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getStudyLinkAriaLabel(studyName, sponsorName, studyId)}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface StudiesTableContentProps {
  studies: Array<StudyInfo>;
  studyDetails: Map<string, StudyDetail>;
  onClickNextButton(study: StudyInfo): void;
}

const StudiesTableContent: React.FC<StudiesTableContentProps> = ({
  studies,
  studyDetails,
  onClickNextButton,
}) => {
  return (
    <TableBody>
      {studies.map((study) => (
        <StudyRow
          key={study.registryId}
          study={study}
          studyDetails={studyDetails}
          onClickNextButton={onClickNextButton}
        />
      ))}
    </TableBody>
  );
};

const Studies: React.FC = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const appConfig = useAppSelector((state) => state.appConfig);
  const appState = useAppSelector((state) => state.appState);
  const userConfig = useAppSelector((state) => state.userConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showProcessSpinner, setShowProgressSpinner] = useState(false);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("studyName");

  const { isLoading, hasError, studyDetails, loadAllStudyDetail } =
    useAllStudyDetailDataLoader();

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  // Redirect to initial set up if appState.userConfigured is false. This happens
  // when users press enter key in the address bar to refresh the screen.
  useEffect(() => {
    if (!appState.userConfigured) {
      navigate(AppRoutes.ROOT);
    }
  }, [appState.userConfigured, navigate]);

  // Set page title.
  usePageTitle("Home");

  // Clear compliance settings cache when the screen is loaded.
  useEffect(() => {
    clearComplianceSettingsCache();
  }, []);

  // Reload data if the current user changes.
  useEffect(() => {
    loadAllStudyDetail(userConfig.studies.map((study) => study.registryId));
  }, [userConfig.studies, loadAllStudyDetail]);

  const handleClickNextButton = useCallback(
    (selectedStudy: StudyInfo) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setShowProgressSpinner(true);

          // Get selected roles
          const selectedRoles = userConfig.userRoles.filter((userRole) => {
            return userRole.registryId === selectedStudy.registryId;
          });

          // Update the selected study in Looker if the user has permission to see Looker dashboards.
          if (
            hasAnyRole(
              selectedRoles,
              Role.CDM,
              Role.SPONSOR_OPS,
              Role.COMPLIANCE_ADMIN,
              Role.CRC
            )
          ) {
            const auth0Token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            let setting = await getComplianceSetting(
              auth0Config.audience!,
              auth0Token,
              selectedStudy.registryId
            );

            if (setting == null) {
              setting = getDefaultComplianceSetting();
            }

            if (!featureFlagProvider.isSurveyEnabled(appConfig.environment)) {
              setting.setSurveySubmissionPercentage(0);
            }

            try {
              // Send selected study and compliance parameters to Looker.
              const updateStudyRequest = new UpdateStudyRequest()
                .setRegistryId(selectedStudy.registryId)
                .setComplianceSetting(setting);

              const studyDataClient = new StudyDataServiceApiClient(
                auth0Config.audience!,
                auth0Token
              );

              // Set selected site id if the user has only CRC role.
              let studySiteId = "";
              if (
                !hasAnyRole(
                  selectedRoles,
                  Role.SPONSOR_OPS,
                  Role.COMPLIANCE_ADMIN,
                  Role.CDM
                ) &&
                hasAnyRole(selectedRoles, Role.CRC)
              ) {
                // There should be only one site for a CRC per study.
                const selectedCrcRole = selectedRoles.find((role) => {
                  return role.role === Role.CRC;
                });

                if (selectedCrcRole !== undefined) {
                  studySiteId = selectedCrcRole.studySiteId;
                  updateStudyRequest.setSiteId(studySiteId);
                }
              }

              await studyDataClient.updateStudy(updateStudyRequest);

              // Get Looker dashboard information
              const listDashboardsRequest = new ListDashboardsRequest()
                .setRegistryId(selectedStudy.registryId)
                .setSiteId(studySiteId);

              const response = await studyDataClient.listDashboards(
                listDashboardsRequest
              );

              const dashboards = new Array<LookerDashboardConfig>();
              for (const dashboard of response.getDashboardsList()) {
                dashboards.push({
                  dashboardId: dashboard.getDashboardId(),
                  queryId: dashboard.getQueryId(),
                });
              }

              dispatch(setLookerConfig({ dashboards: dashboards }));
            } catch (error) {
              setShowProgressSpinner(false);
              setShowErrorMessage(true);
              setErrorMessage("Failed to select the study. Please try again.");
              return;
            }
          }

          // Reset monitoring compliance screen state to default.
          dispatch(resetCrcComplianceScreenState());
          dispatch(resetStudyComplianceScreenState());
          dispatch(resetNonCompliantTabState());

          // Reset participants screen state to default.
          dispatch(resetParticipantsViewState());

          // Reset cache data for compliance screen.
          clearLookerQueryResultsCache();
          clearLookerSettingsCache();

          // Select last week in the week navigation by default.
          dispatch(setPreviousWeekOffset(1));

          dispatch(setSelectedRegistryId(selectedStudy.registryId));
          setShowProgressSpinner(false);

          if (hasAnyRole(selectedRoles, Role.CDM)) {
            navigate(AppRoutes.COMPLIANCE_SETTING);
          } else {
            navigate(AppRoutes.HOME);
          }
        })();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          "User is not authenticated. Please logout and login again."
        );
      }
    },
    [
      appConfig.environment,
      auth0Config,
      dispatch,
      getAccessTokenSilently,
      isAuthenticated,
      navigate,
      userConfig.userRoles,
    ]
  );

  const sortedStudies = useMemo(() => {
    const studies = [...userConfig.studies];
    return studies.sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "study name":
          let studyNameA = studyDetails.has(a.registryId)
            ? studyDetails.get(a.registryId)!.getStudyName()
            : a.displayName;
          let studyNameB = studyDetails.has(b.registryId)
            ? studyDetails.get(b.registryId)!.getStudyName()
            : b.displayName;
          result = studyNameA.localeCompare(studyNameB);
          break;
        case "sponsor":
          let sponsorNameA = studyDetails.has(a.registryId)
            ? studyDetails.get(a.registryId)!.getSponsorName()
            : "";
          let sponsorNameB = studyDetails.has(b.registryId)
            ? studyDetails.get(b.registryId)!.getSponsorName()
            : "";
          result = sponsorNameA.localeCompare(sponsorNameB);
          break;
        case "study ID":
          let studyIdA = studyDetails.has(a.registryId)
            ? studyDetails.get(a.registryId)!.getStudyId()
            : "";
          let studyIdB = studyDetails.has(b.registryId)
            ? studyDetails.get(b.registryId)!.getStudyId()
            : "";
          result = studyIdA.localeCompare(studyIdB);
          break;
      }
      return order === "asc" ? result : -result;
    });
  }, [userConfig.studies, studyDetails, orderBy, order]);

  const theme = useTheme();

  if (!appState.userConfigured) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "left",
        width: "100%",
      }}
    >
      <HeaderNav />
      <Divider sx={{ width: "100%" }} />

      <Box
        role="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: theme.palette.primary.background,
            padding: "27.5px 40px",
          }}
        >
          <Typography
            component="h1"
            variant="display3"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 275,
              lineHeight: "125%",
              color: theme.palette.primary.textOnBackground,
            }}
          >
            {`Welcome, ${userConfig.userFirstName}!`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "40px",
          }}
        >
          <Typography
            component="h2"
            variant="display5"
            sx={{ color: theme.palette.common.black }}
          >
            My studies
          </Typography>
          <Box sx={{ position: "relative", marginTop: "24px" }}>
            {/* Show loading indicator while loading is in progress */}
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {/* Show error screen if there is a loading error */}
            {!isLoading && hasError && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "200px",
                }}
              >
                <Alert severity="error">
                  Failed to load study details. Please try again.
                </Alert>
              </Box>
            )}
            {/** show progress spinner as an overlay above the table content */}
            {showProcessSpinner && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!isLoading && !hasError && (
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="studies">
                  <StudiesTableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <StudiesTableContent
                    studies={sortedStudies}
                    studyDetails={studyDetails}
                    onClickNextButton={(study) => {
                      if (!showProcessSpinner) {
                        setShowErrorMessage(false);
                        handleClickNextButton(study);
                      }
                    }}
                  />
                </Table>
                <A11yStatusMessage message={sortDirectiontMessage} />
              </TableContainer>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        text={errorMessage}
        color="error"
        withIcon
        open={showErrorMessage}
        onClose={() => {
          setShowErrorMessage(false);
        }}
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default Studies;
