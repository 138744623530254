/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.device.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: device/device.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.device = {};
proto.verily.sdp.device.v1alpha1 = require('./device_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.device.v1alpha1.DeviceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.device.v1alpha1.DeviceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.device.v1alpha1.ListDevicesRequest,
 *   !proto.verily.sdp.device.v1alpha1.ListDevicesResponse>}
 */
const methodDescriptor_DeviceService_ListDevices = new grpc.web.MethodDescriptor(
  '/verily.sdp.device.v1alpha1.DeviceService/ListDevices',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.device.v1alpha1.ListDevicesRequest,
  proto.verily.sdp.device.v1alpha1.ListDevicesResponse,
  /**
   * @param {!proto.verily.sdp.device.v1alpha1.ListDevicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.device.v1alpha1.ListDevicesResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.device.v1alpha1.ListDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.device.v1alpha1.ListDevicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.device.v1alpha1.ListDevicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.device.v1alpha1.DeviceServiceClient.prototype.listDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.device.v1alpha1.DeviceService/ListDevices',
      request,
      metadata || {},
      methodDescriptor_DeviceService_ListDevices,
      callback);
};


/**
 * @param {!proto.verily.sdp.device.v1alpha1.ListDevicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.device.v1alpha1.ListDevicesResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.device.v1alpha1.DeviceServicePromiseClient.prototype.listDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.device.v1alpha1.DeviceService/ListDevices',
      request,
      metadata || {},
      methodDescriptor_DeviceService_ListDevices);
};


module.exports = proto.verily.sdp.device.v1alpha1;

