import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;

  /** Corresponding tab index of the TabPanel */
  tabIndex: number;

  /** Currently selected tab index */
  selectedTabIndex: number;
}

/**
 * Container for the tab content under a MUI tab.
 *
 * Implementation is based on examples in https://mui.com/material-ui/react-tabs.
 */
export function TabPanel(props: TabPanelProps) {
  const { children, selectedTabIndex, tabIndex } = props;

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${tabIndex}`}
      aria-labelledby={`simple-tab-${tabIndex}`}
      sx={{
        flexGrow: 1,
        display: selectedTabIndex === tabIndex ? "block" : "none",
        paddingTop: "16px",
      }}
    >
      {selectedTabIndex === tabIndex && (
        <Box sx={{ height: "100%" }}>{children}</Box>
      )}
    </Box>
  );
}

export function tabA11yProps(tabIndex: number) {
  return {
    id: `simple-tab-${tabIndex}`,
    "aria-controls": `simple-tabpanel-${tabIndex}`,
  };
}
