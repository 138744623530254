import { startOfMonth, startOfWeek, endOfWeek, endOfMonth } from "date-fns";

/**
 * Returns every Date from the first Sunday to the last Saturday in a
 * rectangular month. Each Date is at midnight of the local timezone.
 *
 * For example:
 *
 *    getSurroundingMonthDates(2024-01-27) => [2023-12-31, ..., 2024-02-03]
 */
export function getDatesSurroundingMonth(date: Date) {
  const firstDay = startOfWeek(startOfMonth(date));
  const lastDay = endOfWeek(endOfMonth(date));
  const dates = [];
  for (const d = firstDay; d <= lastDay; d.setDate(d.getDate() + 1)) {
    const date = new Date(d);
    dates.push(date);
  }
  return dates;
}
