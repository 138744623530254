import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

export interface LookerDashboardConfig {
  dashboardId: LookerDashboardId;
  queryId: string;
}

export interface LookerConfig {
  dashboards: Array<LookerDashboardConfig>;
}

const initialState: LookerConfig = {
  dashboards: new Array<LookerDashboardConfig>(),
};

const lookerConfigSlice = createSlice({
  name: "lookerConfig",
  initialState,
  reducers: {
    setLookerConfig: (state, action: PayloadAction<LookerConfig>) => {
      state.dashboards = action.payload.dashboards;
    },
  },
});

export const { setLookerConfig } = lookerConfigSlice.actions;
export default lookerConfigSlice.reducer;

/** Selector to convert LookerConfig to a map between LookerDashboard enum and query id */
export const selectLookerQueryIdsMap = createSelector(
  (lookerConfig: LookerConfig) => lookerConfig.dashboards,
  (dashboards) => {
    const queryIdsMap = new Map<LookerDashboardId, string>();
    for (const dashboard of dashboards) {
      queryIdsMap.set(dashboard.dashboardId, dashboard.queryId);
    }
    return queryIdsMap;
  }
);
