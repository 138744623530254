import React from "react";
import { LocalizationProvider as MUILocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns as MUIDateFnsAdapter } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@verily-src/react-design-system";

export interface SDPDatePickerProps {
  label: string;
  value: Date | null | undefined;
  disableFuture?: boolean;
  error: boolean;
  errorText?: string;
  onChange(date: Date | null | undefined): void;
  sx?: any;
}
export const SDPDatePicker: React.FC<SDPDatePickerProps> = ({
  label,
  value,
  disableFuture,
  error,
  errorText,
  onChange,
  sx: sxProps,
}) => {
  return (
    <MUILocalizationProvider dateAdapter={MUIDateFnsAdapter}>
      <DatePicker
        label={label}
        value={value}
        format="M/d/yyyy"
        sx={{
          width: "100%",
          ...sxProps,
        }}
        disableFuture={disableFuture}
        error={error}
        errorText={errorText}
        onChange={(date: Date | null) => onChange(date)}
        useUnlaunchedComponent
      ></DatePicker>
    </MUILocalizationProvider>
  );
};
