import React from "react";
import {
  Alert,
  Box,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { ParticipantSession } from "generated/participantsession/participantsession_pb";
import { RemovePicardPrefix } from "common/ResourceName";
import { WatchAssignmentFieldRef } from "./WatchAssignmentField";
import { format } from "date-fns";
import { Button } from "@verily-src/react-design-system";
import { dialog_border_radius } from "styles/Dimensions";
import HeaderValuePair from "components/HeaderValuePair";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ConfirmAssignWatchDialogProps {
  studyParticipant: StudyParticipant;
  mostRecentAssociation: ParticipantSession | undefined;
  selectedDeviceId: string;
  confirmLoading: boolean;
  setConfirmLoading: (loading: boolean) => void;
  showConfirmWatchAssignmentDialog: boolean;
  setShowConfirmWatchAssignmentDialog: (show: boolean) => void;
  createParticipantSession: (
    participant: StudyParticipant,
    deviceId: string,
    startTime?: Date
  ) => void;
  watchAssignmentRef: React.RefObject<WatchAssignmentFieldRef>;
}

const ConfirmAssignWatchDialog: React.FC<ConfirmAssignWatchDialogProps> = ({
  studyParticipant,
  showConfirmWatchAssignmentDialog,
  setShowConfirmWatchAssignmentDialog,
  mostRecentAssociation,
  setConfirmLoading,
  confirmLoading,
  selectedDeviceId,
  createParticipantSession,
  watchAssignmentRef,
}) => {
  return (
    <MUIDialog
      open={showConfirmWatchAssignmentDialog}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">Confirm details</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {mostRecentAssociation && (
          <Box paddingBottom="24px">
            <Alert severity="info">
              This watch has been assigned to this participant before. Any
              missing compliance data will be backfilled as of
              {" " +
                format(
                  mostRecentAssociation
                    .getInterval()
                    ?.getStartTime()
                    ?.toDate()!,
                  "M/d/yyyy"
                )}
              .
            </Alert>
          </Box>
        )}
        <Box paddingBottom="24px">
          <HeaderValuePair
            component="h2"
            title="Participant ID"
            value={studyParticipant.getSponsorParticipantId()}
            fontFamily="Roboto Mono"
          />
        </Box>
        <Box paddingBottom="24px">
          <HeaderValuePair
            component="h2"
            title="Assigned watch"
            value={RemovePicardPrefix(selectedDeviceId)}
            fontFamily="Roboto Mono"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setShowConfirmWatchAssignmentDialog(false);
            watchAssignmentRef.current!.showDuplicatesDialog();
          }}
        >
          Go back
        </Button>
        <Button
          variant="filled"
          loading={confirmLoading}
          onClick={async () => {
            setConfirmLoading(true);
            // If there is a most recent association, use the start time of that association
            if (mostRecentAssociation) {
              await createParticipantSession(
                studyParticipant,
                selectedDeviceId,
                mostRecentAssociation.getInterval()?.getStartTime()?.toDate()
              );
            } else {
              await createParticipantSession(
                studyParticipant,
                selectedDeviceId
              );
            }
            setConfirmLoading(false);
            setShowConfirmWatchAssignmentDialog(false);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </MUIDialog>
  );
};

export default ConfirmAssignWatchDialog;
