import {
  Alert,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as WatchIcon } from "assets/watch-icon-black-white.svg";
import { ReactComponent as SyncIcon } from "assets/sync-icon-black-white.svg";
import { ReactComponent as PollIcon } from "assets/poll-icon-black-white.svg";
import { ReactComponent as MedicationPillIcon } from "assets/medication-pill-icon-black-white.svg";
import { ReactComponent as UnmetIcon } from "assets/unmet-icon.svg";
import { ReactComponent as MetIcon } from "assets/met-icon.svg";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import {
  ArrowBackIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Link,
  MonitorHeartIcon,
} from "@verily-src/react-design-system";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Calendar from "./Calendar";
import { WatchData, earliestSyncDate } from "./CalendarTypes";
import SummaryPanel from "./SummaryPanel";
import { AppRoutes } from "core/AppRoutes";
import { useAppSelector } from "redux/hooks";
import {
  endOfMonth,
  endOfWeek,
  formatISO,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { getDatesSurroundingMonth } from "./Month";
import { useAuth0 } from "@auth0/auth0-react";
import { getParticipantWearMinutes } from "looker/GetParticipantWearMinutes";
import { getParticipantMedicationLogsDaily } from "looker/GetParticipantMedicationLogsDaily";
import { getLookerSdk } from "looker/LookerSdk";
import { getParticipantSyncTime } from "looker/GetParticipantSyncTime";
import { updateParticipantReportTimePeriod } from "looker/UpdateParticipantReportTimePeriod";
import { updateParticipantId } from "looker/UpdateParticipantId";
import { getParticipantDevices } from "looker/GetParticipantDevices";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import {
  DeviceAssignment,
  participantLatestSyncDateCache,
} from "looker/CalendarViewQueryResultsCache";
import { getParticipantWearTimeRanges } from "looker/GetParticipantWearTimeRanges";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import {
  focusOnRef,
  isActivationKey,
  sxFocusWithin,
} from "common/Accessibility";
import { usePageTitle } from "components/PageTitle";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";
import { getParticipant } from "./GetParticipant";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";

const ParticipantCompliance: React.FC = () => {
  const selectedParticipant = useAppSelector((state) => {
    return state.participantComplianceState;
  });

  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [participant, setParticipant] = useState<StudyParticipant>(
    new StudyParticipant()
  );
  const [watchData, setWatchData] = useState<WatchData[]>([]);
  const [latestSync, setLatestSync] = useState<Date>(new Date());
  const [devices, setDevices] = useState(new Array<DeviceAssignment>());
  const [isLoadingWatchData, setIsLoadingWatchData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [medsLoggingEnabled, setMedsLoggingEnabled] = useState(false);

  const navigate = useNavigate();

  // Set page title.
  usePageTitle("Participant compliance");

  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const now = new Date();
  const [monthStart, setMonthStart] = useState(startOfMonth(now));

  const [complianceSetting, setComplianceSetting] = useState(
    getDefaultComplianceSetting()
  );

  const queryMonthlyWatchData = useCallback(
    async (monthStart: Date) => {
      if (!isAuthenticated) {
        setHasError(true);
        return;
      }

      setHasError(false);
      setIsLoadingWatchData(true);

      const today = startOfToday();
      const firstDay = startOfWeek(startOfMonth(monthStart));
      const lastDay = endOfWeek(endOfMonth(monthStart));

      let latestSync = earliestSyncDate;

      try {
        // Set Looker user attributes in order to query Looker dashboards.
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        let complianceSetting = await getComplianceSetting(
          auth0Config.audience!,
          token,
          currentRegistryId
        );

        if (complianceSetting === null) {
          complianceSetting = getDefaultComplianceSetting();
        }

        setComplianceSetting(complianceSetting);

        await updateParticipantId(
          auth0Config.audience!,
          token,
          selectedParticipant.participantId
        );

        await updateParticipantReportTimePeriod(
          auth0Config.audience!,
          token,
          firstDay,
          lastDay,
          monthStart
        );

        const medsLoggingEnabled =
          complianceSetting.getMedsLoggedPerDay() > 0 ||
          complianceSetting.getMedsLoggedPerWeek() > 0;

        setMedsLoggingEnabled(medsLoggingEnabled);

        // Get compliance metrics simultaneously
        const metrics = await Promise.all([
          getParticipantDevices(
            sdk,
            selectedParticipant.participantId,
            monthStart,
            lookerQueryIdsMap
          ),
          getParticipantWearMinutes(
            sdk,
            selectedParticipant.participantId,
            monthStart,
            lookerQueryIdsMap
          ),
          getParticipantSyncTime(
            sdk,
            selectedParticipant.participantId,
            monthStart,
            lookerQueryIdsMap
          ),
          getParticipantWearTimeRanges(
            sdk,
            selectedParticipant.participantId,
            monthStart,
            lookerQueryIdsMap
          ),
          getParticipantMedicationLogsDaily(
            sdk,
            selectedParticipant.participantId,
            monthStart,
            medsLoggingEnabled,
            lookerQueryIdsMap
          ),
          getParticipant(
            auth0Config.audience!,
            token,
            selectedParticipant.participantId
          ),
        ]);

        const hasError = metrics.some((metric) => {
          return metric.hasError;
        });
        if (hasError) {
          setHasError(true);
          setIsLoadingWatchData(false);
          return;
        }

        const [
          devicesMetric,
          wearMinutesMetric,
          syncTimeMetric,
          wearTimeRangesMetric,
          medsLoggingMetric,
          participantInfo,
        ] = metrics;

        const surroundingDates = getDatesSurroundingMonth(monthStart);
        const watchData = [];
        for (const date of surroundingDates) {
          // We should never get data after today.
          if (isAfter(date, today)) {
            continue;
          }

          const record: WatchData = {
            date: formatISO(date, { representation: "date" }),
          };

          if (wearMinutesMetric.wearMinutesMap.has(record.date)) {
            record.wearHrs =
              wearMinutesMetric.wearMinutesMap.get(record.date)! / 60.0;
          }

          if (syncTimeMetric.syncTimesMap.has(record.date)) {
            record.syncs = 1;
            record.syncTime = syncTimeMetric.syncTimesMap.get(record.date)!;
            if (latestSync < record.syncTime) {
              latestSync = record.syncTime;
            }
          }

          if (wearTimeRangesMetric.wearTimeRangesMap.has(record.date)) {
            record.wearTimeRanges = wearTimeRangesMetric.wearTimeRangesMap.get(
              record.date
            )!;
          }

          if (medsLoggingEnabled) {
            if (medsLoggingMetric.medLogsMap.has(record.date)) {
              record.medicationTags = medsLoggingMetric.medLogsMap.get(
                record.date
              )!;
            }
          }

          watchData.push(record);
        }

        // Use the latest sync date for the participant.
        const cacheKey = selectedParticipant.participantId;
        if (participantLatestSyncDateCache.has(cacheKey)) {
          const cachedLatestSync =
            participantLatestSyncDateCache.get(cacheKey)!;
          if (cachedLatestSync < latestSync) {
            participantLatestSyncDateCache.set(cacheKey, latestSync);
          } else {
            latestSync = cachedLatestSync;
          }
        } else {
          participantLatestSyncDateCache.set(cacheKey, latestSync);
        }

        setWatchData(watchData);
        setDevices(devicesMetric.records);
        setLatestSync(latestSync);
        setParticipant(participantInfo.participant!);
      } catch (error) {
        console.log("Failed to load participant compliance data. " + error);
        setHasError(true);
        setIsLoadingWatchData(false);
        return;
      }

      setIsLoadingWatchData(false);
    },
    [
      isAuthenticated,
      getAccessTokenSilently,
      auth0Config.audience,
      currentRegistryId,
      selectedParticipant.participantId,
      sdk,
      lookerQueryIdsMap,
    ]
  );

  useEffect(() => {
    if (!currentRegistryId) {
      navigate(AppRoutes.HOME);
    }
    queryMonthlyWatchData(monthStart);
  }, [currentRegistryId, monthStart, navigate, queryMonthlyWatchData]);

  const header = (
    <Header
      id={selectedParticipant.subjectId || ""}
      siteName={selectedParticipant.siteName}
    />
  );

  if (isLoadingWatchData) {
    return (
      <Box
        role="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          height: "100vh",
        }}
      >
        {header}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <SlowLoadingIndicator />
        </Box>
      </Box>
    );
  }

  if (hasError || complianceSetting === null) {
    return (
      <Box
        role="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          height: "100vh",
        }}
      >
        {header}
        <Alert severity="error">Failed to load compliance requirements</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        height: "100vh",
        overflowY: "auto",
        overflowX: "auto",
      }}
    >
      {header}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Box
          role="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "24px 0px 24px 40px",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <Paginator
            today={now}
            monthStart={monthStart}
            onMonthChange={setMonthStart}
          />
          <CalendarLegend medsLoggingEnabled={medsLoggingEnabled} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flexGrow: 1,
              minWidth: "860px",
            }}
          >
            <Calendar
              monthStart={monthStart}
              today={startOfDay(now)}
              watchData={watchData}
              complianceSetting={complianceSetting}
              latestSync={latestSync}
              devices={devices}
              scope={selectedParticipant.scope}
              participant={participant}
            />
          </Box>
        </Box>
        <Box
          role="complimentary"
          tabIndex={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "24px 40px 24px 24px",
          }}
        >
          <SummaryPanel
            watchData={watchData}
            complianceSetting={complianceSetting}
            latestSync={latestSync}
            calendarFirstDay={startOfWeek(startOfMonth(monthStart))}
            calendarLastDay={endOfWeek(endOfMonth(monthStart))}
            scope={selectedParticipant.scope}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface HeaderProps {
  id: string;
  siteName?: string;
}

const Header: React.FC<HeaderProps> = ({ id, siteName }) => {
  const returnRoute = useAppSelector(
    (state) => state.participantComplianceState.returnRoute
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const title = `Participant ${id}`;
  const titleRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    focusOnRef(titleRef);
  }, []);

  return (
    <Box
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        padding: "24px 40px",
        width: "100%",
        background: theme.palette.background.canvas,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Link
          tabIndex={0}
          onClick={() => {
            navigate(returnRoute, { state: { prevPath: location.pathname } });
          }}
          onKeyDown={(event) => {
            if (isActivationKey(event)) {
              navigate(returnRoute, { state: { prevPath: location.pathname } });
            }
          }}
          sx={{ marginRight: "12px", marginTop: "4px" }}
        >
          <ArrowBackIcon
            titleAccess="Back"
            sx={{ color: theme.palette.icon.default }}
          />
        </Link>
        <Typography
          component="h1"
          variant="display5"
          ref={titleRef}
          tabIndex={-1}
        >
          {title}
        </Typography>
      </Box>
      {siteName && (
        <Typography
          variant="body1"
          sx={{ marginTop: "8px", marginLeft: "36px" }}
        >
          <b>Site</b>: {siteName}
        </Typography>
      )}
    </Box>
  );
};

interface PaginatorProps {
  today: Date;
  monthStart: Date;
  onMonthChange: (monthStart: Date) => void;
}

const Paginator: React.FC<PaginatorProps> = ({
  today,
  monthStart,
  onMonthChange,
}) => {
  const monthLabel = monthStart.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
  const isCurrentMonth =
    monthLabel ===
    today.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={sxFocusWithin}>
        <IconButton
          onClick={() => {
            const previousMonth = new Date(
              monthStart.setMonth(monthStart.getMonth() - 1)
            );
            onMonthChange(previousMonth);
          }}
        >
          <ChevronLeftIcon titleAccess="Previous month" />
        </IconButton>
      </Box>
      <Box sx={sxFocusWithin}>
        <IconButton
          onClick={() => {
            const previousMonth = new Date(
              monthStart.setMonth(monthStart.getMonth() + 1)
            );
            onMonthChange(previousMonth);
          }}
          disabled={isCurrentMonth}
        >
          <ChevronRightIcon titleAccess="Next month" />
        </IconButton>
      </Box>
      <Typography component="h2" variant="display6">
        {monthLabel}
      </Typography>
    </Stack>
  );
};

interface CalendarLegendProps {
  medsLoggingEnabled: boolean;
}
const CalendarLegend: React.FC<CalendarLegendProps> = ({
  medsLoggingEnabled,
}) => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const IconCellSxProp = {
    verticalAlign: "top",
    horizontalAlign: "left",
    paddingLeft: "0px",
    paddingRight: "16px",
  };

  // Used to hide metrics that are not available now.
  const IconCellSxPropDisplayNone = {
    ...IconCellSxProp,
    display: "none",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "16px 16px 16px 0px",
      }}
    >
      <Box sx={IconCellSxProp}>
        <Typography variant="overline">LEGEND:</Typography>
      </Box>
      <Box sx={IconCellSxProp}>
        <WatchIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          Watch wear
        </Typography>
      </Box>
      <Box sx={IconCellSxProp}>
        <SyncIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          Watch sync
        </Typography>
      </Box>
      {featureFlagProvider.isSurveyEnabled(appConfig.environment) && (
        <Box sx={IconCellSxProp}>
          <PollIcon />
          <Typography variant="caption" sx={{ marginLeft: "4px" }}>
            Survey
          </Typography>
        </Box>
      )}
      {medsLoggingEnabled && ( // if medsLogging is enabled, display the icon in the calendar view
        <Box sx={IconCellSxProp}>
          <MedicationPillIcon />
          <Typography variant="caption" sx={{ marginLeft: "4px" }}>
            Medication log
          </Typography>
        </Box>
      )}
      <Box sx={IconCellSxPropDisplayNone}>
        <DirectionsWalkIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          Virtual motor exam
        </Typography>
      </Box>

      <Box sx={IconCellSxPropDisplayNone}>
        <MonitorHeartIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          ECG
        </Typography>
      </Box>
      <Box sx={IconCellSxProp}>
        <MetIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          Met
        </Typography>
      </Box>
      <Box sx={IconCellSxProp}>
        <UnmetIcon />
        <Typography variant="caption" sx={{ marginLeft: "4px" }}>
          Unmet
        </Typography>
      </Box>
    </Box>
  );
};

export default ParticipantCompliance;
