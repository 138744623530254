import { useEffect } from "react";
import { setInitialized } from "./AppStateSlice";
import appConfigManager from "./AppConfigManager";
import { setAppConfig } from "./AppConfigSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setAuth0Config } from "./Auth0ConfigSlice";
import { Auth0ProviderOptions } from "@auth0/auth0-react";

export const AppStateManager: React.FC = () => {
  const appState = useAppSelector((state) => state.appState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // See go/sdp-design for a complete state diagram.

    // Get environment-specific config values from app.config.json.
    if (!appState.initialized) {
      appConfigManager.loadAppConfig().then((appConfig) => {
        if (appConfig) {
          dispatch(setAppConfig(appConfig));

          const auth0Config: Auth0ProviderOptions = {
            audience: appConfig.auth0Audience,
            clientId: appConfig.auth0ClientId,
            domain: appConfig.auth0Url,
            redirectUri: window.location.origin,
          };
          dispatch(setAuth0Config(auth0Config));

          dispatch(setInitialized(true));
        }
      });
      return;
    }
  }, [appState, dispatch]);

  return <></>;
};
