import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  initialized: boolean;
  userConfigured: boolean;
}

const initialState: AppState = {
  initialized: false,
  userConfigured: false,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<AppState>) => {
      state.initialized = action.payload.initialized;
      state.userConfigured = action.payload.userConfigured;
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setUserConfigured: (state, action: PayloadAction<boolean>) => {
      state.userConfigured = action.payload;
    },
  },
});

export const { setAppState, setInitialized, setUserConfigured } =
  appStateSlice.actions;
export default appStateSlice.reducer;
