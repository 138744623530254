import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, Dialog as MUIDialog, Typography } from "@mui/material";
import { RadioGroup, Radio } from "@verily-src/react-design-system";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import {
  EndParticipantSessionRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { Footer } from "components/Footer";
import { RpcError, StatusCode } from "grpc-web";
import {
  Option,
  RadioOptions,
  TaskOptions,
  ReturnReasonOptions,
  WatchOwnerOptions,
} from "common/RadioOptions";
import { StepInfo, Steps, ManageWatchSteps } from "common/Steps";
import { RemovePicardPrefix } from "common/ResourceName";
import WatchSwapper, { WatchSwapperRef } from "components/WatchSwapper";
import WatchSwapperConfirm, {
  WatchSwapperConfirmRef,
} from "components/WatchSwapperConfirm";
import ContactVerilyDialog from "components/ContactVerilyDialog";
import { ChangeStatusRedirectDialog } from "./ChangeStatusRedirectDialog";
import StepsHeader from "components/StepsHeader";
import { focusOnRef } from "common/Accessibility";
import HeaderValuePair from "components/HeaderValuePair";

// Radio button selections for the task
const taskSelection = new RadioOptions([
  {
    id: TaskOptions.Return,
    label: "Unassign a returned watch",
    nextStepId: ManageWatchSteps.ReturnReason,
  },
  {
    id: TaskOptions.Lost,
    label: "Unassign a lost watch",
    nextStepId: ManageWatchSteps.ConfirmWatchUnassignment,
  },
  {
    id: TaskOptions.Error,
    label: "Fix a watch-association error",
    nextStepId: ManageWatchSteps.WatchAssociationError,
  },
  {
    id: TaskOptions.Other,
    label: "Other",
    nextStepId: -1,
  },
]);

// Radio button selections for the return reasons
const returnSelection = new RadioOptions([
  {
    id: ReturnReasonOptions.ExitStudy,
    label: "They completed the study, or withdrew",
    nextStepId: -1,
  },
  {
    id: ReturnReasonOptions.WatchIssue,
    label: "Technical issues with the watch",
    nextStepId: ManageWatchSteps.ConfirmWatchUnassignment,
  },
  {
    id: ReturnReasonOptions.Other,
    label: "Other",
    nextStepId: ManageWatchSteps.ConfirmWatchUnassignment,
  },
]);

// Radio button selections who has the watch
const watchOwnerSelection = new RadioOptions([
  {
    id: WatchOwnerOptions.StudyStaff,
    label: "Study staff. The watch hasn’t been given to another participant.",
    nextStepId: ManageWatchSteps.ConfirmWatchUnassignment,
  },
  {
    id: WatchOwnerOptions.AnotherParticpant,
    label:
      "Another participant in this study. I need to swap watch assignments.",
    nextStepId: ManageWatchSteps.WatchSwap,
  },
  {
    id: WatchOwnerOptions.Other,
    label: "Other",
    nextStepId: -1,
  },
]);

// Identifies which radio button group to focus on
const enum FocusOptions {
  Task,
  Return,
  WatchOwner,
}

interface ManageWatchDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  participantSession: ParticipantSession;
  onClose(): void;
  onSuccess(msg: string): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const ManageWatchDialog: React.FC<ManageWatchDialogProps> = ({
  open,
  studyParticipant,
  participantSession,
  onClose,
  onSuccess,
  onError,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedTask, setSelectedTask] = useState<Option>(
    taskSelection.default
  );
  const [selectedReturn, setSelectedReturn] = useState<Option>(
    returnSelection.default
  );
  const [selectedWatchOwner, setSelectedWatchOwner] = useState<Option>(
    watchOwnerSelection.default
  );

  const [confirmLoading, setConfirmLoading] = useState(false);

  const watchSwapperRef = useRef<WatchSwapperRef>(null);
  const watchSwapperConfirmRef = useRef<WatchSwapperConfirmRef>(null);

  const [swaps, setSwaps] = useState<Map<string, string>>(
    new Map([[studyParticipant.getSponsorParticipantId(), ""]])
  );

  // Radio button focus management
  const [currentFocus, setCurrentFocus] = useState<FocusOptions>(
    FocusOptions.Task
  );
  const [focusIndex, setFocusIndex] = useState(-1);
  const taskOptionsRef = useRef(
    Array.from(taskSelection.options).map(() => createRef<HTMLInputElement>())
  );
  const returnOptionsRef = useRef(
    Array.from(returnSelection.options).map(() => createRef<HTMLInputElement>())
  );
  const watchOwnerOptionsRef = useRef(
    Array.from(watchOwnerSelection.options).map(() =>
      createRef<HTMLInputElement>()
    )
  );

  useEffect(() => {
    if (open) {
      setCurrentFocus(FocusOptions.Task);
      setFocusIndex(-1);
      setConfirmLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (focusIndex > -1) {
      switch (currentFocus) {
        case FocusOptions.Task:
          focusOnRef(taskOptionsRef.current[focusIndex]);
          break;
        case FocusOptions.Return:
          focusOnRef(returnOptionsRef.current[focusIndex]);
          break;
        case FocusOptions.WatchOwner:
          focusOnRef(watchOwnerOptionsRef.current[focusIndex]);
          break;
      }
    }
  }, [focusIndex, currentFocus]);

  const getUpdateReason = (
    selectedTaskId: number,
    selectedReturnLabel: string,
    selectedWatchOwnerId: number
  ): string => {
    switch (selectedTaskId) {
      case TaskOptions.Return:
        return selectedReturnLabel;
      case TaskOptions.Lost:
        return "Watch lost";
      case TaskOptions.Error:
        if (selectedWatchOwnerId === WatchOwnerOptions.StudyStaff) {
          return "Fix a watch-association error. Study staff is on possession of this watch. The watch has not been given to another participant.";
        } else if (
          selectedWatchOwnerId === WatchOwnerOptions.AnotherParticpant
        ) {
          return "Fix a watch association error. Watch assignments need to be swapped between participants.";
        } else {
          return "Other";
        }
      default:
        return "Unassign watch";
    }
  };

  const stepInfo: Array<StepInfo> = [
    {
      id: ManageWatchSteps.TaskSelection,
      page: (
        <RadioGroup>
          <Box
            sx={{
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Typography component="h2" variant="display6">
              Choose a task
            </Typography>
          </Box>
          {Array.from(taskSelection.options).map(
            ([_, option]: [number, Option], index) => {
              return (
                <Radio
                  inputRef={taskOptionsRef.current[index]}
                  key={index}
                  label={option.label}
                  value={option.nextStepId}
                  checked={selectedTask.id === option.id}
                  onClick={() => {
                    setSelectedTask(option);
                    setCurrentFocus(FocusOptions.Task);
                    setFocusIndex(index);
                  }}
                />
              );
            }
          )}
        </RadioGroup>
      ),
      isPreviousDisabled: true,
      onPrevious: () => {},
      nextButtonText: "Next",
      isNextButtonDisabled: (() => {
        return selectedTask.id === -1;
      })(),
      onNext: () => {
        switch (selectedTask.id) {
          case TaskOptions.Other:
            setShowContactVerilyDialog(true);
            break;
          default:
            setPrevStepId(ManageWatchSteps.TaskSelection);
            setStepId(selectedTask.nextStepId);
            break;
        }
      },
    },
    {
      id: ManageWatchSteps.ReturnReason,
      page: (
        <RadioGroup>
          <Box
            sx={{
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Typography component="h2" variant="display6">
              Why did the participant return the watch?
            </Typography>
          </Box>
          {Array.from(returnSelection.options).map(
            ([_, option]: [number, Option], index) => {
              return (
                <Radio
                  inputRef={returnOptionsRef.current[index]}
                  key={index}
                  label={option.label}
                  value={option.nextStepId}
                  checked={selectedReturn.id === option.id}
                  onClick={() => {
                    setSelectedReturn(option);
                    setCurrentFocus(FocusOptions.Return);
                    setFocusIndex(index);
                  }}
                />
              );
            }
          )}
        </RadioGroup>
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(ManageWatchSteps.TaskSelection);
      },
      isPreviousDisabled: false,
      nextButtonText: "Next",
      isNextButtonDisabled: (() => {
        return selectedReturn.id === -1;
      })(),
      onNext: () => {
        switch (selectedReturn.id) {
          case ReturnReasonOptions.ExitStudy:
            setShowRedirectDialog(true);
            break;
          default:
            setPrevStepId(ManageWatchSteps.ReturnReason);
            setStepId(selectedReturn.nextStepId);
            break;
        }
      },
    },
    {
      id: ManageWatchSteps.WatchAssociationError,
      page: (
        <RadioGroup>
          <Box
            sx={{
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Typography component="h2" variant="display6">
              Who currently has this watch (Device ID{" "}
              <Typography
                variant="inherit"
                display="inline-flex"
                fontFamily="Roboto Mono"
              >
                {RemovePicardPrefix(participantSession.getDeviceId())}
              </Typography>
              )?
            </Typography>
          </Box>
          {Array.from(watchOwnerSelection.options).map(
            ([_, option]: [number, Option], index) => {
              return (
                <Radio
                  inputRef={watchOwnerOptionsRef.current[index]}
                  key={index}
                  label={option.label}
                  value={option.nextStepId}
                  checked={selectedWatchOwner.id === option.id}
                  onClick={() => {
                    setSelectedWatchOwner(option);
                    setCurrentFocus(FocusOptions.WatchOwner);
                    setFocusIndex(index);
                  }}
                />
              );
            }
          )}
        </RadioGroup>
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(ManageWatchSteps.TaskSelection);
      },
      isPreviousDisabled: false,
      nextButtonText: "Next",
      isNextButtonDisabled: (() => {
        return selectedWatchOwner.id === -1;
      })(),
      onNext: () => {
        switch (selectedWatchOwner.id) {
          case WatchOwnerOptions.Other:
            setShowContactVerilyDialog(true);
            break;
          default:
            setPrevStepId(ManageWatchSteps.WatchAssociationError);
            setStepId(selectedWatchOwner.nextStepId);
            break;
        }
      },
    },
    {
      id: ManageWatchSteps.ConfirmWatchUnassignment,
      page: (
        <>
          <Typography component="h2" variant="display6">
            Confirm watch unassignment
          </Typography>
          <Alert severity="warning" sx={{ margin: "24px 0px" }}>
            The participant’s data won’t show up in future compliance metrics
            until a new watch is assigned.
          </Alert>
          <Box sx={{ paddingBottom: "24px" }}>
            <HeaderValuePair
              title="Participant ID"
              value={studyParticipant.getSponsorParticipantId()}
              component={"h3"}
              fontFamily="Roboto Mono"
            />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <HeaderValuePair
              title="Device ID"
              value={RemovePicardPrefix(participantSession.getDeviceId())}
              component={"h3"}
              fontFamily="Roboto Mono"
            />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <HeaderValuePair
              title="Change"
              value="Unassign watch"
              component={"h3"}
            />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <HeaderValuePair
              title="Reason"
              value={getUpdateReason(
                selectedTask.id,
                selectedReturn.label,
                selectedWatchOwner.id
              )}
              component={"h3"}
            />
          </Box>
        </>
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        // Previous button will redirect differently
        // depending on how this step was reached
        switch (prevStepId) {
          case ManageWatchSteps.ReturnReason:
            setPrevStepId(ManageWatchSteps.TaskSelection);
            break;
          case ManageWatchSteps.WatchAssociationError:
            setPrevStepId(ManageWatchSteps.TaskSelection);
            break;
          default:
            setPrevStepId(-1);
            break;
        }
      },
      isPreviousDisabled: false,
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      isNextButtonLoading: confirmLoading,
      onNext: async () => {
        setConfirmLoading(true);
        await unassignWatch(participantSession.getName());
        setConfirmLoading(false);
      },
    },
    {
      id: ManageWatchSteps.WatchSwap,
      page: (
        <WatchSwapper
          ref={watchSwapperRef}
          swaps={swaps}
          setSwaps={setSwaps}
          studyParticipant={studyParticipant}
        />
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(ManageWatchSteps.TaskSelection);
      },
      isPreviousDisabled: false,
      nextButtonText: "Next",
      isNextButtonDisabled: false,
      onNext: () => {
        if (watchSwapperRef.current?.validateSwaps()) {
          setPrevStepId(ManageWatchSteps.WatchSwap);
          setStepId(ManageWatchSteps.ConfirmWatchSwap);
        }
      },
    },
    {
      id: ManageWatchSteps.ConfirmWatchSwap,
      page: (
        <WatchSwapperConfirm
          ref={watchSwapperConfirmRef}
          swaps={swaps}
          onSuccess={() => {
            onSuccess("Watch updated");
          }}
          onError={(msg: string) => {
            onError(msg);
          }}
        />
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(ManageWatchSteps.WatchAssociationError);
      },
      isPreviousDisabled: false,
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      isNextButtonLoading: confirmLoading,
      onNext: async () => {
        setConfirmLoading(true);
        await watchSwapperConfirmRef.current?.swapWatches();
        setConfirmLoading(false);
      },
    },
  ];

  const steps: Steps = new Steps(stepInfo, ManageWatchSteps.TaskSelection);
  const [prevStepId, setPrevStepId] = useState<number>(-1);
  const [stepId, setStepId] = useState<number>(steps.default.id);

  const [showRedirectDialog, setShowRedirectDialog] = useState<boolean>(false);
  const [showContactVerilyDialog, setShowContactVerilyDialog] =
    useState<boolean>(false);

  const resetFields = useCallback(() => {
    setSelectedTask(taskSelection.default);
    setSelectedReturn(returnSelection.default);
    setSelectedWatchOwner(watchOwnerSelection.default);
    setPrevStepId(-1);
    setStepId(steps.default.id);
    setSwaps(new Map([[studyParticipant.getSponsorParticipantId(), ""]]));
  }, [steps.default.id, studyParticipant]);

  useEffect(() => {
    if (open) {
      resetFields();
    }
  }, [open, resetFields]);

  const unassignWatch = useCallback(
    async (participantSessionName: string) => {
      if (!isAuthenticated || !auth0Config) {
        onError("User not authenticated. Please logout and login again.");
        return;
      }

      await (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new ParticipantSessionServiceApiClient(
            auth0Config.audience!,
            token
          );

          await client.endParticipantSession(
            new EndParticipantSessionRequest()
              .setName(participantSessionName)
              .setUpdateReason(
                getUpdateReason(
                  selectedTask.id,
                  selectedReturn.label,
                  selectedWatchOwner.id
                )
              )
          );

          onSuccess("Watch unassigned");
        } catch (error) {
          let errMsg = "Failed to unassign watch. Please try again.";
          if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg = "User is not authorized to manage watch";
          } else if (
            error instanceof RpcError &&
            error.code === StatusCode.NOT_FOUND
          ) {
            errMsg = "Watch already unassigned";
          }

          onError(errMsg);
        }
      })();
    },
    [
      isAuthenticated,
      auth0Config,
      onError,
      getAccessTokenSilently,
      selectedTask.id,
      selectedReturn.label,
      selectedWatchOwner.id,
      onSuccess,
    ]
  );

  return (
    <MUIDialog open={open} fullScreen={true}>
      <StepsHeader
        title="Manage watch"
        stepId={stepId}
        steps={steps}
        onClose={onClose}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "24px 0px",
        }}
      >
        <Box width="40%">
          {steps.get(stepId)!.page}
          <Footer
            leftButtonText="Previous"
            isLeftButtonDisabled={steps.get(stepId)!.isPreviousDisabled}
            handleClickLeftButton={steps.get(stepId)!.onPrevious}
            rightButtonText={steps.get(stepId)!.nextButtonText}
            isRightButtonDisabled={steps.get(stepId)!.isNextButtonDisabled}
            isRightButtonLoading={steps.get(stepId)!.isNextButtonLoading}
            handleClickRightButton={steps.get(stepId)!.onNext}
          />
        </Box>
      </Box>
      <ChangeStatusRedirectDialog
        open={showRedirectDialog}
        onExit={() => {
          setShowRedirectDialog(false);
          onClose();
        }}
        onCancel={() => {
          setShowRedirectDialog(false);
        }}
      />
      <ContactVerilyDialog
        open={showContactVerilyDialog}
        onClose={() => setShowContactVerilyDialog(false)}
      />
    </MUIDialog>
  );
};

export default ManageWatchDialog;
