import { Metadata } from "grpc-web";
import { ComplianceSettingServicePromiseClient } from "generated/compliancesetting/compliancesetting_grpc_web_pb";
import {
  ComplianceSetting,
  CreateComplianceSettingRequest,
  GetComplianceSettingRequest,
  UpdateComplianceSettingRequest,
} from "generated/compliancesetting/compliancesetting_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { startOfToday } from "date-fns";

/**
 * Defines helper methods to call ComplianceSettingService.
 */
export class ComplianceSettingServiceApiClient {
  private gRpcClient: ComplianceSettingServicePromiseClient;

  /**
   * Creates an instance of ComplianceSettingServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-dev.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new ComplianceSettingServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createComplianceSetting(request: CreateComplianceSettingRequest) {
    return this.gRpcClient.createComplianceSetting(
      request,
      this.getGrpcMetaData()
    );
  }

  updateComplianceSetting(request: UpdateComplianceSettingRequest) {
    return this.gRpcClient.updateComplianceSetting(
      request,
      this.getGrpcMetaData()
    );
  }

  getComplianceSetting(registryId: string) {
    const request = new GetComplianceSettingRequest().setName(
      "complianceSettings/" + registryId
    );
    return this.gRpcClient.getComplianceSetting(
      request,
      this.getGrpcMetaData()
    );
  }
}

/**
 * @returns a default compliance setting if it's not defined yet.
 */
export function getDefaultComplianceSetting() {
  const currentDate = startOfToday();
  return new ComplianceSetting()
    .setWearTimeDaysPerWeek(5)
    .setWearTimeHoursPerDay(8)
    .setSyncDaysPerWeek(1)
    .setUpdatedTime(new Timestamp().setSeconds(currentDate.getTime() / 1000));
}
