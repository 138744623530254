import React, { useEffect, useMemo } from "react";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@verily-src/react-design-system";
import { TabPanel, tabA11yProps } from "components/TabPanel";
import { Role } from "generated/studyauth/studyauth_pb";
import useStudyUsersDataLoader from "./StudyUsersDataLoader";
import StudyUsers from "./StudyUsers";
import { useAppSelector } from "redux/hooks";
import {
  hasAnyRole,
  hasRoleInStudy,
  selectCurrentStudyRoles,
} from "core/UserConfigSlice";
import StudySiteUsers from "./StudySiteUsers";
import useStudySitesDataLoader from "studysite/StudySitesDataLoader";
import SingleStudySiteUsers from "./SingleStudySiteUsers";
import { usePageTitle } from "components/PageTitle";

enum TabIndex {
  VerilyStudyAdmin = 0,
  VerilyCDM = 1,
  SponsorStudyAdmin = 2,
  CRC = 3,
}

const StudyUsersHome: React.FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  // Get login user's study site.
  // Note that CRCs can only manage one site per study.
  const studySiteIdFilter = useMemo(() => {
    for (let userRole of selectedRoles) {
      if (userRole.role === Role.CRC) {
        return userRole.studySiteId;
      }
    }

    return "";
  }, [selectedRoles]);

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const {
    isLoading: isLoadingUsers,
    hasError: hasErrorLoadingUsers,
    users,
    userRolesMap,
    loadUsers,
  } = useStudyUsersDataLoader();

  const {
    isLoading: isLoadingSites,
    hasError: hasErrorLoadingSites,
    studySites,
    loadStudySites,
  } = useStudySitesDataLoader();

  const hasLoadingError = useMemo(
    () => hasErrorLoadingUsers || hasErrorLoadingSites,
    [hasErrorLoadingSites, hasErrorLoadingUsers]
  );

  // Set page title.
  usePageTitle("Manage users");

  useEffect(() => {
    loadUsers();
    loadStudySites(currentRegistryId);
  }, [currentRegistryId, loadStudySites, loadUsers]);

  // Set initial tab selection based on user roles.
  useEffect(() => {
    if (hasAnyRole(selectedRoles, Role.SPONSOR_OPS, Role.CDM)) {
      setSelectedTabIndex(TabIndex.VerilyStudyAdmin);
    } else if (hasAnyRole(selectedRoles, Role.COMPLIANCE_ADMIN)) {
      setSelectedTabIndex(TabIndex.SponsorStudyAdmin);
    } else if (hasAnyRole(selectedRoles, Role.CRC)) {
      setSelectedTabIndex(TabIndex.CRC);
    }
  }, [selectedRoles]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 40px 24px 40px",
        }}
      >
        <Box sx={{ marginBottom: "8px" }}>
          <Typography component="h1" variant="display5">
            Manage users
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTabIndex} onChange={handleChange}>
              {hasAnyRole(selectedRoles, Role.SPONSOR_OPS, Role.CDM) && (
                <Tab
                  label="Sponsor Ops (internal)"
                  value={TabIndex.VerilyStudyAdmin}
                  {...tabA11yProps(TabIndex.VerilyStudyAdmin)}
                />
              )}
              {hasAnyRole(selectedRoles, Role.SPONSOR_OPS, Role.CDM) && (
                <Tab
                  label="CDMs (internal)"
                  value={TabIndex.VerilyCDM}
                  {...tabA11yProps(TabIndex.VerilyCDM)}
                />
              )}
              {hasAnyRole(
                selectedRoles,
                Role.SPONSOR_OPS,
                Role.CDM,
                Role.COMPLIANCE_ADMIN
              ) && (
                <Tab
                  label="Compliance Admins (external)"
                  value={TabIndex.SponsorStudyAdmin}
                  {...tabA11yProps(TabIndex.SponsorStudyAdmin)}
                />
              )}

              <Tab
                label="Site Research Coordinators (external)"
                value={TabIndex.CRC}
                {...tabA11yProps(TabIndex.CRC)}
              />
            </Tabs>
          </Box>
          {!hasLoadingError && (
            <>
              <TabPanel
                selectedTabIndex={selectedTabIndex}
                tabIndex={TabIndex.VerilyStudyAdmin}
              >
                <StudyUsers
                  isEditable={hasRoleInStudy(
                    selectedRoles,
                    currentRegistryId,
                    Role.CDM
                  )}
                  users={users}
                  userRolesMap={userRolesMap}
                  registryIdFilter={currentRegistryId}
                  userRoleFilter={Role.SPONSOR_OPS}
                  reloadData={() => {
                    loadUsers();
                  }}
                />
              </TabPanel>
              <TabPanel
                selectedTabIndex={selectedTabIndex}
                tabIndex={TabIndex.VerilyCDM}
              >
                <StudyUsers
                  isEditable={hasRoleInStudy(
                    selectedRoles,
                    currentRegistryId,
                    Role.CDM
                  )}
                  users={users}
                  userRolesMap={userRolesMap}
                  registryIdFilter={currentRegistryId}
                  userRoleFilter={Role.CDM}
                  reloadData={() => {
                    loadUsers();
                  }}
                />
              </TabPanel>
              <TabPanel
                selectedTabIndex={selectedTabIndex}
                tabIndex={TabIndex.SponsorStudyAdmin}
              >
                <StudyUsers
                  isEditable={hasRoleInStudy(
                    selectedRoles,
                    currentRegistryId,
                    Role.CDM
                  )}
                  users={users}
                  userRolesMap={userRolesMap}
                  registryIdFilter={currentRegistryId}
                  userRoleFilter={Role.COMPLIANCE_ADMIN}
                  reloadData={() => {
                    loadUsers();
                  }}
                />
              </TabPanel>
              <TabPanel
                selectedTabIndex={selectedTabIndex}
                tabIndex={TabIndex.CRC}
              >
                {hasAnyRole(
                  selectedRoles,
                  Role.SPONSOR_OPS,
                  Role.CDM,
                  Role.COMPLIANCE_ADMIN
                ) && (
                  <StudySiteUsers
                    isEditable={hasRoleInStudy(
                      selectedRoles,
                      currentRegistryId,
                      Role.CDM
                    )}
                    users={users}
                    userRolesMap={userRolesMap}
                    studySites={studySites}
                    registryIdFilter={currentRegistryId}
                    reloadData={() => {
                      loadUsers();
                    }}
                  />
                )}
                {!hasAnyRole(
                  selectedRoles,
                  Role.SPONSOR_OPS,
                  Role.CDM,
                  Role.COMPLIANCE_ADMIN
                ) && (
                  <SingleStudySiteUsers
                    users={users}
                    userRolesMap={userRolesMap}
                    registryIdFilter={currentRegistryId}
                    studySiteIdFilter={studySiteIdFilter}
                  />
                )}
              </TabPanel>
            </>
          )}
          {hasLoadingError && (
            <Alert
              severity="error"
              sx={{ paddingLeft: "16px", marginTop: "8px" }}
            >
              Failed to load users
            </Alert>
          )}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme: { zIndex: { drawer: number } }) =>
                theme.zIndex.drawer + 1,
            }}
            open={isLoadingUsers || isLoadingSites}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </Box>
    </>
  );
};

export default StudyUsersHome;
