import { complianceSettingsCache } from "./ComplianceSettingsCache";
import { ComplianceSettingServiceApiClient } from "apiclient/ComplianceSettingServiceApiClient";
import {
  ComplianceSetting,
  CreateComplianceSettingRequest,
  UpdateComplianceSettingRequest,
} from "generated/compliancesetting/compliancesetting_pb";

export async function updateComplianceSetting(
  apiEndpoint: string,
  auth0AccessToken: string,
  registryId: string,
  setting: ComplianceSetting,
  hasExistingSetting: boolean,
  updateReason: string
) {
  // Remove cached value so that cache will be populated again.
  complianceSettingsCache.delete(registryId);

  const client = new ComplianceSettingServiceApiClient(
    apiEndpoint,
    auth0AccessToken
  );

  if (hasExistingSetting) {
    const request = new UpdateComplianceSettingRequest().setComplianceSetting(
      setting
    );
    request.setUpdateReason(updateReason);
    await client.updateComplianceSetting(request);
  } else {
    const request = new CreateComplianceSettingRequest().setComplianceSetting(
      setting
    );
    await client.createComplianceSetting(request);
  }
}
