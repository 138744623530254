import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  User,
  StudyInfo,
  StudyRole,
  Role,
} from "generated/studyauth/studyauth_pb";
import {
  Button,
  Search,
  Snackbar,
  Tooltip,
} from "@verily-src/react-design-system";
import InviteStudyAdminDialog from "./InviteStudyAdminDialog";
import ConfirmDeleteStudyAdminDialog from "./ConfirmDeleteStudyAdminDialog";
import EditStudyAdminDialog from "./EditStudyAdminDialog";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import {
  getAddUserButtonAriaLabel,
  getDeleteUserIconAriaLabel,
  getEditUserIconAriaLabel,
  getInvitationDisplayStatus,
  getUserIndex,
  getUserName,
  getUserRoleDescription,
  restoreUserRecordPosition,
} from "components/UserRenderers";
import { tablePaginationSxProps } from "components/Tables";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";
import { sxFocusWithin } from "common/Accessibility";
import { ManageUserDetailsTableHeaderCell } from "components/ManageUserDetailsTableHeaderCell";

interface AdminUserTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const AdminUserTableHeader: React.FC<AdminUserTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="25%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="email"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Email"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="25%"
        >
          <Typography variant="body2em">Email</Typography>
        </SortableTableHeaderCell>
        <TableCell width="25%">
          <Typography variant="body2em">Studies</Typography>
        </TableCell>
        <SortableTableHeaderCell
          columnName="status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Status"
          onRequestSort={onRequestSort}
          width="25%"
        >
          <Typography variant="body2em">Status</Typography>
        </SortableTableHeaderCell>
        <ManageUserDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

interface AdminUserTableContentProps {
  users: Array<User>;
  userRolesMap: Map<string, StudyRole[]>;
  studyNamesMap: Map<string, string>;
  userRoleFilter: Role;
  onClickEditButton(user: User): void;
  onClickDeleteButton(user: User): void;
}

const AdminUserTableContent: React.FC<AdminUserTableContentProps> = ({
  users,
  userRolesMap,
  studyNamesMap,
  userRoleFilter,
  onClickEditButton,
  onClickDeleteButton,
}) => {
  const getUserStudies = (userName: string) => {
    const userRoles = userRolesMap.get(userName);
    if (userRoles) {
      const studyNames = new Array<string>();
      for (var role of userRoles) {
        if (role.getRole() !== userRoleFilter) {
          continue;
        }

        const studyName = studyNamesMap.get(role.getRegistryId());
        if (studyName) {
          studyNames.push(studyName);
        } else {
          console.log(
            "missing study info for registry: " + role.getRegistryId()
          );
        }
      }

      studyNames.sort((a: string, b: string) => a.localeCompare(b));
      return studyNames;
    } else {
      return [""];
    }
  };

  return (
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.getName()}>
          <TableCell width="25%">{getUserName(user)}</TableCell>
          <TableCell width="25%">{user.getEmailAddress()}</TableCell>
          <TableCell width="25%">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {getUserStudies(user.getName()).map((studyName) => (
                <Typography key={studyName} variant="body2">
                  {studyName}
                </Typography>
              ))}
            </Box>
          </TableCell>
          <TableCell width="25%">
            {getInvitationDisplayStatus(user.getInvitationStatus())}
          </TableCell>
          <TableCell width="64px" sx={{ paddingRight: "0px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
                <Tooltip title="Edit">
                  <IconButton
                    tabIndex={0}
                    onClick={() => {
                      onClickEditButton(user);
                    }}
                    sx={{ marginRight: "8px" }}
                  >
                    <EditIcon
                      titleAccess={getEditUserIconAriaLabel(
                        userRoleFilter,
                        getUserName(user)
                      )}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
                <Tooltip title="Delete">
                  <IconButton
                    tabIndex={0}
                    onClick={() => {
                      onClickDeleteButton(user);
                    }}
                  >
                    <DeleteIcon
                      titleAccess={getDeleteUserIconAriaLabel(
                        userRoleFilter,
                        getUserName(user)
                      )}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

interface AdminUserTableControlProps {
  searchText: string;
  userRoleFilter: Role;
  onRequestSearch(searchText: string): void;
  onClickInviteStudyAdminButton(): void;
}

const AdminUserTableControl: React.FC<AdminUserTableControlProps> = ({
  searchText,
  userRoleFilter,
  onRequestSearch,
  onClickInviteStudyAdminButton,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        padding: "8px 0px 8px 4px",
        width: "100%",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
      <Box sx={{ flexGrow: 1 }}></Box>
      <Button
        label="Add user"
        aria-label={getAddUserButtonAriaLabel(userRoleFilter)}
        variant="filled"
        icon={<AddIcon />}
        iconPosition="start"
        onClick={onClickInviteStudyAdminButton}
      />
    </Box>
  );
};

interface AdminUsersProps {
  users: User[];
  userRolesMap: Map<string, StudyRole[]>;
  /** Only show user who has the role specified in the userRoleFilter */
  userRoleFilter: Role;
  studies: StudyInfo[];
  studyNamesMap: Map<string, string>;
  reloadData(): void;
}

const AdminUsers: React.FC<AdminUsersProps> = ({
  users,
  userRolesMap,
  userRoleFilter,
  studies,
  studyNamesMap,
  reloadData,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchText, setSearchText] = React.useState("");

  const [showInviteDialog, setShowInviteDialog] = useState(false);

  const userToEdit = useRef(new User());
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);

  // Stored the edited user index to the filterAndSortedUsers list
  // to keep its current position after editing.
  const editedUserIndex = useRef(-1);

  const userToDelete = useRef(new User());
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbarMessage, setShowSnackbarMessage] = useState(false);

  const resetEditedUserIndex = () => {
    editedUserIndex.current = -1;
  };

  useEffect(() => {
    resetEditedUserIndex();
  }, []);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  const [filteredAndSortedUsers, setFilteredAndSortedUsers] = useState(
    new Array<User>()
  );

  useEffect(() => {
    const userList = users
      .filter((user) => {
        const skipFilter =
          searchText === "" ||
          (editedUserIndex.current >= 0 &&
            userToEdit.current.getName() === user.getName());

        if (!skipFilter) {
          const searchTextLowercase = searchText.toLowerCase();
          if (
            getUserName(user).toLowerCase().indexOf(searchTextLowercase) ===
              -1 &&
            user
              .getEmailAddress()
              .toLowerCase()
              .indexOf(searchTextLowercase) === -1
          ) {
            return false;
          }
        }

        const studyRoles = userRolesMap.get(user.getName());
        if (studyRoles === undefined) {
          return false;
        }

        let hasStudyRole = false;
        for (let studyRole of studyRoles) {
          if (userRoleFilter === studyRole.getRole()) {
            hasStudyRole = true;
            break;
          }
        }

        if (!hasStudyRole) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        let result = 0;
        switch (orderBy) {
          case "name":
            result = getUserName(a).localeCompare(getUserName(b));
            break;
          case "email":
            result = a.getEmailAddress().localeCompare(b.getEmailAddress());
            break;
          case "status":
            result = getInvitationDisplayStatus(
              a.getInvitationStatus()
            ).localeCompare(
              getInvitationDisplayStatus(b.getInvitationStatus())
            );
            break;
        }
        return order === "asc" ? result : -result;
      });

    // Keep position of an edited user.
    if (editedUserIndex.current >= 0) {
      restoreUserRecordPosition(
        userList,
        userToEdit.current.getName(),
        editedUserIndex.current
      );

      // Do not reset the editedUserIndex here since this function is called
      // multiple times before the list of users are rendered.
    }

    setFilteredAndSortedUsers(userList);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(userList.length));
      previousSearch.current = searchText;
    }
  }, [users, searchText, userRolesMap, userRoleFilter, orderBy, order]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          width: "100%",
        }}
      >
        <A11yStatusMessage message={searchResultMessage} />
        <AdminUserTableControl
          searchText={searchText}
          userRoleFilter={userRoleFilter}
          onRequestSearch={(searchText) => {
            resetEditedUserIndex();
            setSearchText(searchText);
            setPage(0);
          }}
          onClickInviteStudyAdminButton={() => {
            resetEditedUserIndex();
            setShowInviteDialog(true);
          }}
        />
        <Typography variant="body1em" sx={{ padding: "16px" }}>
          {getUserRoleDescription(userRoleFilter)}
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 700, marginBottom: "8px" }} aria-label="users">
            <AdminUserTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={(orderBy) => {
                resetEditedUserIndex();
                handleRequestSort(orderBy);
              }}
            />
            <AdminUserTableContent
              users={filteredAndSortedUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              userRolesMap={userRolesMap}
              studyNamesMap={studyNamesMap}
              userRoleFilter={userRoleFilter}
              onClickEditButton={(user) => {
                resetEditedUserIndex();
                userToEdit.current = user;
                setShowEditUserDialog(true);
              }}
              onClickDeleteButton={(user) => {
                resetEditedUserIndex();
                userToDelete.current = user;
                setShowDeleteUserDialog(true);
              }}
            />
          </Table>
          <A11yStatusMessage message={sortDirectiontMessage} />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginRight: "8px", ...tablePaginationSxProps }}
        />
      </Box>
      <InviteStudyAdminDialog
        open={showInviteDialog}
        userRole={userRoleFilter}
        studies={studies}
        onClose={() => {
          setShowInviteDialog(false);
        }}
        onSuccess={() => {
          setShowInviteDialog(false);
          reloadData();
          setSnackbarMessage("Invitation sent");
          setShowSnackbarMessage(true);
        }}
      />
      <EditStudyAdminDialog
        open={showEditUserDialog}
        user={userToEdit.current}
        userRoles={userRolesMap.get(userToEdit.current.getName())}
        userRoleFilter={userRoleFilter}
        studies={studies}
        onClose={() => {
          setShowEditUserDialog(false);
        }}
        onSuccess={() => {
          setShowEditUserDialog(false);
          setSnackbarMessage("User updated");
          setShowSnackbarMessage(true);
          editedUserIndex.current = getUserIndex(
            filteredAndSortedUsers,
            userToEdit.current.getName()
          );
          reloadData();
        }}
      />
      <ConfirmDeleteStudyAdminDialog
        open={showDeleteUserDialog}
        user={userToDelete.current}
        userRoles={userRolesMap.get(userToDelete.current.getName())}
        userRoleFilter={userRoleFilter}
        onClose={() => {
          setShowDeleteUserDialog(false);
        }}
        onSuccess={() => {
          setShowDeleteUserDialog(false);
          reloadData();
          setSnackbarMessage("User removed");
          setShowSnackbarMessage(true);
        }}
      />
      <Snackbar
        role="alert"
        text={snackbarMessage}
        color="success"
        withIcon
        open={showSnackbarMessage}
        onClose={() => {
          setShowSnackbarMessage(false);
        }}
        autoHideDuration={3000}
      />
    </>
  );
};

export default AdminUsers;
