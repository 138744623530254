import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "components/SortableTableHeaderCell";

export interface NonCompliantTabState {
  nonCompliantTabSortOrder: Order;
  nonCompliantTabOrderBy: string;
}

const initialState: NonCompliantTabState = {
  nonCompliantTabSortOrder: "asc",
  nonCompliantTabOrderBy: "unmetRequirements",
};

const NonCompliantTabStateSlice = createSlice({
  name: "nonCompliantTabState",
  initialState,
  reducers: {
    setNonCompliantTabSortOrder: (state, action: PayloadAction<Order>) => {
      state.nonCompliantTabSortOrder = action.payload;
    },
    setNonCompliantTabOrderBy: (state, action: PayloadAction<string>) => {
      state.nonCompliantTabOrderBy = action.payload;
    },
    resetNonCompliantTabState: (state) => {
      state.nonCompliantTabSortOrder = "asc";
      state.nonCompliantTabOrderBy = "unmetRequirements";
    },
  },
});

export const {
  setNonCompliantTabSortOrder,
  setNonCompliantTabOrderBy,
  resetNonCompliantTabState,
} = NonCompliantTabStateSlice.actions;

export default NonCompliantTabStateSlice.reducer;
