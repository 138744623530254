import { Metadata } from "grpc-web";
import { StudyAuthServicePromiseClient } from "generated/studyauth/studyauth_grpc_web_pb";
import {
  CreateUserRequest,
  DeleteUserRequest,
  ListUsersRequest,
  UpdateUserRequest,
  SyncUserAccountRequest,
  ListStudiesRequest,
  AddUserRolesRequest,
  DeleteUserRolesRequest,
  GetUsersRolesRequest,
  GetUserByEmailRequest,
  GetUserRequest,
  SendEmailRequest,
  ValidateEmailRequest,
} from "generated/studyauth/studyauth_pb";

/**
 * Defines helper methods to call StudyAuthService.
 */
export class StudyAuthServiceApiClient {
  private gRpcClient: StudyAuthServicePromiseClient;

  /**
   * Creates an instance of StudyAuthServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new StudyAuthServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createUser(request: CreateUserRequest) {
    return this.gRpcClient.createUser(request, this.getGrpcMetaData());
  }

  updateUser(request: UpdateUserRequest) {
    return this.gRpcClient.updateUser(request, this.getGrpcMetaData());
  }

  deleteUser(request: DeleteUserRequest) {
    return this.gRpcClient.deleteUser(request, this.getGrpcMetaData());
  }

  getUser(request: GetUserRequest) {
    return this.gRpcClient.getUser(request, this.getGrpcMetaData());
  }

  getUserByEmail(request: GetUserByEmailRequest) {
    return this.gRpcClient.getUserByEmail(request, this.getGrpcMetaData());
  }

  listUsers(isSuperAdmin: boolean = false) {
    const request = new ListUsersRequest().setIsSuperAdmin(isSuperAdmin);
    return this.gRpcClient.listUsers(request, this.getGrpcMetaData());
  }

  syncUserAccount() {
    const request = new SyncUserAccountRequest();
    return this.gRpcClient.syncUserAccount(request, this.getGrpcMetaData());
  }

  listStudies() {
    const request = new ListStudiesRequest();
    return this.gRpcClient.listStudies(request, this.getGrpcMetaData());
  }

  addUserRoles(request: AddUserRolesRequest) {
    return this.gRpcClient.addUserRoles(request, this.getGrpcMetaData());
  }

  deleteUserRoles(request: DeleteUserRolesRequest) {
    return this.gRpcClient.deleteUserRoles(request, this.getGrpcMetaData());
  }

  getUsersRoles(request: GetUsersRolesRequest) {
    return this.gRpcClient.getUsersRoles(request, this.getGrpcMetaData());
  }

  sendEmail(request: SendEmailRequest) {
    return this.gRpcClient.sendEmail(request, this.getGrpcMetaData());
  }

  validateEmail(request: ValidateEmailRequest) {
    return this.gRpcClient.validateEmail(request, this.getGrpcMetaData());
  }
}
