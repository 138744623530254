import { formatISO } from "date-fns";
import { LRUCache } from "lru-cache";

/**
 * Sets up cache options to cache 2-month calendar data.
 */
const calendarCacheOptions = {
  max: 2,
};

/**
 * Cache for participant watch wear minutes.
 * The cache key is in the format <study_participant_id>.<month_start_date>
 *
 * The cache needs to be cleared when the calendar view is first opened.
 */
export const participantWearMinutesCache = new LRUCache<
  string,
  Map<string, number>
>(calendarCacheOptions);

/**
 * Cache for participant watch sync time.
 * The cache key is in the format <study_participant_id>.<month_start_date>
 *
 * The cache needs to be cleared when calendar view is first opened.
 */
export const participantSyncTimeCache = new LRUCache<string, Map<string, Date>>(
  calendarCacheOptions
);

/**
 * Cache for participant medication daily logs.
 * The cache key is in the format <study_participant_id>.<month_start_date>
 *
 * The cache needs to be cleared when calendar view is first opened.
 */
export const participantMedLogDailyCache = new LRUCache<
  string,
  Map<string, number>
>(calendarCacheOptions);

/**
 * Participant's device assignment.
 */
export interface DeviceAssignment {
  deviceId: string;
  startTime: Date;
  endTime: Date;
}

/**
 * Cache for participant's device assignments.
 *
 * The cache key is in the format <study_participant_id>.<month_start_date>
 *
 * The cache needs to be cleared when calendar view is first opened.
 */
export const participantDevicesCache = new LRUCache<
  string,
  Array<DeviceAssignment>
>(calendarCacheOptions);

/**
 * Watch wear time range.
 */
export interface WearTimeRange {
  startTime: Date;
  endTime: Date;
}

/**
 * Cache for participant's wear time ranges.
 *
 * The cache key is in the format <study_participant_id>.<month_start_date>
 *
 * The cache needs to be cleared when calendar view is first opened.
 */
export const participantWearTimeRangesCache = new LRUCache<
  string,
  Map<string, Array<WearTimeRange>>
>(calendarCacheOptions);

/**
 * Cache for participant's latest sync date.
 *
 * The cache key is study_participant_id.
 *
 * The cache is cleared when calendar view is first opened and only store
 * 1 value for a participant.
 */
export const participantLatestSyncDateCache = new LRUCache<string, Date>({
  max: 1,
});

export function getParticipantMonthCacheKey(
  studyParticipantId: string,
  monthStartDate: Date
) {
  return (
    studyParticipantId +
    "." +
    formatISO(monthStartDate, { representation: "date" })
  );
}

export function clearCalendarViewCache() {
  participantWearMinutesCache.clear();
  participantSyncTimeCache.clear();
  participantDevicesCache.clear();
  participantWearTimeRangesCache.clear();
  participantLatestSyncDateCache.clear();
  participantMedLogDailyCache.clear();
}
