import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  IconButton,
  Link,
  SelectChangeEvent,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Button,
  MultipleSelectFilterChip,
  Snackbar,
  Search,
  Tag,
  Tooltip,
} from "@verily-src/react-design-system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import SortableTableHeaderCell, {
  Order,
  orderByDateAriaDescription,
  orderByNumberAriaDescription,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import Loading from "components/Loading";
import { Role } from "generated/studyauth/studyauth_pb";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import AddStudyParticipantDialog from "./AddStudyParticipantDialog";
import StudyParticipantDetailDialog from "studyparticipant/StudyParticipantDetails";
import useStudyParticipantsDataLoader from "./StudyParticipantsDataLoader";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectCurrentStudyRoles } from "core/UserConfigSlice";
import {
  setParticipantsViewState,
  initialState as participantsViewInitialState,
} from "studyparticipant/StudyParticipantsStateSlice";
import { format } from "date-fns";
import { ParseIdFromName, RemovePicardPrefix } from "common/ResourceName";
import { AppRoutes } from "core/AppRoutes";
import { tablePaginationSxProps } from "components/Tables";
import { usePageTitle } from "components/PageTitle";
import ParticipantsWatchSwap from "./ParticipantsWatchSwap";
import { autohideTimeout } from "styles/Time";
import pluralize from "pluralize";
import A11yStatusMessage from "components/A11yStatusMessage";
import {
  focusOnRef,
  isActivationKey,
  sxFocusWithin,
} from "common/Accessibility";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import SDPErrorSnackbar from "components/SDPErrorSnackbar";

interface StudyPartcipantsTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const enum ButtonSource {
  None,
  AddStudyParticipant,
  SwapWatches,
}

export enum DeviceStatus {
  Unknown,
  Active,
  FirstSyncPending,
  NoWatchAssigned,
  Inactive,
}

interface DeviceStatusTagProps {
  status: DeviceStatus;
}

const DeviceStatusTag: React.FC<DeviceStatusTagProps> = ({ status }) => {
  const theme = useTheme();

  switch (status) {
    case DeviceStatus.Unknown:
      return <Tag color="warning" label="Unknown" />;
    case DeviceStatus.Active:
      return <Tag color="info" label={getDeviceStatus(status)} />;
    case DeviceStatus.FirstSyncPending:
      return <Tag color="neutral" label={getDeviceStatus(status)} />;
    case DeviceStatus.NoWatchAssigned:
      return (
        <Tag
          color="neutral"
          label={getDeviceStatus(status)}
          sx={{
            background: "none",
            borderColor: theme.palette.neutral.divider,
            color: theme.palette.neutral.main,
          }}
        />
      );
    case DeviceStatus.Inactive:
      return (
        <Tag
          color="neutral"
          label={getDeviceStatus(status)}
          sx={{
            background: "none",
            borderColor: theme.palette.neutral.divider,
            color: theme.palette.neutral.main,
          }}
        />
      );
  }
};

const getParticipantStatus = (status: StudyParticipant.ParticipantStatus) => {
  switch (status) {
    case 0:
      return "";
    case 1:
      return "Enrolled";
    case 2:
      return "Completed";
    case 3:
      return "Withdrawn";
  }
};

const getDeviceStatus = (status: DeviceStatus) => {
  switch (status) {
    case 0:
      return "";
    case 1:
      return "Active";
    case 2:
      return "First sync pending";
    case 3:
      return "No watch assigned";
    case 4:
      return "Inactive";
  }
};

const getShowStudyParticipantLinkAriaLabel = (
  participant: StudyParticipant,
  deviceStatus: DeviceStatus
) => {
  return `View Participant ID ${participant.getSponsorParticipantId()}, Device ID ${RemovePicardPrefix(
    participant.getDeviceId()
  )},
    Enrollment date ${format(
      participant.getEnrollmentStartTime()!.toDate(),
      "M/d/yyyy"
    )},
    Participant status ${getParticipantStatus(participant.getStatus())},
    Device status ${getDeviceStatus(deviceStatus)}`;
};

const StudyParticipantsTableHeader: React.FC<
  StudyPartcipantsTableHeaderProps
> = ({ order, orderBy, onRequestSort }) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="participant ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Participant ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="10%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Participant ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="10%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Device ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="enrollment date"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Enrollment date"
          orderByAriaDescription={orderByDateAriaDescription}
          onRequestSort={onRequestSort}
          width="10%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Enrollment date</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="participant status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Participant status"
          orderByAriaDescription={orderByNumberAriaDescription}
          onRequestSort={onRequestSort}
          width="10%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Participant status</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device status"
          orderByAriaDescription={orderByNumberAriaDescription}
          onRequestSort={onRequestSort}
          width="60%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Device status</Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell ItemName="participant" />
      </TableRow>
    </TableHead>
  );
};

// Define a component to render participant information.
interface StudyParticipantRowProps {
  studyParticipant: StudyParticipant;
  deviceStatuses: Map<string, DeviceStatus>;
  onClickViewParticipantIcon(studyParticipant: StudyParticipant): void;
}

const StudyParticipantRow: React.FC<StudyParticipantRowProps> = ({
  studyParticipant,
  deviceStatuses,
  onClickViewParticipantIcon,
}) => {
  return (
    <TableRow
      hover
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClickViewParticipantIcon(studyParticipant);
      }}
    >
      <TableCell
        width="18%"
        sx={{ paddingLeft: "16px" }}
        style={{ verticalAlign: "middle" }}
      >
        {studyParticipant.getSponsorParticipantId()}
      </TableCell>
      <TableCell
        width="18%"
        sx={{ paddingLeft: "16px" }}
        style={{ verticalAlign: "middle" }}
      >
        {(() => {
          if (studyParticipant.getDeviceId() === "") {
            return "--";
          }
          return RemovePicardPrefix(studyParticipant.getDeviceId());
        })()}
      </TableCell>
      <TableCell
        width="18%"
        sx={{ paddingLeft: "16px" }}
        style={{ verticalAlign: "middle" }}
      >
        {(() => {
          if (!studyParticipant.getEnrollmentStartTime()) {
            return "--";
          }
          return format(
            studyParticipant.getEnrollmentStartTime()!.toDate(),
            "M/d/yyyy"
          );
        })()}
      </TableCell>
      <TableCell
        width="18%"
        sx={{ paddingLeft: "16px" }}
        style={{ verticalAlign: "middle" }}
      >
        {getParticipantStatus(studyParticipant.getStatus()) || "--"}
      </TableCell>
      <TableCell
        width="18%"
        sx={{ paddingLeft: "16px" }}
        style={{ verticalAlign: "middle" }}
      >
        {(() => {
          const participantId = ParseIdFromName(studyParticipant.getName());
          return DeviceStatusTag({
            status: deviceStatuses.get(participantId)!,
          });
        })()}
      </TableCell>
      <TableCell width="96px">
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickViewParticipantIcon(studyParticipant);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickViewParticipantIcon(studyParticipant);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getShowStudyParticipantLinkAriaLabel(
                studyParticipant,
                deviceStatuses.get(
                  ParseIdFromName(studyParticipant.getName())!
                ) ?? DeviceStatus.Unknown
              )}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface StudyParticipantsTableContentProps {
  studyParticipants: StudyParticipant[];
  deviceStatuses: Map<string, DeviceStatus>;
  onClickViewParticipantIcon(studyParticipant: StudyParticipant): void;
}

const StudyParticipantsTableContent: React.FC<
  StudyParticipantsTableContentProps
> = ({ studyParticipants, deviceStatuses, onClickViewParticipantIcon }) => {
  return (
    <TableBody>
      {studyParticipants.map((studyParticipant) => (
        <StudyParticipantRow
          key={studyParticipant.getSponsorParticipantId()}
          studyParticipant={studyParticipant}
          deviceStatuses={deviceStatuses}
          onClickViewParticipantIcon={onClickViewParticipantIcon}
        />
      ))}
    </TableBody>
  );
};

interface StudyParticipantTableControlProps {
  searchText: string;
  onRequestSearch(searchText: string): void;
  selectParticipantStatus: string[];
  selectedDeviceStatus: string[];
  onParticipantStatusSelected(selectParticipantStatus: string[]): void;
  onDeviceStatusSelected(selectedDeviceStatus: string[]): void;
  focusOnSwapWatches: boolean;
  onSwapWatchesClick(): void;
}

const StudyParticipantsTableControl: React.FC<
  StudyParticipantTableControlProps
> = ({
  searchText,
  onRequestSearch,
  selectParticipantStatus,
  selectedDeviceStatus,
  onParticipantStatusSelected,
  onDeviceStatusSelected,
  focusOnSwapWatches,
  onSwapWatchesClick,
}) => {
  const theme = useTheme();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnSwapWatches) {
      focusOnRef(buttonRef);
    }
  }, [focusOnSwapWatches]);

  const participantStatusOptions = useMemo<
    Array<{ label: string; value: string }>
  >(
    () => [
      { label: "Enrolled", value: "1" },
      { label: "Completed", value: "2" },
      { label: "Withdrawn", value: "3" },
    ],
    []
  );

  const deviceStatusOptions = useMemo<Array<{ label: string; value: string }>>(
    () => [
      { label: "Active", value: "1" },
      { label: "First sync pending", value: "2" },
      { label: "No watch assigned", value: "3" },
      { label: "Inactive", value: "4" },
    ],
    []
  );

  const handleParticipantStatusSelected = (
    event: SelectChangeEvent<unknown>
  ) => {
    const {
      target: { value },
    } = event;
    onParticipantStatusSelected(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  const handleDeviceStatusSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    onDeviceStatusSelected(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
      <Box sx={{ marginLeft: "16px" }}>
        <MultipleSelectFilterChip
          placeholder="Participant status"
          value={selectParticipantStatus}
          onChange={handleParticipantStatusSelected}
          options={participantStatusOptions}
          role="listbox"
          ariaLabel={"Filter by participant status"}
        />
      </Box>
      <Box sx={{ marginLeft: "16px" }}>
        <MultipleSelectFilterChip
          placeholder="Device status"
          value={selectedDeviceStatus}
          onChange={handleDeviceStatusSelected}
          options={deviceStatusOptions}
          ariaLabel={"Filter by device status"}
        />
      </Box>
      <Box
        sx={{
          marginLeft: "8px",
          padding: "4px",
          ...sxFocusWithin,
        }}
      >
        <IconButton
          ref={buttonRef}
          tabIndex={0}
          role="link"
          onClick={() => {
            onSwapWatchesClick();
          }}
          sx={{
            color: theme.palette.primary.main,
          }}
          aria-label="Swap watches"
        >
          <CompareArrowsIcon />
          <Typography variant="button1" paddingLeft="4px" color="inherit">
            Swap watches
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

const StudyParticipants: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = useTheme();

  const participantsViewState = useAppSelector((state) => {
    return state.participantsViewState;
  });

  // Reset this component's state to default values if the page route
  // came outside of the participant compliance view
  useEffect(() => {
    if (location.state && location.state.hasOwnProperty("prevPath")) {
      switch (location.state.prevPath) {
        case AppRoutes.PARTICIPANT_COMPLIANCE: {
          return;
        }
      }
    }

    dispatch(setParticipantsViewState(participantsViewInitialState));
  }, [dispatch, location]);

  // Set page title.
  usePageTitle("Manage study participants");

  // Get RegistryId
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  // Get StudySiteId
  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  // Get login user's study site.
  // Note that CRCs can only manage one site per study.
  const currentStudySiteId = useMemo(() => {
    for (let userRole of selectedRoles) {
      if (userRole.role === Role.CRC) {
        return userRole.studySiteId;
      }
    }

    return "";
  }, [selectedRoles]);

  const [reloadData, setReloadData] = useState(true);
  const [lastReloadDate, setLastReloadDate] = useState(new Date());
  const {
    isLoading,
    hasError,
    studyParticipants,
    lastSyncTimes,
    loadStudyParticipants,
    loadParticipantsLastSyncTimes,
  } = useStudyParticipantsDataLoader();
  const [deviceStatuses, setDeviceStatuses] = useState<
    Map<string, DeviceStatus>
  >(new Map());

  useEffect(() => {
    if (currentStudySiteId && reloadData) {
      loadStudyParticipants(currentStudySiteId);
      loadParticipantsLastSyncTimes(currentStudySiteId);
    }
  }, [
    currentStudySiteId,
    loadStudyParticipants,
    loadParticipantsLastSyncTimes,
    reloadData,
  ]);

  // Populate device statuses based on study participants and last sync times
  useEffect(() => {
    let statuses = new Map<string, DeviceStatus>();

    studyParticipants.forEach((studyParticipant) => {
      const participantId = ParseIdFromName(studyParticipant.getName());
      const deviceId = studyParticipant.getDeviceId();
      const associationStartTimeSecs = studyParticipant.getDeviceStartTime()
        ? format(studyParticipant.getDeviceStartTime()!.toDate(), "t")
        : "";

      // key on participantId, deviceId, and associationStartTime (in epoch time)
      const key = `${participantId}_${deviceId}_${associationStartTimeSecs}`;

      const hasUnknownParticipantStatus =
        studyParticipant.getStatus() ===
        StudyParticipant.ParticipantStatus.UNKNOWN;
      const isActiveParticipant =
        studyParticipant.getStatus() ===
        StudyParticipant.ParticipantStatus.ENROLLED;
      const hasDevice = deviceId.length > 0;
      const hasLastSyncTime = lastSyncTimes.has(key);

      if (hasUnknownParticipantStatus) {
        statuses.set(participantId, DeviceStatus.Unknown);
        return;
      }

      // If inactive, return Inactive tag
      //
      // Intentionally excluding participant session association end time check
      // as it will only be populated if the device is removed from the participant
      // in which case it won't show up on this list
      if (!isActiveParticipant) {
        statuses.set(participantId, DeviceStatus.Inactive);
        return;
      } else if (!hasDevice) {
        // Assume active. If no device, return NoWatchAssigned tag
        statuses.set(participantId, DeviceStatus.NoWatchAssigned);
        return;
      } else if (hasLastSyncTime) {
        // Assume has device and active. If last sync time exists, return Active tag
        statuses.set(participantId, DeviceStatus.Active);
        return;
        // Assume has device and active. If last sync time does not exists, return FirstSyncPending tag
      } else if (!hasLastSyncTime) {
        statuses.set(participantId, DeviceStatus.FirstSyncPending);
        return;
      } else {
        // Otherwise, return Unknown tag. Should not reach here.
        statuses.set(participantId, DeviceStatus.Unknown);
        return;
      }
    });

    setDeviceStatuses(statuses);
  }, [studyParticipants, lastSyncTimes]);

  // Reload participants if currently selected study site id changes.
  useEffect(() => {
    setReloadData(true);
  }, [currentStudySiteId]);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");

  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  // Accessibility focus state for buttons
  const [fromButton, setFromButton] = useState<ButtonSource>(ButtonSource.None);
  const [focusSwapWatchesBtn, setFocusSwapWatchesBtn] = useState(false);

  const addParticipantRef = useRef<HTMLButtonElement>(null);

  const resetFocusFields = useCallback(() => {
    setFromButton(ButtonSource.None);
    setFocusSwapWatchesBtn(false);
  }, []);

  // Set focus on the button that triggered the reload.
  useEffect(() => {
    if (!isLoading && fromButton !== ButtonSource.None) {
      // Add delay to allow button to focus after loading is completed.
      const timeoutId = setTimeout(() => {
        switch (fromButton) {
          case ButtonSource.AddStudyParticipant:
            focusOnRef(addParticipantRef);
            break;
          case ButtonSource.SwapWatches:
            setFocusSwapWatchesBtn(true);
            break;
        }

        if (showSuccessSnackbar) {
          setIsSuccessSnackbarOpen(true);
        }

        setFromButton(ButtonSource.None);

        return () => clearTimeout(timeoutId);
      }, 500);
    }
  }, [isLoading, fromButton, showSuccessSnackbar]);

  // Set last reload date when data is reloaded.
  useEffect(() => {
    if (reloadData) {
      setLastReloadDate(new Date());
    }
  }, [reloadData]);

  useEffect(() => {
    // Reset reload flag after loading is completed so that the flag
    // can be set to true to trigger a reload next time.
    if (!isLoading) {
      setReloadData(false);

      // Remove focus settings after reload
      resetFocusFields();
    }
  }, [isLoading, resetFocusFields]);

  const [sortOrder, setSortOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("participantId");
  const [page, setPage] = React.useState(participantsViewState.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchText, setSearchText] = React.useState("");

  const [selectedParticipantStatuses, setSelectedParticipantStatuses] =
    useState<string[]>([]);
  const [selectedDeviceStatuses, setSelectedDeviceStatuses] = useState<
    string[]
  >([]);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDetailDialog, setShowDetailDialog] = useState(
    participantsViewState.isParticipantDialogOpen
  );
  const [showSwapDialog, setShowSwapDialog] = useState(false);

  const [selectedParticipantName, setSelectedParticipantName] = useState(
    participantsViewState.studyParticipantName
  );

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setPage(0);
    setSortOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filteredAndSortedParticipants, setFilteredAndSortedParticipants] =
    useState<StudyParticipant[]>([]);

  // Stores previous search string.
  const previousSearch = useRef("");

  // Stores previously participant and device statuses.
  const previousSelectedParticipantStatuses = useRef("");
  const previousSelectedDeviceStatuses = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  // Filter and sort participants.
  useEffect(() => {
    const records = filterAndSortParticipants(
      studyParticipants,
      deviceStatuses,
      selectedParticipantStatuses,
      selectedDeviceStatuses,
      searchText,
      orderBy,
      sortOrder
    );

    setFilteredAndSortedParticipants(records);

    // Only update status message if triggered by change in filter criteria
    // (i.e. search text, participant status, device status)
    if (
      previousSearch.current !== searchText ||
      previousSelectedParticipantStatuses.current !==
        selectedParticipantStatuses.toString() ||
      previousSelectedDeviceStatuses.current !==
        selectedDeviceStatuses.toString()
    ) {
      setSearchResultMessage(pluralize("search result", records.length, true));

      // Update previous states
      previousSearch.current = searchText;
      previousSelectedParticipantStatuses.current =
        selectedParticipantStatuses.toString();
      previousSelectedDeviceStatuses.current =
        selectedDeviceStatuses.toString();
    }
  }, [
    studyParticipants,
    deviceStatuses,
    selectedParticipantStatuses,
    selectedDeviceStatuses,
    searchText,
    orderBy,
    sortOrder,
  ]);

  return (
    <>
      <div className="main-content-page">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="display5">
            Manage study participants
          </Typography>
          <Button
            ref={addParticipantRef}
            label="Add participant"
            variant="filled"
            icon={<AddIcon />}
            iconPosition="start"
            onClick={() => {
              setShowAddDialog(true);
            }}
            sx={{
              marginRight: "64px",
            }}
            role="link"
            aria-label="Add new participant"
          />
        </Box>
        {isLoading && <Loading />}
        {hasError && (
          <Alert
            severity="error"
            sx={{ marginTop: "24px", paddingLeft: "16px" }}
          >
            Failed to load study participants
          </Alert>
        )}
        {!isLoading && !hasError && (
          <Box
            sx={{
              width: "100%",
              paddingTop: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "left",
                justifyItems: "center",
              }}
            >
              <Typography component="h2" variant="display6" marginRight="24px">
                {studyParticipants.length} enrolled participants
              </Typography>
              <Tag
                color="primary"
                label={`Last updated ${format(
                  lastReloadDate,
                  // date format example: 1/1/2024 3:30pm
                  "M/d/yyyy h:mmaaaaa'm'"
                )}`}
              ></Tag>
            </Box>
            <Paper
              sx={{
                background: theme.palette.background.canvas,
                margin: "24px 0px",
                padding: "16px 16px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                  justifyItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ paddingRight: "24px" }}>
                  <b>{studyParticipants.length}</b> enrolled (all to date)
                </Typography>
                <Typography variant="body2" sx={{ paddingRight: "24px" }}>
                  <b>
                    {
                      studyParticipants.filter(
                        (participant) => participant.getStatus() === 2
                      ).length
                    }
                  </b>{" "}
                  completed
                </Typography>
                <Typography variant="body2" sx={{ paddingRight: "24px" }}>
                  <b>
                    {
                      studyParticipants.filter(
                        (participant) => participant.getStatus() === 3
                      ).length
                    }
                  </b>{" "}
                  withdrawn
                </Typography>
              </Box>
            </Paper>
            <A11yStatusMessage message={searchResultMessage} />
            <StudyParticipantsTableControl
              searchText={searchText}
              onRequestSearch={(searchText) => {
                setSearchText(searchText);
                setPage(0);
              }}
              selectParticipantStatus={selectedParticipantStatuses}
              selectedDeviceStatus={selectedDeviceStatuses}
              onParticipantStatusSelected={(selectedStatus) => {
                setSelectedParticipantStatuses(selectedStatus);
                setPage(0);
              }}
              onDeviceStatusSelected={(selectedStatus) => {
                setSelectedDeviceStatuses(selectedStatus);
                setPage(0);
              }}
              focusOnSwapWatches={focusSwapWatchesBtn}
              onSwapWatchesClick={() => {
                setShowSwapDialog(true);
              }}
            />
            <Paper
              sx={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "32px",
                boxShadow: "none",
              }}
            >
              <TableContainer>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="study participants table"
                >
                  <StudyParticipantsTableHeader
                    order={sortOrder}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  {!hasError && (
                    <StudyParticipantsTableContent
                      studyParticipants={filteredAndSortedParticipants.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )}
                      deviceStatuses={deviceStatuses}
                      onClickViewParticipantIcon={(studyParticipant) => {
                        setSelectedParticipantName(studyParticipant.getName());
                        dispatch(
                          setParticipantsViewState({
                            ...participantsViewState,
                            pageNumber: page,
                          })
                        );
                        setShowDetailDialog(true);
                      }}
                    />
                  )}
                </Table>
                <A11yStatusMessage message={sortDirectiontMessage} />
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredAndSortedParticipants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ marginRight: "96px", ...tablePaginationSxProps }}
              />
            </Paper>
          </Box>
        )}
      </div>
      <AddStudyParticipantDialog
        open={showAddDialog}
        onClose={() => {
          setShowAddDialog(false);
        }}
        onSuccess={(msg: string) => {
          setFromButton(ButtonSource.AddStudyParticipant);
          setShowSuccessSnackbar(true);
          setSuccessSnackbarMessage(msg);
          setShowAddDialog(false);
          setReloadData(true);
        }}
        registryId={currentRegistryId}
        studySiteId={currentStudySiteId}
      />
      <StudyParticipantDetailDialog
        open={showDetailDialog}
        studyParticipantName={selectedParticipantName}
        onClose={() => {
          dispatch(setParticipantsViewState(participantsViewInitialState));
          setShowDetailDialog(false);
          setReloadData(true);
        }}
        onSuccess={(msg: string) => {
          dispatch(setParticipantsViewState(participantsViewInitialState));
          setShowDetailDialog(false);
          setShowSuccessSnackbar(true);
          setSuccessSnackbarMessage(msg);
          setReloadData(true);
        }}
      />
      <ParticipantsWatchSwap
        open={showSwapDialog}
        onClose={() => {
          dispatch(setParticipantsViewState(participantsViewInitialState));
          setShowSwapDialog(false);
        }}
        onSuccess={() => {
          setFromButton(ButtonSource.SwapWatches);
          setShowSuccessSnackbar(true);
          setSuccessSnackbarMessage("Watch assignments updated");
          dispatch(setParticipantsViewState(participantsViewInitialState));
          setShowSwapDialog(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setIsErrorSnackbarOpen(true);
          setErrorSnackbarMessage(msg);
        }}
      />
      <Snackbar
        open={isSuccessSnackbarOpen}
        color="success"
        withIcon={true}
        variant="tonal"
        text={successSnackbarMessage}
        autoHideDuration={autohideTimeout}
        onClose={() => {
          setIsSuccessSnackbarOpen(false);
          setShowSuccessSnackbar(false);
        }}
        role="alert"
      ></Snackbar>
      <SDPErrorSnackbar
        showSnackbar={isErrorSnackbarOpen}
        setShowSnackbar={setIsErrorSnackbarOpen}
        errorMessage={errorSnackbarMessage}
      />
    </>
  );
};

function filterAndSortParticipants(
  studyParticipants: Array<StudyParticipant>,
  deviceStatuses: Map<string, DeviceStatus>,
  selectedParticipantStatus: string[],
  selectedDeviceStatus: string[],
  searchText: string,
  orderBy: string,
  sortOrder: string
) {
  return studyParticipants
    .filter((studyParticipant) => {
      if (searchText !== "") {
        if (
          studyParticipant
            .getSponsorParticipantId()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1 &&
          RemovePicardPrefix(studyParticipant.getDeviceId())
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      // Filter based on participant status filter chip
      if (selectedParticipantStatus.length > 0) {
        if (
          !selectedParticipantStatus.includes(
            studyParticipant.getStatus().toString()
          )
        ) {
          return false;
        }
      }

      // Filter based on device status filter chip
      if (selectedDeviceStatus.length > 0) {
        const deviceStatus = deviceStatuses.get(
          ParseIdFromName(studyParticipant.getName())
        )!;
        if (!selectedDeviceStatus.includes(deviceStatus.toString())) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "participant ID":
          result = a
            .getSponsorParticipantId()
            .localeCompare(b.getSponsorParticipantId());
          break;
        case "device ID":
          result = (() => {
            // This puts participants without device at the end of an ascending list
            if (a.getDeviceId().length === 0) {
              return 1;
            } else if (b.getDeviceId().length === 0) {
              return -1;
            } else {
              return RemovePicardPrefix(a.getDeviceId()).localeCompare(
                RemovePicardPrefix(b.getDeviceId())
              );
            }
          })();
          break;
        case "enrollment date":
          result =
            a.getEnrollmentStartTime()!.toDate() <
            b.getEnrollmentStartTime()!.toDate()
              ? -1
              : 1;
          break;
        case "participant status":
          result = (() => {
            // This puts participants without participant status at the end of an ascending list
            if (a.getStatus() === StudyParticipant.ParticipantStatus.UNKNOWN) {
              return 1;
            } else if (
              b.getStatus() === StudyParticipant.ParticipantStatus.UNKNOWN
            ) {
              return -1;
            } else {
              return a.getStatus() < b.getStatus() ? -1 : 1;
            }
          })();
          break;
        case "device status":
          result = (() => {
            const aDeviceStatus = deviceStatuses.get(
              ParseIdFromName(a.getName())
            )!;
            const bDeviceStatus = deviceStatuses.get(
              ParseIdFromName(b.getName())
            )!;

            // This puts participants without device status at the end of an ascending list
            if (aDeviceStatus === DeviceStatus.Unknown) {
              return 1;
            } else if (bDeviceStatus === DeviceStatus.Unknown) {
              return -1;
            } else {
              return aDeviceStatus < bDeviceStatus ? -1 : 1;
            }
          })();
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });
}

export default StudyParticipants;
