import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button } from "@verily-src/react-design-system";
import { UpdateUserRequest, User } from "generated/studyauth/studyauth_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import UserInfoForm, { UserInfoFormRef } from "components/UserInfoForm";
import { getDevOpsRoleDescription } from "components/UserRenderers";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface EditSuperAdminDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  user: User;
  onSuccess(): void;
}

// The dialog box for editing a super admin
const EditSuperAdminDialog: React.FC<EditSuperAdminDialogProps> = ({
  open,
  onClose,
  user,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const userInfoFormRef = useRef<UserInfoFormRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setErrorMessage("");
    }
  }, [open]);

  const validateInputs = useCallback(async () => {
    let isInputsValid = await userInfoFormRef.current!.validateInput(
      true /* focusOnError */
    );
    if (
      !updateReasonFormRef.current!.validateInput(
        isInputsValid /* focusOnError */
      )
    ) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, []);

  const handleClickSaveButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showEditUserError) {
            throw new Error("Failed to edit user. Please try again.");
          }

          setDisableSaveButton(true);

          const isInputsValid = await validateInputs();
          if (!isInputsValid) {
            setDisableSaveButton(false);
            return;
          }

          // Update user information.
          const userInfo = userInfoFormRef.current!.getUserInfo();
          const userToUpdate = user.clone();
          userToUpdate.setFirstName(userInfo.getFirstName());
          userToUpdate.setLastName(userInfo.getLastName());

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const request = new UpdateUserRequest()
            .setUser(userToUpdate)
            .setUpdateReason(updateReason);
          await client.updateUser(request);

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to edit user. Please try again.");
        }

        setDisableSaveButton(false);
      })();
    } else {
      setErrorMessage(
        "Current user is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    testConfig.showEditUserError,
    user,
    validateInputs,
  ]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6">Edit Dev Ops (internal) user</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Edit user failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography variant="body1" sx={{ padding: "4px 0px 16px 0px" }}>
          {getDevOpsRoleDescription()}
        </Typography>
        <UserInfoForm
          initValue={user}
          emailDisabled={true}
          ref={userInfoFormRef}
        />
        <Box sx={{ marginTop: "8px", marginBottom: "22px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Error correction",
              "Change in role required to perform job responsibilities",
            ]}
            reasonSelectionLabel="Reason for editing user (required)"
            reasonRequiredMessage="Reason for editing user required"
            otherReasonHint="Please provide reason this user is being edited"
            otherReasonRequiredMessage="Other reason for editing user required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Save"
          variant="filled"
          onClick={handleClickSaveButton}
          disabled={disableSaveButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditSuperAdminDialog;
