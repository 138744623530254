/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.studydetail.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: studydetail/studydetail.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.studydetail = {};
proto.verily.sdp.studydetail.v1alpha1 = require('./studydetail_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest,
 *   !proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 */
const methodDescriptor_StudyDetailService_CreateStudyDetail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydetail.v1alpha1.StudyDetailService/CreateStudyDetail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest,
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail,
  /**
   * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydetail.v1alpha1.StudyDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServiceClient.prototype.createStudyDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/CreateStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_CreateStudyDetail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServicePromiseClient.prototype.createStudyDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/CreateStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_CreateStudyDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest,
 *   !proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 */
const methodDescriptor_StudyDetailService_UpdateStudyDetail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydetail.v1alpha1.StudyDetailService/UpdateStudyDetail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest,
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail,
  /**
   * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydetail.v1alpha1.StudyDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServiceClient.prototype.updateStudyDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/UpdateStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_UpdateStudyDetail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServicePromiseClient.prototype.updateStudyDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/UpdateStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_UpdateStudyDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest,
 *   !proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 */
const methodDescriptor_StudyDetailService_GetStudyDetail = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydetail.v1alpha1.StudyDetailService/GetStudyDetail',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest,
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail,
  /**
   * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydetail.v1alpha1.StudyDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServiceClient.prototype.getStudyDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/GetStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_GetStudyDetail,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServicePromiseClient.prototype.getStudyDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/GetStudyDetail',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_GetStudyDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest,
 *   !proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse>}
 */
const methodDescriptor_StudyDetailService_ListStudyDetails = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydetail.v1alpha1.StudyDetailService/ListStudyDetails',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest,
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse,
  /**
   * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServiceClient.prototype.listStudyDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/ListStudyDetails',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_ListStudyDetails,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetailServicePromiseClient.prototype.listStudyDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydetail.v1alpha1.StudyDetailService/ListStudyDetails',
      request,
      metadata || {},
      methodDescriptor_StudyDetailService_ListStudyDetails);
};


module.exports = proto.verily.sdp.studydetail.v1alpha1;

