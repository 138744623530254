import { AppConfig } from "./AppConfigSlice";

export const CONFIGS: Record<string, AppConfig> = {
  local: {
    environment: "local",
    lookerInstanceUrl: "viewpoint-dev.looker.verily.com",
    lookerProxyUrl: "https://viewpoint-dev.looker.verily.com:443",
    auth0Audience: "https://sdp-dev-unstable.api.verily.com",
    auth0ClientId: "BWUSBWXAv8M0aXR2zqa3UV8fMutfU9qI",
    auth0Url: "auth.watch-dev.verily.com",
  },
  "dev-unstable": {
    environment: "dev-unstable",
    lookerInstanceUrl: "viewpoint-dev.looker.verily.com",
    lookerProxyUrl: "https://viewpoint-dev.looker.verily.com:443",
    auth0Audience: "https://sdp-dev-unstable.api.verily.com",
    auth0ClientId: "BWUSBWXAv8M0aXR2zqa3UV8fMutfU9qI",
    auth0Url: "auth.watch-dev.verily.com",
  },
  dev: {
    environment: "dev",
    lookerInstanceUrl: "viewpoint-dev.looker.verily.com",
    lookerProxyUrl: "https://viewpoint-dev.looker.verily.com:443",
    auth0Audience: "https://sdp-dev.api.verily.com",
    auth0ClientId: "VCXGJ4AQevAFzrjWtoCON71kO5Gw0GLB",
    auth0Url: "auth.watch-dev.verily.com",
  },
  staging: {
    environment: "staging",
    lookerInstanceUrl: "viewpoint-prod.looker.verily.com",
    lookerProxyUrl: "https://viewpoint-prod.looker.verily.com:443",
    auth0Audience: "https://sdp-staging.api.verily.com",
    auth0ClientId: "kUUlaPimcvoDR5XRZAQCAppOl2cmpjKf",
    auth0Url: "auth.watch-staging.verily.com",
  },
  prod: {
    environment: "prod",
    lookerInstanceUrl: "viewpoint-prod.looker.verily.com",
    lookerProxyUrl: "https://viewpoint-prod.looker.verily.com:443",
    auth0Audience: "https://sdp.api.verily.com",
    auth0ClientId: "77Fu5Ic42TJmAO7H95OzqOJAPqfXxiTZ",
    auth0Url: "auth.watch.verily.com",
  },
};
