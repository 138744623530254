import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookerSdk } from "looker/LookerSdk";
import { updateReportTimePeriod } from "looker/UpdateReportTimePeriod";
import { ComplianceType } from "looker/QueryResultsCache";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { StudySite } from "generated/studysite/studysite_pb";
import { getStudyParticipantsComplianceRecords } from "looker/GetStudyParticipantsComplianceRecords";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import { ParseSiteIdFromName } from "common/ResourceName";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export interface SiteComplianceRecord {
  site: StudySite;
  totalActive: number;
  compliancePercentage: number;
}

export interface SiteComplianceSummary {
  records: Array<SiteComplianceRecord>;
}

// Defines a custom effect to load study site compliance data.
function useStudySiteComplianceTabDataLoader() {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<SiteComplianceSummary>({
    records: new Array<SiteComplianceRecord>(),
  });

  const loadStudySiteComplianceData = useCallback(
    (registryId: string, previousWeekOffset: number) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const weekStart = getWeekStartDate(previousWeekOffset);
            const weekEnd = getWeekEndDate(previousWeekOffset);

            // Set report time period.
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            await updateReportTimePeriod(
              auth0Config.audience!,
              token,
              weekStart,
              weekEnd,
              previousWeekOffset
            );

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              registryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalCompliance: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalCompliance.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalCompliance.push(ComplianceType.MedLogWeekly);
            }

            // Get compliance records.
            const complianceRecords =
              await getStudyParticipantsComplianceRecords(
                sdk,
                previousWeekOffset,
                optionalCompliance,
                lookerQueryIdsMap
              );

            if (complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            const totalCompliantPerSite = new Map<string, number>();
            const totalNonCompliantPerSite = new Map<string, number>();

            for (const record of complianceRecords.compliantRecords) {
              if (totalCompliantPerSite.has(record.siteId)) {
                totalCompliantPerSite.set(
                  record.siteId,
                  totalCompliantPerSite.get(record.siteId)! + 1
                );
              } else {
                totalCompliantPerSite.set(record.siteId, 1);
              }
            }

            for (const record of complianceRecords.noncompliantRecords) {
              if (totalNonCompliantPerSite.has(record.siteId)) {
                totalNonCompliantPerSite.set(
                  record.siteId,
                  totalNonCompliantPerSite.get(record.siteId)! + 1
                );
              } else {
                totalNonCompliantPerSite.set(record.siteId, 1);
              }
            }

            const siteComplianceRecords = new Array<SiteComplianceRecord>();

            // Get all sites for the study
            const client = new StudySiteServiceApiClient(
              auth0Config.audience!,
              token
            );

            const response = await client.listStudySites(registryId);
            for (const site of response.getStudySitesList()) {
              if (site.getArchivedDate() !== undefined) {
                continue;
              }

              const siteId = ParseSiteIdFromName(site.getName());
              const totalCompliant = totalCompliantPerSite.has(siteId)
                ? totalCompliantPerSite.get(siteId)!
                : 0;
              const totalNonCompliant = totalNonCompliantPerSite.has(siteId)
                ? totalNonCompliantPerSite.get(siteId)!
                : 0;
              const totalActive = totalCompliant + totalNonCompliant;
              const compliancePercentage =
                totalActive > 0
                  ? Math.round(((totalCompliant * 1.0) / totalActive) * 100)
                  : 0;

              siteComplianceRecords.push({
                site: site,
                totalActive: totalActive,
                compliancePercentage: compliancePercentage,
              });
            }

            let summary = {
              records: siteComplianceRecords,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      auth0Config,
      getAccessTokenSilently,
      isAuthenticated,
      lookerQueryIdsMap,
      sdk,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadStudySiteComplianceData,
  };
}

export default useStudySiteComplianceTabDataLoader;
