import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { User } from "generated/studyauth/studyauth_pb";
import {
  Button,
  Search,
  Snackbar,
  Tooltip,
} from "@verily-src/react-design-system";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import {
  getDevOpsRoleDescription,
  getInvitationDisplayStatus,
  getUserIndex,
  restoreUserRecordPosition,
} from "components/UserRenderers";
import InviteSuperAdminDialog from "./InviteSuperAdminDialog";
import ConfirmDeleteSuperAdminDialog from "./ConfirmDeleteSuperAdminDialog";
import EditSuperAdminDialog from "./EditSuperAdminDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { tablePaginationSxProps } from "components/Tables";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";
import { sxFocusWithin } from "common/Accessibility";
import { ManageUserDetailsTableHeaderCell } from "components/ManageUserDetailsTableHeaderCell";

interface SuperAdminsTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const SuperAdminsTableHeader: React.FC<SuperAdminsTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="25%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="email"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Email"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="55%"
        >
          <Typography variant="body2em">Email</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Status"
          onRequestSort={onRequestSort}
          width="20%"
        >
          <Typography variant="body2em">Status</Typography>
        </SortableTableHeaderCell>
        <ManageUserDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

// Define a component to render user information.
interface SuperAdminRowProps {
  user: User;
  onClickEditButton(user: User): void;
  onClickDeleteButton(user: User): void;
}

function getUserName(user: User) {
  return user.getFirstName() + " " + user.getLastName();
}

const SuperAdminRow: React.FC<SuperAdminRowProps> = ({
  user,
  onClickEditButton,
  onClickDeleteButton,
}) => {
  return (
    <TableRow>
      <TableCell width="25%" sx={{ paddingLeft: "16px" }}>
        {getUserName(user)}
      </TableCell>
      <TableCell width="55%">{user.getEmailAddress()}</TableCell>
      <TableCell width="20%">
        {getInvitationDisplayStatus(user.getInvitationStatus())}
      </TableCell>
      <TableCell width="64px" sx={{ paddingRight: "0px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
            <Tooltip title="Edit">
              <IconButton
                tabIndex={0}
                onClick={() => {
                  onClickEditButton(user);
                }}
                sx={{ marginRight: "8px" }}
              >
                <EditIcon
                  titleAccess={`Edit Dev Ops ${getUserName(user)} user info`}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
            <Tooltip title="Delete">
              <IconButton
                tabIndex={0}
                onClick={() => {
                  onClickDeleteButton(user);
                }}
              >
                <DeleteIcon
                  titleAccess={`Remove existing Dev Ops user ${getUserName(
                    user
                  )}`}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

interface SuperAdminsTableContentProps {
  users: Array<User>;
  onClickEditButton(user: User): void;
  onClickDeleteButton(user: User): void;
}

const SuperAdminsTableContent: React.FC<SuperAdminsTableContentProps> = ({
  users,
  onClickEditButton,
  onClickDeleteButton,
}) => {
  return (
    <TableBody>
      {users.map((user) => (
        <SuperAdminRow
          key={user.getName()}
          user={user}
          onClickEditButton={onClickEditButton}
          onClickDeleteButton={onClickDeleteButton}
        />
      ))}
    </TableBody>
  );
};

interface SuperAdminsTitleProps {
  searchText: string;
  onRequestSearch(searchText: string): void;
  onClickInviteUserButton(): void;
}

const SuperAdminsTitle: React.FC<SuperAdminsTitleProps> = ({
  searchText,
  onRequestSearch,
  onClickInviteUserButton,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "8px 0px 8px 4px",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
      <Button
        label="Add user"
        aria-label="Add new Dev Ops"
        variant="filled"
        icon={<AddIcon />}
        iconPosition="start"
        onClick={onClickInviteUserButton}
        sx={{
          marginLeft: "16px",
        }}
      />
    </Box>
  );
};

interface SuperAdminsProps {
  users: User[];
  reloadData(): void;
}

const SuperAdmins: React.FC<SuperAdminsProps> = ({ users, reloadData }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchText, setSearchText] = React.useState("");

  const [showInviteDialog, setShowInviteDialog] = useState(false);

  const userToEdit = useRef(new User());
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);

  // Stored the edited user index to the filterAndSortedUsers list
  // to keep its current position after editing.
  const editedUserIndex = useRef(-1);

  const userToDelete = useRef(new User());
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbarMessage, setShowSnackbarMessage] = useState(false);

  const { logout } = useAuth0();

  const resetEditedUserIndex = () => {
    editedUserIndex.current = -1;
  };

  useEffect(() => {
    resetEditedUserIndex();
  }, []);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  const [filteredAndSortedUsers, setFilteredAndSortedUsers] = useState(
    new Array<User>()
  );

  useEffect(() => {
    const userList = users
      .filter((user) => {
        const skipFilter =
          searchText === "" ||
          (editedUserIndex.current >= 0 &&
            userToEdit.current.getName() === user.getName());

        if (!skipFilter) {
          const searchTextLowercase = searchText.toLowerCase();
          if (
            getUserName(user).toLowerCase().indexOf(searchTextLowercase) ===
              -1 &&
            user
              .getEmailAddress()
              .toLowerCase()
              .indexOf(searchTextLowercase) === -1
          ) {
            return false;
          }
        }

        return true;
      })
      .sort((a, b) => {
        let result = 0;
        switch (orderBy) {
          case "name":
            result = getUserName(a).localeCompare(getUserName(b));
            break;
          case "email":
            result = a.getEmailAddress().localeCompare(b.getEmailAddress());
            break;
          case "status":
            result = getInvitationDisplayStatus(
              a.getInvitationStatus()
            ).localeCompare(
              getInvitationDisplayStatus(b.getInvitationStatus())
            );
            break;
        }
        return order === "asc" ? result : -result;
      });

    // Keep position of an edited user.
    if (editedUserIndex.current >= 0) {
      restoreUserRecordPosition(
        userList,
        userToEdit.current.getName(),
        editedUserIndex.current
      );

      // Do not reset the editedUserIndex here since this function is called
      // multiple times before the list of users are rendered.
    }

    setFilteredAndSortedUsers(userList);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(userList.length));
      previousSearch.current = searchText;
    }
  }, [users, order, orderBy, searchText]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          width: "100%",
        }}
      >
        <A11yStatusMessage message={searchResultMessage} />
        <SuperAdminsTitle
          searchText={searchText}
          onRequestSearch={(searchText) => {
            resetEditedUserIndex();
            setSearchText(searchText);
            setPage(0);
          }}
          onClickInviteUserButton={() => {
            resetEditedUserIndex();
            setShowInviteDialog(true);
          }}
        />
        <Typography variant="body1em" sx={{ padding: "16px" }}>
          {getDevOpsRoleDescription()}
        </Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 700, marginBottom: "8px" }}
            aria-label="DevOps"
          >
            <SuperAdminsTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={(orderBy) => {
                resetEditedUserIndex();
                handleRequestSort(orderBy);
              }}
            />
            <SuperAdminsTableContent
              users={filteredAndSortedUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              onClickEditButton={(user) => {
                resetEditedUserIndex();
                userToEdit.current = user;
                setShowEditUserDialog(true);
              }}
              onClickDeleteButton={(user) => {
                resetEditedUserIndex();
                userToDelete.current = user;
                setShowDeleteUserDialog(true);
              }}
            />
          </Table>
          <A11yStatusMessage message={sortDirectiontMessage} />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginRight: "8px", ...tablePaginationSxProps }}
        />
      </Box>
      <InviteSuperAdminDialog
        open={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
        }}
        onSuccess={() => {
          setShowInviteDialog(false);
          reloadData();
          setSnackbarMessage("Invitation sent");
          setShowSnackbarMessage(true);
        }}
      />
      <EditSuperAdminDialog
        open={showEditUserDialog}
        user={userToEdit.current}
        onClose={() => {
          setShowEditUserDialog(false);
        }}
        onSuccess={() => {
          setShowEditUserDialog(false);
          setSnackbarMessage("User updated");
          setShowSnackbarMessage(true);
          editedUserIndex.current = getUserIndex(
            filteredAndSortedUsers,
            userToEdit.current.getName()
          );
          reloadData();
        }}
      />
      <ConfirmDeleteSuperAdminDialog
        open={showDeleteUserDialog}
        user={userToDelete.current}
        onClose={() => {
          setShowDeleteUserDialog(false);
        }}
        onSuccess={(removeSelf) => {
          setShowDeleteUserDialog(false);
          setSnackbarMessage("User removed");
          setShowSnackbarMessage(true);
          if (removeSelf) {
            logout({
              returnTo: window.location.origin,
            });
          } else {
            reloadData();
          }
        }}
      />
      <Snackbar
        role="alert"
        text={snackbarMessage}
        color="success"
        withIcon
        open={showSnackbarMessage}
        onClose={() => {
          setShowSnackbarMessage(false);
        }}
        autoHideDuration={3000}
      />
    </>
  );
};

export default SuperAdmins;
