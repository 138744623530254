// source: studyauth/studyauth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.AccountType', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.EmailType', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.GetUserRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.InvitationStatus', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.Role', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.StudyInfo', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.StudyRole', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.User', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.UserRole', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.User.displayName = 'proto.verily.sdp.studyauth.v1alpha1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.StudyRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.StudyRole.displayName = 'proto.verily.sdp.studyauth.v1alpha1.StudyRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.displayName = 'proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.UserRole.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.UserRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.UserRole.displayName = 'proto.verily.sdp.studyauth.v1alpha1.UserRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.displayName = 'proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.StudyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.StudyInfo.displayName = 'proto.verily.sdp.studyauth.v1alpha1.StudyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.displayName = 'proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.displayName = 'proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    invitationStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isSuperAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User}
 */
proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.User;
  return proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User}
 */
proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {!proto.verily.sdp.studyauth.v1alpha1.AccountType} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 6:
      var value = /** @type {!proto.verily.sdp.studyauth.v1alpha1.InvitationStatus} */ (reader.readEnum());
      msg.setInvitationStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuperAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getInvitationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsSuperAdmin();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountType account_type = 5;
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AccountType}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getAccountType = function() {
  return /** @type {!proto.verily.sdp.studyauth.v1alpha1.AccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AccountType} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional InvitationStatus invitation_status = 6;
 * @return {!proto.verily.sdp.studyauth.v1alpha1.InvitationStatus}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getInvitationStatus = function() {
  return /** @type {!proto.verily.sdp.studyauth.v1alpha1.InvitationStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.InvitationStatus} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setInvitationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_super_admin = 7;
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.getIsSuperAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.User.prototype.setIsSuperAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.verily.sdp.studyauth.v1alpha1.User.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest;
  return proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyauth.v1alpha1.User;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studyauth.v1alpha1.User.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.verily.sdp.studyauth.v1alpha1.User}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.verily.sdp.studyauth.v1alpha1.User} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.User, 1));
};


/**
 * @param {?proto.verily.sdp.studyauth.v1alpha1.User|undefined} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.CreateUserRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.verily.sdp.studyauth.v1alpha1.User.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest;
  return proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyauth.v1alpha1.User;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studyauth.v1alpha1.User.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.verily.sdp.studyauth.v1alpha1.User}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.getUser = function() {
  return /** @type{?proto.verily.sdp.studyauth.v1alpha1.User} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.User, 1));
};


/**
 * @param {?proto.verily.sdp.studyauth.v1alpha1.User|undefined} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.UpdateUserRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest;
  return proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.GetUserRequest;
  return proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest;
  return proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUserByEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuperAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest;
  return proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuperAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuperAdmin();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_super_admin = 1;
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.prototype.getIsSuperAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersRequest.prototype.setIsSuperAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.verily.sdp.studyauth.v1alpha1.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse;
  return proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyauth.v1alpha1.User;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studyauth.v1alpha1.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.User>}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.User, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.User>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.User}
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studyauth.v1alpha1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest;
  return proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SyncUserAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.StudyRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    studySiteId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.StudyRole;
  return proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 2:
      var value = /** @type {!proto.verily.sdp.studyauth.v1alpha1.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudySiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.StudyRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStudySiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Role role = 2;
 * @return {!proto.verily.sdp.studyauth.v1alpha1.Role}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.getRole = function() {
  return /** @type {!proto.verily.sdp.studyauth.v1alpha1.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.Role} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string study_site_id = 3;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.getStudySiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyRole.prototype.setStudySiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    studyRolesList: jspb.Message.toObjectList(msg.getStudyRolesList(),
    proto.verily.sdp.studyauth.v1alpha1.StudyRole.toObject, includeInstance),
    updateReason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest;
  return proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = new proto.verily.sdp.studyauth.v1alpha1.StudyRole;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinaryFromReader);
      msg.addStudyRoles(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudyRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.verily.sdp.studyauth.v1alpha1.StudyRole.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StudyRole study_roles = 2;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.getStudyRolesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.StudyRole, 2));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.setStudyRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.addStudyRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.verily.sdp.studyauth.v1alpha1.StudyRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.clearStudyRolesList = function() {
  return this.setStudyRolesList([]);
};


/**
 * optional string update_reason = 3;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.AddUserRolesRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    studyRolesList: jspb.Message.toObjectList(msg.getStudyRolesList(),
    proto.verily.sdp.studyauth.v1alpha1.StudyRole.toObject, includeInstance),
    updateReason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest;
  return proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = new proto.verily.sdp.studyauth.v1alpha1.StudyRole;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinaryFromReader);
      msg.addStudyRoles(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudyRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.verily.sdp.studyauth.v1alpha1.StudyRole.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StudyRole study_roles = 2;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.getStudyRolesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.StudyRole, 2));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.setStudyRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.addStudyRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.verily.sdp.studyauth.v1alpha1.StudyRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.clearStudyRolesList = function() {
  return this.setStudyRolesList([]);
};


/**
 * optional string update_reason = 3;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.DeleteUserRolesRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    withStudyInfo: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest;
  return proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserNames(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithStudyInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getWithStudyInfo();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated string user_names = 1;
 * @return {!Array<string>}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.getUserNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.setUserNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.addUserNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.clearUserNamesList = function() {
  return this.setUserNamesList([]);
};


/**
 * optional bool with_study_info = 2;
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.getWithStudyInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesRequest.prototype.setWithStudyInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    registryDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bqProjectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bqDatasetName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo;
  return proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBqProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBqDatasetName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegistryDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBqProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBqDatasetName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string registry_display_name = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.getRegistryDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.setRegistryDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bq_project_id = 3;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.getBqProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.setBqProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bq_dataset_name = 4;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.getBqDatasetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.prototype.setBqDatasetName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.UserRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UserRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    studyRolesList: jspb.Message.toObjectList(msg.getStudyRolesList(),
    proto.verily.sdp.studyauth.v1alpha1.StudyRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.UserRole;
  return proto.verily.sdp.studyauth.v1alpha1.UserRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UserRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = new proto.verily.sdp.studyauth.v1alpha1.StudyRole;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.StudyRole.deserializeBinaryFromReader);
      msg.addStudyRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.UserRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UserRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudyRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.verily.sdp.studyauth.v1alpha1.StudyRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StudyRole study_roles = 2;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.getStudyRolesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.StudyRole, 2));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyRole>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.setStudyRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyRole}
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.addStudyRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.verily.sdp.studyauth.v1alpha1.StudyRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.UserRole.prototype.clearStudyRolesList = function() {
  return this.setStudyRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userRolesList: jspb.Message.toObjectList(msg.getUserRolesList(),
    proto.verily.sdp.studyauth.v1alpha1.UserRole.toObject, includeInstance),
    studiesList: jspb.Message.toObjectList(msg.getStudiesList(),
    proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse;
  return proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyauth.v1alpha1.UserRole;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.UserRole.deserializeBinaryFromReader);
      msg.addUserRoles(value);
      break;
    case 2:
      var value = new proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.deserializeBinaryFromReader);
      msg.addStudies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studyauth.v1alpha1.UserRole.serializeBinaryToWriter
    );
  }
  f = message.getStudiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserRole user_roles = 1;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.UserRole>}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.getUserRolesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.UserRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.UserRole, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.UserRole>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.setUserRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.UserRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.UserRole}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.addUserRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studyauth.v1alpha1.UserRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.clearUserRolesList = function() {
  return this.setUserRolesList([]);
};


/**
 * repeated StudyDataExportInfo studies = 2;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo>}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.getStudiesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo, 2));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.setStudiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.addStudies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.verily.sdp.studyauth.v1alpha1.StudyDataExportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.GetUsersRolesResponse.prototype.clearStudiesList = function() {
  return this.setStudiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest;
  return proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.StudyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    registryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.StudyInfo;
  return proto.verily.sdp.studyauth.v1alpha1.StudyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.StudyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string registry_id = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.StudyInfo.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studiesList: jspb.Message.toObjectList(msg.getStudiesList(),
    proto.verily.sdp.studyauth.v1alpha1.StudyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse;
  return proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studyauth.v1alpha1.StudyInfo;
      reader.readMessage(value,proto.verily.sdp.studyauth.v1alpha1.StudyInfo.deserializeBinaryFromReader);
      msg.addStudies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studyauth.v1alpha1.StudyInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudyInfo studies = 1;
 * @return {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyInfo>}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.getStudiesList = function() {
  return /** @type{!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studyauth.v1alpha1.StudyInfo, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studyauth.v1alpha1.StudyInfo>} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse} returns this
*/
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.setStudiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studyauth.v1alpha1.StudyInfo}
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.addStudies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studyauth.v1alpha1.StudyInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ListStudiesResponse.prototype.clearStudiesList = function() {
  return this.setStudiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userRole: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updateReason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest;
  return proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.verily.sdp.studyauth.v1alpha1.EmailType} */ (reader.readEnum());
      msg.setEmailType(value);
      break;
    case 3:
      var value = /** @type {!proto.verily.sdp.studyauth.v1alpha1.Role} */ (reader.readEnum());
      msg.setUserRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EmailType email_type = 2;
 * @return {!proto.verily.sdp.studyauth.v1alpha1.EmailType}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.getEmailType = function() {
  return /** @type {!proto.verily.sdp.studyauth.v1alpha1.EmailType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.EmailType} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.setEmailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Role user_role = 3;
 * @return {!proto.verily.sdp.studyauth.v1alpha1.Role}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.getUserRole = function() {
  return /** @type {!proto.verily.sdp.studyauth.v1alpha1.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.verily.sdp.studyauth.v1alpha1.Role} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.setUserRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string update_reason = 4;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse;
  return proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.SendEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest;
  return proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasValidFormat: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    hasValidDomain: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse;
  return proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValidFormat(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValidDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasValidFormat();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHasValidDomain();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool has_valid_format = 1;
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.getHasValidFormat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.setHasValidFormat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool has_valid_domain = 2;
 * @return {boolean}
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.getHasValidDomain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse} returns this
 */
proto.verily.sdp.studyauth.v1alpha1.ValidateEmailResponse.prototype.setHasValidDomain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.verily.sdp.studyauth.v1alpha1.AccountType = {
  ACCOUNT_TYPE_UNSPECIFIED: 0,
  EMAIL_PASSWORD: 1,
  ENTERPRISE: 2
};

/**
 * @enum {number}
 */
proto.verily.sdp.studyauth.v1alpha1.InvitationStatus = {
  INVITATION_STATUS_UNSPECIFIED: 0,
  INVITE_FAILED: 1,
  INVITED: 2,
  ACCEPTED: 3
};

/**
 * @enum {number}
 */
proto.verily.sdp.studyauth.v1alpha1.Role = {
  ROLE_UNSPECIFIED: 0,
  DEV_OPS: 1,
  SPONSOR_OPS: 2,
  STUDY_MONITOR: 3,
  PARTICIPANT_MONITOR: 4,
  COMPLIANCE_ADMIN: 5,
  CDM: 6,
  CRC: 7
};

/**
 * @enum {number}
 */
proto.verily.sdp.studyauth.v1alpha1.EmailType = {
  EMAIL_TYPE_UNSPECIFIED: 0,
  INVITATION: 1
};

goog.object.extend(exports, proto.verily.sdp.studyauth.v1alpha1);
