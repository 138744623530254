import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
  Alert,
  AlertTitle,
  Box,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button } from "@verily-src/react-design-system";
import {
  CreateUserRequest,
  EmailType,
  SendEmailRequest,
  Role,
} from "generated/studyauth/studyauth_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import UserInfoForm, { UserInfoFormRef } from "components/UserInfoForm";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { getDevOpsRoleDescription } from "components/UserRenderers";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface InviteSuperAdminDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  onSuccess(): void;
}

// The dialog box for inviting a super admin.
const InviteSuperAdminDialog: React.FC<InviteSuperAdminDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const userInfoFormRef = useRef<UserInfoFormRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [disableAddUserButton, setDisableAddUserButton] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setErrorMessage("");
    }
  }, [open]);

  const validateInputs = useCallback(async () => {
    let isInputsValid = await userInfoFormRef.current!.validateInput(
      true /* focusOnError */
    );
    if (
      !updateReasonFormRef.current!.validateInput(
        isInputsValid /* focusOnError */
      )
    ) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, []);

  const handleClickAddUserButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showInviteUserError) {
            throw new Error("failed to invite user");
          }

          setDisableAddUserButton(true);

          const isInputsValid = await validateInputs();
          if (!isInputsValid) {
            setDisableAddUserButton(false);
            return;
          }

          const user = userInfoFormRef.current!.getUserInfo();
          user.setIsSuperAdmin(true);

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const request = new CreateUserRequest()
            .setUser(user)
            .setUpdateReason(updateReason);
          const userCreated = await client.createUser(request);

          const sendEmailRequest = new SendEmailRequest()
            .setName(userCreated.getName())
            .setEmailType(EmailType.INVITATION)
            .setUserRole(Role.DEV_OPS)
            .setUpdateReason(updateReason);

          await client.sendEmail(sendEmailRequest);

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to invite the user. Please try again.");
        }

        setDisableAddUserButton(false);
      })();
    } else {
      setErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    testConfig.showInviteUserError,
    validateInputs,
  ]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display5">Add Dev Ops (internal) user</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Invitation failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography variant="body1" sx={{ padding: "4px 0px 16px 0px" }}>
          {getDevOpsRoleDescription()}
        </Typography>
        <UserInfoForm ref={userInfoFormRef} verilyEmailRequired={true} />
        <Box sx={{ marginTop: "8px", marginBottom: "22px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Access required to perform job responsibilities",
              "Temporary access required to perform troubleshooting",
            ]}
            reasonSelectionLabel="Reason for adding user (required)"
            reasonRequiredMessage="Reason for adding user required"
            otherReasonHint="Please provide reason this user is being added"
            otherReasonRequiredMessage="Other reason for adding user required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Add user"
          variant="filled"
          onClick={handleClickAddUserButton}
          disabled={disableAddUserButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default InviteSuperAdminDialog;
