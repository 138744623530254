import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookerSdk } from "looker/LookerSdk";
import { ComplianceType, NonCompliantReason } from "looker/QueryResultsCache";
import { updateReportTimePeriod } from "looker/UpdateReportTimePeriod";
import { getParticipantsComplianceRecords } from "looker/GetParticipantsComplianceRecords";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { getStudyParticipantsComplianceRecords } from "looker/GetStudyParticipantsComplianceRecords";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export interface NonCompliantSummaryRecord {
  reason: NonCompliantReason;
  numberOfParticipants: number;
}

export interface NonCompliantSummary {
  /** total number of active participants */
  total: number;

  /** number of non-compliant participants */
  nonCompliant: number;

  records: Array<NonCompliantSummaryRecord>;
}

// Scope for calculating compliance metrics
export enum ComplianceScope {
  Site,
  Study,
}

// Defines a custom effect to load non-compliant data for site or study
function useNonCompliantDataLoader(scope: ComplianceScope) {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<NonCompliantSummary>({
    total: 0,
    nonCompliant: 0,
    records: new Array<NonCompliantSummaryRecord>(),
  });

  const loadNonCompliantData = useCallback(
    (currentRegistryId: string, previousWeekOffset: number) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const weekStart = getWeekStartDate(previousWeekOffset);
            const weekEnd = getWeekEndDate(previousWeekOffset);

            // Set report time period.
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            await updateReportTimePeriod(
              auth0Config.audience!,
              token,
              weekStart,
              weekEnd,
              previousWeekOffset
            );

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              currentRegistryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalComplianceTypes: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalComplianceTypes.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalComplianceTypes.push(ComplianceType.MedLogWeekly);
            }

            const complianceRecords =
              scope === ComplianceScope.Site
                ? await getParticipantsComplianceRecords(
                    sdk,
                    previousWeekOffset,
                    optionalComplianceTypes,
                    lookerQueryIdsMap
                  )
                : await getStudyParticipantsComplianceRecords(
                    sdk,
                    previousWeekOffset,
                    optionalComplianceTypes,
                    lookerQueryIdsMap
                  );

            if (complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            const records = complianceRecords.noncompliantRecords;
            const multipleReasonsRecords = records.filter((item) => {
              return item.reasons.size > 1;
            });
            const wearOnlyRecords = records.filter((item) => {
              return (
                item.reasons.size === 1 &&
                item.reasons.has(NonCompliantReason.WearTime)
              );
            });
            const syncOnlyRecords = records.filter((item) => {
              return (
                item.reasons.size === 1 &&
                item.reasons.has(NonCompliantReason.SyncTime)
              );
            });

            const summaryRecords: NonCompliantSummaryRecord[] = [
              {
                reason: NonCompliantReason.Multiple,
                numberOfParticipants: multipleReasonsRecords.length,
              },
              {
                reason: NonCompliantReason.WearTime,
                numberOfParticipants: wearOnlyRecords.length,
              },
              {
                reason: NonCompliantReason.SyncTime,
                numberOfParticipants: syncOnlyRecords.length,
              },
            ];

            // Optional compliance metrics
            for (let opt of optionalComplianceTypes) {
              if (
                opt === ComplianceType.MedLogDaily ||
                opt === ComplianceType.MedLogWeekly
              ) {
                let filteredRecords = records.filter((item) => {
                  return (
                    item.reasons.size === 1 &&
                    item.reasons.has(NonCompliantReason.MedLog)
                  );
                });

                summaryRecords.push({
                  reason: NonCompliantReason.MedLog,
                  numberOfParticipants: filteredRecords.length,
                });
              }
            }

            let nonCompliantTotal = 0;
            for (const record of summaryRecords) {
              nonCompliantTotal += record.numberOfParticipants;
            }

            let summary = {
              total: complianceRecords.activeParticipantsCount,
              nonCompliant: nonCompliantTotal,
              records: summaryRecords,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      auth0Config,
      getAccessTokenSilently,
      isAuthenticated,
      lookerQueryIdsMap,
      scope,
      sdk,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadNonCompliantData,
  };
}

export default useNonCompliantDataLoader;
