import { Looker40SDK } from "@looker/sdk";
import {
  getParticipantMonthCacheKey,
  participantMedLogDailyCache,
} from "./CalendarViewQueryResultsCache";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get a participant's medication logs in a given month.
 *
 * The function assumes that the Looker user attributes for participant id,
 * month start date and month end date are set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantMedicationLogsDaily(
  sdk: Looker40SDK,
  studyParticipantId: string,
  monthStartDate: Date,
  medsLoggingEnabled: boolean,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let medLogsMap = new Map<string, number>();
  let hasError = false;

  if (!medsLoggingEnabled) {
    return {
      medLogsMap: medLogsMap,
      hasError: false,
    };
  }

  try {
    const cacheKey = getParticipantMonthCacheKey(
      studyParticipantId,
      monthStartDate
    );

    if (participantMedLogDailyCache.has(cacheKey)) {
      const medLogsCached = participantMedLogDailyCache.get(cacheKey)!!;
      return { medLogsMap: medLogsCached, hasError: false };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANT_MED_LOGGING_DAILY_COUNT
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        /** Week Start date is in ISO 8601 format YYYY-MM-DD */
        const medLogDate =
          row["participant_medication_logs_daily_count.event_date"];
        const medLogCount =
          row["participant_medication_logs_daily_count.medication_log_count"];
        medLogsMap.set(medLogDate, medLogCount);
      }

      // Put results in cache
      participantMedLogDailyCache.set(cacheKey, medLogsMap);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { medLogsMap: medLogsMap, hasError: hasError };
}
