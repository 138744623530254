import React, { useCallback, useRef, useState } from "react";
import { Typography, Alert, Box } from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import {
  DeleteStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { RpcError, StatusCode } from "grpc-web";
import { disableButtonTimeout } from "styles/Time";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import { ParseIdFromName } from "common/ResourceName";
import { A11yLineBreak } from "components/A11yLineBreak";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";

interface RemoveStudyParticipantDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The confirmation dialog for deleting a study participant
const RemoveStudyParticipantDialog: React.FC<
  RemoveStudyParticipantDialogProps
> = ({ open, studyParticipant, onClose, onSuccess, onError }) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const handleClickDeleteButton = useCallback(async () => {
    if (updateReasonFormRef.current!.validateInput(true)) {
      setDeleteLoading(true);

      if (!isAuthenticated || !auth0Config) {
        setDeleteLoading(false);
        onError("User not authenticated. Please logout and login again.");
        return;
      }

      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        // Set up API clients
        const studyParticipantServiceClient =
          new StudyParticipantServiceApiClient(auth0Config.audience!, token);
        const participantSessionServiceClient =
          new ParticipantSessionServiceApiClient(auth0Config.audience!, token);

        try {
          // Delete Participant Session and end PBr Device Session
          const studyParticipantId = ParseIdFromName(
            studyParticipant.getName()
          );
          await participantSessionServiceClient.deleteParticipantSession(
            new DeleteStudyParticipantRequest()
              .setName(`participantSessions/${studyParticipantId}`)
              .setUpdateReason(updateReasonFormRef.current!.getUpdateReason())
          );
        } catch (error) {
          if (
            error instanceof RpcError &&
            error.code === StatusCode.NOT_FOUND
          ) {
            // continue with execution
          } else {
            throw error;
          }
        }

        // Delete Study Participant and foreign key dependencies
        await studyParticipantServiceClient.deleteStudyParticipant(
          new DeleteStudyParticipantRequest().setName(
            studyParticipant.getName()
          )
        );

        onSuccess();
      } catch (error) {
        console.log("error reason %s", error);
        let errMsg = "Something went wrong on our end. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User not authorized to remove the participant.";
        }

        onError(errMsg);
      } finally {
        setTimeout(() => {
          setDeleteLoading(false);
        }, disableButtonTimeout);
      }
    }
  }, [
    isAuthenticated,
    auth0Config,
    getAccessTokenSilently,
    studyParticipant,
    onSuccess,
    onError,
  ]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">Remove participant?</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Box sx={{ marginBottom: "16px" }}>
          <Alert severity="error">You can’t undo this action.</Alert>
        </Box>
        <Box sx={{ marginBottom: "16px" }}>
          <Typography>
            <b>
              This participant's compliance data will no longer be displayed.
            </b>
            <A11yLineBreak />
            If the participant requests their data to be deleted, please contact
            the study sponsor for next steps.
          </Typography>
        </Box>
        <UpdateReasonForm
          ref={updateReasonFormRef}
          predefinedReasons={["Error correction", "Other"]}
          reasonSelectionLabel="Reason for removing participant (required)"
          reasonRequiredMessage="Reason for removing participant required"
          otherReasonAllowed={true}
        />
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          color="destructive"
          onClick={handleClickDeleteButton}
          loading={deleteLoading}
        >
          Remove participant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveStudyParticipantDialog;
