import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { dialog_border_radius } from "styles/Dimensions";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import SettingSummary from "./SettingSummary";
import { updateComplianceSetting } from "./UpdateComplianceSetting";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import { UpdateStudyRequest } from "generated/studydata/studydata_pb";
import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ConfirmComplianceSettingDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  registryId: string;
  setting: ComplianceSetting;
  hasExistingSetting: boolean;
  onClose(): void;
  onSuccess(): void;
}

// The confirmation dialog for editing compliance setting for a study.
const ConfirmComplianceSettingDialog: React.FC<
  ConfirmComplianceSettingDialogProps
> = ({ open, registryId, setting, hasExistingSetting, onClose, onSuccess }) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const handleClickSubmitButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showSaveComplianceSettingError) {
            throw new Error("failed to save compliance setting");
          }

          if (
            hasExistingSetting &&
            !updateReasonFormRef.current?.validateInput(true /* focusOnError */)
          ) {
            return;
          }

          setDisableSubmitButton(true);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          await updateComplianceSetting(
            auth0Config.audience!,
            token,
            registryId,
            setting,
            hasExistingSetting,
            hasExistingSetting
              ? updateReasonFormRef.current?.getUpdateReason()!
              : ""
          );

          // Update Looker user attributes so that CDM can visit monitoring compliance screen
          // without going back to studies screen to see correct compliance metrics.
          const updateStudyRequest = new UpdateStudyRequest()
            .setRegistryId(registryId)
            .setComplianceSetting(setting);

          const studyDataClient = new StudyDataServiceApiClient(
            auth0Config.audience!,
            token
          );

          await studyDataClient.updateStudy(updateStudyRequest);

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setShowErrorDialog(true);
        }

        setDisableSubmitButton(false);
      })();
    } else {
      setShowErrorDialog(true);
    }
  }, [
    auth0Config,
    getAccessTokenSilently,
    hasExistingSetting,
    isAuthenticated,
    onSuccess,
    registryId,
    setting,
    testConfig.showSaveComplianceSettingError,
  ]);

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setShowErrorDialog(false);
      setDisableSubmitButton(false);
    }
  }, [open]);

  const dialogWidth = useMemo(() => {
    return showErrorDialog ? "448px !important" : "648px !important";
  }, [showErrorDialog]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: dialogWidth,
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "16px" }}
      >
        <Typography variant="display6">
          {showErrorDialog
            ? "Publishing failed"
            : "Confirm compliance parameters"}
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {showErrorDialog && (
          <Typography variant="body1">
            There was a problem publishing your compliance parameters. Please
            try again.
          </Typography>
        )}
        {!showErrorDialog && (
          <>
            <Typography variant="body1">
              Confirm these are the correct parameters before publishing.
              Publishing will update these parameters for everyone in this
              study.
            </Typography>
            <Divider
              sx={{ marginTop: "16px", backgroundColor: "rgb(128, 128, 128)" }}
            />
            <SettingSummary
              complianceSetting={setting}
              showCompactMode={true}
            />
            {hasExistingSetting && (
              <Box sx={{ marginTop: "8px" }}>
                <UpdateReasonForm
                  ref={updateReasonFormRef}
                  predefinedReasons={["Error correction"]}
                  reasonSelectionLabel="Reason for change (required)"
                  reasonRequiredMessage="Reason for change required"
                  otherReasonHint="Please provide reason for change"
                  otherReasonRequiredMessage="Other reason for change required"
                />
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          autoFocus
          disabled={disableSubmitButton}
          loading={disableSubmitButton}
          onClick={handleClickSubmitButton}
        >
          {showErrorDialog ? "Try again" : "Publish"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmComplianceSettingDialog;
