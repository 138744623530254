import React, { useEffect, useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { dialog_border_radius } from "styles/Dimensions";

interface InfoSectionProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  buttonText: string;
  isButtonDisabled?: boolean;
  onClick: () => void;
  btnFocus: boolean;
  btnAriaLabel?: string;
  value?: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  icon,
  children,
  buttonText,
  isButtonDisabled,
  onClick,
  btnFocus,
  btnAriaLabel,
  value,
}) => {
  const theme = useTheme();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (btnFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [btnFocus]);

  return (
    <Box
      marginBottom="24px"
      padding="24px"
      sx={{
        alignSelf: "center",
        borderRadius: dialog_border_radius,
        background: theme.palette.background.canvas,
        width: "680px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "inherit",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "inherit", paddingBottom: "0px" }}>
            <Box>{icon}</Box>
            <Box paddingLeft="16px">
              <Typography
                component="h2"
                variant="display6"
                aria-label={`${title}${value !== undefined ? ` ${value}` : ""}`}
              >
                {title}
                <Typography
                  paragraph={false}
                  variant="body1"
                  fontFamily="Roboto Mono"
                >
                  {value !== undefined && (
                    <React.Fragment>
                      <br />
                    </React.Fragment>
                  )}
                  {value}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              ref={buttonRef}
              variant="outlined"
              disabled={isButtonDisabled || false}
              onClick={onClick}
              aria-label={btnAriaLabel}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
        {children !== undefined && <Box paddingLeft="40px">{children}</Box>}
      </Box>
    </Box>
  );
};

export default InfoSection;
