import { Looker40SDK } from "@looker/sdk";
import {
  WearTimeRange,
  getParticipantMonthCacheKey,
  participantWearTimeRangesCache,
} from "./CalendarViewQueryResultsCache";
import { parseISO } from "date-fns";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get a participant wear time ranges for a given month.
 *
 * The function assumes that the Looker user attributes for participant id,
 * month start date and month end date are set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantWearTimeRanges(
  sdk: Looker40SDK,
  studyParticipantId: string,
  monthStartDate: Date,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let wearTimeRangesMap = new Map<string, Array<WearTimeRange>>();
  let hasError = false;

  try {
    const cacheKey = getParticipantMonthCacheKey(
      studyParticipantId,
      monthStartDate
    );

    if (participantWearTimeRangesCache.has(cacheKey)) {
      const wearTimeRangesCached =
        participantWearTimeRangesCache.get(cacheKey)!;
      return { wearTimeRangesMap: wearTimeRangesCached, hasError: false };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANT_WEAR_TIME_RANGES
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        /** Wear date is in ISO 8601 format YYYY-MM-DD */
        const wearDate = row["participant_wear_time_ranges.event_date"];

        /**
         * Wear time range start time and end time are in ISO 8601 format.
         * For example: 2024-02-12T16:30:50.
         */
        const wearTimeRange = {
          startTime: parseISO(row["participant_wear_time_ranges.start_time"]),
          endTime: parseISO(row["participant_wear_time_ranges.end_time"]),
        };
        if (wearTimeRangesMap.has(wearDate)) {
          wearTimeRangesMap.get(wearDate)!.push(wearTimeRange);
        } else {
          const timeRanges: Array<WearTimeRange> = [wearTimeRange];
          wearTimeRangesMap.set(wearDate, timeRanges);
        }
      }

      // Put results in cache.
      participantWearTimeRangesCache.set(cacheKey, wearTimeRangesMap);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { wearTimeRangesMap: wearTimeRangesMap, hasError: hasError };
}
