import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Radio, Typography, useTheme } from "@mui/material";
import {
  card_border_radius,
  card_padding,
  compliance_setting_card_min_width,
} from "styles/Dimensions";
import { NumberInput } from "./NumberInput";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { sxFocusWithin } from "common/Accessibility";

const medsLoggingMaxDailyDose = 5;
const medsLoggingMaxWeeklyDose = 35;

type MedsLoggingOption = "NotApplicable" | "DosesPerDay" | "DosesPerWeek";

// Define a component to set meds logging compliance parameters
interface MedsLoggingSettingCardProps {
  initValue: ComplianceSetting | null;
}

// Defines an interface for the functions exposed from MedsLoggingSettingCard
export interface MedsLoggingSettingCardRef {
  validateInput: () => boolean;
  getDosesPerDay: () => number;
  getDosesPerWeek: () => number;
}

export const MedsLoggingSettingCard = forwardRef<
  MedsLoggingSettingCardRef,
  MedsLoggingSettingCardProps
>(({ initValue }, ref) => {
  const [dosesPerDay, setDosesPerDay] = useState(0);
  const [dosesPerWeek, setDosesPerWeek] = useState(0);
  const [showDosesPerDayError, setShowDosesPerDayError] = useState(false);
  const [showDosesPerWeekError, setShowDosesPerWeekError] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<MedsLoggingOption>("NotApplicable");

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (initValue) {
      setDosesPerDay(initValue.getMedsLoggedPerDay());
      setDosesPerWeek(initValue.getMedsLoggedPerWeek());
      if (initValue.getMedsLoggedPerDay() > 0) {
        setSelectedOption("DosesPerDay");
      } else if (initValue.getMedsLoggedPerWeek() > 0) {
        setSelectedOption("DosesPerWeek");
      } else {
        setSelectedOption("NotApplicable");
      }
    } else {
      setDosesPerDay(0);
      setDosesPerWeek(0);
      setSelectedOption("NotApplicable");
    }

    setShowDosesPerDayError(false);
    setShowDosesPerWeekError(false);
  }, [initValue]);

  const handleMedsLoggingOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selected = event.target.value as MedsLoggingOption;
    setSelectedOption(selected);

    if (selected !== "DosesPerWeek") {
      setDosesPerWeek(0);
      setShowDosesPerWeekError(false);
    }

    if (selected !== "DosesPerDay") {
      setDosesPerDay(0);
      setShowDosesPerDayError(false);
    }
  };

  const validateMedsLogging = useCallback(() => {
    if (selectedOption === "DosesPerDay") {
      const isValid = dosesPerDay > 0 && dosesPerDay <= medsLoggingMaxDailyDose;
      setShowDosesPerDayError(!isValid);
      return isValid;
    }

    if (selectedOption === "DosesPerWeek") {
      const isValid =
        dosesPerWeek > 0 && dosesPerWeek <= medsLoggingMaxWeeklyDose;
      setShowDosesPerWeekError(!isValid);
      return isValid;
    }

    return true;
  }, [dosesPerDay, dosesPerWeek, selectedOption]);

  const validateInput = useCallback(() => {
    return validateMedsLogging();
  }, [validateMedsLogging]);

  const getDosesPerDay = useCallback(() => {
    return selectedOption === "DosesPerDay" ? dosesPerDay : 0;
  }, [dosesPerDay, selectedOption]);

  const getDosesPerWeek = useCallback(() => {
    return selectedOption === "DosesPerWeek" ? dosesPerWeek : 0;
  }, [dosesPerWeek, selectedOption]);

  // Expose functions to parent components.
  useImperativeHandle(
    ref,
    () => ({
      validateInput,
      getDosesPerDay,
      getDosesPerWeek,
    }),
    [getDosesPerDay, getDosesPerWeek, validateInput]
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        minWidth: compliance_setting_card_min_width,
        marginTop: "24px",
        padding: card_padding,
        borderRadius: card_border_radius,
        background: theme.palette.background.canvas,
      }}
    >
      <Typography component="h2" variant="display6" fontWeight="600">
        Medication logging (optional)
      </Typography>
      <Typography variant="caption" sx={{ margin: "8px 0px" }}>
        Select one of the three options below.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "top",
          marginTop: "8px",
        }}
      >
        <Radio
          checked={selectedOption === "NotApplicable"}
          onChange={handleMedsLoggingOptionChange}
          value="NotApplicable"
          name="logging-options"
          inputProps={{ "aria-label": "Not applicable" }}
          sx={{ marginTop: "8px", marginRight: "2px", ...sxFocusWithin }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          Not applicable
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "top",
          marginTop: "24px",
        }}
      >
        <Box>
          <Radio
            checked={selectedOption === "DosesPerDay"}
            onChange={handleMedsLoggingOptionChange}
            value="DosesPerDay"
            name="logging-options"
            inputProps={{ "aria-label": "Medication logged per day" }}
            sx={{ marginTop: "8px", marginRight: "2px", ...sxFocusWithin }}
          />
        </Box>
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <NumberInput
          id="dosesPerDay"
          value={dosesPerDay}
          label="Number of"
          placeholderText="##"
          helperText={
            showDosesPerDayError
              ? `Enter a number between 1 to ${medsLoggingMaxDailyDose}`
              : `Between 1 to ${medsLoggingMaxDailyDose}`
          }
          showError={showDosesPerDayError}
          onChange={(newValue) => {
            setShowDosesPerDayError(false);
            setDosesPerDay(newValue);
          }}
          onMouseLeave={() => {
            validateMedsLogging();
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          doses of medication logged per day
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "top",
          marginTop: "16px",
          marginBottom: "12px",
        }}
      >
        <Box>
          <Radio
            checked={selectedOption === "DosesPerWeek"}
            onChange={handleMedsLoggingOptionChange}
            value="DosesPerWeek"
            name="logging-options"
            inputProps={{ "aria-label": "Medication logged per week" }}
            sx={{ marginTop: "8px", marginRight: "2px", ...sxFocusWithin }}
          />
        </Box>
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <NumberInput
          id="dosesPerWeek"
          value={dosesPerWeek}
          label="Number of"
          placeholderText="##"
          helperText={
            showDosesPerWeekError
              ? `Enter a number between 1 to ${medsLoggingMaxWeeklyDose}`
              : `Between 1 to ${medsLoggingMaxWeeklyDose}`
          }
          showError={showDosesPerWeekError}
          onChange={(newValue) => {
            setShowDosesPerWeekError(false);
            setDosesPerWeek(newValue);
          }}
          onMouseLeave={() => {
            validateMedsLogging();
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          doses of medication logged per week
        </Typography>
      </Box>
    </Box>
  );
});
