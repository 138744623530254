import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { format } from "date-fns";
import { useRef } from "react";
import {
  CalendarDay,
  earliestSyncDate,
  tableBorderWidth,
} from "./CalendarTypes";

export enum GreyBarType {
  NONE,
  NO_DATA,
  AWAITING_DATA,
}

function getNoDataBarTitle(type: GreyBarType) {
  return type === GreyBarType.NO_DATA ? "No watch activity" : "Waiting to sync";
}

function getNoDataBarDetail(
  type: GreyBarType,
  latestSync: Date,
  isSingleDay: boolean
) {
  if (type === GreyBarType.AWAITING_DATA) {
    const syncDate = format(latestSync, "M/d/yyyy");
    const earliestDate = format(earliestSyncDate, "M/d/yyyy");
    return syncDate === earliestDate
      ? "Participant hasn't synced their watch"
      : `Participant last synced their watch on ${syncDate}.`;
  } else {
    return `Participant has synced the watch, and there were no activities that took place on ${
      isSingleDay ? "this day" : "these days"
    }.`;
  }
}

/**
 * Returns grey bars which each overlay one or more days of the week.
 *
 * Contiguous days with "noData" are "returned together" as one bar with the
 * title, "No data". Contiguous days with "awaitingData" become a bar with the
 * title, "Waiting to sync". Bars never overlap.
 */
export function scanGreyBars(
  week: CalendarDay[],
  firstWatchStart: Date,
  exitStudyDate: Date | null,
  latestSync: Date,
  watchStartDateIndexes: Map<number, string>
): React.ReactNode {
  const mask: GreyBarType[] = week.map((day, i) => {
    if (day.date < firstWatchStart) {
      return GreyBarType.NONE;
    }

    if (exitStudyDate !== null && day.date >= exitStudyDate) {
      return GreyBarType.NONE;
    }

    // Do not show gray bar for watch start day.
    if (watchStartDateIndexes.has(i)) {
      return GreyBarType.NONE;
    }
    if (day.noData) {
      return GreyBarType.NO_DATA;
    }
    if (day.awaitingData) {
      return GreyBarType.AWAITING_DATA;
    }
    return GreyBarType.NONE;
  });
  const reduced = mask.reduce((bars, type, i) => {
    const last: GreyBarBaseProps | undefined = bars[bars.length - 1];
    if (last?.type === type) {
      last.length += 1;
    } else {
      bars.push({
        type,
        length: 1,
        offset: i,
      });
    }
    return bars;
  }, [] as GreyBarBaseProps[]);
  const filtered = reduced.filter((props) => props.type !== GreyBarType.NONE);
  const mapped: GreyBarProps[] = filtered.map((props) => {
    return {
      ...props,
      title: getNoDataBarTitle(props.type),
      tooltipDetail: getNoDataBarDetail(
        props.type,
        latestSync,
        props.length === 1
      ),
    };
  });
  return mapped.map((props, i) => <GreyBar key={i} {...props} />);
}

interface GreyBarBaseProps {
  type: GreyBarType;
  /** The number of "No data" days. */
  length: number;
  /** The number of days before the start of */
  offset: number;
}

interface GreyBarProps extends GreyBarBaseProps {
  title: string;
  tooltipDetail: string;
}

export const GreyBar: React.FC<GreyBarProps> = ({
  title,
  tooltipDetail,
  length,
  offset,
}) => {
  const theme = useTheme();
  return (
    <td>
      <Tooltip
        arial-hidden="true"
        title={
          <Stack>
            <Typography color="white" variant="captionem">
              {title}
            </Typography>
            <Typography color="white" variant="caption">
              {tooltipDetail}
            </Typography>
          </Stack>
        }
        slotProps={{
          tooltip: {
            sx: { backgroundColor: theme.palette.background.contrast },
          },
        }}
      >
        <Box
          arial-hidden="true"
          position="absolute"
          bottom="8px"
          left={`calc(100% * ${offset}/7 + ${tableBorderWidth}px)`}
          width={`calc(100% * ${length}/7 - ${2 * tableBorderWidth}px - 8px)`}
          borderRadius="4px"
          bgcolor={theme.palette.background.canvas}
          sx={{
            paddingY: "4px",
            "&:hover": {
              boxShadow: theme.shadows,
              border: "2px solid",
              borderColor: theme.palette.neutral.divider,
            },
          }}
        >
          <Typography fontSize={12} sx={{ marginLeft: "12px" }}>
            {title}
          </Typography>
        </Box>
      </Tooltip>
    </td>
  );
};

interface NoDataBarProps {
  type: GreyBarType;
  latestSync: Date;
  showFocus: boolean;
  showTooltip: boolean;
}

// NoDataBar in each table cell.
// It's used for a11y support to allow keyboard users to focus on it and trigger tooltip
// using arrow keys.
export const NoDataBar: React.FC<NoDataBarProps> = ({
  type,
  latestSync,
  showFocus,
  showTooltip,
}) => {
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (boxRef.current != null) {
      if (showFocus) {
        boxRef.current.focus();
      } else {
        boxRef.current.blur();
      }
    }
  }, [showFocus]);

  const theme = useTheme();
  const title = getNoDataBarTitle(type);
  return (
    <Tooltip
      open={showTooltip}
      aria-live="polite"
      title={
        <Stack>
          <Typography color="white" variant="captionem">
            {title}
          </Typography>
          <Typography color="white" variant="caption">
            {getNoDataBarDetail(type, latestSync, true)}
          </Typography>
        </Stack>
      }
      slotProps={{
        tooltip: {
          sx: { backgroundColor: theme.palette.background.contrast },
        },
      }}
    >
      <Box
        ref={boxRef}
        tabIndex={showFocus ? 0 : -1}
        width="100%"
        borderRadius="4px"
        bgcolor={theme.palette.background.canvas}
        sx={{
          paddingY: "4px",
          "&:hover": {
            boxShadow: theme.shadows,
            border: "2px solid",
            borderColor: theme.palette.neutral.divider,
          },
        }}
      >
        <Typography fontSize={12} sx={{ marginLeft: "12px" }}>
          {title}
        </Typography>
      </Box>
    </Tooltip>
  );
};
