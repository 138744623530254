import React, { useEffect, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { StudyDetail } from "generated/studydetail/studydetail_pb";

interface VerilyCDMCardProps {
  studyDetail: StudyDetail;
  showCompactMode: boolean;
  focusOnEditButton: boolean;
  onEditButtonClick: () => void;
}

const VerilyCDMCard: React.FC<VerilyCDMCardProps> = ({
  studyDetail,
  showCompactMode,
  focusOnEditButton,
  onEditButtonClick: onEditDetailButtonClick,
}) => {
  const fontVariant = showCompactMode ? "body2" : "body1";
  const cellPadding = showCompactMode ? "16px" : "24px";
  const tableWidth = showCompactMode ? 552 : 752;

  const ParamNameCellSxProp = {
    verticalAlign: "top",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: cellPadding,
    paddingBottom: cellPadding,
  };

  const ParamCaptionCellSxProp = {
    verticalAlign: "center",
    paddingLeft: "0px",
    paddingTop: "8px",
  };

  const editStudyDetailButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnEditButton && editStudyDetailButtonRef.current) {
      editStudyDetailButtonRef.current.focus();
    }
  }, [focusOnEditButton]);

  return (
    <Box
      sx={{
        alignItems: "center",
        width: "100%",
      }}
    >
      <Table sx={{ minWidth: tableWidth }} aria-label="CDM ">
        <TableBody>
          {/* Show the study Name */}
          <TableRow>
            <TableCell width="100%" sx={ParamNameCellSxProp}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography component="h2" variant="display6" fontWeight="600">
                  Verily CDM contact person
                </Typography>
                <Button
                  variant="filled"
                  label="Edit"
                  ref={editStudyDetailButtonRef}
                  onClick={onEditDetailButtonClick}
                />
              </Box>
              <Typography sx={ParamCaptionCellSxProp} variant="caption">
                if other portal users need user or site management, they'll
                contact the CDM contact person using the email shown here.
              </Typography>
            </TableCell>
          </TableRow>
          {/* Show sync time compliance parameter */}
          <TableRow>
            <TableCell sx={ParamNameCellSxProp}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  width="150px"
                  component="h3"
                  variant={fontVariant}
                  fontWeight="600"
                >
                  Email
                </Typography>
                {studyDetail.getVerilyCdm().length > 0 && (
                  <Typography variant={fontVariant}>
                    {studyDetail.getVerilyCdm()}
                  </Typography>
                )}
                {studyDetail.getVerilyCdm().length === 0 && (
                  <Typography variant={fontVariant}>
                    Click <b>Edit</b> to set email
                  </Typography>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default VerilyCDMCard;
