import React, { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import AdminSidebar from "./AdminSidebar";
import { Outlet, useNavigate } from "react-router-dom";
import ErrorScreen from "components/Error";
import { AppRoutes } from "core/AppRoutes";
import { Box } from "@mui/material";
import HeaderNav from "core/HeaderNav";

/**
 * Main view of the admin UI after user is configed.
 * Otherwise, it shows a loading screen.
 */
const AdminMain: React.FC = () => {
  const appState = useAppSelector((state) => state.appState);
  const userConfig = useAppSelector((state) => state.userConfig);
  const navigate = useNavigate();

  useEffect(() => {
    if (!appState.userConfigured) {
      navigate(AppRoutes.ROOT);
    }
  }, [appState.userConfigured, navigate]);

  if (!appState.userConfigured) {
    return <></>;
  }

  if (!userConfig.isSuperAdmin) {
    return <ErrorScreen message={"Only super admin can access this screen."} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <HeaderNav />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <AdminSidebar />
        <Box
          role="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflowY: "auto",
            backgroundColor: "#ffffff",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminMain;
