import { StudyDataServiceApiClient } from "apiclient/StudyDataServiceApiClient";
import { toDateProto } from "common/Dates";
import { UpdateParticipantReportTimePeriodRequest } from "generated/studydata/studydata_pb";
import {
  defaultMonthStartDate,
  getMonthStartDateCache,
  setMonthStartDateCache,
} from "./SettingsCache";
import { formatISO } from "date-fns";

export async function updateParticipantReportTimePeriod(
  apiEndpoint: string,
  auth0AccessToken: string,
  calendarMonthFirstWeekStartDate: Date,
  calendarMonthLastWeekEndDate: Date,
  monthStartDate: Date
) {
  const monthStartDateString = formatISO(monthStartDate, {
    representation: "date",
  });

  // Bypass the update if we already set the time period for the month.
  if (getMonthStartDateCache() === monthStartDateString) {
    return;
  }

  const studyDataClient = new StudyDataServiceApiClient(
    apiEndpoint,
    auth0AccessToken
  );

  try {
    await studyDataClient.updateParticipantReportTimePeriod(
      new UpdateParticipantReportTimePeriodRequest()
        .setMonthStartDate(toDateProto(calendarMonthFirstWeekStartDate))
        .setMonthEndDate(toDateProto(calendarMonthLastWeekEndDate))
    );
  } catch (error) {
    // There is an error. We don't know whether the report time period is
    // updated or now. So reset the cached value to allow any update to
    // go through.
    setMonthStartDateCache(defaultMonthStartDate);
    throw error;
  }

  setMonthStartDateCache(monthStartDateString);
}
