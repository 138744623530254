import { LRUCache } from "lru-cache";

/**
 * Sets up cache options to cache 3-week data. We use previous week offset as cache key.
 */
const cacheOptions = {
  max: 3,
};

export interface ParticipantRecord {
  participantId: string;
  subjectId: string;
  deviceId: string;
  siteId: string;
}

export interface ParticipantComplianceRecord extends ParticipantRecord {
  isCompliant: boolean;
}

/**
 * Cache for enrolled participants.
 */
export const participantsCache = new LRUCache<number, ParticipantRecord[]>(
  cacheOptions
);

/**
 * Cache for participants wear compliance records.
 */
export const participantsWearComplianceCache = new LRUCache<
  number,
  ParticipantComplianceRecord[]
>(cacheOptions);

/**
 * Cache for participants sync compliance records.
 */
export const participantsSyncComplianceCache = new LRUCache<
  number,
  ParticipantComplianceRecord[]
>(cacheOptions);

/**
 * Cache for participants medication log daily compliance records.
 */
export const participantsMedLogDailyComplianceCache = new LRUCache<
  number,
  ParticipantComplianceRecord[]
>(cacheOptions);

/**
 * Cache for participants medication log weekly compliance records.
 */
export const participantsMedLogWeeklyComplianceCache = new LRUCache<
  number,
  ParticipantComplianceRecord[]
>(cacheOptions);

export enum ComplianceType {
  Wear,
  Sync,
  MedLogDaily,
  MedLogWeekly,
}

export enum NonCompliantReason {
  /**
   * Define an unknown state to indicate the CRC non-compliant detail screen is
   * not displayed in CrcNonCompliantDetailState.
   */
  UNKNOWN,

  /**
   * Several requirements unmet.
   * Only used to display the non-compliant summary screen.
   */
  Multiple,

  /** Wear time not compliant */
  WearTime,

  /** Sync time not compliant */
  SyncTime,

  /** Medication Log not compliant */
  MedLog,
}

export const NonComplianceMap: Map<ComplianceType, NonCompliantReason> =
  new Map([
    [ComplianceType.Wear, NonCompliantReason.WearTime],
    [ComplianceType.Sync, NonCompliantReason.SyncTime],
    [ComplianceType.MedLogDaily, NonCompliantReason.MedLog],
    [ComplianceType.MedLogWeekly, NonCompliantReason.MedLog],
  ]);

export interface NonCompliantRecord {
  participantId: string;
  subjectId: string;
  deviceId: string;
  siteId: string;
  reasons: Set<NonCompliantReason>;
}

/**
 * Clears all the cached query results.
 */
export function clearLookerQueryResultsCache() {
  participantsCache.clear();
  participantsWearComplianceCache.clear();
  participantsSyncComplianceCache.clear();
  participantsMedLogDailyComplianceCache.clear();
  participantsMedLogWeeklyComplianceCache.clear();
}
