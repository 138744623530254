/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.studydata.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: studydata/studydata.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var compliancesetting_compliancesetting_pb = require('../compliancesetting/compliancesetting_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.studydata = {};
proto.verily.sdp.studydata.v1alpha1 = require('./studydata_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse>}
 */
const methodDescriptor_StudyDataService_AuthorizeUser = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/AuthorizeUser',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest,
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.authorizeUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/AuthorizeUser',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_AuthorizeUser,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.AuthorizeUserResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.authorizeUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/AuthorizeUser',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_AuthorizeUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse>}
 */
const methodDescriptor_StudyDataService_UpdateStudy = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateStudy',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest,
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.updateStudy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateStudy',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateStudy,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateStudyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.UpdateStudyResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.updateStudy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateStudy',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateStudy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse>}
 */
const methodDescriptor_StudyDataService_UpdateSite = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateSite',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest,
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.updateSite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateSite',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateSite,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateSiteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.UpdateSiteResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.updateSite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateSite',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateSite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse>}
 */
const methodDescriptor_StudyDataService_UpdateParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest,
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.updateParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.updateParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse>}
 */
const methodDescriptor_StudyDataService_UpdateReportTimePeriod = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateReportTimePeriod',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest,
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.updateReportTimePeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateReportTimePeriod',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateReportTimePeriod,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.UpdateReportTimePeriodResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.updateReportTimePeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateReportTimePeriod',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateReportTimePeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse>}
 */
const methodDescriptor_StudyDataService_UpdateParticipantReportTimePeriod = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipantReportTimePeriod',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest,
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.updateParticipantReportTimePeriod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipantReportTimePeriod',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateParticipantReportTimePeriod,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.UpdateParticipantReportTimePeriodResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.updateParticipantReportTimePeriod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/UpdateParticipantReportTimePeriod',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_UpdateParticipantReportTimePeriod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest,
 *   !proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse>}
 */
const methodDescriptor_StudyDataService_ListDashboards = new grpc.web.MethodDescriptor(
  '/verily.sdp.studydata.v1alpha1.StudyDataService/ListDashboards',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest,
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse,
  /**
   * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServiceClient.prototype.listDashboards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/ListDashboards',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_ListDashboards,
      callback);
};


/**
 * @param {!proto.verily.sdp.studydata.v1alpha1.ListDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studydata.v1alpha1.ListDashboardsResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studydata.v1alpha1.StudyDataServicePromiseClient.prototype.listDashboards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studydata.v1alpha1.StudyDataService/ListDashboards',
      request,
      metadata || {},
      methodDescriptor_StudyDataService_ListDashboards);
};


module.exports = proto.verily.sdp.studydata.v1alpha1;

