import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import { StudySite } from "generated/studysite/studysite_pb";

// Defines a custom effect to load data for the study site screen.
function useStudySitesDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [studySites, setStudySites] = useState(new Array<StudySite>());
  const [nonDeleteableStudySites, setNonDeletableStudySties] = useState(
    new Array<StudySite>()
  );

  const loadStudySites = useCallback(
    (registryId: string) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setIsLoading(true);
          setHasError(false);

          try {
            if (registryId === "") {
              throw new Error("missing selected study");
            }

            // load study sites
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const client = new StudySiteServiceApiClient(
              auth0Config.audience!,
              token
            );

            const response = await client.listStudySites(registryId);
            const sortedStudySites = response
              .getStudySitesList()
              .sort((a, b) => {
                return a.getSiteName().localeCompare(b.getSiteName());
              });

            setStudySites(sortedStudySites);

            const nonDeleteableStudySitesResponse =
              await client.listNonDeletableStudySites(registryId);
            setNonDeletableStudySties(
              nonDeleteableStudySitesResponse.getStudySitesList()
            );
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      }
    },
    [isAuthenticated, auth0Config, getAccessTokenSilently]
  );

  return {
    isLoading,
    hasError,
    studySites,
    nonDeleteableStudySites,
    loadStudySites,
  };
}

export default useStudySitesDataLoader;
