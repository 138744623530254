import { studyDetailsCache } from "./StudyDetailsCache";
import { StudyDetailServiceApiClient } from "apiclient/StudyDetailServiceApiClient";
import { RpcError, StatusCode } from "grpc-web";

export async function listStudyDetails(
  apiEndpoint: string,
  auth0AccessToken: string,
  registryIds: string[]
) {
  try {
    const client = new StudyDetailServiceApiClient(
      apiEndpoint,
      auth0AccessToken
    );

    const response = await client.listStudyDetails(registryIds);

    // Save records in the cache.
    response
      .getStudyDetailsList()
      .map((detail) =>
        studyDetailsCache.set(detail.getName().substring(13), detail)
      ); // studyDetails/ is the prefix.

    return response.getStudyDetailsList();
  } catch (error) {
    if (error instanceof RpcError && error.code === StatusCode.NOT_FOUND) {
      return null;
    } else {
      throw error;
    }
  }
}
