import React, { useMemo } from "react";
import { Box, SelectChangeEvent, useTheme } from "@mui/material";
import { SingleSelectDropdown } from "@verily-src/react-design-system";

const daysPerWeekSelection = [1, 2, 3, 4, 5, 6, 7];

interface DaysPerWeekSelectionProps {
  daysPerWeek: number | undefined;
  labelId: string;
  helperText: string;
  showError: boolean;
  onDaysPerWeekChange(newValue: number): void;
  onValidate(): void;
}

export const DaysPerWeekSelection: React.FC<DaysPerWeekSelectionProps> = ({
  daysPerWeek,
  labelId,
  helperText,
  showError,
  onDaysPerWeekChange,
  onValidate,
}) => {
  const handleDaysPerWeekChange = (event: SelectChangeEvent<unknown>) => {
    const selectedDays = parseInt(event.target.value as string);
    onDaysPerWeekChange(selectedDays);
  };

  const dropdownOptions = useMemo<Array<{ label: string; value: string }>>(
    () =>
      daysPerWeekSelection.map((numberOfDays) => ({
        label: numberOfDays.toString(),
        value: numberOfDays.toString(),
      })),
    []
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: "0px 8px",
      }}
    >
      <SingleSelectDropdown
        value={daysPerWeek ? daysPerWeek.toString() : ""}
        options={dropdownOptions}
        label="Number of"
        labelId={labelId}
        placeholder="#"
        helperText={helperText}
        error={showError}
        onChange={handleDaysPerWeekChange}
        onBlur={() => {
          // This happens when users click the select component
          // and then click away with a selection or not.
          onValidate();
        }}
        onMouseLeave={() => {
          // This happens when users mouse enter the select component
          // without clicking it and then mouse leave the component.
          onValidate();
        }}
        sx={{
          width: "240px",
          background: theme.palette.background.paper,
        }}
      />
    </Box>
  );
};
