import { ReactComponent as WatchIcon } from "assets/watch-icon.svg";
import { ReactComponent as SyncIcon } from "assets/sync-icon.svg";
import { ReactComponent as PollIcon } from "assets/poll-icon.svg";
import { ReactComponent as MedicationPillIcon } from "assets/medication-pill-icon.svg";
import {
  Box,
  Grid,
  Stack,
  TableCell,
  Tooltip,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { format, Duration, formatDuration } from "date-fns";

import { WearTimeRange } from "looker/CalendarViewQueryResultsCache";
import { useRef } from "react";
import { useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import {
  HighlightedIcon,
  failureBackgroundColor,
  successBackgroundColor,
} from "./HighlightedIcon";
import { MedicationTagSetting, tableCellStyle } from "./CalendarTypes";
import { getDateAriaLabel } from "./CalendarUtils";

interface CalendarCellProps {
  date: Date;
  highlight: boolean;
  grey: boolean;
  watchWearDuration?: Duration;
  watchWearCompliant?: boolean;
  watchWearTimeRanges?: Array<WearTimeRange>;
  watchSync?: boolean;
  syncTime?: Date;
  surveysPrompted?: number;
  surveysAnswered?: number;
  SurveySubmissionPercentage: number;
  checkMedicationTag?: boolean;
  medicationTags?: number;
  medicationTagSetting: MedicationTagSetting;
  weekIndex: number;
}

export const CalendarCell: React.FC<CalendarCellProps> = ({
  date,
  grey,
  highlight,
  watchWearDuration,
  watchWearCompliant,
  watchWearTimeRanges,
  watchSync,
  syncTime,
  surveysPrompted,
  surveysAnswered,
  SurveySubmissionPercentage,
  checkMedicationTag,
  medicationTags,
  medicationTagSetting,
  weekIndex,
}) => {
  const theme = useTheme();

  // The ref to the TableCell element.
  const cellRef = useRef<HTMLTableCellElement>(null);

  // handleKeyDown and handleClick are stable for the lifetime of the component:
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    cellRef,
    false,
    weekIndex
  );

  // Set focus on the TableCell when needed.
  useFocusEffect(focused, cellRef);

  const [showTooltip, setShowTooltip] = useState(false);

  // Index of icons that need to receive focus
  const [focusedIconIndex, setFocusedIconIndex] = useState<number | null>(null);

  let dayCircleColor: string | undefined;
  let dayNumberColor = theme.palette.text.emphasized;
  if (highlight) {
    dayCircleColor = theme.palette.primary.main;
    dayNumberColor = theme.palette.common.white;
  }
  if (grey) {
    if (dayCircleColor) {
      dayCircleColor = lighten(dayCircleColor, 0.5);
    }
    dayNumberColor = theme.palette.text.default;
  }

  let totalCompleted = 0;
  let totalRequirements = 0;
  const icons = [];
  const tooltipParagraphs = [];

  // Note that watchWearCompliant is always defined when watchWearDuration is defined.
  // watchWearTimeRanges could be empty if wear duration is 1 minute.
  if (watchWearDuration && watchWearCompliant !== undefined) {
    totalRequirements += 1;
    if (watchWearCompliant) {
      totalCompleted += 1;
    }

    icons.push(
      <HighlightedIcon
        key="watch-wear-icon"
        icon={<WatchIcon arial-hidden="true" />}
        success={watchWearCompliant}
        ariaLabel={
          watchWearCompliant
            ? "Watch wear requirement met"
            : "Watch wear requirement unmet"
        }
        showFocus={focusedIconIndex === icons.length}
      />
    );
    tooltipParagraphs.push(
      <Stack key="watch-wear-tooltip">
        <Typography color="white" variant="captionem">
          Watch wear
        </Typography>
        <Typography color="white" variant="caption">
          {formatDuration(watchWearDuration)}
        </Typography>
        {watchWearTimeRanges?.map((timeRange) => {
          return (
            <Typography color="white" variant="caption">
              {format(timeRange.startTime, "h:mmaaaaa'm'")} -{" "}
              {format(timeRange.endTime, "h:mmaaaaa'm'")}
            </Typography>
          );
        })}
      </Stack>
    );
  }
  if (watchSync) {
    totalRequirements += 1;
    totalCompleted += 1;
    icons.push(
      <HighlightedIcon
        key="watch-sync-icon"
        icon={<SyncIcon arial-hidden="true" />}
        success
        ariaLabel="Watch sync requirement met"
        showFocus={focusedIconIndex === icons.length}
      />
    );
    tooltipParagraphs.push(
      <Stack key="watch-sync-tooltip">
        <Typography color="white" variant="captionem">
          Watch sync
        </Typography>
        {syncTime && (
          <Typography color="white" variant="caption">
            Completed at {format(syncTime, "h:mma")}
          </Typography>
        )}
      </Stack>
    );
  }
  if (surveysPrompted || surveysAnswered) {
    // In practice, surveysAnswered <= surveysPrompted. But just in case
    // surveysAnswered > surveysPrompted, render "100% completion".
    const got = surveysAnswered || 0;
    totalCompleted += got;
    const want = surveysPrompted || got; // assert want > 0
    totalRequirements += want;
    const ratio = got / want;
    const percentageCompleted = Math.round(ratio * 100);
    const isSuccess = percentageCompleted >= SurveySubmissionPercentage;
    icons.push(
      <HighlightedIcon
        key="surveys-icon"
        icon={<PollIcon arial-hidden="true" />}
        success={isSuccess}
        ariaLabel={
          isSuccess ? "Survey requirement met" : "Survey requirement unmet"
        }
        showFocus={focusedIconIndex === icons.length}
      />
    );
    tooltipParagraphs.push(
      <Stack key="surveys-tooltip">
        <Typography color="white" variant="captionem">
          Survey
        </Typography>
        <Typography color="white" variant="caption">
          {percentageCompleted}% completed
        </Typography>
      </Stack>
    );
  }

  if (checkMedicationTag) {
    const medicationTagDailyCount = medicationTags ?? 0;
    const requiredCount =
      medicationTagSetting.frequency === "daily"
        ? medicationTagSetting.requiredCount
        : 1;
    let isSuccess = false;

    if (medicationTagDailyCount >= requiredCount) {
      totalRequirements += 1;
      totalCompleted += 1;
      isSuccess = true;
    }

    // Display medication icon under the following conditions:
    // - show all success/fail icons for daily medication requirements
    // - show only success icons for weekly medication requirements only
    if (isSuccess || medicationTagSetting.frequency === "daily") {
      icons.push(
        <HighlightedIcon
          key="medication-icon"
          icon={<MedicationPillIcon arial-hidden="true" />}
          success={isSuccess}
          ariaLabel={
            isSuccess
              ? "Medication log requirement met"
              : "Medication log requirement unmet"
          }
          showFocus={focusedIconIndex === icons.length}
        />
      );
    }

    tooltipParagraphs.push(
      <Stack key="medication-tooltip">
        <Typography color="white" variant="captionem">
          Medication tag
        </Typography>
        <Typography color="white" variant="caption">
          {medicationTagDailyCount} of {medicationTagSetting.requiredCount}{" "}
          completed
        </Typography>
      </Stack>
    );
  }

  const tooltipTitle = tooltipParagraphs.length ? (
    <Stack spacing={1}>
      <Typography color="white" variant="body2">
        {format(date, "EEEE M/d/yyyy")}
      </Typography>
      {tooltipParagraphs}
    </Stack>
  ) : undefined;

  let backgroundColor: string | undefined;
  if (totalRequirements > 0) {
    if (totalRequirements > totalCompleted) {
      backgroundColor = failureBackgroundColor;
    } else {
      backgroundColor = successBackgroundColor;
    }
  }

  const topAlignAndHug = {
    popper: {
      modifiers: [
        {
          name: "offset",
          options: {
            // These values seem to ensure the tooltip touches its source
            // element at all browser zoom levels. They are partially borrowed
            // from the example:
            // https://mui.com/material-ui/react-tooltip/#distance-from-anchor
            offset: [-2, -14],
          },
        },
      ],
    },
    tooltip: {
      sx: { backgroundColor: theme.palette.background.contrast },
    },
  };

  // Handle navigation between icons in the table cell.
  useEffect(() => {
    setFocusedIconIndex(null);
  }, [date, focused]);

  // Returns whether the keyboard event is handled and the focus should still be
  // inside the table cell.
  const handleIconNavigation = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "ArrowLeft") {
      setShowTooltip(false);
      if (focusedIconIndex === null) {
        return false;
      }

      const nextIndex = focusedIconIndex - 1;
      if (nextIndex < 0) {
        return false;
      }
      setFocusedIconIndex(nextIndex);
      return true;
    }

    if (event.key === "ArrowRight") {
      if (icons.length === 0) {
        return false;
      }

      if (focusedIconIndex === null) {
        // Focus on the first icon when ArrowRight key is first pressed in the table cell.
        setFocusedIconIndex(0);
        return true;
      }

      const nextIndex = focusedIconIndex + 1;
      // Handle the case when ArrayRight key is pressed when focused on the last icon.
      if (nextIndex > icons.length - 1) {
        if (showTooltip) {
          // If tooltip already displayed, hide it.
          setShowTooltip(false);
          return false;
        } else {
          // Otherwise show tooltip.
          setShowTooltip(true);
          return true;
        }
      }

      setFocusedIconIndex(nextIndex);
      return true;
    }

    if (showTooltip) {
      setShowTooltip(false);
    }

    return false;
  };

  return (
    <TableCell
      role="gridcell"
      ref={cellRef}
      tabIndex={tabIndex}
      onKeyDown={(event) => {
        const handled = handleIconNavigation(event);
        if (handled) {
          return;
        }

        // Call roving-tabindex key down handler.
        handleKeyDown(event);
      }}
      onClick={handleClick}
      sx={{
        ...tableCellStyle,
        padding: "0px",
        backgroundColor: backgroundColor,
        borderColor: theme.palette.background.separator,
        "&:hover": {
          boxShadow: theme.shadows,
          border: "2px solid",
          borderColor: theme.palette.neutral.divider,
        },
      }}
    >
      <Tooltip
        open={showTooltip}
        aria-live="polite"
        title={tooltipTitle}
        placement="right-start"
        slotProps={topAlignAndHug}
      >
        <Stack
          width="100%"
          height="100%"
          minHeight="100px"
          justifyContent="space-between"
          sx={{ padding: "8px" }}
          onMouseEnter={() => {
            setShowTooltip(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          <Box
            alignSelf="end"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={theme.spacing(3)}
            height={theme.spacing(3)}
            borderRadius="100%"
            bgcolor={dayCircleColor}
            sx={{
              marginBottom: "16px",
            }}
          >
            <Typography
              aria-label={getDateAriaLabel(date)}
              lineHeight="100%"
              color={dayNumberColor}
              fontSize={12}
              variant={grey ? "overline" : "overlineem"}
            >
              {date.getDate()}
            </Typography>
          </Box>
          <Grid display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="8px">
            {icons}
          </Grid>
        </Stack>
      </Tooltip>
    </TableCell>
  );
};
