import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "core/AppRoutes";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem } from "@verily-src/react-design-system";
import { useEffect, useState } from "react";
import { Box, boxClasses, drawerClasses, paperClasses } from "@mui/material";
import { header_nav_height } from "styles/Dimensions";
import { useAppSelector } from "redux/hooks";

const AdminSidebar = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();

  const appConfig = useAppSelector((state) => state.appConfig);
  const [footerMenuItems, setFooterMenuItems] = useState(new Array<NavItem>());

  const navigateToUrl = (path: string) => {
    if (path === AppRoutes.LOGOUT) {
      logout({
        returnTo: window.location.origin,
      });
    } else {
      navigate(path);
    }
  };

  // Set up footer menu items.
  useEffect(() => {
    const menuItems = new Array<NavItem>();

    if (appConfig.environment !== "prod") {
      menuItems.push({
        name: "Testing",
        path: AppRoutes.ADMIN_TEST_TOOL,
        icon: <AutoAwesomeIcon />,
      });
    }

    setFooterMenuItems(menuItems);
  }, [user, appConfig.environment]);

  return (
    <Box
      tabIndex={0}
      sx={{
        [`& .${drawerClasses.paperAnchorLeft}.${paperClasses.root}.${drawerClasses.paper}`]:
          {
            top: header_nav_height,
            height: `calc(100% - ${header_nav_height})`,
          },
        [`& a[pendo-id="nav-verily-logo"]`]: {
          display: "none",
        },
        [`& .${boxClasses.root}:has(a[pendo-id="nav-verily-logo"])`]: {
          height: "4em",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Nav
        homePath={AppRoutes.ADMIN}
        homeLogo={<></>}
        navigateToUrl={navigateToUrl}
        navItems={[
          {
            name: "Users",
            path: AppRoutes.ADMIN,
            icon: <ManageAccountsIcon />,
          },
        ]}
        navItemGroups={[]}
        navFooterItems={footerMenuItems}
        showDividers={false}
      />
    </Box>
  );
};

export default AdminSidebar;
