import Box from "@mui/material/Box";
import { InvitationStatus, User, Role } from "generated/studyauth/studyauth_pb";

export function getUserName(user: User) {
  return user.getFirstName() + " " + user.getLastName();
}

export function getInvitationDisplayStatus(invitationStatus: number) {
  switch (invitationStatus) {
    case InvitationStatus.INVITE_FAILED: {
      return "Invite failed";
    }
    case InvitationStatus.INVITED: {
      return "Pending sign up";
    }
    case InvitationStatus.ACCEPTED: {
      return "Active";
    }
    default: {
      return "Unknown";
    }
  }
}

interface InvitationStatusViewProps {
  invitationStatus: InvitationStatus;
}

export const InvitationStatusView: React.FC<InvitationStatusViewProps> = ({
  invitationStatus,
}) => {
  return <Box>{getInvitationDisplayStatus(invitationStatus)}</Box>;
};

export function getUserIndex(users: Array<User>, userName: string) {
  for (let i = 0; i < users.length; i++) {
    if (users[i].getName() === userName) {
      return i;
    }
  }
  return -1;
}

export function restoreUserRecordPosition(
  users: Array<User>,
  userName: string,
  originalIndex: number
) {
  const index = getUserIndex(users, userName);
  if (index >= 0) {
    // Remove user from its current sorted position
    const removedUsers = users.splice(index, 1);

    // Add user to its original position.
    users.splice(originalIndex, 0, ...removedUsers);
  }
}

export function getUserRoleDescription(userRole: Role) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return (
        "Sponsor Ops monitor compliance across sites. " +
        "They can view sites within studies, and view internal and external users. They can't view PII."
      );
    case Role.CDM:
      return (
        "CDMs can build studies and manage compliance guidelines. " +
        "They can monitor compliance across sites. " +
        "They can also add and remove sites from studies, and manage internal and external users. " +
        "They can't view PII."
      );
    case Role.COMPLIANCE_ADMIN:
      return (
        "Compliance Admins can monitor compliance across sites. " +
        "They can view sites within studies, and view users. They can't view PII."
      );
    case Role.CRC:
      return (
        "Site Research Coordinators can monitor compliance, view other Research Coordinators, " +
        "and manage study participants within their sites. They can't view PII."
      );
    default:
      throw new Error("Unsupported role");
  }
}

export function getDevOpsRoleDescription() {
  return "Dev Ops can only manage internal users. They can't monitor compliance or view PII.";
}

export function getAddUserDialogTitle(userRole: Role) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return "Add Sponsor Ops (internal) user";
    case Role.CDM:
      return "Add CDM (internal) user";
    case Role.COMPLIANCE_ADMIN:
      return "Add Compliance Admin (external) user";
    case Role.CRC:
      return "Add Site Research Coordinator (external) user";
    default:
      throw new Error("Unsupported role");
  }
}

export function getEditUserDialogTitle(userRole: Role) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return "Edit Sponsor Ops (internal) user";
    case Role.CDM:
      return "Edit CDM (internal) user";
    case Role.COMPLIANCE_ADMIN:
      return "Edit Compliance Admin (external) user";
    case Role.CRC:
      return "Edit Site Research Coordinator (external) user";
    default:
      throw new Error("Unsupported role");
  }
}

export function getAddUserButtonAriaLabel(userRole: Role) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return "Add new Sponsor Ops user";
    case Role.CDM:
      return "Add new CDM user";
    case Role.COMPLIANCE_ADMIN:
      return "Add new Compliance Admin user";
    case Role.CRC:
      return "Add new Site Research Coordinator user";
    default:
      throw new Error("Unsupported role");
  }
}

export function getEditUserIconAriaLabel(userRole: Role, userName: string) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return `Edit Sponsor Ops ${userName} user info`;
    case Role.CDM:
      return `Edit CDM ${userName} user info`;
    case Role.COMPLIANCE_ADMIN:
      return `Edit Compliance Admin ${userName} user info`;
    case Role.CRC:
      return `Edit Site Research Coordinator ${userName} user info`;
    default:
      throw new Error("Unsupported role");
  }
}

export function getDeleteUserIconAriaLabel(userRole: Role, userName: string) {
  switch (userRole) {
    case Role.SPONSOR_OPS:
      return `Remove existing Sponsor Ops user ${userName}`;
    case Role.CDM:
      return `Remove existing CDM user ${userName}`;
    case Role.COMPLIANCE_ADMIN:
      return `Remove existing Compliance Admin user ${userName}`;
    case Role.CRC:
      return `Remove Site Research Coordinator user ${userName}`;
    default:
      throw new Error("Unsupported role");
  }
}
