export interface StepInfo {
  id: number;
  title?: string;
  page: React.ReactNode;
  previousButtonText?: string;
  isPreviousDisabled: boolean;
  onPrevious: () => void;
  nextButtonText: string;
  isNextButtonDisabled: boolean;
  isNextButtonLoading?: boolean;
  onNext: () => void;
}

export class Steps {
  steps: Map<number, StepInfo>;
  default: StepInfo;

  constructor(steps: StepInfo[], defaultId?: number) {
    if (steps.length === 0) {
      throw new Error("Steps must not be empty");
    }

    this.steps = new Map<number, StepInfo>();
    for (const step of steps) {
      this.steps.set(step.id, step);
    }

    this.default = steps[0];
    if (defaultId) {
      this.default = this.get(defaultId) ?? this.default;
    }
  }

  get(id: number): StepInfo | undefined {
    return this.steps.get(id);
  }

  isDefault(id: number): boolean {
    return this.default.id === id;
  }
}

export enum EditParticipantIdSteps {
  EditReason = 0,
  EditParticipantId = 1,
  ConfirmParticipantId = 2,
  ContactVerily = 3,
}

export enum ManageWatchSteps {
  TaskSelection = 0,
  ReturnReason = 1,
  ConfirmWatchUnassignment = 2,
  WatchAssociationError = 3,
  WatchSwap = 4,
  ConfirmWatchSwap = 5,
  ContactVerily = 6,
}

export enum UpdateParticipantStatusSteps {
  StatusSelection = 0,
  ConfirmStatusUpdate = 1,
}

export enum EditEnrollmentDateSteps {
  NewDateEntry = 0,
  ConfirmDate = 1,
  EnrollmentDateError = 2,
}

export enum EditExitStudyDateSteps {
  NewDateEntry = 0,
  ConfirmDate = 1,
  ExitStudyDateError = 2,
}
