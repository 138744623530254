import React, { useEffect, useRef, useState } from "react";
import { Box, TablePagination, Typography, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { User, StudyRole, Role } from "generated/studyauth/studyauth_pb";
import { Search } from "@verily-src/react-design-system";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import {
  getInvitationDisplayStatus,
  getUserRoleDescription,
  InvitationStatusView,
} from "components/UserRenderers";
import { tablePaginationSxProps } from "components/Tables";
import { useAppSelector } from "redux/hooks";
import { getStudyUsersAlert } from "./StudyUsersAlert";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";

interface SingleStudySiteUsersTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const SingleStudySiteUsersTableHeader: React.FC<
  SingleStudySiteUsersTableHeaderProps
> = ({ order, orderBy, onRequestSort }) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="34%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="email"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Email"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="33%"
        >
          <Typography variant="body2em">Email</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Status"
          onRequestSort={onRequestSort}
          width="33%"
        >
          <Typography variant="body2em">Status</Typography>
        </SortableTableHeaderCell>
      </TableRow>
    </TableHead>
  );
};

function getUserName(user: User) {
  return user.getFirstName() + " " + user.getLastName();
}

interface SingleStudySiteUsersTableContentProps {
  users: Array<User>;
}

const SingleStudySiteUsersTableContent: React.FC<
  SingleStudySiteUsersTableContentProps
> = ({ users }) => {
  return (
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.getName()}>
          <TableCell
            width="34%"
            sx={{ paddingLeft: "16px" }}
            style={{ verticalAlign: "top" }}
          >
            {getUserName(user)}
          </TableCell>
          <TableCell width="33%">{user.getEmailAddress()}</TableCell>
          <TableCell width="33%">
            <InvitationStatusView
              invitationStatus={user.getInvitationStatus()}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

interface SingleStudySiteUserTableControlProps {
  searchText: string;
  onRequestSearch(searchText: string): void;
}

const SingleStudySiteUsersTableControl: React.FC<
  SingleStudySiteUserTableControlProps
> = ({ searchText, onRequestSearch }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "8px 0px 8px 4px",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
    </Box>
  );
};

interface SingleStudySiteUsersProps {
  users: User[];
  userRolesMap: Map<string, StudyRole[]>;
  registryIdFilter: string;
  studySiteIdFilter: string;
}

const SingleStudySiteUsers: React.FC<SingleStudySiteUsersProps> = ({
  users,
  userRolesMap,
  registryIdFilter,
  studySiteIdFilter,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchText, setSearchText] = React.useState("");

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  const [filteredAndSortedUsers, setFilteredAndSortedUsers] = useState(
    new Array<User>()
  );

  useEffect(() => {
    const userList = users
      .filter((user) => {
        if (searchText !== "") {
          const searchTextLowercase = searchText.toLowerCase();
          if (
            getUserName(user).toLowerCase().indexOf(searchTextLowercase) ===
              -1 &&
            user
              .getEmailAddress()
              .toLowerCase()
              .indexOf(searchTextLowercase) === -1
          ) {
            return false;
          }
        }

        const studyRoles = userRolesMap.get(user.getName());
        if (studyRoles === undefined) {
          return false;
        }

        let hasRequiredRole = false;
        for (let studyRole of studyRoles) {
          if (
            registryIdFilter === studyRole.getRegistryId() &&
            studySiteIdFilter === studyRole.getStudySiteId() &&
            studyRole.getRole() === Role.CRC
          ) {
            hasRequiredRole = true;
            break;
          }
        }

        return hasRequiredRole;
      })
      .sort((a, b) => {
        let result = 0;
        switch (orderBy) {
          case "name":
            result = getUserName(a).localeCompare(getUserName(b));
            break;
          case "email":
            result = a.getEmailAddress().localeCompare(b.getEmailAddress());
            break;
          case "status":
            result = getInvitationDisplayStatus(
              a.getInvitationStatus()
            ).localeCompare(
              getInvitationDisplayStatus(b.getInvitationStatus())
            );
            break;
        }
        return order === "asc" ? result : -result;
      });

    setFilteredAndSortedUsers(userList);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(userList.length));
      previousSearch.current = searchText;
    }
  }, [
    users,
    searchText,
    userRolesMap,
    registryIdFilter,
    studySiteIdFilter,
    orderBy,
    order,
  ]);

  const theme = useTheme();

  const registryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          width: "100%",
        }}
      >
        <A11yStatusMessage message={searchResultMessage} />
        <SingleStudySiteUsersTableControl
          searchText={searchText}
          onRequestSearch={(searchText) => {
            setSearchText(searchText);
            setPage(0);
          }}
        />
        <Typography variant="body1em" sx={{ padding: "16px" }}>
          {getUserRoleDescription(Role.CRC)}
        </Typography>
        <Box
          sx={{
            width: "100%",
            padding: "8px 16px",
            borderRadius: "16px",
            backgroundColor: theme.palette.background.canvas,
          }}
        >
          <Typography variant="body2">
            {getStudyUsersAlert(registryId)}
          </Typography>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 700, marginBottom: "8px" }} aria-label="users">
            <SingleStudySiteUsersTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <SingleStudySiteUsersTableContent
              users={filteredAndSortedUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
            />
          </Table>
          <A11yStatusMessage message={sortDirectiontMessage} />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginRight: "16px", ...tablePaginationSxProps }}
        />
      </Box>
    </>
  );
};

export default SingleStudySiteUsers;
