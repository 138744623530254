import React from "react";
import { Button } from "@verily-src/react-design-system";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";

interface DuplicateParticipantFoundDialogProps {
  open: boolean;
  onClose(): void;
}

const DuplicateParticipantFoundDialog: React.FC<
  DuplicateParticipantFoundDialogProps
> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">
          Duplicate participant ID found
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Typography variant="body1">
          This participant ID has been assigned to another participant in this
          study. Please enter a different participant ID.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button variant="filled" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateParticipantFoundDialog;
