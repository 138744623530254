export const ParseIdFromName = (name: string) => {
  const regex = new RegExp("[a-zA-Z0-9/._-]+/([a-zA-Z0-9._-]+)$");
  const match = regex.exec(name);
  if (match && match.length > 1) {
    return match[1];
  }
  return "";
};

export const ParseParticipantIdFromNamespace = (participantName: string) => {
  // Expected format: <namespace>.<registry_id>.<participant_id>
  const regex = new RegExp("[a-zA-Z0-9-_ /]+.[a-zA-Z0-9._-]+:([a-zA-Z0-9-]+)$");
  const match = regex.exec(participantName);
  if (match && match.length > 1) {
    return match[1];
  }
  return "";
};

const picardPrefix = "com.verily.picard.";
export const RemovePicardPrefix = (deviceId: string) => {
  if (deviceId.startsWith(picardPrefix)) {
    return deviceId.slice(picardPrefix.length);
  }

  return deviceId;
};

export const ParseSiteIdFromName = (name: string) => {
  const regex = new RegExp("studySites/([a-zA-Z0-9._-]+)$");
  const match = regex.exec(name);
  if (match && match.length > 1) {
    return match[1];
  }
  return "";
};
