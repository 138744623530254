import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIcon } from "@verily-src/react-design-system";
import { Steps } from "common/Steps";
import { sxFocusWithin } from "common/Accessibility";

interface StepsHeaderProps {
  title: string;
  stepId: number;
  steps: Steps;
  onClose: () => void;
}

const StepsHeader: React.FC<StepsHeaderProps> = ({
  title,
  stepId,
  steps,
  onClose,
}) => {
  const theme = useTheme();

  const titleRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    titleRef.current?.focus();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "24px",
        marginBottom: "40px",
        background: theme.palette.background.canvas,
      }}
    >
      <Box sx={{ marginRight: "12px", ...sxFocusWithin }}>
        <IconButton
          onClick={() => {
            if (steps.isDefault(stepId)) {
              onClose();
            } else {
              steps.get(stepId)!.onPrevious();
            }
          }}
          aria-label="Back"
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography
        component="h1"
        variant="display5"
        ref={titleRef}
        tabIndex={-1}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default StepsHeader;
