import React from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import { hasAnyRole, selectCurrentStudyRoles } from "core/UserConfigSlice";
import { Role } from "generated/studyauth/studyauth_pb";
import CrcCompliance from "./CrcCompliance";
import StudyCompliance from "./StudyCompliance";

const ComplianceHome: React.FC = () => {
  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  if (
    hasAnyRole(selectedRoles, Role.SPONSOR_OPS, Role.CDM, Role.COMPLIANCE_ADMIN)
  ) {
    return <StudyCompliance />;
  } else if (hasAnyRole(selectedRoles, Role.CRC)) {
    return <CrcCompliance />;
  } else {
    // This shouldn't happen. The screen should be hidden for Verily CDM role.
    return <Box>Unsupported role</Box>;
  }
};

export default ComplianceHome;
