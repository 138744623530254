import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
} from "@mui/material";
import { Button, TextField } from "@verily-src/react-design-system";
import { Device, ListDevicesRequest } from "generated/device/device_pb";
import { DeviceServiceApiClient } from "apiclient/DeviceServiceApiClient";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { RpcError, StatusCode } from "grpc-web";
import { ParseIdFromName } from "common/ResourceName";
import MultipleDevicesFoundDialog from "./MultipleDevicesFoundDialog";
import PicardStickerExamples from "assets/picard-sticker-examples.svg";
import { dialog_border_radius } from "styles/Dimensions";
import SDPErrorSnackbar from "components/SDPErrorSnackbar";
import A11yDialogTitle from "components/A11yDialogTitle";

const picardStickerExamplesImageDescription =
  "Two example labels, each show the letters SN, followed by a sample serial number. An arrow points to the last 4 digits of the serial number, which are also highlighted.";

export interface WatchAssignmentFieldRef {
  validateDeviceTextField: () => boolean;
  searchDevices: () => void;
  fetchDevice: () => string;
  showDuplicatesDialog: () => void;
}

interface WatchAssignmentFieldProps {
  registryId: string;
  onSuccess(): void;
}

// The confirmation dialog for unassigning a watch
const WatchAssignmentField = forwardRef<
  WatchAssignmentFieldRef,
  WatchAssignmentFieldProps
>(({ registryId, onSuccess }, ref) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const inputRef = useRef(null);

  const [deviceLast4Digits, setDeviceLast4Digits] = useState("");
  const [deviceIdHasError, setDeviceIdHasError] = useState(false);
  const [deviceIdErrorMessage, setDeviceIdErrorMessage] = useState("");

  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  const [duplicateDeviceDialogOpen, setDuplicateDeviceDialogOpen] =
    useState(false);

  const [deviceAlreadyAssignedDialogOpen, setDeviceAlreadyAssignedDialogOpen] =
    useState(false);

  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  // Set initial state on first render.
  useEffect(() => {
    setDeviceLast4Digits("");
    setDeviceIdHasError(false);
    setDeviceIdErrorMessage("");
  }, []);

  const validateDeviceTextField = useCallback(() => {
    // Reset error fields
    setIsErrorSnackbarOpen(false);
    setDeviceIdHasError(false);
    setDeviceIdErrorMessage("");

    // Check for empty fields
    if (deviceLast4Digits.length === 0) {
      setDeviceIdHasError(true);
      setDeviceIdErrorMessage("Required");
      return false;
    } else if (deviceLast4Digits.length !== 4) {
      setDeviceIdHasError(true);
      setDeviceIdErrorMessage("Must be 4 digits");
      return false;
    }
    return true;
  }, [deviceLast4Digits.length]);

  const fetchDevice = useCallback(() => {
    return selectedDeviceId;
  }, [selectedDeviceId]);

  const searchDevices = useCallback(async () => {
    if (!isAuthenticated || !auth0Config) {
      setDeviceIdHasError(true);
      setDeviceIdErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
      return;
    }

    await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new DeviceServiceApiClient(auth0Config.audience!, token);

        const response = await client.listDevices(
          new ListDevicesRequest()
            .setParent(`registries/${registryId}`)
            .setFilter(`regexp=(${deviceLast4Digits.toUpperCase()})$`)
        );

        const devices = response.getDevicesList();
        const availableDevices = devices.filter(
          (device) => device.getParticipantid() === ""
        );
        if (devices.length === 0) {
          setDeviceIdHasError(true);
          setDeviceIdErrorMessage(
            "Device ID does not exist. Try a different device ID."
          );
        } else if (availableDevices.length === 0) {
          setDeviceAlreadyAssignedDialogOpen(true);
        } else if (availableDevices.length > 1) {
          // show duplicate devices modal
          setDevices(availableDevices.sort());
          setDuplicateDeviceDialogOpen(true);
        } else {
          setSelectedDeviceId(ParseIdFromName(availableDevices[0].getName()));
          onSuccess();
        }
      } catch (error) {
        let errMsg = "Unable to assign watch. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User not authorized to delete the participant.";
        }

        setIsErrorSnackbarOpen(true);
        setErrorSnackbarMessage(errMsg);
      }
    })();
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    registryId,
    onSuccess,
    deviceLast4Digits,
  ]);

  const showDuplicatesDialog = useCallback(() => {
    if (devices.length > 1) {
      setDuplicateDeviceDialogOpen(true);
    }
  }, [devices.length]);

  useImperativeHandle(
    ref,
    () => {
      return {
        validateDeviceTextField,
        searchDevices,
        fetchDevice,
        showDuplicatesDialog,
      };
    },
    [validateDeviceTextField, searchDevices, fetchDevice, showDuplicatesDialog]
  );

  const DeviceAlreadyAssignedDialog: React.FC = () => {
    return (
      <Dialog
        open={deviceAlreadyAssignedDialogOpen}
        maxWidth={false}
        PaperProps={{
          style: { borderRadius: dialog_border_radius },
          sx: {
            width: "448px !important",
          },
        }}
      >
        <A11yDialogTitle
          component="h1"
          sx={{ paddingTop: "24px", paddingBottom: "12px" }}
        >
          <Typography variant="display6">
            Error: device already assigned
          </Typography>
        </A11yDialogTitle>
        <DialogContent sx={{ paddingTop: "0px" }}>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">
              This device ID is already assigned to another participant. You
              can’t reassign devices. Please assign a different device.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "right" }}
          sx={{ padding: "0px 24px 24px 24px" }}
        >
          <Button
            variant="filled"
            onClick={() => {
              setDeviceAlreadyAssignedDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Box
        margin="normal"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        ref={inputRef}
      >
        <Typography component="h2" variant="display6">
          Assign watch
        </Typography>
        <Typography sx={{ padding: "4px 0px" }}>
          You’ll find the serial number on a sticker on the side of the watch
          packaging. Look for the letters SN.
        </Typography>
        <TextField
          id="deviceId"
          label="Last 4 digits of serial number (required)"
          placeholder="####"
          multiline={false}
          error={deviceIdHasError}
          errorText={deviceIdErrorMessage}
          value={deviceLast4Digits}
          sx={{ margin: "8px 0px 42px" }}
          onChange={(event) => {
            setDeviceLast4Digits(
              event.target.value.toUpperCase().replaceAll("O", "0")
            );
          }}
          inputProps={{
            maxLength: 4,
            style: { fontFamily: "Roboto Mono" },
          }}
        />
        <Box
          component="img"
          src={PicardStickerExamples}
          sx={{
            alignSelf: "center",
            justifySelf: "center",
          }}
          aria-label={picardStickerExamplesImageDescription}
          alt={picardStickerExamplesImageDescription}
        />
      </Box>
      <MultipleDevicesFoundDialog
        open={duplicateDeviceDialogOpen}
        devices={devices}
        selectedDeviceId={selectedDeviceId}
        setSelectedDeviceId={setSelectedDeviceId}
        onSuccess={() => {
          setDuplicateDeviceDialogOpen(false);
          onSuccess();
        }}
        onClose={() => {
          setDuplicateDeviceDialogOpen(false);
          setDevices([]);
          setSelectedDeviceId("");
        }}
      ></MultipleDevicesFoundDialog>
      <DeviceAlreadyAssignedDialog />
      <SDPErrorSnackbar
        showSnackbar={isErrorSnackbarOpen}
        setShowSnackbar={setIsErrorSnackbarOpen}
        errorMessage={errorSnackbarMessage}
      />
    </>
  );
});

export default WatchAssignmentField;
