import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  card_border_radius,
  card_padding,
  compliance_setting_card_min_width,
} from "styles/Dimensions";
import { DaysPerWeekSelection } from "./DaysPerWeekSelection";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";

// Define a component to set sync time compliance parameters.
interface SyncTimeSettingCardProps {
  initValue: ComplianceSetting | null;
}

// Defines an interface for the functions exposed from SyncTimeSettingCard
export interface WearTimeSettingCardRef {
  validateInput: () => boolean;
  getDaysPerWeek: () => number;
}

export const SyncTimeSettingCard = forwardRef<
  WearTimeSettingCardRef,
  SyncTimeSettingCardProps
>(({ initValue }, ref) => {
  const [daysPerWeek, setDaysPerWeek] = useState<number | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (initValue) {
      setDaysPerWeek(initValue.getSyncDaysPerWeek());
    } else {
      setDaysPerWeek(undefined);
    }

    setShowError(false);
  }, [initValue]);

  const validateDaysPerWeek = useCallback(
    (daysPerWeek: number | undefined) => {
      let isValid = daysPerWeek !== undefined;
      setShowError(!isValid);
      return isValid;
    },
    [setShowError]
  );

  const validateInput = useCallback(() => {
    return validateDaysPerWeek(daysPerWeek);
  }, [daysPerWeek, validateDaysPerWeek]);

  const getDaysPerWeek = useCallback(() => {
    return daysPerWeek === undefined ? 0 : daysPerWeek;
  }, [daysPerWeek]);

  // Expose functions to parent components.
  useImperativeHandle(
    ref,
    () => ({
      validateInput,
      getDaysPerWeek,
    }),
    [getDaysPerWeek, validateInput]
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        minWidth: compliance_setting_card_min_width,
        marginTop: "24px",
        padding: card_padding,
        borderRadius: card_border_radius,
        background: theme.palette.background.canvas,
      }}
    >
      <Typography
        component="h2"
        variant="display6"
        fontWeight="600"
        sx={{ marginBottom: "24px" }}
      >
        Watch sync (required)
      </Typography>
      <Typography component="h3" variant="body1" fontWeight="600">
        Days with syncs per week
      </Typography>
      <Typography variant="caption" sx={{ margin: "8px 0px" }}>
        Weeks start Sunday and end Saturday
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "8px",
          marginBottom: "12px",
        }}
      >
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <DaysPerWeekSelection
          daysPerWeek={daysPerWeek}
          labelId="sync-time-days-per-week"
          helperText={showError ? "Required" : ""}
          showError={showError}
          onDaysPerWeekChange={(value) => {
            setDaysPerWeek(value);
          }}
          onValidate={() => {
            validateDaysPerWeek(daysPerWeek);
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          day(s) per week
        </Typography>
      </Box>
    </Box>
  );
});
