import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { InputTextField, InputTextFieldRef } from "components/InputText";
import { dialog_border_radius } from "styles/Dimensions";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import { updateStudyDetail } from "./UpdateStudyDetail";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface EditStudyDetailsDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  registryId: string;
  detail: StudyDetail;
  hasExistingStudyDetail: boolean;
  onClose(): void;
  onSuccess(): void;
}

// Define an enum to specify which field should focus on when there are errors.
enum FormField {
  StudyName = 0,
  SponsorName = 1,
  UpdateReason = 2,
}

// The edit dialog for editing study detail for a study.
const EditStudyDetailDialog: React.FC<EditStudyDetailsDialogProps> = ({
  open,
  registryId,
  detail,
  hasExistingStudyDetail,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [showError, setShowError] = useState(false);
  const [hasSimilarDetail, setHasSimilarDetail] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [studyName, setStudyName] = useState(detail.getStudyName());
  const [studyNameErrorMessage, setStudyNameErrorMessage] = useState("");

  const [sponsorName, setSponsorName] = useState(detail.getSponsorName());
  const [sponsorNameErrorMessage, setSponsorNameErrorMessage] = useState("");

  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [studyId, setStudyId] = useState(detail.getStudyId());

  const theme = useTheme();

  // Hold the reference to form fields which could show a validation error
  // and need to be focused on.
  const formFields = useRef(new Array<InputTextFieldRef | null>());

  const handleClickSaveButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          setDisableSaveButton(true);

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const errorFields = new Array<FormField>();

          if (studyName.trim().length < 1) {
            setStudyNameErrorMessage("Study name required");
            errorFields.push(FormField.StudyName);
          }

          if (sponsorName.trim().length < 1) {
            setSponsorNameErrorMessage("Sponsor required");
            errorFields.push(FormField.SponsorName);
          }

          // Focus on first error field if any
          if (errorFields.length > 0) {
            formFields.current[errorFields[0]]?.focus();
          }

          if (
            !updateReasonFormRef.current!.validateInput(
              errorFields.length === 0 /* focusOnError */
            )
          ) {
            errorFields.push(FormField.UpdateReason);
          }

          if (errorFields.length === 0) {
            detail.setStudyName(studyName);
            detail.setSponsorName(sponsorName);
            detail.setStudyId(studyId);
            detail.setName("studyDetails/" + registryId);

            await updateStudyDetail(
              auth0Config.audience!,
              token,
              registryId,
              detail,
              hasExistingStudyDetail,
              updateReasonFormRef.current!.getUpdateReason()
            );

            onSuccess();
          }
        } catch (error) {
          console.log("error reason %s", error);
          setShowError(true);
          var errorMessage = String(error);
          if (errorMessage.includes("similar study detail already exists")) {
            setHasSimilarDetail(true);
          }
        }
        setDisableSaveButton(false);
      })();
    } else {
      setShowError(true);
    }
  }, [
    isAuthenticated,
    auth0Config,
    getAccessTokenSilently,
    studyName,
    sponsorName,
    detail,
    studyId,
    registryId,
    hasExistingStudyDetail,
    onSuccess,
  ]);

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setShowError(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "16px" }}
      >
        <Typography variant="display6">Edit Study Details</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {showError && (
          <Alert severity="error">
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                {hasSimilarDetail
                  ? "Duplicate study details"
                  : "Failed to edit the study detail"}
              </Typography>
            </AlertTitle>
            {hasSimilarDetail && (
              <Typography
                variant="body2"
                color={theme.palette.error.textOnBackground}
              >
                Another study includes that combination of Name, Sponsor and
                Study ID. Please enter a different Study Name, Sponsor or Study
                ID.
              </Typography>
            )}
          </Alert>
        )}
        <InputTextField
          id="studyName"
          label="Study name (required)"
          ref={(el) => (formFields.current[FormField.StudyName] = el)}
          value={studyName}
          placeholder="Study name"
          helperText={studyNameErrorMessage}
          showError={studyNameErrorMessage.length > 0 || showError}
          onChange={(newValue) => {
            setStudyNameErrorMessage("");
            setStudyName(newValue);
          }}
        />
        <InputTextField
          id="sponsorName"
          label="Sponsor (required)"
          ref={(el) => (formFields.current[FormField.SponsorName] = el)}
          value={sponsorName}
          placeholder="Sponsor"
          helperText={sponsorNameErrorMessage}
          showError={sponsorNameErrorMessage.length > 0 || showError}
          onChange={(newValue) => {
            setSponsorNameErrorMessage("");
            setSponsorName(newValue);
          }}
          sx={{ marginTop: "30px" }}
        />
        <InputTextField
          id="studyId"
          label="Study ID (optional)"
          value={studyId}
          showError={showError}
          onChange={(newValue) => {
            setStudyId(newValue);
          }}
          sx={{ marginTop: "30px" }}
        />
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={["Error correction"]}
            reasonSelectionLabel="Reason for change (required)"
            reasonRequiredMessage="Reason for change required"
            otherReasonHint="Please provide reason"
            otherReasonRequiredMessage="Other reason for change required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          disabled={disableSaveButton}
          onClick={handleClickSaveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStudyDetailDialog;
