import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface WeekNavigationState {
  previousWeekOffset: number;
}

const initialState: WeekNavigationState = {
  previousWeekOffset: 1,
};

const weekNavigationStateSlice = createSlice({
  name: "weekNavigationState",
  initialState,
  reducers: {
    setPreviousWeekOffset: (state, action: PayloadAction<number>) => {
      state.previousWeekOffset = action.payload;
    },
  },
});

export const { setPreviousWeekOffset } = weekNavigationStateSlice.actions;
export default weekNavigationStateSlice.reducer;
