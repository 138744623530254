/**
 * @fileoverview gRPC-Web generated client stub for verily.sdp.studyparticipant.v1alpha1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: studyparticipant/studyparticipant.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../google/api/annotations_pb.js')

var google_api_client_pb = require('../google/api/client_pb.js')

var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js')

var google_api_resource_pb = require('../google/api/resource_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.verily = {};
proto.verily.sdp = {};
proto.verily.sdp.studyparticipant = {};
proto.verily.sdp.studyparticipant.v1alpha1 = require('./studyparticipant_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest,
 *   !proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 */
const methodDescriptor_StudyParticipantService_CreateStudyParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/CreateStudyParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest,
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.createStudyParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/CreateStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_CreateStudyParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.CreateStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.createStudyParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/CreateStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_CreateStudyParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest,
 *   !proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 */
const methodDescriptor_StudyParticipantService_UpdateStudyParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/UpdateStudyParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest,
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.updateStudyParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/UpdateStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_UpdateStudyParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.UpdateStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.updateStudyParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/UpdateStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_UpdateStudyParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_StudyParticipantService_DeleteStudyParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/DeleteStudyParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.deleteStudyParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/DeleteStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_DeleteStudyParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.DeleteStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.deleteStudyParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/DeleteStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_DeleteStudyParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest,
 *   !proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 */
const methodDescriptor_StudyParticipantService_GetStudyParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/GetStudyParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest,
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.getStudyParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/GetStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_GetStudyParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.GetStudyParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.getStudyParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/GetStudyParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_GetStudyParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest,
 *   !proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 */
const methodDescriptor_StudyParticipantService_LookupSponsorParticipant = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/LookupSponsorParticipant',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest,
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.lookupSponsorParticipant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/LookupSponsorParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_LookupSponsorParticipant,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.LookupSponsorParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipant>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.lookupSponsorParticipant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/LookupSponsorParticipant',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_LookupSponsorParticipant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest,
 *   !proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse>}
 */
const methodDescriptor_StudyParticipantService_ListStudyParticipants = new grpc.web.MethodDescriptor(
  '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/ListStudyParticipants',
  grpc.web.MethodType.UNARY,
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest,
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse,
  /**
   * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse.deserializeBinary
);


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServiceClient.prototype.listStudyParticipants =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/ListStudyParticipants',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_ListStudyParticipants,
      callback);
};


/**
 * @param {!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.verily.sdp.studyparticipant.v1alpha1.ListStudyParticipantsResponse>}
 *     Promise that resolves to the response
 */
proto.verily.sdp.studyparticipant.v1alpha1.StudyParticipantServicePromiseClient.prototype.listStudyParticipants =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/verily.sdp.studyparticipant.v1alpha1.StudyParticipantService/ListStudyParticipants',
      request,
      metadata || {},
      methodDescriptor_StudyParticipantService_ListStudyParticipants);
};


module.exports = proto.verily.sdp.studyparticipant.v1alpha1;

