import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComplianceRange } from "components/ComplianceRangeFilter";
import { Order } from "components/SortableTableHeaderCell";
import { WritableDraft } from "immer/dist/internal";

export enum StudyComplianceTabIndex {
  StudySummary = 0,
  NonCompliantParticipants = 1,
  SiteCompliance = 2,
}

export interface StudyComplianceState {
  selectedTab: StudyComplianceTabIndex;

  studySiteComplianceTabSortOrder: Order;
  studySiteComplianceTabOrderBy: string;
  studySiteComplianceTabPageNumber: number;
  studySiteComplianceTabRowsPerPage: number;
  studySiteComplianceTabSearchText: string;
  studySiteComplianceTabSelectedCountry: string[];
  studySiteComplianceTabComplianceRange: ComplianceRange;
}

const initialState: StudyComplianceState = {
  selectedTab: StudyComplianceTabIndex.StudySummary,

  studySiteComplianceTabSortOrder: "asc",
  studySiteComplianceTabOrderBy: "siteName",
  studySiteComplianceTabPageNumber: 0,
  studySiteComplianceTabRowsPerPage: 5,
  studySiteComplianceTabSearchText: "",
  studySiteComplianceTabSelectedCountry: [],
  studySiteComplianceTabComplianceRange: {
    minPercentage: 0,
    maxPercentage: 100,
  },
};

function resetStudySiteComplianceTabStateInternal(
  state: WritableDraft<StudyComplianceState>
) {
  state.studySiteComplianceTabSortOrder = "asc";
  state.studySiteComplianceTabOrderBy = "siteName";
  state.studySiteComplianceTabPageNumber = 0;
  state.studySiteComplianceTabRowsPerPage = 5;
  state.studySiteComplianceTabSearchText = "";
  state.studySiteComplianceTabSelectedCountry = [];
  state.studySiteComplianceTabComplianceRange = {
    minPercentage: 0,
    maxPercentage: 100,
  };
}

const studyComplianceStateSlice = createSlice({
  name: "studyComplianceState",
  initialState,
  reducers: {
    setSelectedStudyComplianceTab: (
      state,
      action: PayloadAction<StudyComplianceTabIndex>
    ) => {
      state.selectedTab = action.payload;
    },
    resetStudyComplianceScreenState: (state) => {
      state.selectedTab = StudyComplianceTabIndex.StudySummary;
      resetStudySiteComplianceTabStateInternal(state);
    },
    setStudySiteComplianceTabSortOrder: (
      state,
      action: PayloadAction<Order>
    ) => {
      state.studySiteComplianceTabSortOrder = action.payload;
    },
    setStudySiteComplianceTabOrderBy: (
      state,
      action: PayloadAction<string>
    ) => {
      state.studySiteComplianceTabOrderBy = action.payload;
    },
    setStudySiteComplianceTabPageNumber: (
      state,
      action: PayloadAction<number>
    ) => {
      state.studySiteComplianceTabPageNumber = action.payload;
    },
    setStudySiteComplianceTabRowsPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.studySiteComplianceTabRowsPerPage = action.payload;
    },
    setStudySiteComplianceTabSearchText: (
      state,
      action: PayloadAction<string>
    ) => {
      state.studySiteComplianceTabSearchText = action.payload;
    },
    setStudySiteComplianceTabSelectedCountry: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.studySiteComplianceTabSelectedCountry = action.payload;
    },
    setStudySiteComplianceTabComplianceRange: (
      state,
      action: PayloadAction<ComplianceRange>
    ) => {
      state.studySiteComplianceTabComplianceRange = action.payload;
    },
    resetStudySiteComplianceTabState: (state) => {
      resetStudySiteComplianceTabStateInternal(state);
    },
  },
});

export const {
  setSelectedStudyComplianceTab,
  resetStudyComplianceScreenState,
  setStudySiteComplianceTabSortOrder,
  setStudySiteComplianceTabOrderBy,
  setStudySiteComplianceTabPageNumber,
  setStudySiteComplianceTabRowsPerPage,
  setStudySiteComplianceTabSearchText,
  setStudySiteComplianceTabSelectedCountry,
  setStudySiteComplianceTabComplianceRange,
  resetStudySiteComplianceTabState,
} = studyComplianceStateSlice.actions;

export default studyComplianceStateSlice.reducer;
