import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button } from "@verily-src/react-design-system";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ManageWatchRedirectDialogProps {
  open: boolean;
  onExit: () => void;
  onCancel: () => void;
}

export const ManageWatchRedirectDialog: React.FC<
  ManageWatchRedirectDialogProps
> = ({ open, onExit, onCancel }) => {
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">
          For device related changes, go to “Manage watch” instead.
        </Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Typography variant="body1">
          For any device assignment changes, please exit this page and go to
          “Manage watch”.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button variant="filled" onClick={onExit}>
          Exit this page
        </Button>
      </DialogActions>
    </Dialog>
  );
};
