import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, Dialog, Paper, Typography, useTheme } from "@mui/material";
import { RadioGroup, Radio } from "@verily-src/react-design-system";
import { SDPDatePicker } from "components/SDPDatePicker";
import NumetricWatch from "assets/watchface-time-nobg 1.svg";
import {
  isStudyParticipantEmpty,
  StudyParticipantServiceApiClient,
} from "apiclient/StudyParticipantServiceApiClient";
import {
  isParticipantSessionNullOrEmpty,
  ParticipantSessionServiceApiClient,
} from "apiclient/ParticipantSessionServiceApiClient";
import {
  UpdateStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import { Footer } from "components/Footer";
import { format, isValid } from "date-fns";
import { toDateProto, parseDate } from "common/Dates";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { RpcError, StatusCode } from "grpc-web";
import { StepInfo, Steps, UpdateParticipantStatusSteps } from "common/Steps";
import {
  ListParticipantSessionsRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import StepsHeader from "components/StepsHeader";
import { focusOnRef } from "common/Accessibility";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import HeaderValuePair from "components/HeaderValuePair";
import { ParseIdFromName } from "common/ResourceName";

export interface ParticipantStatus {
  label: string;
  value: number;
  helperText?: string;
  dateFieldLabel?: string;
  isInactive?: boolean;
}

export const ParticipantStatuses: ParticipantStatus[] = [
  {
    label: "Unknown",
    value: 0,
  },
  {
    label: "Enrolled",
    value: 1,
    helperText:
      "Participant is currently enrolled in this study. You can assign a watch to them.",
    dateFieldLabel: "Enrollment date",
    isInactive: false,
  },
  {
    label: "Completed",
    value: 2,
    helperText: "Participant won’t show up in future compliance metrics.",
    dateFieldLabel: "Completion date",
    isInactive: true,
  },
  {
    label: "Withdrawn",
    value: 3,
    helperText: "Participant won’t show up in future compliance metrics.",
    dateFieldLabel: "Withdrawal date",
    isInactive: true,
  },
];

export const GetParticipantStatusById = (id: number): ParticipantStatus => {
  const status = ParticipantStatuses.find((status) => {
    return status.value === id;
  });

  return status ? status : ParticipantStatuses[0];
};

interface UpdateParticipantStatusDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  participantSession: ParticipantSession;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const UpdateParticipantStatusDialog: React.FC<
  UpdateParticipantStatusDialogProps
> = ({
  open,
  studyParticipant,
  participantSession,
  onClose,
  onSuccess,
  onError,
}) => {
  const theme = useTheme();

  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedStatus, setSelectedStatus] = useState<ParticipantStatus>(
    GetParticipantStatusById(studyParticipant.getStatus())
  );
  const [enrollmentDate, setEnrollmentDate] = useState<Date>(
    studyParticipant.getEnrollmentStartTime()?.toDate()!
  );
  const [exitStudyDate, setExitStudyDate] = useState<Date>(
    parseDate(new Date())
  );
  const [firstWatchAssociation, setFirstWatchAssociation] = useState<
    ParticipantSession | undefined
  >(undefined);

  const [datePickerValue, setDatePickerValue] = useState<
    Date | null | undefined
  >(parseDate(new Date()));
  const [datePickerError, setDatePickerError] = useState<boolean>(false);
  const [datePickerErrorText, setDatePickerErrorText] = useState<string>("");

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [focusIndex, setFocusIndex] = useState(-1);
  const optionsRef = useRef(
    ParticipantStatuses.map(() => createRef<HTMLInputElement>())
  );
  const [updateReason, setUpdateReason] = useState<string>("");
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  useEffect(() => {
    setFocusIndex(-1);

    if (open) {
      // Set first association
      (async () => {
        setFirstWatchAssociation(undefined);
        if (!isAuthenticated || !auth0Config) {
          onError("User not authenticated. Please logout and login again.");
        }

        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const participantSessionApiClient =
            new ParticipantSessionServiceApiClient(
              auth0Config.audience!,
              token
            );

          const studyParticipantId = ParseIdFromName(
            studyParticipant.getName()
          );
          const response =
            await participantSessionApiClient.listParticipantSessionHistory(
              new ListParticipantSessionsRequest().setParent(
                `studyParticipants/${studyParticipantId}`
              )
            );

          const participantSessionHistory = response.getSessionsList();

          // First association should be last entry
          // List is sorted by start time in descending order
          if (
            participantSessionHistory.length > 0 &&
            participantSessionHistory[0].getDeviceId() ===
              studyParticipant.getDeviceId()
          ) {
            setFirstWatchAssociation(
              participantSessionHistory[participantSessionHistory.length - 1]
            );
          }
        } catch (error) {
          console.log("error reason %s", error);
          let errMsg = "Failed to assign watch. Please try again.";
          if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg =
              "User is not authorized to assign a watch to this participant.";
          }

          onError(errMsg);
        }
      })();
    }
  }, [
    open,
    isAuthenticated,
    auth0Config,
    getAccessTokenSilently,
    onError,
    studyParticipant,
  ]);

  useEffect(() => {
    if (focusIndex > -1) {
      focusOnRef(optionsRef.current[focusIndex]);
    }
  }, [focusIndex]);

  const stepInfo: StepInfo[] = [
    {
      id: UpdateParticipantStatusSteps.StatusSelection,
      page: (
        <>
          <RadioGroup>
            {ParticipantStatuses.slice(1).map(
              (option: ParticipantStatus, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: "24px",
                      width: "100%",
                    }}
                  >
                    <Radio
                      inputRef={optionsRef.current[index]}
                      key={index}
                      label={option.label}
                      value={option.value}
                      helperText={option.helperText}
                      checked={selectedStatus.value === option.value}
                      onChange={(event: any) => {
                        const status = GetParticipantStatusById(
                          Number(event.target.value)
                        );

                        setSelectedStatus(status);

                        if (status.value === 1) {
                          setDatePickerValue(enrollmentDate);
                        } else {
                          setDatePickerValue(exitStudyDate);
                        }

                        setDatePickerError(false);
                        setDatePickerErrorText("");
                        setFocusIndex(index);
                      }}
                    />
                    {selectedStatus.value === option.value &&
                      selectedStatus.value !== studyParticipant.getStatus() && (
                        <SDPDatePicker
                          label={option.dateFieldLabel + " (required)"}
                          disableFuture={true}
                          value={datePickerValue}
                          onChange={(selectedDate) => {
                            setDatePickerError(false);
                            setDatePickerErrorText("");

                            setDatePickerValue(selectedDate);
                          }}
                          error={datePickerError}
                          errorText={datePickerErrorText}
                          sx={{
                            marginTop: "8px",
                            marginLeft: "30px",
                          }}
                        ></SDPDatePicker>
                      )}
                  </Box>
                );
              }
            )}
          </RadioGroup>
          {studyParticipant.getStatus() !== 1 && (
            <UpdateReasonForm
              ref={updateReasonFormRef}
              predefinedReasons={["Error correction", "Other"]}
              reasonSelectionLabel="Reason for updating status (required)"
              reasonRequiredMessage="Reason for updating status required"
              otherReasonAllowed={true}
            />
          )}
        </>
      ),
      isPreviousDisabled: true,
      onPrevious: () => {},
      nextButtonText: "Next",
      isNextButtonDisabled: false,
      onNext: () => {
        if (
          validateDatePickerField() &&
          (updateReasonFormRef.current?.validateInput() ||
            studyParticipant.getStatus() === 1)
        ) {
          setStepId(UpdateParticipantStatusSteps.ConfirmStatusUpdate);
        }
        if (studyParticipant.getStatus() === 1) {
          setUpdateReason(
            "Participant " + selectedStatus.label.toLowerCase() + "."
          );
        } else {
          setUpdateReason(updateReasonFormRef.current!.getUpdateReason());
        }
      },
    },
    {
      id: UpdateParticipantStatusSteps.ConfirmStatusUpdate,
      page: (
        <>
          <Typography component="h2" variant="display6">
            Confirm status change
          </Typography>
          {!selectedStatus.isInactive && (
            <Alert severity="info" sx={{ marginTop: "24px" }}>
              If you mark this participant back to “
              {selectedStatus.label.toLowerCase()}
              ,” they’ll show up in future compliance metrics.
            </Alert>
          )}
          {selectedStatus.isInactive && (
            <Alert severity="warning" sx={{ marginTop: "24px" }}>
              If you mark this participant as “
              {selectedStatus.label.toLowerCase()}
              ,” they won’t show up in future compliance metrics.
            </Alert>
          )}
          <Box>
            <Box sx={{ padding: "24px 0px" }}>
              <HeaderValuePair
                title="Participant ID"
                component="h3"
                fontFamily="Roboto Mono"
                value={studyParticipant.getSponsorParticipantId()}
              />
            </Box>
            <Box sx={{ paddingBottom: "24px" }}>
              <HeaderValuePair
                title="Status"
                component="h3"
                value={
                  selectedStatus.label +
                  (isValid(datePickerValue)
                    ? ", on " + format(datePickerValue!, "M/d/yyyy")
                    : "")
                }
              />
            </Box>
            <Box>
              {studyParticipant.getStatus() !== 1 && (
                <HeaderValuePair
                  title="Reason for change"
                  component="h2"
                  value={updateReason}
                />
              )}
              {selectedStatus.isInactive && (
                <>
                  <Box
                    sx={{
                      paddingTop: "24px",
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        backgroundColor: theme.palette.background.canvas,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          margin: "24px",
                          paddingRight: "24px",
                          width: "50%",
                        }}
                      >
                        <Typography variant="body1em" paragraph={true}>
                          Reminder: Upload watch data
                        </Typography>
                        <Typography variant="body1">
                          When this participant returns their watch, remember to
                          do a final data upload.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={NumetricWatch}
                          sx={{
                            alignSelf: "center",
                            justifySelf: "center",
                          }}
                          alt=""
                        />
                      </Box>
                    </Paper>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      ),
      onPrevious: () => {
        setStepId(UpdateParticipantStatusSteps.StatusSelection);
      },
      isPreviousDisabled: false,
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      isNextButtonLoading: confirmLoading,
      onNext: async () => {
        setConfirmLoading(true);
        await updateParticipantStatus(
          studyParticipant,
          selectedStatus.value,
          datePickerValue!
        );
        setConfirmLoading(false);
      },
    },
  ];

  const steps: Steps = new Steps(
    stepInfo,
    UpdateParticipantStatusSteps.StatusSelection
  );
  const [stepId, setStepId] = useState<number>(steps.default.id);

  const resetFields = useCallback(() => {
    setSelectedStatus(GetParticipantStatusById(studyParticipant.getStatus()));
    setEnrollmentDate(studyParticipant.getEnrollmentStartTime()?.toDate()!);
    setExitStudyDate(parseDate(new Date()));
    setDatePickerValue(
      studyParticipant
        ? studyParticipant.getEnrollmentStartTime()?.toDate()!
        : parseDate(new Date())
    );
    setDatePickerError(false);
    setDatePickerErrorText("");
    setStepId(steps.default.id);
  }, [studyParticipant, steps.default.id]);

  useEffect(() => {
    if (open) {
      resetFields();
    }
  }, [open, resetFields]);

  const validateDatePickerField = useCallback(() => {
    // Reset error fields
    setDatePickerError(false);
    setDatePickerErrorText("");

    // If selected date is invalid, throw an error
    if (!datePickerValue || !isValid(datePickerValue)) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a valid date.");
      return false;
    }

    // Check that the exit study date is after the enrollment date
    if (
      selectedStatus.isInactive &&
      datePickerValue < studyParticipant.getEnrollmentStartTime()?.toDate()!
    ) {
      setDatePickerError(true);
      setDatePickerErrorText(`Select a ${
        selectedStatus.dateFieldLabel?.toLowerCase() ?? "date"
      } that comes
        after this participant was enrolled${
          !isStudyParticipantEmpty(studyParticipant)
            ? format(
                studyParticipant!.getEnrollmentStartTime()!.toDate(),
                " (M/d/yyyy)"
              )
            : ""
        }.`);
      return false;
    }

    // Check that the enrollment date is before watch start date
    if (
      !selectedStatus.isInactive &&
      !isParticipantSessionNullOrEmpty(firstWatchAssociation) &&
      datePickerValue >
        firstWatchAssociation!.getInterval()!.getStartTime()!.toDate()
    ) {
      setDatePickerError(true);
      setDatePickerErrorText(
        `Select a date that comes before the participant received their first watch${
          !isParticipantSessionNullOrEmpty(participantSession)
            ? format(
                firstWatchAssociation!.getInterval()!.getStartTime()!.toDate(),
                " (M/d/yyyy)"
              )
            : ""
        }.`
      );
      return false;
    }

    // If selected date is in the future, throw an error
    const currentDate = parseDate(new Date());
    if (parseDate(datePickerValue) > currentDate) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a date that is today or earlier.");
      return false;
    }

    return true;
  }, [
    datePickerValue,
    setDatePickerError,
    setDatePickerErrorText,
    selectedStatus,
    studyParticipant,
    participantSession,
    firstWatchAssociation,
  ]);

  const updateParticipantStatus = useCallback(
    async (
      studyParticipant: StudyParticipant,
      status: number,
      statusDate: Date | undefined
    ) => {
      if (!isAuthenticated || !auth0Config) {
        onError("User is not authenticated. Please logout and login again.");
        return;
      }

      await (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const studyParticipantApiClient =
            new StudyParticipantServiceApiClient(auth0Config.audience!, token);

          // If status is Enrolled, then exitStudyDate should be undefined
          if (status === 1) {
            await studyParticipantApiClient.updateStudyParticipant(
              new UpdateStudyParticipantRequest()
                .setStudyParticipant(
                  new StudyParticipant()
                    .setName(studyParticipant.getName())
                    .setEnrollmentStartTime(Timestamp.fromDate(statusDate!))
                    .setEnrollmentStartDate(toDateProto(statusDate!))
                    .setStatus(status)
                    .setExitStudyDate(undefined)
                )
                .setUpdateMask(
                  new FieldMask().setPathsList([
                    "status",
                    "enrollment_start_time",
                    "exit_study_date",
                  ])
                )
                .setUpdateReason(updateReason)
            );
          } else {
            await studyParticipantApiClient.updateStudyParticipant(
              new UpdateStudyParticipantRequest()
                .setStudyParticipant(
                  new StudyParticipant()
                    .setName(studyParticipant.getName())
                    .setStatus(status)
                    .setExitStudyDate(
                      statusDate ? toDateProto(statusDate!) : undefined
                    )
                )
                .setUpdateMask(
                  new FieldMask().setPathsList(["status", "exit_study_date"])
                )
                .setUpdateReason(updateReason)
            );
          }

          onSuccess();
        } catch (error) {
          let errMsg = "Failed to update participant status. Please try again.";
          if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg = "User is not authorized to update participant status.";
          }

          onError(errMsg);
        }
      })();
    },
    [
      isAuthenticated,
      auth0Config,
      onError,
      getAccessTokenSilently,
      onSuccess,
      updateReason,
    ]
  );

  return (
    <>
      <Dialog open={open} fullScreen={true}>
        <StepsHeader
          title="Update participant status"
          stepId={stepId}
          steps={steps}
          onClose={onClose}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "24px 0px",
          }}
        >
          <Box width="50%">
            {steps.get(stepId)!.page}
            <Footer
              leftButtonText="Previous"
              isLeftButtonDisabled={steps.get(stepId)!.isPreviousDisabled}
              handleClickLeftButton={steps.get(stepId)!.onPrevious}
              rightButtonText={steps.get(stepId)!.nextButtonText}
              isRightButtonDisabled={steps.get(stepId)!.isNextButtonDisabled}
              isRightButtonLoading={steps.get(stepId)!.isNextButtonLoading}
              handleClickRightButton={steps.get(stepId)!.onNext}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default UpdateParticipantStatusDialog;
