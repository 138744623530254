import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as ComplianceSettingEmptyIcon } from "assets/compliance-setting-empty-icon.svg";
import { Button, Snackbar } from "@verily-src/react-design-system";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useComplianceSettingDataLoader from "./ComplianceSettingDataLoader";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "core/AppRoutes";
import SettingSummary from "./SettingSummary";
import { setShowSuccessMessage } from "./ComplianceSettingSlice";
import { usePageTitle } from "components/PageTitle";

const ComplianceSetting: React.FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const showSuccessMessage = useAppSelector(
    (state) => state.complianceSettingState.showSuccessMessage
  );
  const fromScreen = useAppSelector(
    (state) => state.complianceSettingState.fromScreen
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const editParametersButtonRef = useRef<HTMLButtonElement | null>(null);

  const { isLoading, hasError, complianceSetting, loadComplianceSetting } =
    useComplianceSettingDataLoader();

  // Set page title.
  usePageTitle("Compliance parameters");

  // Reload data if currently selected study changes.
  useEffect(() => {
    loadComplianceSetting(currentRegistryId);
  }, [currentRegistryId, loadComplianceSetting]);

  // Set focus on the edit parameters button after exit from edit compliance setting screen.
  useEffect(() => {
    if (!isLoading && fromScreen === AppRoutes.EDIT_COMPLIANCE_SETTING) {
      if (editParametersButtonRef.current) {
        editParametersButtonRef.current.focus();
      }

      if (showSuccessMessage) {
        // Add 500ms delay to show the snackbar so that the success message
        // is announced after the focused button is announced.
        const timeoutId = setTimeout(() => {
          setOpenSnackbar(true);
        }, 500);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [isLoading, fromScreen, showSuccessMessage]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        flexGrow: "1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography component="h1" variant="display5">
          Compliance parameters
        </Typography>
        {/* Show "Edit parameters" button if compliance setting exists */}
        {!isLoading && !hasError && complianceSetting !== null && (
          <Button
            variant="filled"
            label="Edit parameters"
            ref={editParametersButtonRef}
            onClick={() => {
              navigate(AppRoutes.EDIT_COMPLIANCE_SETTING);
            }}
          />
        )}
      </Box>
      {/* Show loading indicator while loading is in progress */}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Show error screen if there is a loading error */}
      {!isLoading && hasError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <Alert severity="error">
            Failed to load compliance parameters. Please try again.
          </Alert>
        </Box>
      )}
      {/* Show empty screen view if there is no compliance setting */}
      {!isLoading && !hasError && complianceSetting === null && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexGrow: "1",
          }}
        >
          <ComplianceSettingEmptyIcon />
          <Button
            variant="filled"
            label="Set compliance parameters"
            role="link"
            onClick={() => {
              navigate(AppRoutes.EDIT_COMPLIANCE_SETTING);
            }}
            sx={{
              marginTop: "44px",
            }}
          />
        </Box>
      )}
      {/* Show compliance setting if it exists */}
      {!isLoading && !hasError && complianceSetting !== null && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              padding: "8px 24px 24px 24px",
              background: theme.palette.background.canvas,
              borderRadius: "16px",
            }}
          >
            <SettingSummary
              complianceSetting={complianceSetting}
              showCompactMode={false}
            />
          </Box>
        </Box>
      )}
      <Snackbar
        role="alert"
        text="Parameters published"
        color="success"
        withIcon
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          dispatch(setShowSuccessMessage(false));
        }}
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default ComplianceSetting;
