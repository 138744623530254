import { TableCell, Typography, useTheme } from "@mui/material";
import { useRef } from "react";
import { useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import { tableCellStyle } from "./CalendarTypes";

// Implement a HeaderCell to allow roving-tabindex to cover header cells.
interface HeaderCellProps {
  day: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({ day }) => {
  // The ref to the TableCell element.
  const cellRef = useRef<HTMLTableCellElement>(null);

  // handleKeyDown and handleClick are stable for the lifetime of the component:
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    cellRef,
    false,
    0
  );

  // Set focus on the TableCell when needed.
  useFocusEffect(focused, cellRef);

  const theme = useTheme();
  return (
    <TableCell
      role="gridcell"
      ref={cellRef}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      sx={{
        ...tableCellStyle,
        backgroundColor: theme.palette.background.canvas,
        borderColor: theme.palette.background.separator,
        textAlign: "center",
        padding: "8px",
      }}
    >
      <Typography variant="overlineem">{day}</Typography>
    </TableCell>
  );
};
