import React from "react";
import { Alert, Box, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { WatchAssignmentFieldRef } from "./WatchAssignmentField";
import { RemovePicardPrefix } from "common/ResourceName";
import { format } from "date-fns";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";

interface ConfirmAddParticipantDialogProps {
  showConfirmAddParticipantDialog: boolean;
  setShowConfirmAddParticipantDialog: (show: boolean) => void;
  participantId: string;
  enrollmentStartDate: Date;
  selectedDeviceId: string;
  confirmLoading: boolean;
  setConfirmLoading: (loading: boolean) => void;
  addNewParticipant: () => void;
  watchAssignmentRef: React.RefObject<WatchAssignmentFieldRef>;
}

const ConfirmAddParticipantDialog: React.FC<
  ConfirmAddParticipantDialogProps
> = ({
  showConfirmAddParticipantDialog,
  setShowConfirmAddParticipantDialog,
  participantId,
  enrollmentStartDate,
  selectedDeviceId,
  confirmLoading,
  setConfirmLoading,
  addNewParticipant,
  watchAssignmentRef,
}) => {
  return (
    <Dialog
      open={showConfirmAddParticipantDialog}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">Confirm participant details</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Box paddingBottom="24px">
          <Alert severity="warning">
            Review and confirm the details below.
          </Alert>
        </Box>
        <Box paddingBottom="24px">
          <Typography component="h2" variant="body1em">
            Participant ID
          </Typography>
          <Typography>{participantId}</Typography>
        </Box>
        <Box paddingBottom="24px">
          <Typography component="h2" variant="body1em">
            Enrollment date
          </Typography>
          <Typography>{format(enrollmentStartDate, "M/d/yyyy")}</Typography>
        </Box>
        <Box paddingBottom="24px">
          <Typography component="h2" variant="body1em">
            Assigned watch
          </Typography>
          <Typography>{RemovePicardPrefix(selectedDeviceId)}</Typography>
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setShowConfirmAddParticipantDialog(false);
            watchAssignmentRef.current!.showDuplicatesDialog();
          }}
          sx={{
            marginRight: "8px",
          }}
        >
          Go back
        </Button>
        <Button
          variant="filled"
          loading={confirmLoading}
          onClick={async () => {
            setConfirmLoading(true);
            await addNewParticipant();
            setConfirmLoading(false);
            setShowConfirmAddParticipantDialog(false);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAddParticipantDialog;
