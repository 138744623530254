import React, { FC, useMemo, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TablePagination,
  Link,
} from "@mui/material";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import { ReactComponent as CheckIcon } from "assets/check-icon-emphasized.svg";
import { ReactComponent as CrcCompliantIcon } from "assets/crc-compliant-icon.svg";
import { ReactComponent as NotEnoughDataIllustration } from "assets/not-enough-data-illustration.svg";
import { ReactComponent as ZeroCompliantIllustration } from "assets/zero-compliant-illustration.svg";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ComplianceSettingPanel from "./ComplianceSettingPanel";
import useCrcCompliantTabDataLoader, {
  CompliantSummary,
} from "./CrcCompliantTabDataLoader";
import WeekNavigation from "core/WeekNavigation";
import { backgroud_success } from "styles/colors";
import {
  setCompliantTabSortOrder,
  setCompliantTabOrderBy,
  setCompliantTabPageNumber,
  setCompliantTabRowsPerPage,
  resetCompliantTabState,
} from "./CrcComplianceStateSlice";
import { useNavigate } from "react-router";
import { AppRoutes } from "core/AppRoutes";
import { ParticipantRecord } from "looker/QueryResultsCache";
import { setParticipantComplianceState } from "participantcompliance/ParticipantComplianceStateSlice";
import { clearCalendarViewCache } from "looker/CalendarViewQueryResultsCache";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import { tablePaginationSxProps } from "components/Tables";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import { isActivationKey } from "common/Accessibility";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import { RemovePicardPrefix } from "common/ResourceName";
import { Tooltip } from "@verily-src/react-design-system";
import A11yStatusMessage from "components/A11yStatusMessage";

interface CompliantTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const CompliantTableHeader: React.FC<CompliantTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="participant ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Participant ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="50%"
        >
          <Typography variant="body2em">Participant ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="50%"
        >
          <Typography variant="body2em">Device ID</Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

function getShowCalendarLinkAriaLabel(record: ParticipantRecord) {
  return `View Participant ID ${
    record.subjectId
  }, Device ID ${RemovePicardPrefix(record.deviceId)}`;
}

// Define a component to render compliant record
interface CompliantRowProps {
  record: ParticipantRecord;
  onClickNextButton(record: ParticipantRecord): void;
}

const CompliantRow: React.FC<CompliantRowProps> = ({
  record,
  onClickNextButton,
}) => {
  return (
    <TableRow
      hover
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClickNextButton(record);
      }}
    >
      <TableCell width="50%">{record.subjectId}</TableCell>
      <TableCell width="50%">{RemovePicardPrefix(record.deviceId)}</TableCell>
      <TableCell width="48px" sx={{ paddingRight: "12px" }}>
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickNextButton(record);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickNextButton(record);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getShowCalendarLinkAriaLabel(record)}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface CompliantTableContentProps {
  records: Array<ParticipantRecord>;
  onClickNextButton(record: ParticipantRecord): void;
}

const CompliantTableContent: React.FC<CompliantTableContentProps> = ({
  records,
  onClickNextButton,
}) => {
  return (
    <TableBody>
      {records.map((record) => (
        <CompliantRow
          key={record.subjectId}
          record={record}
          onClickNextButton={onClickNextButton}
        />
      ))}
    </TableBody>
  );
};

const HeaderMessage: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "start",
        alignItems: "center",
        marginTop: "24px",
        backgroundColor: backgroud_success,
        padding: "8px 8px 8px 24px",
        borderRadius: "16px",
      }}
    >
      <CheckIcon />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          margin: "0px 16px",
        }}
      >
        <Typography variant="body2">
          These participants deserve a pat on the back!
        </Typography>
      </Box>
      <CrcCompliantIcon />
    </Box>
  );
};

function getComliantNumber(summary: CompliantSummary) {
  if (summary.total <= 0) {
    return "--/--";
  } else {
    return `${summary.compliant}/${summary.total}`;
  }
}

const NotEnoughDataView: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NotEnoughDataIllustration />
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        Not enough data to show compliance.
      </Typography>
    </Box>
  );
};

const ZeroCompliantView: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ZeroCompliantIllustration />
      <Typography variant="body1" sx={{ fontWeight: 600, textAlign: "center" }}>
        No participants were compliant. Why not check in and see what's going
        on?
      </Typography>
    </Box>
  );
};

const CrcComplianceTab: FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );
  const dispatch = useAppDispatch();

  const { isLoading, hasError, summary, loadCompliantData } =
    useCrcCompliantTabDataLoader();

  // Reload data if currently selected study or week changes.
  useEffect(() => {
    loadCompliantData(currentRegistryId, previousWeekOffset);
  }, [currentRegistryId, previousWeekOffset, loadCompliantData]);

  const {
    compliantTabSortOrder: sortOrder,
    compliantTabOrderBy: orderBy,
    compliantTabPageNumber: page,
    compliantTabRowsPerPage: rowsPerPage,
  } = useAppSelector((state) => state.crcComplianceState);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    dispatch(setCompliantTabSortOrder(newSortOrder));
    dispatch(setCompliantTabOrderBy(orderByColumn));
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setCompliantTabPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setCompliantTabRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setCompliantTabPageNumber(0));
  };

  const sortedRecords = useMemo(() => {
    return summary.records.sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "participant ID":
          result = a.subjectId.localeCompare(b.subjectId);
          break;
        case "device ID":
          result = a.deviceId.localeCompare(b.deviceId);
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });
  }, [summary.records, orderBy, sortOrder]);

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginRight: "64px",
          flexGrow: 1,
        }}
      >
        <WeekNavigation
          enabled={!isLoading}
          previousWeekOffset={previousWeekOffset}
          onClickPreviousWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset + 1));
            dispatch(resetCompliantTabState());
          }}
          onClickNextWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset - 1));
            dispatch(resetCompliantTabState());
          }}
        />
        {isLoading && <SlowLoadingIndicator />}
        {!isLoading && (
          <>
            <Typography component="h2" variant="display6">
              {getComliantNumber(summary)} enrolled participants with active
              watches met all requirements
            </Typography>
            {!hasError && summary.total === 0 && <NotEnoughDataView />}
            {!hasError && summary.total > 0 && summary.compliant === 0 && (
              <ZeroCompliantView />
            )}
            {(hasError || (summary.total > 0 && summary.compliant > 0)) && (
              <>
                {!hasError && <HeaderMessage />}
                <TableContainer>
                  <Table
                    sx={{
                      minWidth: 700,
                      marginTop: "16px",
                      marginBottom: "8px",
                    }}
                    aria-label="compliant"
                  >
                    <CompliantTableHeader
                      order={sortOrder}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    {!hasError && (
                      <CompliantTableContent
                        records={sortedRecords.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )}
                        onClickNextButton={(record) => {
                          dispatch(
                            setParticipantComplianceState({
                              participantId: record.participantId,
                              subjectId: record.subjectId,
                              deviceId: record.deviceId,
                              returnRoute: AppRoutes.HOME,
                            })
                          );
                          clearCalendarViewCache();
                          navigate(AppRoutes.PARTICIPANT_COMPLIANCE);
                        }}
                      />
                    )}
                    {hasError && (
                      <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                        Failed to load compliant data
                      </Alert>
                    )}
                  </Table>
                  <A11yStatusMessage message={sortDirectiontMessage} />
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={sortedRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={tablePaginationSxProps}
                />
              </>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
        }}
      >
        <ComplianceSettingPanel registryId={currentRegistryId} />
      </Box>
    </Box>
  );
};

export default CrcComplianceTab;
