import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import { listStudyDetails } from "./ListStudyDetails";

// Load study details for the studies page.
function useStudyDetailDataLoader() {
  const auth0Config = useAppSelector((state) => state.auth0Config);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [studyDetails, setStudyDetails] = useState<Map<string, StudyDetail>>(
    new Map()
  );

  const loadAllStudyDetail = useCallback(
    (registryIds: string[]) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          setIsLoading(true);
          setHasError(false);

          try {
            if (registryIds.length === 0) {
              throw new Error("missing selected study");
            }

            // load study detail
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const details = await listStudyDetails(
              auth0Config.audience!,
              token,
              registryIds
            );

            const studyDetailsMap = new Map<string, StudyDetail>();
            if (details != null) {
              details.map((detail) =>
                studyDetailsMap.set(detail.getName().substring(13), detail)
              );
            }

            setStudyDetails(studyDetailsMap);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      }
    },
    [isAuthenticated, auth0Config, getAccessTokenSilently]
  );

  return {
    isLoading,
    hasError,
    studyDetails,
    loadAllStudyDetail,
  };
}

export default useStudyDetailDataLoader;
