import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Dialog, IconButton, Typography, useTheme } from "@mui/material";
import { Button, ArrowBackIcon } from "@verily-src/react-design-system";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import {
  CreateParticipantSessionRequest,
  ListParticipantSessionsRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { RpcError, StatusCode } from "grpc-web";
import { ParseIdFromName } from "common/ResourceName";
import { Interval } from "generated/google/type/interval_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import WatchAssignmentField, {
  WatchAssignmentFieldRef,
} from "./WatchAssignmentField";
import ConfirmAssignWatchDialog from "./ConfirmAssignWatchDialog";
import { focusOnRef } from "common/Accessibility";

interface AssignWatchDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The confirmation dialog for unassigning a watch
const AssignWatchDialog: React.FC<AssignWatchDialogProps> = ({
  open,
  studyParticipant,
  onClose,
  onSuccess,
  onError,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [mostRecentAssociation, setMostRecentAssociation] = useState<
    ParticipantSession | undefined
  >(undefined);

  const [validateLoading, setValidateLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [
    showConfirmWatchAssignmentDialog,
    setShowConfirmWatchAssignmentDialog,
  ] = useState(false);

  const theme = useTheme();

  const titleRef = useRef<any>(null);
  const watchAssignmentRef = useRef<WatchAssignmentFieldRef>(null);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setSelectedDeviceId("");
      setMostRecentAssociation(undefined);
      setValidateLoading(false);
      setConfirmLoading(false);
      setTimeout(() => {
        focusOnRef(titleRef);
      }, 0);
    }
  }, [open]);

  const createParticipantSession = useCallback(
    async (
      participant: StudyParticipant,
      deviceId: string,
      startTime?: Date
    ) => {
      if (!isAuthenticated || !auth0Config) {
        onError("User not authenticated. Please logout and login again.");
        return;
      }

      await (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const participantSessionApiClient =
            new ParticipantSessionServiceApiClient(
              auth0Config.audience!,
              token
            );

          const studyParticipantId = ParseIdFromName(participant.getName());
          const startTimestamp = new Timestamp();
          if (startTime) {
            startTimestamp.fromDate(startTime);
          } else {
            startTimestamp.fromDate(new Date());
          }

          await participantSessionApiClient.createParticipantSession(
            new CreateParticipantSessionRequest().setParticipantSession(
              new ParticipantSession()
                .setName(`participantSessions/${studyParticipantId}`)
                .setDeviceId(deviceId)
                .setInterval(new Interval().setStartTime(startTimestamp))
            )
          );

          setSelectedDeviceId("");
          onSuccess();
        } catch (error) {
          let errMsg = "Failed to assign watch. Please try again.";
          if (
            error instanceof RpcError &&
            error.code === StatusCode.ALREADY_EXISTS
          ) {
            errMsg = `Device ${deviceId} already associated to a participant`;
          } else if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg =
              "User is not authorized to assign a watch to this participant.";
          }

          onError(errMsg);
        }
      })();
    },
    [auth0Config, getAccessTokenSilently, isAuthenticated, onSuccess, onError]
  );

  const getMostRecentAssociation = useCallback(
    async (
      participant: StudyParticipant,
      deviceId: string
    ): Promise<ParticipantSession | undefined> => {
      if (!isAuthenticated || !auth0Config) {
        onError("User not authenticated. Please logout and login again.");
        return undefined;
      }

      const session = await (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const participantSessionApiClient =
            new ParticipantSessionServiceApiClient(
              auth0Config.audience!,
              token
            );

          const studyParticipantId = ParseIdFromName(participant.getName());
          const response =
            await participantSessionApiClient.listParticipantSessionHistory(
              new ListParticipantSessionsRequest().setParent(
                `studyParticipants/${studyParticipantId}`
              )
            );

          const participantSessionHistory = response.getSessionsList();

          // Most recent association should be first entry
          // List is sorted by start time in descending order
          if (
            participantSessionHistory.length > 0 &&
            participantSessionHistory[0].getDeviceId() === deviceId
          ) {
            return participantSessionHistory[0];
          }

          return undefined;
        } catch (error) {
          console.log("error reason %s", error);
          let errMsg = "Failed to assign watch. Please try again.";
          if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg =
              "User is not authorized to assign a watch to this participant.";
          }

          onError(errMsg);

          return undefined;
        }
      })();

      return session;
    },
    [auth0Config, getAccessTokenSilently, isAuthenticated, onError]
  );

  return (
    <>
      <Dialog open={open} fullScreen={true} fullWidth={true}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "space-between",
            padding: "24px 40px",
            background: theme.palette.background.canvas,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                onClose();
              }}
              sx={{ marginRight: "12px" }}
              aria-label="Back"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              component="h2"
              ref={titleRef}
              variant="display5"
              tabIndex={-1}
            >
              Assign watch
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              label="Cancel"
              color="primary"
              variant="outlined"
              onClick={onClose}
              sx={{
                marginRight: "8px",
              }}
            />
            <Button
              label="Save"
              variant="filled"
              loading={validateLoading}
              disabled={validateLoading}
              onClick={async () => {
                setValidateLoading(true);
                const isDeviceValid =
                  watchAssignmentRef.current!.validateDeviceTextField();
                if (isDeviceValid) {
                  await watchAssignmentRef.current!.searchDevices();
                }
                setValidateLoading(false);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            alignSelf: "center",
            padding: "40px 0px",
            width: "464px !important",
          }}
        >
          <WatchAssignmentField
            ref={watchAssignmentRef}
            registryId={studyParticipant.getRegistryId()}
            onSuccess={async () => {
              const deviceId = watchAssignmentRef.current!.fetchDevice();
              setSelectedDeviceId(deviceId);
              setMostRecentAssociation(
                await getMostRecentAssociation(studyParticipant, deviceId)
              );
              setShowConfirmWatchAssignmentDialog(true);
            }}
          ></WatchAssignmentField>
          <ConfirmAssignWatchDialog
            studyParticipant={studyParticipant}
            mostRecentAssociation={mostRecentAssociation}
            selectedDeviceId={selectedDeviceId}
            confirmLoading={confirmLoading}
            setConfirmLoading={setConfirmLoading}
            showConfirmWatchAssignmentDialog={showConfirmWatchAssignmentDialog}
            setShowConfirmWatchAssignmentDialog={
              setShowConfirmWatchAssignmentDialog
            }
            createParticipantSession={createParticipantSession}
            watchAssignmentRef={watchAssignmentRef}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AssignWatchDialog;
