import { AppConfig } from "./AppConfigSlice";
import { CONFIGS } from "./EnvConfigs";

/**
 * Retrieves the app configuration.
 */
class AppConfigManager {
  appConfig?: AppConfig;

  loadAppConfig(): Promise<AppConfig | undefined> {
    return new Promise<AppConfig | undefined>((resolve) => {
      fetch("/config/app.config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.appConfig = CONFIGS[jsonData.environment];
          resolve(this.appConfig);
        })
        .catch((error) => {
          console.log("Cannot parse app config: " + error);
          resolve(undefined);
        });
    });
  }
}

const appConfigManager = new AppConfigManager();

export default appConfigManager;
