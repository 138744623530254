import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Dialog as MUIDialog, Typography } from "@mui/material";
import { RadioGroup, Radio, TextField } from "@verily-src/react-design-system";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import {
  LookupSponsorParticipantRequest,
  StudyParticipant,
  UpdateStudyParticipantRequest,
} from "generated/studyparticipant/studyparticipant_pb";
import { Footer } from "components/Footer";
import { RpcError, StatusCode } from "grpc-web";
import {
  Option,
  RadioOptions,
  EditParticipantIdReasonOptions,
} from "common/RadioOptions";
import { StepInfo, Steps, EditParticipantIdSteps } from "common/Steps";
import DuplicateParticipantFoundDialog from "./DuplicateParticipantFoundDialog";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import ContactVerilyDialog from "components/ContactVerilyDialog";
import { ManageWatchRedirectDialog } from "./ManageWatchRedirectDialog";
import { focusOnRef } from "common/Accessibility";
import StepsHeader from "components/StepsHeader";
import HeaderValuePair from "components/HeaderValuePair";

// Radio button selections for the task
const editReasonSelection = new RadioOptions([
  {
    id: EditParticipantIdReasonOptions.Typo,
    label:
      "I have to fix a typo. The participant’s watch assignment won’t change.",
    nextStepId: EditParticipantIdSteps.EditParticipantId,
  },
  {
    id: EditParticipantIdReasonOptions.Error,
    label: "The ID was associated with this watch by mistake.",
    nextStepId: -1,
  },
  {
    id: EditParticipantIdReasonOptions.Other,
    label: "Other",
    nextStepId: -1,
  },
]);

interface EditParticipantIdDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const EditParticipantIdDialog: React.FC<EditParticipantIdDialogProps> = ({
  open,
  studyParticipant,
  onClose,
  onSuccess,
  onError,
}) => {
  // Get RegistryId
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedReason, setSelectedReason] = useState<Option>(
    editReasonSelection.default
  );

  const [focusIndex, setFocusIndex] = useState(-1);
  const optionsRef = useRef(
    Array.from(editReasonSelection.options).map(() =>
      createRef<HTMLInputElement>()
    )
  );

  const [validateLoading, setValidateLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  useEffect(() => {
    setFocusIndex(-1);
  }, [open]);

  useEffect(() => {
    if (focusIndex > -1) {
      focusOnRef(optionsRef.current[focusIndex]);
    }
  }, [focusIndex]);

  const [newParticipantId, setNewParticipantId] = useState<string>("");
  const [newIdTextFieldError, setNewIdTextFieldError] =
    useState<boolean>(false);
  const [newIdTextFieldHelperText, setNewIdTextFieldHelperText] =
    useState<string>("");

  const stepInfo: Array<StepInfo> = [
    {
      id: EditParticipantIdSteps.EditReason,
      page: (
        <RadioGroup>
          <Box
            sx={{
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Typography component="h2" variant="display6">
              Why do you need to edit this participant’s ID?
            </Typography>
          </Box>
          {Array.from(editReasonSelection.options).map(
            ([_, option]: [number, Option], index) => {
              return (
                <Radio
                  inputRef={optionsRef.current[index]}
                  key={index}
                  label={option.label}
                  value={option.nextStepId}
                  checked={selectedReason.id === option.id}
                  onChange={() => {
                    setSelectedReason(option);
                    setFocusIndex(index);
                  }}
                />
              );
            }
          )}
        </RadioGroup>
      ),
      isPreviousDisabled: true,
      onPrevious: () => {},
      nextButtonText: "Next",
      isNextButtonDisabled: (() => {
        return selectedReason.id === -1;
      })(),
      onNext: () => {
        switch (selectedReason.id) {
          case EditParticipantIdReasonOptions.Other:
            setShowContactVerilyDialog(true);
            break;
          case EditParticipantIdReasonOptions.Error:
            setShowRedirectDialog(true);
            break;
          default:
            setPrevStepId(EditParticipantIdSteps.EditReason);
            setStepId(selectedReason.nextStepId);
            break;
        }
      },
    },
    {
      id: EditParticipantIdSteps.EditParticipantId,
      page: (
        <>
          <Box sx={{ paddingBottom: "24px" }}>
            <HeaderValuePair
              component="h2"
              title="Previous participant ID"
              variant_title="display6"
              fontFamily="Roboto Mono"
              value={studyParticipant.getSponsorParticipantId()}
            />
          </Box>
          <Box sx={{ paddingBottom: "24px" }}>
            <Typography component="h2" variant="display6">
              New participant ID
            </Typography>
            <TextField
              label="Participant ID (required)"
              value={newParticipantId}
              placeholder="XXXXX"
              error={newIdTextFieldError}
              helperText={newIdTextFieldHelperText}
              onChange={(e) => {
                setNewIdTextFieldError(false);
                setNewIdTextFieldHelperText("");
                setNewParticipantId(e.target.value);
              }}
              sx={{
                marginTop: "8px",
                display: "flex",
                justifySelf: "start",
              }}
            ></TextField>
          </Box>
        </>
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(-1);
      },
      isPreviousDisabled: false,
      nextButtonText: "Next",
      isNextButtonDisabled: false,
      isNextButtonLoading: validateLoading,
      onNext: async () => {
        setValidateLoading(true);
        if (await validateParticipantId()) {
          setPrevStepId(EditParticipantIdSteps.EditParticipantId);
          setStepId(EditParticipantIdSteps.ConfirmParticipantId);
        }
        setValidateLoading(false);
      },
    },
    {
      id: EditParticipantIdSteps.ConfirmParticipantId,
      page: (
        <>
          <Typography component="h2" variant="display6">
            Confirm participant ID change
          </Typography>
          <Box sx={{ padding: "24px 0px" }}>
            <HeaderValuePair
              component="h3"
              title="Previous participant ID"
              fontFamily="Roboto Mono"
              value={studyParticipant.getSponsorParticipantId()}
            />
          </Box>
          <Box>
            <HeaderValuePair
              component="h3"
              title="New participant ID"
              fontFamily="Roboto Mono"
              value={newParticipantId}
            />
          </Box>
          <Box sx={{ padding: "24px 0px" }}>
            <HeaderValuePair
              component="h3"
              title="Update Reason"
              value={selectedReason.label}
            />
          </Box>
        </>
      ),
      onPrevious: () => {
        setStepId(prevStepId);
        setPrevStepId(EditParticipantIdSteps.EditReason);
      },
      isPreviousDisabled: false,
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      isNextButtonLoading: editLoading,
      onNext: async () => {
        setEditLoading(true);
        await editParticipantId();
        setEditLoading(false);
      },
    },
  ];

  const steps: Steps = new Steps(stepInfo, EditParticipantIdSteps.EditReason);
  const [prevStepId, setPrevStepId] = useState<number>(-1);
  const [stepId, setStepId] = useState<number>(steps.default.id);

  const [showDuplicateParticipantDialog, setShowDuplicateParticipantDialog] =
    useState<boolean>(false);
  const [showRedirectDialog, setShowRedirectDialog] = useState<boolean>(false);
  const [showContactVerilyDialog, setShowContactVerilyDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setSelectedReason(editReasonSelection.default);
      setPrevStepId(-1);
      setStepId(steps.default.id);
      setNewParticipantId("");
      setNewIdTextFieldError(false);
      setNewIdTextFieldHelperText("");
      setValidateLoading(false);
      setEditLoading(false);
    }
  }, [open, steps.default.id]);

  const validateParticipantId = useCallback(async () => {
    const newParticipantIdTrimmed = newParticipantId.trim();
    if (newParticipantIdTrimmed.length === 0) {
      setNewIdTextFieldError(true);
      setNewIdTextFieldHelperText("Participant ID Required");
      return false;
    }

    if (!isAuthenticated || !auth0Config) {
      onError("User not authenticated. Please logout and login again.");
      return false;
    }

    const isAvailableParticipantId = await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new StudyParticipantServiceApiClient(
          auth0Config.audience!,
          token
        );

        await client.lookupSponsorParticipant(
          new LookupSponsorParticipantRequest()
            .setRegistryId(currentRegistryId)
            .setSponsorParticipantId(newParticipantIdTrimmed)
        );

        // If lookupSponsorParticipant doesn't throw a NOT_FOUND error,
        // then the participant ID is already in use
        setShowDuplicateParticipantDialog(true);
        return false;
      } catch (error) {
        let errMsg = "Failed to update participant ID. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to update participant.";
        } else if (
          error instanceof RpcError &&
          error.code === StatusCode.NOT_FOUND
        ) {
          // We want the participant to be missing
          return true;
        }

        onError(errMsg);
        return false;
      }
    })();

    return isAvailableParticipantId;
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    currentRegistryId,
    newParticipantId,
    onError,
  ]);

  const editParticipantId = useCallback(async () => {
    if (!isAuthenticated || !auth0Config) {
      onError("User not authenticated. Please logout and login again.");
      return;
    }

    await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new StudyParticipantServiceApiClient(
          auth0Config.audience!,
          token
        );

        await client.updateStudyParticipant(
          new UpdateStudyParticipantRequest()
            .setStudyParticipant(
              new StudyParticipant()
                .setName(studyParticipant.getName())
                .setSponsorParticipantId(newParticipantId)
            )
            .setUpdateMask(
              new FieldMask().setPathsList(["sponsor_participant_id"])
            )
            .setUpdateReason(selectedReason.label)
        );

        onSuccess();
      } catch (error) {
        let errMsg = "Failed to update participant ID. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to update participant.";
        } else if (
          error instanceof RpcError &&
          error.code === StatusCode.NOT_FOUND
        ) {
          errMsg = "Participant not found.";
        }

        onError(errMsg);
      }
    })();
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    newParticipantId,
    studyParticipant,
    onError,
    selectedReason,
  ]);

  return (
    <MUIDialog open={open} fullScreen={true}>
      <StepsHeader
        title="Edit participant ID"
        stepId={stepId}
        steps={steps}
        onClose={onClose}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          padding: "24px 0px",
        }}
      >
        <Box width="40%">
          {steps.get(stepId)!.page}
          <Footer
            leftButtonText="Previous"
            isLeftButtonDisabled={steps.get(stepId)!.isPreviousDisabled}
            handleClickLeftButton={steps.get(stepId)!.onPrevious}
            rightButtonText={steps.get(stepId)!.nextButtonText}
            isRightButtonDisabled={steps.get(stepId)!.isNextButtonDisabled}
            isRightButtonLoading={steps.get(stepId)!.isNextButtonLoading}
            handleClickRightButton={steps.get(stepId)!.onNext}
          />
        </Box>
      </Box>
      <DuplicateParticipantFoundDialog
        open={showDuplicateParticipantDialog}
        onClose={() => {
          setShowDuplicateParticipantDialog(false);
        }}
      ></DuplicateParticipantFoundDialog>
      <ManageWatchRedirectDialog
        open={showRedirectDialog}
        onExit={() => {
          setShowRedirectDialog(false);
          onClose();
        }}
        onCancel={() => {
          setShowRedirectDialog(false);
        }}
      />
      <ContactVerilyDialog
        open={showContactVerilyDialog}
        onClose={() => {
          setShowContactVerilyDialog(false);
        }}
      />
    </MUIDialog>
  );
};

export default EditParticipantIdDialog;
