import { useAppSelector, useAppDispatch } from "redux/hooks";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import {
  setShowLoadUserError,
  setShowEditUserError,
  setShowInviteUserError,
  setShowDeleteUserError,
  setShowSaveComplianceSettingError,
} from "test_tools/TestConfigSlice";

function TestTools() {
  const dispatch = useAppDispatch();
  const testConfig = useAppSelector((state) => state.testConfig);

  return (
    <div className="main-content-page">
      <Typography variant="display4">Testing</Typography>
      <FormGroup
        sx={{
          marginTop: "16px",
          padding: "16px",
          border: "1px solid #CED4D8",
          borderRadius: "4px",
          width: "500px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={testConfig.showLoadUserError}
              onChange={(event) => {
                dispatch(setShowLoadUserError(event.target.checked));
              }}
            />
          }
          label="Show load user error"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={testConfig.showInviteUserError}
              onChange={(event) => {
                dispatch(setShowInviteUserError(event.target.checked));
              }}
            />
          }
          label="Show invite user error"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={testConfig.showEditUserError}
              onChange={(event) => {
                dispatch(setShowEditUserError(event.target.checked));
              }}
            />
          }
          label="Show edit user error"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={testConfig.showDeleteUserError}
              onChange={(event) => {
                dispatch(setShowDeleteUserError(event.target.checked));
              }}
            />
          }
          label="Show delete user error"
        />
      </FormGroup>

      <FormGroup
        sx={{
          marginTop: "16px",
          padding: "16px",
          border: "1px solid #CED4D8",
          borderRadius: "4px",
          width: "500px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={testConfig.showSaveComplianceSettingError}
              onChange={(event) => {
                dispatch(
                  setShowSaveComplianceSettingError(event.target.checked)
                );
              }}
            />
          }
          label="Show publish compliance setting error"
        />
      </FormGroup>
    </div>
  );
}

export default TestTools;
