import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import {
  card_border_radius,
  card_padding,
  compliance_setting_card_min_width,
} from "styles/Dimensions";
import { NumberInput } from "./NumberInput";
import { DaysPerWeekSelection } from "./DaysPerWeekSelection";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";

// Define a component to set wear time compliance parameters.
interface WearTimeSettingCardProps {
  initValue: ComplianceSetting | null;
}

// Defines an interface for the functions exposed from WearTimeSettingCard
export interface WearTimeSettingCardRef {
  validateInput: () => boolean;
  getDaysPerWeek: () => number;
  getHoursPerDay: () => number;
}

export const WearTimeSettingCard = forwardRef<
  WearTimeSettingCardRef,
  WearTimeSettingCardProps
>(({ initValue }, ref) => {
  const [daysPerWeek, setDaysPerWeek] = useState<number | undefined>(undefined);
  const [showDaysPerWeekError, setShowDaysPerWeekError] = useState(false);
  const [hoursPerDay, setHoursPerDay] = useState(0);
  const [showHoursPerDayError, setShowHoursPerDayError] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (initValue) {
      setDaysPerWeek(initValue.getWearTimeDaysPerWeek());
      setHoursPerDay(initValue.getWearTimeHoursPerDay());
    } else {
      setDaysPerWeek(undefined);
      setHoursPerDay(0);
    }

    setShowDaysPerWeekError(false);
    setShowHoursPerDayError(false);
  }, [initValue]);

  const validateDaysPerWeek = useCallback(
    (daysPerWeek: number | undefined) => {
      let isValid = daysPerWeek !== undefined;
      setShowDaysPerWeekError(!isValid);
      return isValid;
    },
    [setShowDaysPerWeekError]
  );

  const validateHoursPerDay = useCallback(
    (hoursPerDay: number) => {
      const isValid = hoursPerDay >= 1 && hoursPerDay <= 23;
      setShowHoursPerDayError(!isValid);
      return isValid;
    },
    [setShowHoursPerDayError]
  );

  const validateInput = useCallback(() => {
    let isInputsValid = true;
    if (!validateDaysPerWeek(daysPerWeek)) {
      isInputsValid = false;
    }

    if (!validateHoursPerDay(hoursPerDay)) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, [daysPerWeek, hoursPerDay, validateDaysPerWeek, validateHoursPerDay]);

  const getDaysPerWeek = useCallback(() => {
    return daysPerWeek === undefined ? 0 : daysPerWeek;
  }, [daysPerWeek]);

  const getHoursPerDay = useCallback(() => {
    return hoursPerDay;
  }, [hoursPerDay]);

  // Expose functions to parent components.
  useImperativeHandle(
    ref,
    () => ({
      validateInput,
      getDaysPerWeek,
      getHoursPerDay,
    }),
    [getDaysPerWeek, getHoursPerDay, validateInput]
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        minWidth: compliance_setting_card_min_width,
        padding: card_padding,
        borderRadius: card_border_radius,
        background: theme.palette.background.canvas,
      }}
    >
      <Typography
        component="h2"
        variant="display6"
        fontWeight="600"
        sx={{ marginBottom: "24px" }}
      >
        Watch wear time (required)
      </Typography>
      <Typography component="h3" variant="body1" fontWeight="600">
        Days per week
      </Typography>
      <Typography variant="caption" sx={{ margin: "8px 0px" }}>
        Weeks start Sunday and end Saturday
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          marginTop: "8px",
          marginBottom: "22px",
        }}
      >
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <DaysPerWeekSelection
          daysPerWeek={daysPerWeek}
          labelId="wear-time-days-per-week"
          helperText={showDaysPerWeekError ? "Required" : ""}
          showError={showDaysPerWeekError}
          onDaysPerWeekChange={(value) => setDaysPerWeek(value)}
          onValidate={() => {
            validateDaysPerWeek(daysPerWeek);
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          day(s) per week
        </Typography>
      </Box>
      <Divider
        sx={{
          width: "100%",
          marginTop: "8px",
          marginBottom: "24px",
          opacity: 1,
          borderColor: theme.palette.background.separator,
        }}
      />
      <Typography component="h3" variant="body1" fontWeight="600">
        Hours per day
      </Typography>
      <Typography variant="caption" sx={{ margin: "8px 0px" }}>
        People will remove the watch for at least 1 hour per day, due to
        showering and other activities.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "top",
          marginTop: "8px",
          marginBottom: "12px",
        }}
      >
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          At least
        </Typography>
        <NumberInput
          id="hoursPerDay"
          value={hoursPerDay}
          label="Number of"
          placeholderText="##"
          helperText={
            showHoursPerDayError
              ? "Enter a number between 1 to 23"
              : "Between 1 to 23"
          }
          showError={showHoursPerDayError}
          onChange={(newValue) => {
            setHoursPerDay(newValue);
          }}
          onMouseLeave={() => {
            validateHoursPerDay(hoursPerDay);
          }}
        />
        <Typography variant="body1" sx={{ marginTop: "16px" }}>
          hour(s) per day
        </Typography>
      </Box>
    </Box>
  );
});
