import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  IconButton,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { User, StudyRole, Role } from "generated/studyauth/studyauth_pb";
import {
  Button,
  Search,
  Snackbar,
  Tooltip,
} from "@verily-src/react-design-system";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import {
  getAddUserButtonAriaLabel,
  getDeleteUserIconAriaLabel,
  getEditUserIconAriaLabel,
  getInvitationDisplayStatus,
  getUserIndex,
  getUserRoleDescription,
  InvitationStatusView,
  restoreUserRecordPosition,
} from "components/UserRenderers";
import InviteStudySiteUserDialog from "./InviteStudySiteUserDialog";
import { StudySite } from "generated/studysite/studysite_pb";
import { useAppSelector } from "redux/hooks";
import EditStudySiteUserDialog from "./EditStudySiteUserDialog";
import ConfirmDeleteStudySiteUserDialog from "./ConfirmDeleteStudySiteUserDialog";
import { tablePaginationSxProps } from "components/Tables";
import { getStudyUsersAlert } from "./StudyUsersAlert";
import A11yStatusMessage, {
  getSearchResultStatusMessage,
} from "components/A11yStatusMessage";
import { sxFocusWithin } from "common/Accessibility";
import { ManageUserDetailsTableHeaderCell } from "components/ManageUserDetailsTableHeaderCell";

interface StudySiteUsersTableHeaderProps {
  isEditable: boolean;
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const StudySiteUsersTableHeader: React.FC<StudySiteUsersTableHeaderProps> = ({
  isEditable,
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="name"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Name"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="25%"
          sx={{ paddingLeft: "16px" }}
        >
          <Typography variant="body2em">Name</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="email"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Email"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="25%"
        >
          <Typography variant="body2em">Email</Typography>
        </SortableTableHeaderCell>
        <TableCell width="25%">
          <Typography variant="body2em">Site</Typography>
        </TableCell>
        <SortableTableHeaderCell
          columnName="status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Status"
          onRequestSort={onRequestSort}
          width="25%"
        >
          <Typography variant="body2em">Status</Typography>
        </SortableTableHeaderCell>
        {isEditable && <ManageUserDetailsTableHeaderCell />}
      </TableRow>
    </TableHead>
  );
};

function getUserName(user: User) {
  return user.getFirstName() + " " + user.getLastName();
}

interface StudySiteUsersTableContentProps {
  isEditable: boolean;
  users: Array<User>;
  registryId: string;
  userRolesMap: Map<string, StudyRole[]>;
  studySiteNamesMap: Map<string, string>;
  onClickEditButton(user: User): void;
  onClickDeleteButton(user: User): void;
}

const StudySiteUsersTableContent: React.FC<StudySiteUsersTableContentProps> = ({
  isEditable,
  users,
  registryId,
  userRolesMap,
  studySiteNamesMap,
  onClickEditButton,
  onClickDeleteButton,
}) => {
  const getUserSiteName = (userName: string) => {
    const userRoles = userRolesMap.get(userName);
    if (userRoles) {
      for (var role of userRoles) {
        if (
          role.getRegistryId() === registryId &&
          role.getRole() === Role.CRC
        ) {
          const studySiteName = studySiteNamesMap.get(
            "studySites/" + role.getStudySiteId()
          );

          // Just return since a CRC can have only one assigned study site per registry.
          return studySiteName === null ? "" : studySiteName;
        }
      }
    }
    return "";
  };

  return (
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.getName()}>
          <TableCell width="25%" sx={{ paddingLeft: "16px" }}>
            {getUserName(user)}
          </TableCell>
          <TableCell width="25%">{user.getEmailAddress()}</TableCell>
          <TableCell width="25%">{getUserSiteName(user.getName())}</TableCell>
          <TableCell width="25%">
            <InvitationStatusView
              invitationStatus={user.getInvitationStatus()}
            />
          </TableCell>
          {isEditable && (
            <TableCell width="64px" sx={{ paddingRight: "0px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
                  <Tooltip title="Edit">
                    <IconButton
                      tabIndex={0}
                      onClick={() => {
                        onClickEditButton(user);
                      }}
                    >
                      <EditIcon
                        titleAccess={getEditUserIconAriaLabel(
                          Role.CRC,
                          getUserName(user)
                        )}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ marginRight: "8px", ...sxFocusWithin }}>
                  <Tooltip title="Delete">
                    <IconButton
                      tabIndex={0}
                      onClick={() => {
                        onClickDeleteButton(user);
                      }}
                    >
                      <DeleteIcon
                        titleAccess={getDeleteUserIconAriaLabel(
                          Role.CRC,
                          getUserName(user)
                        )}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

interface StudySiteUsersTableControlProps {
  isEditable: boolean;
  searchText: string;
  onRequestSearch(searchText: string): void;
  onClickInviteUserButton(): void;
}

const StudySiteUsersTableControl: React.FC<StudySiteUsersTableControlProps> = ({
  isEditable,
  searchText,
  onRequestSearch,
  onClickInviteUserButton,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "8px 0px 8px 4px",
      }}
    >
      <Search
        placeHolder="Search"
        textFieldProps={{
          defaultValue: searchText,
          showClearInputButton: false,
          onChange: (e) => {
            onRequestSearch(e.target.value);
          },
        }}
        fullWidth={false}
        sx={{ width: "300px" }}
      />
      {isEditable && (
        <Button
          label="Add user"
          aria-label={getAddUserButtonAriaLabel(Role.CRC)}
          variant="filled"
          icon={<AddIcon />}
          iconPosition="start"
          onClick={onClickInviteUserButton}
        />
      )}
    </Box>
  );
};

interface StudySiteUsersProps {
  users: User[];
  userRolesMap: Map<string, StudyRole[]>;
  studySites: StudySite[];
  /** Only show user who has the given role in the registry */
  registryIdFilter: string;
  isEditable: boolean;
  reloadData(): void;
}

const StudySiteUsers: React.FC<StudySiteUsersProps> = ({
  users,
  userRolesMap,
  studySites,
  registryIdFilter,
  isEditable,
  reloadData,
}) => {
  const studySiteNamesMap = useMemo(
    () =>
      new Map(
        studySites.map((studySite) => [
          studySite.getName(),
          studySite.getSiteName(),
        ])
      ),
    [studySites]
  );

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchText, setSearchText] = React.useState("");

  const [showInviteDialog, setShowInviteDialog] = useState(false);

  const userToEdit = useRef(new User());
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);

  // Stored the edited user index to the filterAndSortedUsers list
  // to keep its current position after editing.
  const editedUserIndex = useRef(-1);

  const userToDelete = useRef(new User());
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbarMessage, setShowSnackbarMessage] = useState(false);

  const resetEditedUserIndex = () => {
    editedUserIndex.current = -1;
  };

  useEffect(() => {
    resetEditedUserIndex();
  }, []);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(orderByColumn);
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Stores previous search string.
  const previousSearch = useRef("");

  // Status message about number of search results for a11y support.
  const [searchResultMessage, setSearchResultMessage] = useState("");

  const [filteredAndSortedUsers, setFilteredAndSortedUsers] = useState(
    new Array<User>()
  );

  useEffect(() => {
    const userList = users
      .filter((user) => {
        const skipFilter =
          searchText === "" ||
          (editedUserIndex.current >= 0 &&
            userToEdit.current.getName() === user.getName());

        if (!skipFilter) {
          const searchTextLowercase = searchText.toLowerCase();
          if (
            getUserName(user).toLowerCase().indexOf(searchTextLowercase) ===
              -1 &&
            user
              .getEmailAddress()
              .toLowerCase()
              .indexOf(searchTextLowercase) === -1
          ) {
            return false;
          }
        }

        const studyRoles = userRolesMap.get(user.getName());
        if (studyRoles === undefined) {
          return false;
        }

        let hasRequiredRole = false;
        for (let studyRole of studyRoles) {
          if (
            registryIdFilter === studyRole.getRegistryId() &&
            studyRole.getRole() === Role.CRC
          ) {
            hasRequiredRole = true;
            break;
          }
        }

        return hasRequiredRole;
      })
      .sort((a, b) => {
        let result = 0;
        switch (orderBy) {
          case "name":
            result = getUserName(a).localeCompare(getUserName(b));
            break;
          case "email":
            result = a.getEmailAddress().localeCompare(b.getEmailAddress());
            break;
          case "status":
            result = getInvitationDisplayStatus(
              a.getInvitationStatus()
            ).localeCompare(
              getInvitationDisplayStatus(b.getInvitationStatus())
            );
            break;
        }
        return order === "asc" ? result : -result;
      });

    // Keep position of an edited user.
    if (editedUserIndex.current >= 0) {
      restoreUserRecordPosition(
        userList,
        userToEdit.current.getName(),
        editedUserIndex.current
      );

      // Do not reset the editedUserIndex here since this function is called
      // multiple times before the list of users are rendered.
    }

    setFilteredAndSortedUsers(userList);

    // Only update status message if triggered by search text change.
    if (previousSearch.current !== searchText) {
      setSearchResultMessage(getSearchResultStatusMessage(userList.length));
      previousSearch.current = searchText;
    }
  }, [users, searchText, userRolesMap, registryIdFilter, orderBy, order]);

  const theme = useTheme();

  const registryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          width: "100%",
        }}
      >
        <A11yStatusMessage message={searchResultMessage} />
        <StudySiteUsersTableControl
          isEditable={isEditable}
          searchText={searchText}
          onRequestSearch={(searchText) => {
            resetEditedUserIndex();
            setSearchText(searchText);
            setPage(0);
          }}
          onClickInviteUserButton={() => {
            resetEditedUserIndex();
            setShowInviteDialog(true);
          }}
        />
        <Typography variant="body1em" sx={{ padding: "16px" }}>
          {getUserRoleDescription(Role.CRC)}
        </Typography>
        {!isEditable && (
          <Box
            sx={{
              width: "100%",
              padding: "8px 16px",
              borderRadius: "16px",
              backgroundColor: theme.palette.background.canvas,
            }}
          >
            <Typography variant="body2">
              {getStudyUsersAlert(registryId)}
            </Typography>
          </Box>
        )}
        <TableContainer>
          <Table sx={{ minWidth: 700, marginBottom: "8px" }} aria-label="users">
            <StudySiteUsersTableHeader
              isEditable={isEditable}
              order={order}
              orderBy={orderBy}
              onRequestSort={(orderBy) => {
                resetEditedUserIndex();
                handleRequestSort(orderBy);
              }}
            />
            <StudySiteUsersTableContent
              isEditable={isEditable}
              registryId={registryIdFilter}
              users={filteredAndSortedUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              userRolesMap={userRolesMap}
              studySiteNamesMap={studySiteNamesMap}
              onClickEditButton={(user) => {
                resetEditedUserIndex();
                userToEdit.current = user;
                setShowEditUserDialog(true);
              }}
              onClickDeleteButton={(user) => {
                resetEditedUserIndex();
                userToDelete.current = user;
                setShowDeleteUserDialog(true);
              }}
            />
          </Table>
          <A11yStatusMessage message={sortDirectiontMessage} />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={tablePaginationSxProps}
        />
      </Box>
      <InviteStudySiteUserDialog
        open={showInviteDialog}
        registryId={registryIdFilter}
        studySites={studySites}
        onClose={() => {
          setShowInviteDialog(false);
        }}
        onSuccess={() => {
          setShowInviteDialog(false);
          setSnackbarMessage("Invitation sent");
          setShowSnackbarMessage(true);
          reloadData();
        }}
      />
      <EditStudySiteUserDialog
        open={showEditUserDialog}
        user={userToEdit.current}
        userRoles={userRolesMap.get(userToEdit.current.getName())}
        registryId={registryIdFilter}
        studySites={studySites}
        onClose={() => {
          setShowEditUserDialog(false);
        }}
        onSuccess={() => {
          setShowEditUserDialog(false);
          setSnackbarMessage("User updated");
          setShowSnackbarMessage(true);
          editedUserIndex.current = getUserIndex(
            filteredAndSortedUsers,
            userToEdit.current.getName()
          );
          reloadData();
        }}
      />
      <ConfirmDeleteStudySiteUserDialog
        open={showDeleteUserDialog}
        user={userToDelete.current}
        userRoles={userRolesMap.get(userToDelete.current.getName())}
        registryId={registryIdFilter}
        onClose={() => {
          setShowDeleteUserDialog(false);
        }}
        onSuccess={() => {
          setShowDeleteUserDialog(false);
          setSnackbarMessage("User removed");
          setShowSnackbarMessage(true);
          reloadData();
        }}
      />
      <Snackbar
        role="alert"
        text={snackbarMessage}
        color="success"
        withIcon
        open={showSnackbarMessage}
        onClose={() => {
          setShowSnackbarMessage(false);
        }}
        autoHideDuration={3000}
      />
    </>
  );
};

export default StudySiteUsers;
