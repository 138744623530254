export const a11yOutlineBorder = "2px solid #007aff";

// sx properties to add a border to a box if any element within it has focus.
export const sxFocusWithin = {
  "&:focus-within": {
    outline: a11yOutlineBorder,
  },
};

// sx properties for visually hidden element but accessible to screen readers.
// See reference of the styles:
//  https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
export const sxVisuallyHidden = {
  border: "0",
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: "0",
  position: "absolute",
  width: "1px",
};

// Check if the key is "Enter" or "Space" key to activiate a link.
export function isActivationKey(event: React.KeyboardEvent) {
  return event.key === "Enter" || event.key === " ";
}

export function isEscapeKey(event: React.KeyboardEvent) {
  return event.key === "Escape";
}

// Helper function to focus on a ref if it exists.
export function focusOnRef(
  ref: React.RefObject<HTMLElement | HTMLButtonElement>
) {
  if (ref.current) {
    ref.current.focus();
  }
}

// Allow components to fill the whole screen for accessibility
export const sxFullScreen = {
  width: "100%",
  height: "100%",
  overflow: "auto",
};
