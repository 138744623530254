import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import {
  Button,
  MultipleSelectDropdown,
} from "@verily-src/react-design-system";
import {
  AddUserRolesRequest,
  DeleteUserRolesRequest,
  Role,
  StudyInfo,
  StudyRole,
  UpdateUserRequest,
  User,
} from "generated/studyauth/studyauth_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import UserInfoForm, { UserInfoFormRef } from "components/UserInfoForm";
import { getUserRoleDescription } from "components/UserRenderers";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface EditStudyAdminDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  onClose(): void;
  user: User;
  userRoles: StudyRole[] | undefined;
  userRoleFilter: Role;
  studies: StudyInfo[];
  onSuccess(): void;
}

// The dialog box for editing a study admin.
const EditStudyAdminDialog: React.FC<EditStudyAdminDialogProps> = ({
  open,
  onClose,
  user,
  userRoles,
  userRoleFilter,
  studies,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const testConfig = useAppSelector((state) => state.testConfig);

  const userInfoFormRef = useRef<UserInfoFormRef>(null);
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const [selectedRegistryIds, setSelectedRegistryIds] = useState<string[]>([]);
  const [selectedRegistryIdsErrorMessage, setSelectedRegistryIdsErrorMessage] =
    useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      const registryIds = new Array<string>();
      if (userRoles) {
        for (let studyRole of userRoles) {
          if (studyRole.getRole() === userRoleFilter) {
            registryIds.push(studyRole.getRegistryId());
          }
        }
      }
      setSelectedRegistryIds(registryIds);

      setSelectedRegistryIdsErrorMessage("");
      setErrorMessage("");
    }
  }, [open, userRoleFilter, userRoles]);

  const handleRegistryIdsSelected = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setSelectedRegistryIdsErrorMessage("");
    setSelectedRegistryIds(
      (typeof value === "string" ? value.split(",") : value) as string[]
    );
  };

  const registryIdsRef = useRef<HTMLInputElement>(null);

  const validateInputs = useCallback(async () => {
    let isInputsValid = await userInfoFormRef.current!.validateInput(
      true /* focusOnError */
    );

    if (selectedRegistryIds.length < 1) {
      setSelectedRegistryIdsErrorMessage("Study required");

      if (isInputsValid) {
        registryIdsRef.current?.focus();
      }

      isInputsValid = false;
    }

    if (
      !updateReasonFormRef.current!.validateInput(
        isInputsValid /* focusOnError */
      )
    ) {
      isInputsValid = false;
    }

    return isInputsValid;
  }, [selectedRegistryIds.length]);

  const handleClickSaveButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (testConfig.showEditUserError) {
            throw new Error("Failed to edit user. Please try again.");
          }

          setDisableSaveButton(true);

          const isInputsValid = await validateInputs();
          if (!isInputsValid) {
            setDisableSaveButton(false);
            return;
          }

          // Make sure that new user roles do not conflict with existing ones.
          const selectedRegistryIdsSet = new Set(selectedRegistryIds);
          if (userRoles) {
            for (let studyRole of userRoles) {
              if (selectedRegistryIdsSet.has(studyRole.getRegistryId())) {
                if (studyRole.getRole() !== userRoleFilter) {
                  // Show an error message if the user has a different role for the study.
                  setErrorMessage(
                    "This user was invited to one of these studies in a different role. " +
                      "Please remove them from that role. Then reassign them to the new role."
                  );
                  setDisableSaveButton(false);
                  return;
                }
              }
            }
          }

          // Update user information.
          const userInfo = userInfoFormRef.current!.getUserInfo();
          const userToUpdate = user.clone();
          userToUpdate.setFirstName(userInfo.getFirstName());
          userToUpdate.setLastName(userInfo.getLastName());

          const updateReason = updateReasonFormRef.current!.getUpdateReason();

          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudyAuthServiceApiClient(
            auth0Config.audience!,
            token
          );

          const request = new UpdateUserRequest()
            .setUser(userToUpdate)
            .setUpdateReason(updateReason);
          await client.updateUser(request);

          // Build a set of registry ids for currently managed studies.
          const currentRegistryIdsSet = new Set<string>();
          if (userRoles) {
            for (let studyRole of userRoles) {
              if (studyRole.getRole() === userRoleFilter) {
                currentRegistryIdsSet.add(studyRole.getRegistryId());
              }
            }
          }

          const registryIdsToDelete = Array.from(currentRegistryIdsSet).filter(
            (registryId) => !selectedRegistryIdsSet.has(registryId)
          );
          const registryIdsToAdd = selectedRegistryIds.filter(
            (registryId) => !currentRegistryIdsSet.has(registryId)
          );

          // Delete user roles.
          if (registryIdsToDelete.length > 0) {
            const studyRoles = registryIdsToDelete.map((registryId) => {
              return new StudyRole()
                .setRegistryId(registryId)
                .setRole(userRoleFilter);
            });
            const deleteRolesRequest = new DeleteUserRolesRequest()
              .setUserName(user.getName())
              .setStudyRolesList(studyRoles)
              .setUpdateReason(updateReason);
            await client.deleteUserRoles(deleteRolesRequest);
          }

          // Add user roles.
          if (registryIdsToAdd.length > 0) {
            const studyRoles = registryIdsToAdd.map((registryId) => {
              return new StudyRole()
                .setRegistryId(registryId)
                .setRole(userRoleFilter);
            });
            const addRolesRequest = new AddUserRolesRequest()
              .setUserName(user.getName())
              .setStudyRolesList(studyRoles)
              .setUpdateReason(updateReason);
            await client.addUserRoles(addRolesRequest);
          }

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to edit user. Please try again.");
        }

        setDisableSaveButton(false);
      })();
    } else {
      setErrorMessage(
        "Current user is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    selectedRegistryIds,
    testConfig.showEditUserError,
    user,
    userRoleFilter,
    userRoles,
    validateInputs,
  ]);

  const dialogTitle = useMemo(() => {
    switch (userRoleFilter) {
      case Role.SPONSOR_OPS:
        return "Edit Sponsor Ops (internal) user";
      case Role.CDM:
        return "Edit CDM (internal) user";
      default:
        throw new Error("Unsupported role");
    }
  }, [userRoleFilter]);

  const selectStudyOptions = useMemo(() => {
    const options = studies.map((study) => {
      return { value: study.getRegistryId(), label: study.getDisplayName() };
    });
    return options;
  }, [studies]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6">{dialogTitle}</Typography>
      </A11yDialogTitle>
      <DialogContent>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Edit user failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <Typography variant="body1" sx={{ padding: "4px 0px 16px 0px" }}>
          {getUserRoleDescription(userRoleFilter)}
        </Typography>
        <UserInfoForm
          initValue={user}
          emailDisabled={true}
          ref={userInfoFormRef}
        />
        <Typography variant="body1em">Study</Typography>
        <MultipleSelectDropdown
          labelId="SelectStudy"
          label="Selected studies"
          inputRef={registryIdsRef}
          placeholder="Select (required)"
          helperText={selectedRegistryIdsErrorMessage}
          error={selectedRegistryIdsErrorMessage.length > 0}
          value={selectedRegistryIds}
          onChange={handleRegistryIdsSelected}
          options={selectStudyOptions}
          sx={{ width: "600px" }}
          formControlSx={{ marginTop: "12px" }}
        />
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={[
              "Error correction",
              "Change in role required to perform job responsibilities",
            ]}
            reasonSelectionLabel="Reason for editing user (required)"
            reasonRequiredMessage="Reason for editing user required"
            otherReasonHint="Please provide reason this user is being edited"
            otherReasonRequiredMessage="Other reason for editing user required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          sx={{
            marginRight: "4px",
          }}
        />
        <Button
          label="Save"
          variant="filled"
          onClick={handleClickSaveButton}
          disabled={disableSaveButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditStudyAdminDialog;
