import { Metadata } from "grpc-web";
import { DeviceServicePromiseClient } from "generated/device/device_grpc_web_pb";
import { ListDevicesRequest } from "generated/device/device_pb";

/**
 * Defines helper methods to call DeviceService.
 */
export class DeviceServiceApiClient {
  private gRpcClient: DeviceServicePromiseClient;

  /**
   * Creates an instance of DeviceServiceServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new DeviceServicePromiseClient(apiEndPoint, null, null);
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  listDevices(request: ListDevicesRequest) {
    return this.gRpcClient.listDevices(request, this.getGrpcMetaData());
  }
}
