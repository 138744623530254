import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Tag, RadioGroup, Tooltip } from "@verily-src/react-design-system";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import { AppRoutes } from "core/AppRoutes";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { NonCompliantReason } from "looker/QueryResultsCache";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getWeekDisplay } from "core/WeekNavigation";
import SortableTableHeaderCell, {
  Order,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import useNonCompliantDetailDataLoader, {
  NonCompliantDetailRecord,
  TaskStatus,
} from "./NonCompliantDetailDataLoader";
import {
  setNonCompliantDetailOrderBy,
  setNonCompliantDetailPageNumber,
  setNonCompliantDetailRowsPerPage,
  setNonCompliantDetailSortOrder,
  setNonCompliantReason,
} from "./NonCompliantDetailStateSlice";
import { setParticipantComplianceState } from "participantcompliance/ParticipantComplianceStateSlice";
import { clearCalendarViewCache } from "looker/CalendarViewQueryResultsCache";
import { capitalize } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ParticipantComplianceTask,
  CreateParticipantComplianceTaskRequest,
} from "generated/participantcompliancetask/participantcompliancetask_pb";
import { ParticipantComplianceTaskServiceApiClient } from "apiclient/ParticipantComplianceTaskServiceApiClient";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { toDateProto } from "common/Dates";
import { Snackbar } from "@verily-src/react-design-system";
import pluralize from "pluralize";
import { tablePaginationSxProps } from "components/Tables";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import { isActivationKey, sxFocusWithin } from "common/Accessibility";
import { usePageTitle } from "components/PageTitle";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import { ComplianceScope } from "./NonCompliantTabDataLoader";
import { StudySite } from "generated/studysite/studysite_pb";
import A11yInfoTooltip from "components/A11yInfoTooltip";
import { RemovePicardPrefix } from "common/ResourceName";
import A11yStatusMessage from "components/A11yStatusMessage";

interface HeaderProps {
  reason: NonCompliantReason;
}

const Header: React.FC<HeaderProps> = ({ reason }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getTitle = useCallback(() => {
    switch (reason) {
      case NonCompliantReason.Multiple:
        return "More than one unmet requirement";
      case NonCompliantReason.WearTime:
        return "Unmet requirement: watch wear time only";
      case NonCompliantReason.SyncTime:
        return "Unmet requirement: watch sync only";
      case NonCompliantReason.MedLog:
        return "Unmet requirement: medication log only";
    }
  }, [reason]);

  const dispatch = useAppDispatch();
  return (
    <Box
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "24px 40px",
        background: theme.palette.background.canvas,
      }}
    >
      <Box sx={{ marginRight: "12px", ...sxFocusWithin }}>
        <IconButton
          role="link"
          onClick={() => {
            dispatch(setNonCompliantReason(NonCompliantReason.UNKNOWN));
            navigate(AppRoutes.HOME);
          }}
        >
          <ClearIcon titleAccess="Exit" />
        </IconButton>
      </Box>
      <Typography component="h1" variant="display5">
        {getTitle()}
      </Typography>
    </Box>
  );
};

function getColumnWidth(hasMultipleReasons: boolean, scope: ComplianceScope) {
  if (hasMultipleReasons && scope === ComplianceScope.Study) {
    return "20%";
  }

  if (hasMultipleReasons || scope === ComplianceScope.Study) {
    return "25%";
  }

  return "33%";
}

function getStatusColumnWidth(
  hasMultipleReasons: boolean,
  scope: ComplianceScope
) {
  if (hasMultipleReasons && scope === ComplianceScope.Study) {
    return "20%";
  }

  if (hasMultipleReasons || scope === ComplianceScope.Study) {
    return "25%";
  }

  return "34%";
}

interface NonCompliantDetailTableHeaderProps {
  order: Order;
  orderBy: string;
  nonCompliantReason: NonCompliantReason;
  scope: ComplianceScope;
  onRequestSort: (orderBy: string) => void;
}

const NonCompliantDetailTableHeader: React.FC<
  NonCompliantDetailTableHeaderProps
> = ({ order, orderBy, nonCompliantReason, scope, onRequestSort }) => {
  const hasMultipleReasons = nonCompliantReason === NonCompliantReason.Multiple;
  const columnWidth = getColumnWidth(hasMultipleReasons, scope);
  const statusColumnWidth = getStatusColumnWidth(hasMultipleReasons, scope);
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderCell
          columnName="participant ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Participant ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width={columnWidth}
        >
          <Typography variant="body2em">Participant ID</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="device ID"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Device ID"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width={columnWidth}
        >
          <Typography variant="body2em">Device ID</Typography>
        </SortableTableHeaderCell>
        {hasMultipleReasons && (
          <TableCell width={columnWidth}>
            <Typography variant="body2em">Unmet requirements</Typography>
          </TableCell>
        )}
        {scope === ComplianceScope.Study && (
          <SortableTableHeaderCell
            columnName="site"
            order={order}
            orderBy={orderBy}
            orderByAriaLabel="Site"
            orderByAriaDescription={orderByTextAriaDescription}
            onRequestSort={onRequestSort}
            width={columnWidth}
          >
            <Typography variant="body2em">Site</Typography>
          </SortableTableHeaderCell>
        )}
        <SortableTableHeaderCell
          columnName="outreach status"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Outreach status"
          onRequestSort={onRequestSort}
          width={statusColumnWidth}
        >
          {scope === ComplianceScope.Study && (
            <A11yInfoTooltip
              ariaLabel="Outreach status tooltip"
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "220px",
                    padding: "4px",
                  }}
                >
                  <Typography
                    variant="caption"
                    color={theme.palette.primary.textOnMain}
                    sx={{ opacity: 0.87 }}
                  >
                    Outreach status is controlled by the site. After a site
                    reaches out to a participant, they may mark a participant's
                    outreach status as “Done”
                  </Typography>
                </Box>
              }
              placement="left-start"
              sx={{
                marginRight: "8px",
                display: "flex", // Use flexbox for positioning
                "& .MuiTooltip-popper": {
                  marginTop: 0, // Remove default top margin
                },
                "& .MuiTooltip-tooltip": {
                  alignSelf: "flex-start", // Align tooltip to top
                },
              }}
            />
          )}
          <Typography variant="body2em">
            {scope === ComplianceScope.Site
              ? "Outreach status"
              : "Site to participant outreach"}
          </Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

interface TaskStatusTagProps {
  status: TaskStatus;
}

const TaskStatusTag: React.FC<TaskStatusTagProps> = ({ status }) => {
  switch (status) {
    case TaskStatus.Resolved:
      return <Tag color="success" label="Done" />;
    case TaskStatus.NeedsContacting:
      return <Tag color="warning" label="Not done" />;
  }
};

const nonCompliantReasons = [
  { reason: NonCompliantReason.WearTime, description: "watch wear time" },
  { reason: NonCompliantReason.SyncTime, description: "watch sync" },
  { reason: NonCompliantReason.MedLog, description: "medication logs" },
];

function getUnmetRequirements(record: NonCompliantDetailRecord) {
  let unmetRequirements = "";
  for (let reason of nonCompliantReasons) {
    if (record.reasons.has(reason.reason)) {
      if (unmetRequirements !== "") {
        unmetRequirements += ", ";
      }
      unmetRequirements += reason.description;
    }
  }
  return capitalize(unmetRequirements);
}

function getShowCalendarLinkAriaLabel(
  record: NonCompliantDetailRecord,
  hasMultipleReasons: boolean
) {
  return `View Participant ID ${
    record.subjectId
  }, Device ID ${RemovePicardPrefix(record.deviceId)}, ${
    hasMultipleReasons
      ? "Unmet requirements " + getUnmetRequirements(record) + ","
      : ""
  } Outreach status ${
    record.status === TaskStatus.Resolved ? "Done" : "Not done"
  }`;
}

// Define a component to render non-compliant detail record
interface NonCompliantDetailRowProps {
  record: NonCompliantDetailRecord;
  nonCompliantReason: NonCompliantReason;
  isLastWeek: boolean;
  scope: ComplianceScope;
  sites: Map<string, StudySite>;
  onClickNextButton(record: NonCompliantDetailRecord): void;
  updateTaskStatus(
    record: NonCompliantDetailRecord,
    taskStatus: TaskStatus
  ): void;
}

const NonCompliantDetailRow: React.FC<NonCompliantDetailRowProps> = ({
  record,
  nonCompliantReason,
  isLastWeek,
  scope,
  sites,
  onClickNextButton,
  updateTaskStatus,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [taskStatusValue, setTaskStatusValue] = useState(
    TaskStatus.NeedsContacting
  );

  const handleClickTaskStatusButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setTaskStatusValue(record.status);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleTaskStatusMenuClose = (event: React.MouseEvent) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleTaskStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const intValue = parseInt((event.target as HTMLInputElement).value);
    setTaskStatusValue(intValue);
    updateTaskStatus(record, intValue);
    setAnchorEl(null);
    event.stopPropagation();
  };

  const hasMultipleReasons = nonCompliantReason === NonCompliantReason.Multiple;

  const columnWidth = getColumnWidth(hasMultipleReasons, scope);
  const statusColumnWidth = getStatusColumnWidth(hasMultipleReasons, scope);

  return (
    <TableRow
      hover
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClickNextButton(record);
      }}
    >
      <TableCell width={columnWidth}>{record.subjectId}</TableCell>
      <TableCell width={columnWidth}>
        {RemovePicardPrefix(record.deviceId)}
      </TableCell>
      {hasMultipleReasons && (
        <TableCell width={columnWidth}>
          {getUnmetRequirements(record)}
        </TableCell>
      )}
      {scope === ComplianceScope.Study && (
        <TableCell width={columnWidth}>
          {sites.get(record.siteId)?.getSiteName()}
        </TableCell>
      )}
      <TableCell width={statusColumnWidth} style={{ alignContent: "start" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <TaskStatusTag status={record.status} />
          {isLastWeek && scope === ComplianceScope.Site && (
            <>
              <Box sx={{ marginLeft: "4px", ...sxFocusWithin }}>
                <IconButton
                  id="task-status-button"
                  aria-controls={open ? "task-status-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickTaskStatusButton}
                >
                  <ArrowDropDownIcon
                    titleAccess={`Change outreach status for ${record.subjectId}`}
                  />
                </IconButton>
              </Box>
              <Popover
                id="task-status-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleTaskStatusMenuClose}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: theme.shadows,
                  },
                }}
              >
                <FormControl sx={{ padding: "8px 8px 8px 16px" }}>
                  <RadioGroup
                    value={taskStatusValue}
                    onChange={handleTaskStatusChange}
                  >
                    <FormControlLabel
                      value={TaskStatus.NeedsContacting}
                      control={
                        <Radio
                          autoFocus={
                            taskStatusValue === TaskStatus.NeedsContacting
                          }
                          sx={sxFocusWithin}
                        />
                      }
                      label={
                        <TaskStatusTag status={TaskStatus.NeedsContacting} />
                      }
                    />
                    <FormControlLabel
                      value={TaskStatus.Resolved}
                      control={
                        <Radio
                          autoFocus={taskStatusValue === TaskStatus.Resolved}
                          sx={sxFocusWithin}
                        />
                      }
                      label={<TaskStatusTag status={TaskStatus.Resolved} />}
                    />
                  </RadioGroup>
                </FormControl>
              </Popover>
            </>
          )}
        </Box>
      </TableCell>
      <TableCell width="48px" sx={{ paddingRight: "12px" }}>
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickNextButton(record);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickNextButton(record);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getShowCalendarLinkAriaLabel(record, hasMultipleReasons)}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface NonCompliantDetailTableContentProps {
  records: Array<NonCompliantDetailRecord>;
  nonCompliantReason: NonCompliantReason;
  isLastWeek: boolean;
  scope: ComplianceScope;
  sites: Map<string, StudySite>;
  onClickNextButton(record: NonCompliantDetailRecord): void;
  updateTaskStatus(
    record: NonCompliantDetailRecord,
    taskStatus: TaskStatus
  ): void;
}

const NonCompliantDetailTableContent: React.FC<
  NonCompliantDetailTableContentProps
> = ({
  records,
  nonCompliantReason,
  isLastWeek,
  scope,
  sites,
  onClickNextButton,
  updateTaskStatus,
}) => {
  return (
    <TableBody>
      {records.map((record) => (
        <NonCompliantDetailRow
          key={record.subjectId}
          record={record}
          nonCompliantReason={nonCompliantReason}
          isLastWeek={isLastWeek}
          scope={scope}
          sites={sites}
          onClickNextButton={onClickNextButton}
          updateTaskStatus={updateTaskStatus}
        />
      ))}
    </TableBody>
  );
};

function getParticipantIndex(
  records: NonCompliantDetailRecord[],
  participantId: string
) {
  for (let i = 0; i < records.length; i++) {
    if (records[i].participantId === participantId) {
      return i;
    }
  }
  return -1;
}

function getSiteName(sites: Map<string, StudySite>, siteId: string) {
  if (sites.has(siteId)) {
    return sites.get(siteId)!.getSiteName();
  }
  return "";
}

const NonCompliantDetail: React.FC = () => {
  const scope = useAppSelector((state) => state.nonCompliantDetailState.scope);

  const nonComplianceReason = useAppSelector(
    (state) => state.nonCompliantDetailState.reason
  );

  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );

  const { isLoading, hasError, summary, loadNonCompliantDetailData } =
    useNonCompliantDetailDataLoader(scope);

  // Set page title.
  usePageTitle("Noncompliant detail");

  // Reload data if selected study, week or non-compliant reason changes.
  useEffect(() => {
    if (nonComplianceReason !== NonCompliantReason.UNKNOWN) {
      loadNonCompliantDetailData(
        previousWeekOffset,
        nonComplianceReason,
        currentRegistryId
      );
    }
  }, [
    currentRegistryId,
    previousWeekOffset,
    nonComplianceReason,
    loadNonCompliantDetailData,
  ]);

  const { sortOrder, orderBy, pageNumber, rowsPerPage } = useAppSelector(
    (state) => state.nonCompliantDetailState
  );

  const dispatch = useAppDispatch();

  // Stored edited participant info to keep its position after task status update.
  const editedParticipantId = useRef("");
  const editedParticipantIndex = useRef(-1);

  const resetEditedParticipantIndex = () => {
    editedParticipantIndex.current = -1;
  };

  useEffect(() => {
    resetEditedParticipantIndex();
  }, []);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    dispatch(setNonCompliantDetailSortOrder(newSortOrder));
    dispatch(setNonCompliantDetailOrderBy(orderByColumn));
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setNonCompliantDetailPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setNonCompliantDetailRowsPerPage(parseInt(event.target.value, 10))
    );
    dispatch(setNonCompliantDetailPageNumber(0));
  };

  const navigate = useNavigate();

  const sortedRecords = useMemo(() => {
    const records = summary.records.sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "participant ID":
          result = a.subjectId.localeCompare(b.subjectId);
          break;
        case "device ID":
          result = a.deviceId.localeCompare(b.deviceId);
          break;
        case "site":
          let nameA = getSiteName(summary.sites, a.siteId);
          let nameB = getSiteName(summary.sites, b.siteId);
          result = nameA.localeCompare(nameB);
          break;
        case "outreach status":
          result = a.status - b.status;
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });

    // Keep the position of edited participant record.
    if (editedParticipantIndex.current >= 0) {
      const index = getParticipantIndex(records, editedParticipantId.current);
      if (index >= 0) {
        // Remove participant from its sorted position.
        const removedParticipants = records.splice(index, 1);

        // Add participant to its original position.
        records.splice(
          editedParticipantIndex.current,
          0,
          ...removedParticipants
        );
      }
    }

    return records;
  }, [summary.records, summary.sites, orderBy, sortOrder]);

  const getSummaryMessage = useCallback(() => {
    const nonCompliantOverTotal = `${summary.nonCompliant}/${summary.total}`;

    switch (nonComplianceReason) {
      case NonCompliantReason.Multiple:
        return `${nonCompliantOverTotal} enrolled participants had more than one unmet requirement this week`;
      case NonCompliantReason.WearTime:
        const complianceHours = pluralize(
          "hour",
          summary.complianceSetting.getWearTimeHoursPerDay(),
          true
        );
        const complianceDays = pluralize(
          "day",
          summary.complianceSetting.getWearTimeDaysPerWeek(),
          true
        );
        return (
          `${nonCompliantOverTotal} enrolled participants wore their watch ` +
          `for fewer than ${complianceHours} a day, ${complianceDays} this week`
        );
      case NonCompliantReason.SyncTime:
        const syncComplianceDays = pluralize(
          "day",
          summary.complianceSetting.getSyncDaysPerWeek(),
          true
        );
        return (
          `${nonCompliantOverTotal} enrolled participants synced their watch ` +
          `on fewer than ${syncComplianceDays} this week`
        );
      case NonCompliantReason.MedLog:
        const daily = summary.complianceSetting.getMedsLoggedPerDay();
        const weekly = summary.complianceSetting.getMedsLoggedPerWeek();

        let medLogFrequencyText = "";
        if (daily > 0) {
          medLogFrequencyText = pluralize("dose", daily, true) + " per day";
        } else if (weekly > 0) {
          medLogFrequencyText = pluralize("dose", weekly, true);
        }
        return (
          `${nonCompliantOverTotal} enrolled participants logged ` +
          `fewer than ${medLogFrequencyText} this week`
        );
    }
  }, [
    nonComplianceReason,
    summary.complianceSetting,
    summary.nonCompliant,
    summary.total,
  ]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const updateTaskStatus = useCallback(
    (record: NonCompliantDetailRecord, taskStatus: TaskStatus) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            editedParticipantId.current = record.participantId;
            editedParticipantIndex.current = getParticipantIndex(
              sortedRecords,
              record.participantId
            );

            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const taskServiceClient =
              new ParticipantComplianceTaskServiceApiClient(
                auth0Config.audience!,
                token
              );

            const date = new Date();
            const unixTimestamp = Math.floor(date.getTime() / 1000);
            const taskStatusPb =
              taskStatus === TaskStatus.Resolved
                ? ParticipantComplianceTask.TaskStatus.RESOLVED
                : ParticipantComplianceTask.TaskStatus.NEED_CONTACTING;

            await taskServiceClient.createParticipantComplianceTask(
              new CreateParticipantComplianceTaskRequest().setParticipantComplianceTask(
                new ParticipantComplianceTask()
                  .setName(
                    "studyParticipants/" +
                      record.participantId +
                      "/participantComplianceTasks/" +
                      unixTimestamp
                  )
                  .setTimestamp(Timestamp.fromDate(date))
                  .setDate(toDateProto(date))
                  .setTaskStatus(taskStatusPb)
              )
            );
          } catch (error) {
            console.log("error reason %s", error);
            setShowErrorMessage(true);
          }

          loadNonCompliantDetailData(
            previousWeekOffset,
            nonComplianceReason,
            currentRegistryId
          );
        })();
      } else {
        //TODO(STUDYDVICE-4628) - add error dialog for other errors
        console.log(
          "User is not authenticated. Please logout and login again."
        );
      }
    },
    [
      isAuthenticated,
      auth0Config,
      loadNonCompliantDetailData,
      previousWeekOffset,
      nonComplianceReason,
      currentRegistryId,
      sortedRecords,
      getAccessTokenSilently,
    ]
  );

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <Header reason={nonComplianceReason} />
      <Box
        role="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 40px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Typography variant="body2em">
            {getWeekDisplay(previousWeekOffset)}
          </Typography>
        </Box>
        {isLoading && <SlowLoadingIndicator />}
        {!isLoading && (
          <>
            <Typography component="h2" variant="display6">
              {getSummaryMessage()}
            </Typography>
            {nonComplianceReason === NonCompliantReason.MedLog && (
              <Alert
                severity="info"
                icon={<InfoIcon />}
                sx={{
                  marginTop: "10px",
                  background: theme.palette.info.background,
                }}
              >
                <Typography
                  variant="body1em"
                  color={theme.palette.info.textOnBackground}
                >
                  Note: Medication logs should not be used for clinical decision
                  support
                </Typography>
              </Alert>
            )}
            <TableContainer sx={{ marginTop: "16px" }}>
              <Table
                sx={{
                  minWidth: 700,
                  marginBottom: "8px",
                }}
                aria-label="Noncompliant detail"
              >
                <NonCompliantDetailTableHeader
                  order={sortOrder}
                  orderBy={orderBy}
                  nonCompliantReason={nonComplianceReason}
                  scope={scope}
                  onRequestSort={(orderBy) => {
                    resetEditedParticipantIndex();
                    handleRequestSort(orderBy);
                  }}
                />
                {!hasError && (
                  <NonCompliantDetailTableContent
                    records={sortedRecords.slice(
                      pageNumber * rowsPerPage,
                      pageNumber * rowsPerPage + rowsPerPage
                    )}
                    nonCompliantReason={nonComplianceReason}
                    isLastWeek={previousWeekOffset === 1}
                    scope={scope}
                    sites={summary.sites}
                    onClickNextButton={(record) => {
                      dispatch(
                        setParticipantComplianceState({
                          participantId: record.participantId,
                          subjectId: record.subjectId,
                          deviceId: record.deviceId,
                          siteName: summary.sites
                            .get(record.siteId)
                            ?.getSiteName(),
                          scope: scope,
                          returnRoute: AppRoutes.NON_COMPLIANT_DETAIL,
                        })
                      );
                      clearCalendarViewCache();
                      navigate(AppRoutes.PARTICIPANT_COMPLIANCE);
                    }}
                    updateTaskStatus={updateTaskStatus}
                  />
                )}
                {hasError && (
                  <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                    Failed to load participants' data
                  </Alert>
                )}
              </Table>
              <A11yStatusMessage message={sortDirectiontMessage} />
            </TableContainer>
            <Snackbar
              open={showErrorMessage}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={3000}
              color="error"
              onClose={() => {
                setShowErrorMessage(false);
              }}
              text="Failed to update the compliance task"
              withIcon={true}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedRecords.length}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={tablePaginationSxProps}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default NonCompliantDetail;
