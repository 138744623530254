import { createSlice, PayloadAction } from "@reduxjs/toolkit";
/**
 * Stores information of the study participants table view.
 */
export interface StudyParticipantsViewState {
  pageNumber: number;
  isParticipantDialogOpen: boolean;
  studyParticipantName: string;
}

export const initialState: StudyParticipantsViewState = {
  pageNumber: 0,
  isParticipantDialogOpen: false,
  studyParticipantName: "",
};

const studyParticipantsViewStateSlice = createSlice({
  name: "studyParticipantsViewState",
  initialState,
  reducers: {
    resetParticipantsViewState: (state) => {
      state.pageNumber = initialState.pageNumber;
      state.isParticipantDialogOpen = initialState.isParticipantDialogOpen;
      state.studyParticipantName = initialState.studyParticipantName;
    },
    setParticipantsViewState: (
      state,
      action: PayloadAction<StudyParticipantsViewState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
      state.isParticipantDialogOpen = action.payload.isParticipantDialogOpen;
      state.studyParticipantName = action.payload.studyParticipantName;
    },
  },
});

export const { resetParticipantsViewState, setParticipantsViewState } =
  studyParticipantsViewStateSlice.actions;

export default studyParticipantsViewStateSlice.reducer;
