import { Snackbar } from "@verily-src/react-design-system";
import { autohideTimeout } from "styles/Time";

export interface SDPErrorSnackbarProps {
  showSnackbar: boolean;
  setShowSnackbar: (show: boolean) => void;
  errorMessage: string;
}

const SDPErrorSnackbar: React.FC<SDPErrorSnackbarProps> = ({
  showSnackbar,
  setShowSnackbar,
  errorMessage,
}) => {
  return (
    <Snackbar
      open={showSnackbar}
      color="error"
      variant="tonal"
      withIcon={true}
      text={errorMessage}
      autoHideDuration={autohideTimeout}
      onClose={() => {
        setShowSnackbar(false);
      }}
      role="alert"
    ></Snackbar>
  );
};

export default SDPErrorSnackbar;
