import React, { useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { Breadcrumbs, Button } from "@verily-src/react-design-system";
import { ReactComponent as VerilyNumetricLogo } from "assets/verily-numetric-logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { useAppSelector } from "redux/hooks";
import { dialog_border_radius, header_nav_height } from "styles/Dimensions";
import { useAuth0 } from "@auth0/auth0-react";
import { studyDetailsCache } from "studydetails/StudyDetailsCache";
import { sxFocusWithin } from "common/Accessibility";
import A11yInfoTooltip from "components/A11yInfoTooltip";
import A11yDialogTitle from "components/A11yDialogTitle";

const HeaderNav: React.FC = () => {
  const userConfig = useAppSelector((state) => state.userConfig);
  const { logout } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

  const selectedStudy = useMemo(() => {
    const selectedStudies = userConfig.studies.filter(
      (study) => study.registryId === userConfig.selectedRegistryId
    );
    return selectedStudies.length > 0 ? selectedStudies[0] : null;
  }, [userConfig.selectedRegistryId, userConfig.studies]);

  const isAdminScreen = location.pathname.startsWith(AppRoutes.ADMIN);
  const isHomeScreen = location.pathname === AppRoutes.STUDIES || isAdminScreen;

  // Support profile menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickProfileIcon = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const theme = useTheme();
  return (
    <Box
      aria-label="Top"
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        background: theme.palette.primary.background,
        padding: "20px 24px",
        height: header_nav_height,
      }}
    >
      <Link
        onClick={() => {
          if (isAdminScreen) {
            navigate(AppRoutes.ADMIN);
          } else {
            navigate(AppRoutes.STUDIES);
          }
        }}
        tabIndex={0}
        sx={{ paddingBottom: "2px" }}
      >
        <VerilyNumetricLogo title="Verily numetric logo" />
      </Link>
      {!isHomeScreen && (
        <Breadcrumbs sx={{ marginLeft: "24px" }}>
          <Link
            component="button"
            role="link"
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate(AppRoutes.STUDIES);
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.muted }}
            >
              My Studies
            </Typography>
          </Link>
          {selectedStudy !== null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="body1em" sx={{ marginRight: "8px" }}>
                {selectedStudy.displayName}
              </Typography>
              <A11yInfoTooltip
                ariaLabel="Study details tooltip"
                title={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {/* show sponsor name after it's been set up */}
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.textOnMain}
                        sx={{
                          opacity: 0.87,
                          fontFamily: "Roboto Mono",
                          width: "100px",
                        }}
                      >
                        Sponsor:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.textOnMain}
                        sx={{ opacity: 0.6, fontFamily: "Roboto Mono" }}
                      >
                        {studyDetailsCache.has(selectedStudy.registryId)
                          ? studyDetailsCache
                              .get(selectedStudy.registryId)
                              ?.getSponsorName()
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.textOnMain}
                        sx={{
                          opacity: 0.87,
                          fontFamily: "Roboto Mono",
                          width: "100px",
                        }}
                      >
                        Study ID:
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.textOnMain}
                        sx={{ opacity: 0.6, fontFamily: "Roboto Mono" }}
                      >
                        {studyDetailsCache.has(selectedStudy.registryId)
                          ? studyDetailsCache
                              .get(selectedStudy.registryId)
                              ?.getStudyId()
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </Box>
          )}
        </Breadcrumbs>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box sx={sxFocusWithin}>
        <IconButton
          onClick={() => {
            setShowHelpDialog(true);
          }}
        >
          <HelpCenterIcon
            titleAccess="Help"
            sx={{ color: theme.palette.icon.default }}
          />
        </IconButton>
      </Box>
      <Box sx={sxFocusWithin}>
        <IconButton onClick={handleClickProfileIcon}>
          <AccountCircleIcon
            titleAccess="Profile"
            sx={{ color: theme.palette.icon.default }}
          />
        </IconButton>
      </Box>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenu-paper": {
            boxShadow: theme.shadows,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            logout({
              returnTo: window.location.origin,
            });
          }}
          sx={{ padding: "0px 32px" }}
        >
          <Typography variant="body2">Sign out</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={showHelpDialog}
        maxWidth={false}
        PaperProps={{
          style: { borderRadius: dialog_border_radius },
          sx: { width: "448px !important" },
        }}
      >
        <A11yDialogTitle sx={{ paddingTop: "24px", paddingBottom: "20px" }}>
          <Typography variant="display6">Support</Typography>
        </A11yDialogTitle>
        <DialogContent sx={{ paddingBottom: "16px" }}>
          <Typography variant="body1em">Need help? Contact:</Typography>
          <Typography variant="body1" sx={{ marginBottom: "32px" }}>
            numetric-portal@verily.com or 833-531-0204
          </Typography>
          <Typography variant="body1em">Privacy policy</Typography>
          <Link
            href="https://verily.com/privacy-policy"
            target="_blank"
            rel="noopener"
          >
            <Typography variant="body1" sx={{ color: theme.palette.info.main }}>
              https://verily.com/privacy-policy
            </Typography>
          </Link>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "right" }}
          sx={{ padding: "0px 24px 24px 24px" }}
        >
          <Button
            label="Close"
            variant="filled"
            onClick={() => {
              setShowHelpDialog(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HeaderNav;
