import React from "react";
import { TableSortLabel } from "@mui/material";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { sxFocusWithin } from "common/Accessibility";

export type Order = "asc" | "desc";

export const orderByTextAriaDescription =
  "Press to change sorting order a-z and Z-a";

export const orderByNumberAriaDescription =
  "Press to change sorting order 0-X and X-0";

export const orderByDateAriaDescription =
  "Press to change date sorting order from earliest to latest and latest to earliest";

// Defines a table header cell to support sorting.
interface SortableTableHeaderCellProps extends TableCellProps {
  columnName: string;
  order: Order;
  orderBy: string;
  orderByAriaLabel?: string;
  orderByAriaDescription?: string;
  onRequestSort: (orderBy: string) => void;
}

const SortableTableHeaderCell: React.FC<SortableTableHeaderCellProps> = (
  props
) => {
  const {
    columnName,
    order,
    orderBy,
    orderByAriaLabel,
    orderByAriaDescription,
    onRequestSort,
    children,
    ...remaining
  } = props;

  return (
    <TableCell
      sortDirection={orderBy === columnName ? order : false}
      {...remaining}
    >
      <TableSortLabel
        aria-label={orderByAriaLabel}
        aria-description={orderByAriaDescription}
        active={orderBy === columnName}
        direction={orderBy === columnName ? order : "asc"}
        onClick={() => onRequestSort(columnName)}
        sx={sxFocusWithin}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableHeaderCell;
