import { Metadata } from "grpc-web";
import { ParticipantSessionServicePromiseClient } from "generated/participantsession/participantsession_grpc_web_pb";
import {
  CreateParticipantSessionRequest,
  GetParticipantSessionRequest,
  EndParticipantSessionRequest,
  ListParticipantSessionsRequest,
  SwapParticipantSessionsRequest,
  DeleteParticipantSessionRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";

/**
 * Defines helper methods to call ParticipantSessionService.
 */
export class ParticipantSessionServiceApiClient {
  private gRpcClient: ParticipantSessionServicePromiseClient;

  /**
   * Creates an instance of ParticipantSessionServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-autopush.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new ParticipantSessionServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createParticipantSession(request: CreateParticipantSessionRequest) {
    return this.gRpcClient.createParticipantSession(
      request,
      this.getGrpcMetaData()
    );
  }

  getParticipantSession(request: GetParticipantSessionRequest) {
    return this.gRpcClient.getParticipantSession(
      request,
      this.getGrpcMetaData()
    );
  }

  endParticipantSession(request: EndParticipantSessionRequest) {
    return this.gRpcClient.endParticipantSession(
      request,
      this.getGrpcMetaData()
    );
  }

  listParticipantSessionHistory(request: ListParticipantSessionsRequest) {
    return this.gRpcClient.listParticipantSessions(
      request,
      this.getGrpcMetaData()
    );
  }

  deleteParticipantSession(request: DeleteParticipantSessionRequest) {
    return this.gRpcClient.deleteParticipantSession(
      request,
      this.getGrpcMetaData()
    );
  }

  swapParticipantSessions(request: SwapParticipantSessionsRequest) {
    return this.gRpcClient.swapParticipantSessions(
      request,
      this.getGrpcMetaData()
    );
  }
}

export function isParticipantSessionNullOrEmpty(
  participantSession?: ParticipantSession
) {
  return !participantSession || participantSession.toArray().length === 0;
}
