import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import {
  isStudyParticipantEmpty,
  StudyParticipantServiceApiClient,
} from "apiclient/StudyParticipantServiceApiClient";
import {
  UpdateStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import { format } from "date-fns";
import { fromDateProto, toDateProto, parseDate } from "common/Dates";
import { SDPDatePicker } from "components/SDPDatePicker";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { RpcError, StatusCode } from "grpc-web";
import {
  GetParticipantStatusById,
  ParticipantStatus,
} from "./UpdateParticipantStatusDialog";
import { isValid } from "date-fns";
import { EditExitStudyDateSteps, StepInfo, Steps } from "common/Steps";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";
import HeaderValuePair from "components/HeaderValuePair";

interface EditExitStudyDateDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const EditExitStudyDateDialog: React.FC<EditExitStudyDateDialogProps> = ({
  open,
  studyParticipant,
  onClose,
  onSuccess,
  onError,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [status, setStatus] = useState<ParticipantStatus>(
    GetParticipantStatusById(studyParticipant.getStatus())
  );
  const [exitStudyDate, setExitStudyDate] = useState<Date>(
    studyParticipant.getExitStudyDate()
      ? fromDateProto(studyParticipant.getExitStudyDate()!)
      : parseDate(new Date())
  );

  const [datePickerValue, setDatePickerValue] = useState<
    Date | null | undefined
  >(
    studyParticipant.getExitStudyDate()
      ? fromDateProto(studyParticipant.getExitStudyDate()!)
      : parseDate(new Date())
  );
  const [datePickerError, setDatePickerError] = useState<boolean>(false);
  const [datePickerErrorText, setDatePickerErrorText] = useState<string>("");

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [stepId, setStepId] = useState<number>(0);
  const [updateReason, setUpdateReason] = useState<string>("");
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  useEffect(() => {
    if (open) {
      setStepId(EditExitStudyDateSteps.NewDateEntry);
      setExitStudyDate(
        studyParticipant.getExitStudyDate()
          ? fromDateProto(studyParticipant.getExitStudyDate()!)
          : parseDate(new Date())
      );
      setDatePickerValue(
        studyParticipant.getExitStudyDate()
          ? fromDateProto(studyParticipant.getExitStudyDate()!)
          : parseDate(new Date())
      );
      setDatePickerError(false);
      setDatePickerErrorText("");
      setEditLoading(false);
    }
  }, [open, studyParticipant]);

  useEffect(() => {
    setStatus(GetParticipantStatusById(studyParticipant.getStatus()));
  }, [studyParticipant]);

  const validateDatePickerField = useCallback(() => {
    // Reset error fields
    setDatePickerError(false);
    setDatePickerErrorText("");

    // If selected date is invalid, throw an error
    if (!datePickerValue || !isValid(datePickerValue)) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a valid date.");
      return false;
    }

    if (datePickerValue < studyParticipant.getEnrollmentStartTime()!.toDate()) {
      setStepId(EditExitStudyDateSteps.ExitStudyDateError);
      return false;
    }

    // If selected date is in the future, throw an error
    const currentDate = parseDate(new Date());
    if (parseDate(datePickerValue!) > currentDate) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a date that is today or earlier.");
      return false;
    }

    return true;
  }, [
    datePickerValue,
    setDatePickerError,
    setDatePickerErrorText,
    studyParticipant,
  ]);

  const updateExitStudyDate = useCallback(
    async (participantName: string, status: ParticipantStatus, date: Date) => {
      if (!isAuthenticated || !auth0Config) {
        onError("User is not authenticated. Please logout and login again.");
        return;
      }
      await (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const studyParticipantApiClient =
            new StudyParticipantServiceApiClient(auth0Config.audience!, token);

          await studyParticipantApiClient.updateStudyParticipant(
            new UpdateStudyParticipantRequest()
              .setStudyParticipant(
                new StudyParticipant()
                  .setName(participantName)
                  .setExitStudyDate(toDateProto(date))
              )
              .setUpdateMask(new FieldMask().setPathsList(["exit_study_date"]))
              .setUpdateReason(updateReason)
          );

          onSuccess();
        } catch (error) {
          let errMsg = `Failed to update ${
            status.dateFieldLabel?.toLowerCase() ?? "date"
          }. Please try again.`;
          if (
            error instanceof RpcError &&
            error.code === StatusCode.PERMISSION_DENIED
          ) {
            errMsg = "User is not authorized to update participant status.";
          }

          onError(errMsg);
        }
      })();
    },
    [
      isAuthenticated,
      auth0Config,
      onError,
      getAccessTokenSilently,
      updateReason,
      onSuccess,
    ]
  );

  const stepInfo: StepInfo[] = [
    {
      id: EditExitStudyDateSteps.NewDateEntry,
      title: `Edit ${status.dateFieldLabel?.toLowerCase() ?? "date"}`,
      page: (
        <>
          <Box>
            <HeaderValuePair
              component="h2"
              title={
                "Current " + status.dateFieldLabel?.toLowerCase() ?? "date"
              }
              value={
                studyParticipant?.getExitStudyDate()
                  ? format(
                      fromDateProto(studyParticipant.getExitStudyDate()!),
                      "M/d/yyyy"
                    )
                  : "n/a"
              }
            />
          </Box>
          <SDPDatePicker
            label={`Updated ${
              status.dateFieldLabel?.toLowerCase() ?? "date"
            } (required)`}
            value={datePickerValue}
            disableFuture={true}
            error={datePickerError}
            errorText={datePickerErrorText}
            onChange={(selectedDate) => {
              setDatePickerError(false);
              setDatePickerErrorText("");

              setDatePickerValue(selectedDate);
            }}
            sx={{
              margin: "32px 0px 38px",
            }}
          ></SDPDatePicker>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={["Error correction", "Other"]}
            reasonSelectionLabel={`Reason for updating ${status.dateFieldLabel?.toLowerCase()} (required)`}
            reasonRequiredMessage={`Reason for updating ${status.dateFieldLabel?.toLowerCase()} required`}
            otherReasonAllowed={true}
          />
        </>
      ),
      previousButtonText: "Cancel",
      isPreviousDisabled: false,
      onPrevious: () => {
        onClose();
      },
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      onNext: () => {
        if (
          validateDatePickerField() &&
          updateReasonFormRef.current?.validateInput()
        ) {
          setExitStudyDate(parseDate(datePickerValue!));
          setStepId(EditExitStudyDateSteps.ConfirmDate);
          setUpdateReason(updateReasonFormRef.current!.getUpdateReason());
        }
      },
    },
    {
      id: EditExitStudyDateSteps.ConfirmDate,
      title: `Confirm ${
        status.dateFieldLabel?.toLowerCase() ?? "date updates"
      }`,
      page: (
        <>
          <Alert icon={false} severity="warning">
            Review and confirm the details below.
          </Alert>
          <Box sx={{ paddingTop: "16px" }}>
            <HeaderValuePair
              component="h2"
              title="Participant ID"
              value={studyParticipant.getSponsorParticipantId()}
              fontFamily="Roboto Mono"
              paragraph={true}
            />
            <HeaderValuePair
              component="h2"
              title={
                "Current " + status.dateFieldLabel?.toLowerCase() ?? "date"
              }
              value={
                studyParticipant?.getExitStudyDate()
                  ? format(
                      fromDateProto(studyParticipant.getExitStudyDate()!),
                      "M/d/yyyy"
                    )
                  : "n/a"
              }
              paragraph={true}
            />
            <HeaderValuePair
              component="h2"
              title={
                "Updated " + status.dateFieldLabel?.toLowerCase() ?? "date"
              }
              value={
                studyParticipant?.getExitStudyDate()
                  ? format(exitStudyDate, "M/d/yyyy")
                  : "n/a"
              }
              paragraph={true}
            />
            <HeaderValuePair
              component="h2"
              title="Reason for change"
              value={updateReason}
              paragraph={true}
            />
          </Box>
        </>
      ),
      previousButtonText: "Go back",
      isPreviousDisabled: false,
      onPrevious: () => {
        setStepId(EditExitStudyDateSteps.NewDateEntry);
      },
      nextButtonText: "Confirm",
      isNextButtonDisabled: false,
      isNextButtonLoading: editLoading,
      onNext: async () => {
        setEditLoading(true);
        await updateExitStudyDate(
          studyParticipant.getName(),
          status,
          exitStudyDate
        );
        setEditLoading(false);
      },
    },
    {
      id: EditExitStudyDateSteps.ExitStudyDateError,
      title: `${
        status.dateFieldLabel ?? "Date"
      } must come after enrollment date`,
      page: (
        <Typography variant="body1">
          Select a {status.dateFieldLabel?.toLowerCase() ?? "date"} that comes
          after this participant was enrolled
          {!isStudyParticipantEmpty(studyParticipant)
            ? format(
                studyParticipant!.getEnrollmentStartTime()!.toDate(),
                " (M/d/yyyy)"
              )
            : ""}
          .
        </Typography>
      ),
      previousButtonText: "Go back",
      isPreviousDisabled: false,
      onPrevious: () => {
        setStepId(EditExitStudyDateSteps.NewDateEntry);
      },
      nextButtonText: "Close",
      isNextButtonDisabled: false,
      onNext: () => {
        onClose();
      },
    },
  ];

  const steps: Steps = new Steps(stepInfo, EditExitStudyDateSteps.NewDateEntry);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">{steps.get(stepId)!.title}</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {steps.get(stepId)!.page}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={() => steps.get(stepId)!.onPrevious()}
          sx={{
            marginRight: "8px",
          }}
          disabled={steps.get(stepId)!.isPreviousDisabled}
        >
          {steps.get(stepId)!.previousButtonText ?? "Previous"}
        </Button>
        <Button
          variant="filled"
          onClick={() => steps.get(stepId)!.onNext()}
          disabled={steps.get(stepId)!.isNextButtonDisabled}
        >
          {steps.get(stepId)!.nextButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExitStudyDateDialog;
