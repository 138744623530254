import { Looker40SDK } from "@looker/sdk";
import {
  getParticipantMonthCacheKey,
  participantWearMinutesCache,
} from "./CalendarViewQueryResultsCache";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get a participant wear minutes for a given month.
 *
 * The function assumes that the Looker user attributes for participant id,
 * month start date and month end date are set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
export async function getParticipantWearMinutes(
  sdk: Looker40SDK,
  studyParticipantId: string,
  monthStartDate: Date,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let wearMinutesMap = new Map<string, number>();
  let hasError = false;

  try {
    const cacheKey = getParticipantMonthCacheKey(
      studyParticipantId,
      monthStartDate
    );

    if (participantWearMinutesCache.has(cacheKey)) {
      const wearMinutesCached = participantWearMinutesCache.get(cacheKey)!!;
      return { wearMinutesMap: wearMinutesCached, hasError: false };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANT_WEAR_MINUTES
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        /** Wear date is in ISO 8601 format YYYY-MM-DD */
        const wearDate = row["participant_wear_minutes.event_date"];
        const wearMinutes = row["participant_wear_minutes.wear_minutes"];
        wearMinutesMap.set(wearDate, wearMinutes);
      }

      // Put results in cache.
      participantWearMinutesCache.set(cacheKey, wearMinutesMap);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return { wearMinutesMap: wearMinutesMap, hasError: hasError };
}
