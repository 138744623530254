import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";
import { setUserConfigured } from "./AppStateSlice";
import { UserConfig, setUserConfig, StudyRoleInfo } from "./UserConfigSlice";
import { StudyInfo } from "../common/StudyInfo";
import {
  GetUserRequest,
  GetUsersRolesRequest,
  StudyDataExportInfo,
  UserRole,
} from "generated/studyauth/studyauth_pb";
import { AppRoutes } from "./AppRoutes";
import { useNavigate } from "react-router-dom";
import { lookerAuthTokenCache } from "../looker/LookerAuthTokenCache";
import Loading from "components/Loading";
import { Box, Button, Typography } from "@mui/material";

/**
 * Retrieves the user configuration from the Study Sponsor Service and Looker.
 */
export const InitialSetup: React.FC = () => {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const appState = useAppSelector((state) => state.appState);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [errorRetryCount, setErrorRetryCount] = useState(0);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (isLoading || !isAuthenticated) {
          return;
        }

        if (appState.userConfigured) {
          return;
        }

        const auth0Token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new StudyAuthServiceApiClient(
          auth0Config.audience!,
          auth0Token
        );

        // Sync user account data.
        try {
          await client.syncUserAccount();
        } catch (error) {
          console.log("failed to sync user account", error);
          dispatch(setUserConfigured(true));
          navigate(AppRoutes.ACCESS_DENIED);
          return;
        }

        const user = await client.getUser(new GetUserRequest());

        // Redirect to admin home screen if the user is a super admin.
        if (user.getIsSuperAdmin()) {
          const cfg: UserConfig = {
            studies: [],
            userName: user.getName(),
            userFirstName: user.getFirstName(),
            userRoles: [],
            isSuperAdmin: true,
            selectedRegistryId: "",
          };

          dispatch(setUserConfig(cfg));
          dispatch(setUserConfigured(true));
          navigate(AppRoutes.ADMIN);
          return;
        }

        // Get user roles and related studies.
        const getRolesRequest = new GetUsersRolesRequest().setWithStudyInfo(
          true
        );
        const getRolesResponse = await client.getUsersRoles(getRolesRequest);
        const userRoles = getRolesResponse.getUserRolesList();
        const studies = getRolesResponse.getStudiesList();

        if (userRoles.length < 1 || studies.length < 1) {
          dispatch(setUserConfigured(true));
          navigate(AppRoutes.ACCESS_DENIED);
          return;
        }

        const userRole = userRoles[0];

        // Initialize LookerAuthTokenCache.
        lookerAuthTokenCache.init(
          auth0Config.audience!,
          getAccessTokenSilently
        );

        // Call AuthorizeUser API to get a new auth token. The call also creates the Looker user
        // if it does not exist yet.
        await lookerAuthTokenCache.refreshAuthToken();

        const cfg: UserConfig = {
          studies: convertToStudyInfoList(studies),
          userName: userRole.getUserName(),
          userFirstName: user.getFirstName(),
          userRoles: getUserStudyRoles(userRole),
          isSuperAdmin: false,
          selectedRegistryId: "",
        };

        dispatch(setUserConfig(cfg));
        dispatch(setUserConfigured(true));

        // Navigate to the select study screen.
        navigate(AppRoutes.STUDIES);
      } catch (error) {
        console.log("error reason %s", error);
        setShowError(true);
      }
    })();
  }, [
    getAccessTokenSilently,
    auth0Config,
    appConfig,
    getIdTokenClaims,
    dispatch,
    navigate,
    appState.userConfigured,
    isLoading,
    isAuthenticated,
    errorRetryCount,
  ]);

  if (showError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="body1">
          Study devices portal is temporarily unavailable. Please try reload in
          a few minutes.
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setShowError(false);
            setErrorRetryCount(errorRetryCount + 1);
          }}
          sx={{ marginTop: "32px" }}
        >
          Reload
        </Button>
      </Box>
    );
  }

  if (!isLoading && isAuthenticated && !appState.userConfigured) {
    return <Loading />;
  }

  return <></>;
};

// Convert a list of StudyDataExportInfo proto to a list of StudyInfo so that it can be stored
// in redux state.
function convertToStudyInfoList(studies: StudyDataExportInfo[]) {
  return studies
    .map((study): StudyInfo => {
      return {
        registryId: study.getRegistryId(),
        displayName: study.getRegistryDisplayName(),
        bqProject: study.getBqProjectId(),
        bqDataset: study.getBqDatasetName(),
      };
    })
    .sort((a: StudyInfo, b: StudyInfo) => {
      return a.displayName.localeCompare(b.displayName);
    });
}

function getUserStudyRoles(userRole: UserRole | undefined) {
  if (!userRole) {
    return new Array<StudyRoleInfo>();
  }

  // Convert StudyRole proto to StudyRoleInfo so that it can be stored in redux state.
  return userRole.getStudyRolesList().map((studyRole): StudyRoleInfo => {
    return {
      role: studyRole.getRole(),
      registryId: studyRole.getRegistryId(),
      studySiteId: studyRole.getStudySiteId(),
    };
  });
}
