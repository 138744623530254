import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Login with Auth0 if users haven't logged in yet.
const LoginController: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return <></>;
};

export default LoginController;
