import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { Snackbar } from "@verily-src/react-design-system";
import { useAppSelector } from "redux/hooks";
import useStudyDetailDataLoader from "./StudyDetailsDataLoader";
import EditStudyDetailDialog from "./EditStudyDetailDialog";
import EditVerilyCdmDialog from "./EditVerilyCdmDialog";
import { StudyDetail } from "generated/studydetail/studydetail_pb";
import StudyDetailCard from "./StudyDetailCard";
import VerilyCDMCard from "./VerilyCDMCard";
import { usePageTitle } from "components/PageTitle";

const StudyDetailsHome: React.FC = () => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  const {
    isLoading,
    hasError,
    studyDetail,
    loadStudyDetail,
    hasExistingStudyDetail,
  } = useStudyDetailDataLoader();

  const [showEditStudyDetailDialog, setShowEditStudyDetailDialog] =
    useState(false);

  const [showEditVerilyCdmDialog, setShowEditVerilyCdmDialog] = useState(false);

  const [focusOnEditStudyDetailButton, setFocusOnEditStudyDetailButton] =
    useState(false);

  const [focusOnEditVerilyCdmButton, setFocusOnEditVerilyCdmButton] =
    useState(false);

  const [fromEditStudyDetailDialog, setFromEditStudyDetailDialog] =
    useState(false);

  const [fromEditVerilyCdmDialog, setFromEditVerilyCdmDialog] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);

  const [statusMessage, setStatusMessage] = useState<string>("");

  // Set page title.
  usePageTitle("Study details");

  // Reload data if currently selected study changes.
  useEffect(() => {
    loadStudyDetail(currentRegistryId);
  }, [currentRegistryId, loadStudyDetail]);

  // Set focus on the edit buttons after exit from edit study detail or Verily CDM dialogs.
  useEffect(() => {
    if (!isLoading && (fromEditStudyDetailDialog || fromEditVerilyCdmDialog)) {
      // Add 500ms delay so that the success message is announced first.
      const timeoutId = setTimeout(() => {
        if (fromEditStudyDetailDialog || fromEditVerilyCdmDialog) {
          setShowSnackBar(true);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [isLoading, fromEditStudyDetailDialog, fromEditVerilyCdmDialog]);

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "24px 40px 24px 40px",
      }}
    >
      <Box sx={{ marginBottom: "8px" }}>
        <Typography component="h1" variant="display5">
          Study details
        </Typography>
      </Box>
      {/* Show loading indicator while loading is in progress */}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Show error screen if there is a loading error */}
      {!isLoading && hasError && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <Alert severity="error">
            Failed to load study details. Please try again.
          </Alert>
        </Box>
      )}

      {/* Show study detail if it exists */}
      {!isLoading && !hasError && studyDetail !== null && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "start",
                padding: "8px 24px 24px 24px",
                background: theme.palette.background.canvas,
                borderRadius: "16px",
              }}
            >
              <StudyDetailCard
                studyDetail={studyDetail}
                showCompactMode={false}
                focusOnEditButton={focusOnEditStudyDetailButton}
                onEditButtonClick={() => {
                  setShowEditStudyDetailDialog(true);
                  setFocusOnEditVerilyCdmButton(false);
                  setFocusOnEditStudyDetailButton(false);
                  setFromEditStudyDetailDialog(false);
                  setFromEditVerilyCdmDialog(false);
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "start",
                padding: "8px 24px 24px 24px",
                background: theme.palette.background.canvas,
                borderRadius: "16px",
                marginTop: "40px",
              }}
            >
              <VerilyCDMCard
                studyDetail={studyDetail}
                showCompactMode={false}
                focusOnEditButton={focusOnEditVerilyCdmButton}
                onEditButtonClick={() => {
                  setShowEditVerilyCdmDialog(true);
                  setFocusOnEditVerilyCdmButton(false);
                  setFocusOnEditStudyDetailButton(false);
                  setFromEditStudyDetailDialog(false);
                  setFromEditVerilyCdmDialog(false);
                }}
              />
            </Box>
          </Box>

          <EditVerilyCdmDialog
            open={showEditVerilyCdmDialog}
            registryId={currentRegistryId}
            onClose={() => {
              setShowEditVerilyCdmDialog(false);
              loadStudyDetail(currentRegistryId);
              setFocusOnEditVerilyCdmButton(true);
            }}
            detail={studyDetail === null ? new StudyDetail() : studyDetail}
            hasExistingStudyDetail={hasExistingStudyDetail}
            verilyEmailRequired={true}
            onSuccess={() => {
              setShowEditVerilyCdmDialog(false);
              setFromEditVerilyCdmDialog(true);
              setFocusOnEditVerilyCdmButton(true);
              loadStudyDetail(currentRegistryId);
              setStatusMessage("Updated");
            }}
          />

          <EditStudyDetailDialog
            open={showEditStudyDetailDialog}
            registryId={currentRegistryId}
            onClose={() => {
              setShowEditStudyDetailDialog(false);
              loadStudyDetail(currentRegistryId);
              setFocusOnEditStudyDetailButton(true);
            }}
            detail={studyDetail === null ? new StudyDetail() : studyDetail}
            hasExistingStudyDetail={hasExistingStudyDetail}
            onSuccess={() => {
              setShowEditStudyDetailDialog(false);
              setFromEditStudyDetailDialog(true);
              setFocusOnEditStudyDetailButton(true);
              loadStudyDetail(currentRegistryId);
              setStatusMessage("Updated");
            }}
          />

          <Snackbar
            open={showSnackBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            color="success"
            role="alert"
            onClose={() => {
              setShowSnackBar(false);
              setStatusMessage("");
            }}
            text={statusMessage}
            withIcon={true}
          />
        </>
      )}
    </Box>
  );
};

export default StudyDetailsHome;
