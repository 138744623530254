import { useEffect } from "react";

// Define a custom hook to set page title.
export function usePageTitle(screenName: string) {
  useEffect(() => {
    document.title = `Numetric Watch Portal - ${screenName}`;

    return () => {
      document.title = "Numetric Watch Portal";
    };
  }, [screenName]);
}
