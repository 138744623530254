import React, { useEffect, useRef, useState } from "react";
import { RpcError, StatusCode } from "grpc-web";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import {
  DeleteStudySiteRequest,
  StudySite,
} from "generated/studysite/studysite_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { dialog_border_radius } from "styles/Dimensions";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import A11yDialogTitle from "components/A11yDialogTitle";

interface DeleteStudySiteDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studySite: StudySite;
  onClose(): void;
  onSuccess(): void;
}

// The confirmation dialog for deleting a study site.
const DeleteStudySiteDialog: React.FC<DeleteStudySiteDialogProps> = ({
  open,
  studySite,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [errorMessage, setErrorMessage] = useState("");
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const handleClickConfirmButton = () => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          setDisableConfirmButton(true);
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          let isInputsValid = true;

          if (
            !updateReasonFormRef.current!.validateInput(true /* focusOnError */)
          ) {
            isInputsValid = false;
          }

          if (isInputsValid) {
            const client = new StudySiteServiceApiClient(
              auth0Config.audience!,
              token
            );

            const request = new DeleteStudySiteRequest()
              .setName(studySite.getName())
              .setUpdateReason(updateReasonFormRef.current!.getUpdateReason());

            await client.deleteStudySite(request);
            onSuccess();
          }
        } catch (error) {
          console.log("error reason %s", error);

          let msg = "Failed to delete the study site. Please try again.";
          if (error instanceof RpcError) {
            if (error.code === StatusCode.FAILED_PRECONDITION) {
              msg = "Failed to delete the study site. It's already in use.";
            }
          }
          setErrorMessage(msg);
        }

        setDisableConfirmButton(false);
      })();
    } else {
      setErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
    }
  };

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setErrorMessage("");
      setDisableConfirmButton(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "444px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display5">Delete site</Typography>
      </A11yDialogTitle>
      {errorMessage.length > 0 && (
        <Alert severity="error" sx={{ paddingLeft: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent sx={{ padding: "24px" }}>
        <Typography variant="body1">
          This will remove this site from this study. Any users associated with
          this site will also be removed.
        </Typography>
        <Typography variant="body1" sx={{ paddingTop: "24px" }}>
          You can't undo this aciton.
        </Typography>
        <Box sx={{ marginTop: "30px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={["Error correction"]}
            reasonSelectionLabel="Reason for change (required)"
            reasonRequiredMessage="Reason for change required"
            otherReasonHint="Please provide reason"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          disabled={disableConfirmButton}
          onClick={handleClickConfirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStudySiteDialog;
