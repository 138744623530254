import {
  Box,
  Stack,
  TableCell,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useRovingTabIndex, useFocusEffect } from "react-roving-tabindex";
import { tableCellStyle } from "./CalendarTypes";
import { getDateAriaLabel } from "./CalendarUtils";
import { GreyBarType, NoDataBar } from "./NoDataBars";

interface NoDataCalendarCellProps {
  date: Date;
  highlight: boolean;
  grey: boolean;
  type: GreyBarType;
  lastestSync: Date;
  weekIndex: number;
}

export const NoDataCalendarCell: React.FC<NoDataCalendarCellProps> = ({
  date,
  grey,
  highlight,
  type,
  lastestSync,
  weekIndex,
}) => {
  const theme = useTheme();

  // The ref to the TableCell element.
  const cellRef = useRef<HTMLTableCellElement>(null);

  // handleKeyDown and handleClick are stable for the lifetime of the component:
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    cellRef,
    false,
    weekIndex
  );

  // Set focus on the TableCell when needed.
  useFocusEffect(focused, cellRef);

  let dayCircleColor: string | undefined;
  let dayNumberColor = theme.palette.text.emphasized;
  if (highlight) {
    dayCircleColor = theme.palette.primary.main;
    dayNumberColor = theme.palette.common.white;
  }
  if (grey) {
    if (dayCircleColor) {
      dayCircleColor = lighten(dayCircleColor, 0.5);
    }
    dayNumberColor = theme.palette.text.default;
  }

  // Handle navigation to NoDataBar
  const [showNoDataBarFocus, setShowNoDataBarFocus] = useState(false);
  const [showNoDataBarTooltip, setShowNoDataBarTooltip] = useState(false);

  useEffect(() => {
    setShowNoDataBarFocus(false);
    setShowNoDataBarTooltip(false);
  }, [date, focused]);

  // Returns whether the keyboard event is handled and the focus should still be
  // inside the table cell.
  const handleIconNavigation = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "ArrowRight") {
      if (!showNoDataBarFocus) {
        setShowNoDataBarFocus(true);
        return true;
      }

      if (!showNoDataBarTooltip) {
        setShowNoDataBarTooltip(true);
        return true;
      }

      setShowNoDataBarTooltip(false);
      return false;
    }

    setShowNoDataBarFocus(false);
    setShowNoDataBarTooltip(false);
    return false;
  };

  return (
    <TableCell
      role="gridcell"
      ref={cellRef}
      tabIndex={tabIndex}
      onKeyDown={(event) => {
        const handled = handleIconNavigation(event);
        if (handled) {
          return;
        }
        handleKeyDown(event);
      }}
      onClick={handleClick}
      sx={{
        ...tableCellStyle,
        padding: "0px",
        borderColor: theme.palette.background.separator,
      }}
    >
      <Stack
        width="100%"
        height="100%"
        minHeight="100px"
        justifyContent="space-between"
        sx={{ padding: "8px" }}
      >
        <Box
          alignSelf="end"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={theme.spacing(3)}
          height={theme.spacing(3)}
          borderRadius="100%"
          bgcolor={dayCircleColor}
          sx={{
            marginBottom: "16px",
          }}
        >
          <Typography
            aria-label={getDateAriaLabel(date)}
            lineHeight="100%"
            color={dayNumberColor}
            fontSize={12}
            variant={grey ? "overline" : "overlineem"}
          >
            {date.getDate()}
          </Typography>
        </Box>
        <NoDataBar
          type={type}
          latestSync={lastestSync}
          showFocus={showNoDataBarFocus}
          showTooltip={showNoDataBarTooltip}
        />
      </Stack>
    </TableCell>
  );
};
