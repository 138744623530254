// source: participantcompliancetask/participantcompliancetask.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest', null, global);
goog.exportSymbol('proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest', null, global);
goog.exportSymbol('proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse', null, global);
goog.exportSymbol('proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask', null, global);
goog.exportSymbol('proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.displayName = 'proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.displayName = 'proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.displayName = 'proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.displayName = 'proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    date: (f = msg.getDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    taskStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask;
  return proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus} */ (reader.readEnum());
      msg.setTaskStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTaskStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus = {
  UNKNOWN: 0,
  NEED_CONTACTING: 1,
  RESOLVED: 2
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date date = 3;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.getDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.hasDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaskStatus task_status = 4;
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.getTaskStatus = function() {
  return /** @type {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.TaskStatus} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.prototype.setTaskStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    participantComplianceTask: (f = msg.getParticipantComplianceTask()) && proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest;
  return proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = new proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask;
      reader.readMessage(value,proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinaryFromReader);
      msg.setParticipantComplianceTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParticipantComplianceTask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ParticipantComplianceTask participant_compliance_task = 2;
 * @return {?proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.getParticipantComplianceTask = function() {
  return /** @type{?proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask, 2));
};


/**
 * @param {?proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask|undefined} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.setParticipantComplianceTask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.clearParticipantComplianceTask = function() {
  return this.setParticipantComplianceTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.CreateParticipantComplianceTaskRequest.prototype.hasParticipantComplianceTask = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    participantsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    startDate: (f = msg.getStartDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    siteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    registryId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest;
  return proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addParticipants(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegistryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string participants = 1;
 * @return {!Array<string>}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.getParticipantsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.setParticipantsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.addParticipants = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional google.type.Date start_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date end_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string site_id = 4;
 * @return {string}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string registry_id = 5;
 * @return {string}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.getRegistryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksRequest.prototype.setRegistryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse;
  return proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask;
      reader.readMessage(value,proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ParticipantComplianceTask tasks = 1;
 * @return {!Array<!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask>} value
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse} returns this
*/
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask}
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.participantcompliancetask.v1alpha1.ParticipantComplianceTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse} returns this
 */
proto.verily.sdp.participantcompliancetask.v1alpha1.FilterParticipantComplianceTasksResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


goog.object.extend(exports, proto.verily.sdp.participantcompliancetask.v1alpha1);
