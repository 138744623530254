import { LRUCache } from "lru-cache";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";

/**
 * Sets up options to cache compliance settings for 3 studies.
 */
const cacheOptions = {
  max: 3,
};

/**
 * Cache for compliance settings.
 */
export const complianceSettingsCache = new LRUCache<string, ComplianceSetting>(
  cacheOptions
);

export function clearComplianceSettingsCache() {
  complianceSettingsCache.clear();
}
