import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import {
  AccountType,
  User,
  ValidateEmailRequest,
} from "generated/studyauth/studyauth_pb";
import { InputTextField, InputTextFieldRef } from "components/InputText";
import validator from "validator";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "redux/hooks";
import { StudyAuthServiceApiClient } from "apiclient/StudyAuthServiceApiClient";

interface UserInfoFormProps {
  initValue?: User;
  emailDisabled?: boolean;
  verilyEmailRequired?: boolean;
}

// Defines an interface for the functions exposed from UserInfoForm.
export interface UserInfoFormRef {
  clearErrorMessage: () => void;
  validateInput: (focusOnError?: boolean) => Promise<boolean>;
  getUserInfo: () => User;
}

// Define an enum to specify which field should focus on when there are errors.
enum FormField {
  FirstName = 0,
  LastName = 1,
  Email = 2,
}

// A form to collect user information.
const UserInfoForm = forwardRef<UserInfoFormRef, UserInfoFormProps>(
  ({ initValue, emailDisabled, verilyEmailRequired }, ref) => {
    const auth0Config = useAppSelector((state) => state.auth0Config);
    const { getAccessTokenSilently } = useAuth0();

    const [firstName, setFirstName] = useState("");
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

    const [lastName, setLastName] = useState("");
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

    const [email, setEmail] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const clearErrorMessage = useCallback(() => {
      setFirstNameErrorMessage("");
      setLastNameErrorMessage("");
      setEmailErrorMessage("");
    }, []);

    // Set initial state when the dialog is opened.
    useEffect(() => {
      if (initValue) {
        setFirstName(initValue.getFirstName());
        setLastName(initValue.getLastName());
        setEmail(initValue.getEmailAddress());
      } else {
        setFirstName("");
        setLastName("");
        setEmail("");
      }

      clearErrorMessage();
    }, [clearErrorMessage, initValue]);

    // Hold the reference to form fields which could show a validation error
    // and need to be focused on.
    const formFields = useRef(new Array<InputTextFieldRef | null>());

    const validateInput = useCallback(
      async (focusOnError) => {
        const errorFields = new Array<FormField>();

        if (firstName.trim().length < 1) {
          setFirstNameErrorMessage("First name required");
          errorFields.push(FormField.FirstName);
        }

        if (lastName.trim().length < 1) {
          setLastNameErrorMessage("Last name required");
          errorFields.push(FormField.LastName);
        }

        if (email.length < 1) {
          setEmailErrorMessage("Email required");
          errorFields.push(FormField.Email);
        } else if (!validator.isEmail(email)) {
          setEmailErrorMessage("Email has invalid format");
          errorFields.push(FormField.Email);
        } else if (verilyEmailRequired) {
          if (!email.endsWith("@verily.com")) {
            setEmailErrorMessage("Please use a verily.com email address");
            errorFields.push(FormField.Email);
          }
        } else {
          // Call ValidateEmail API to validate email address and domain
          try {
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            const client = new StudyAuthServiceApiClient(
              auth0Config.audience!,
              token
            );

            const request = new ValidateEmailRequest().setEmail(email);
            const response = await client.validateEmail(request);

            if (!response.getHasValidFormat()) {
              setEmailErrorMessage("Email has invalid format");
              errorFields.push(FormField.Email);
            } else if (!response.getHasValidDomain()) {
              setEmailErrorMessage("Email has invalid domain");
              errorFields.push(FormField.Email);
            }
          } catch (error) {
            console.log("Failed in ValidateEmail: " + error);
            setEmailErrorMessage("Email has invalid format");
            errorFields.push(FormField.Email);
          }
        }

        // Focus on first error field
        if (errorFields.length > 0 && focusOnError) {
          formFields.current[errorFields[0]]?.focus();
        }

        return errorFields.length === 0;
      },
      [
        auth0Config.audience,
        email,
        firstName,
        getAccessTokenSilently,
        lastName,
        verilyEmailRequired,
      ]
    );

    const getUserInfo = useCallback(() => {
      const user = new User()
        .setFirstName(firstName.trim())
        .setLastName(lastName.trim())
        .setEmailAddress(email)
        .setAccountType(AccountType.EMAIL_PASSWORD);
      return user;
    }, [email, firstName, lastName]);

    // Expose functions to parent components.
    useImperativeHandle(
      ref,
      () => ({
        clearErrorMessage,
        validateInput,
        getUserInfo,
      }),
      [getUserInfo, validateInput, clearErrorMessage]
    );

    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <InputTextField
          id="firstName"
          label="First name (required)"
          ref={(el) => (formFields.current[FormField.FirstName] = el)}
          placeholder="First name"
          helperText={firstNameErrorMessage}
          showError={firstNameErrorMessage.length > 0}
          value={firstName}
          onChange={(newValue) => {
            setFirstNameErrorMessage("");
            setFirstName(newValue);
          }}
          sx={{ margin: "8px 0px 22px 0px" }}
        />
        <InputTextField
          id="lastName"
          label="Last name (required)"
          ref={(el) => (formFields.current[FormField.LastName] = el)}
          placeholder="Last name"
          helperText={lastNameErrorMessage}
          showError={lastNameErrorMessage.length > 0}
          value={lastName}
          onChange={(newValue) => {
            setLastNameErrorMessage("");
            setLastName(newValue);
          }}
          sx={{ margin: "8px 0px 22px 0px" }}
        />
        <InputTextField
          id="email"
          label="Email (required)"
          ref={(el) => (formFields.current[FormField.Email] = el)}
          placeholder="name@email.com"
          helperText={emailErrorMessage}
          showError={emailErrorMessage.length > 0}
          value={email}
          disabled={emailDisabled}
          onChange={(newValue) => {
            setEmailErrorMessage("");
            setEmail(newValue.trim());
          }}
          sx={{ margin: "8px 0px 22px 0px" }}
        />
      </Box>
    );
  }
);

export default UserInfoForm;
