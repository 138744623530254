import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import {
  GetStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";

export async function getParticipant(
  apiEndpoint: string,
  auth0AccessToken: string,
  studyParticipantId: string
) {
  let studyParticipant: StudyParticipant | null = null;
  let hasError = false;

  try {
    const studyParticipantClient = new StudyParticipantServiceApiClient(
      apiEndpoint,
      auth0AccessToken
    );

    const request = new GetStudyParticipantRequest().setName(
      "studyParticipants/" + studyParticipantId
    );

    studyParticipant = await studyParticipantClient.getStudyParticipant(
      request
    );
  } catch (error) {
    console.log("error reason %s", error);
    hasError = true;
  }

  return { participant: studyParticipant, hasError: hasError };
}
