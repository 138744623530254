import { Box } from "@mui/material";
import { sxVisuallyHidden } from "common/Accessibility";
import pluralize from "pluralize";

export function getSearchResultStatusMessage(count: number) {
  return pluralize("search result", count, true);
}

interface A11yStatusMessageProps {
  message: string;
}

// A component to convey dynamic a11y related status updates or announcements.
const A11yStatusMessage: React.FC<A11yStatusMessageProps> = ({ message }) => {
  return (
    <Box
      role="status"
      aria-live="polite"
      aria-atomic="true"
      sx={sxVisuallyHidden}
    >
      {message}
    </Box>
  );
};

export default A11yStatusMessage;
