import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { Alert, Box, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SingleSelectDropdown } from "@verily-src/react-design-system";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { RemovePicardPrefix } from "common/ResourceName";
import _ from "lodash";
import useStudyParticipantsDataLoader from "studyparticipant/StudyParticipantsDataLoader";
import { selectCurrentStudyRoles } from "core/UserConfigSlice";
import { Role } from "generated/studyauth/studyauth_pb";
import Loading from "./Loading";

interface ParticipantRow {
  participantId: string;
  hasError: boolean;
  errorText: string;
}

export interface WatchSwapperRef {
  validateSwaps: () => boolean;
  focusOnTitle: () => void;
}

interface WatchSwapperProps {
  swaps: Map<string, string>;
  setSwaps: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  studyParticipant?: StudyParticipant;
}

// The dialog for updating a participant status
const WatchSwapper = forwardRef<WatchSwapperRef, WatchSwapperProps>(
  ({ swaps, setSwaps, studyParticipant }, ref) => {
    const theme = useTheme();
    const inputRef = useRef(null);
    const titleRef = useRef<HTMLHeadingElement>(null);

    const participantsViewState = useAppSelector((state) => {
      return state.participantsViewState;
    });

    // The UI will display at minimum 2 participant rows
    const minimumParticipants = 2;
    const defaultParticipantRow: ParticipantRow = {
      participantId: "",
      hasError: false,
      errorText: "",
    };
    const [selectedParticipants, setSelectedParticipants] = useState<
      Array<ParticipantRow>
    >(Array(minimumParticipants).fill(defaultParticipantRow));

    const [participantsDropdownOptions, setParticipantsDropdownOptions] =
      useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
      var selected: ParticipantRow[] = [];
      if (swaps.size > 0) {
        selected = Array.from(swaps.keys()).map((id) => {
          let newRow = _.clone(defaultParticipantRow);
          newRow.participantId = id;
          return newRow;
        });
      }

      // Add default rows
      for (let i = selected.length; i < minimumParticipants; i++) {
        selected.push(defaultParticipantRow);
      }

      setSelectedParticipants(selected);

      // Reset other fields
      setParticipantsDropdownOptions([]);
    }, []);

    // Set default selected participant, if provided
    useEffect(() => {
      if (
        studyParticipant &&
        !swaps.has(studyParticipant.getSponsorParticipantId())
      ) {
        setSwaps(new Map([[studyParticipant.getSponsorParticipantId(), ""]]));
      }
    }, [swaps, setSwaps, studyParticipant]);

    // When selected participants are updated, update swaps with auto-selected pairs
    useEffect(() => {
      if (
        selectedParticipants.length === 2 &&
        selectedParticipants.every((row) => row.participantId)
      ) {
        let newSwaps = new Map<string, string>([
          [
            selectedParticipants[0].participantId,
            selectedParticipants[1].participantId,
          ],
          [
            selectedParticipants[1].participantId,
            selectedParticipants[0].participantId,
          ],
        ]);
        setSwaps(newSwaps);
      }
    }, [selectedParticipants, setSwaps]);

    const [participantsMap, setParticipantsMap] = useState<
      Map<string, StudyParticipant>
    >(new Map<string, StudyParticipant>());

    // Get StudySiteId
    const selectedRoles = useAppSelector((state) =>
      selectCurrentStudyRoles(state.userConfig)
    );

    // Note that CRCs can only manage one site per study.
    const currentStudySiteId = useMemo(() => {
      for (let userRole of selectedRoles) {
        if (userRole.role === Role.CRC) {
          return userRole.studySiteId;
        }
      }

      return "";
    }, [selectedRoles]);

    const { hasError, isLoading, studyParticipants, loadStudyParticipants } =
      useStudyParticipantsDataLoader();
    const [isParticipantsMapReady, setIsParticipantsMapReady] = useState(false);

    useEffect(() => {
      if (currentStudySiteId) {
        loadStudyParticipants(currentStudySiteId);
      }
    }, [currentStudySiteId, loadStudyParticipants]);

    // Update participant map
    useEffect(() => {
      studyParticipants.forEach((participant: StudyParticipant) => {
        setParticipantsMap(
          participantsMap.set(
            participant.getSponsorParticipantId(),
            participant
          )
        );
      });
      if (studyParticipants.length > 0) {
        setIsParticipantsMapReady(true);
      }
    }, [participantsViewState, participantsMap, studyParticipants]);

    // Update list of dropdown options
    useEffect(() => {
      const participantOptions = studyParticipants
        .filter((participant: StudyParticipant) => {
          // Exclude the following:
          // - studyParticipant, if provided
          // - participant has no device
          // - participant has exited the study
          // - participant already selected
          return (
            (studyParticipant
              ? participant.getSponsorParticipantId() !==
                studyParticipant.getSponsorParticipantId()
              : true) &&
            participant.getDeviceId().length > 0 &&
            ![
              StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED,
              StudyParticipant.ParticipantStatus.EXIT_STUDY_WITHDRAWN,
            ].includes(participant.getStatus()) &&
            !selectedParticipants.some(
              (row) =>
                row.participantId === participant.getSponsorParticipantId()
            )
          );
        })
        .map((participant: StudyParticipant) => {
          return {
            label: participant.getSponsorParticipantId(),
            value: participant.getSponsorParticipantId(),
          };
        });

      setParticipantsDropdownOptions(participantOptions);
    }, [
      participantsViewState,
      selectedParticipants,
      studyParticipant,
      studyParticipants,
    ]);

    const validateSwaps = useCallback(() => {
      let isValid = true;
      const newSelected = selectedParticipants.map((row: ParticipantRow) => {
        if (row.participantId.length === 0) {
          row.hasError = true;
          row.errorText = "Please select a participant";
          isValid = false;
        }

        return row;
      });
      setSelectedParticipants(newSelected);

      return isValid;
    }, [selectedParticipants]);

    const focusOnTitle = useCallback(() => {
      titleRef.current?.focus();
    }, []);

    useImperativeHandle(
      ref,
      () => {
        return { validateSwaps, focusOnTitle };
      },
      [validateSwaps, focusOnTitle]
    );
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Alert severity="error" sx={{ marginTop: "24px", paddingLeft: "16px" }}>
          Failed to load watch swapping page. Please try again.
        </Alert>
      );
    }
    return (
      <Box ref={inputRef}>
        <Box>
          <Typography
            ref={titleRef}
            tabIndex={-1}
            component="h2"
            variant="display6"
          >
            Swap watches
          </Typography>
          <Typography variant="body1" paddingTop="16px">
            First, select a participant to swap watches with. Then, select the
            watch you want to swap.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
            position: "absolute",
          }}
        >
          <Paper
            sx={{
              marginTop: "24px",
              padding: "16px 50px",
              background: theme.palette.background.canvas,
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="swap-watches-table">
                <TableHead>
                  <TableRow sx={{ marginLeft: "60px" }}>
                    <TableCell>Participant ID</TableCell>
                    <TableCell>Current device</TableCell>
                    <TableCell>New device</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedParticipants.map((row, index) => {
                    const isRequiredParticipant = studyParticipant
                      ? row.participantId ===
                        studyParticipant.getSponsorParticipantId()
                      : false;

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                          {isRequiredParticipant && row.participantId}
                          {!isRequiredParticipant && (
                            <SingleSelectDropdown
                              ariaLabel="participant-dropdown"
                              value={row.participantId}
                              renderValue={(value) => {
                                let valueText = value ? value : "Select";
                                let fontColor = value
                                  ? theme.palette.text.primary
                                  : theme.palette.text.secondary;

                                return (
                                  <Typography
                                    sx={{
                                      fontFamily: "Roboto Mono",
                                      color: fontColor,
                                    }}
                                  >
                                    {valueText}
                                  </Typography>
                                );
                              }}
                              error={row.hasError}
                              helperText={row.errorText}
                              options={participantsDropdownOptions}
                              placeholder="Select"
                              sx={{
                                backgroundColor: theme.palette.background.paper,
                                width: "240px",
                              }}
                              onChange={(event) => {
                                // Get selected value
                                const selectedParticipantId = event.target
                                  .value as string;

                                setSelectedParticipants(
                                  selectedParticipants.map((selected, i) => {
                                    if (i === index) {
                                      return {
                                        participantId: selectedParticipantId,
                                        hasError: false,
                                        errorText: "",
                                      };
                                    }

                                    return selected;
                                  })
                                );
                              }}
                            ></SingleSelectDropdown>
                          )}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                          {isParticipantsMapReady && row.participantId
                            ? RemovePicardPrefix(
                                participantsMap
                                  .get(row.participantId)!
                                  .getDeviceId()
                              )
                            : "--"}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                          {(() => {
                            const swapWithParticipantId = swaps.get(
                              row.participantId
                            );

                            const deviceId =
                              swapWithParticipantId && isParticipantsMapReady
                                ? participantsMap
                                    .get(swapWithParticipantId)!
                                    .getDeviceId()
                                : "--";
                            return RemovePicardPrefix(deviceId);
                          })()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    );
  }
);

export default WatchSwapper;
