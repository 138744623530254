import { Metadata } from "grpc-web";
import { StudyDetailServicePromiseClient } from "generated/studydetail/studydetail_grpc_web_pb";
import {
  StudyDetail,
  CreateStudyDetailRequest,
  GetStudyDetailRequest,
  UpdateStudyDetailRequest,
  ListStudyDetailsRequest,
} from "generated/studydetail/studydetail_pb";

/**
 * Defines helper methods to call StudyDetailService.
 */
export class StudyDetailServiceApiClient {
  private gRpcClient: StudyDetailServicePromiseClient;

  /**
   * Creates an instance of StudyDetailServiceApiClient.
   *
   * Note that the class should be created for one time use since it caches the auth0AccessToken
   * which could expire.
   *
   * @param apiEndPoint the url points to the API server, such as "https://sdp-dev.api.verily.com".
   * @param auth0AccessToken auth0 access token
   */
  constructor(apiEndPoint: string, private auth0AccessToken: string) {
    this.gRpcClient = new StudyDetailServicePromiseClient(
      apiEndPoint,
      null,
      null
    );
  }

  /**
   * Returns the meta data for gRpc call with authorization header and gRpc call
   * timeout as 10 seconds.
   */
  getGrpcMetaData(): Metadata {
    return {
      Authorization: "Bearer " + this.auth0AccessToken,
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  createStudyDetail(request: CreateStudyDetailRequest) {
    return this.gRpcClient.createStudyDetail(request, this.getGrpcMetaData());
  }

  updateStudyDetail(request: UpdateStudyDetailRequest) {
    return this.gRpcClient.updateStudyDetail(request, this.getGrpcMetaData());
  }

  getStudyDetail(registryId: string) {
    const request = new GetStudyDetailRequest().setName(
      "studyDetails/" + registryId
    );
    return this.gRpcClient.getStudyDetail(request, this.getGrpcMetaData());
  }

  listStudyDetails(registryIds: string[]) {
    const names = registryIds.map((id) => "studyDetails/" + id);
    const request = new ListStudyDetailsRequest().setNamesList(names);
    return this.gRpcClient.listStudyDetails(request, this.getGrpcMetaData());
  }
}

/**
 * @returns a default study detail if it's not defined yet.
 */
export function getDefaultStudyDetail() {
  return new StudyDetail().setName("");
}
