import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppRoutes } from "core/AppRoutes";
import { ComplianceScope } from "home/NonCompliantTabDataLoader";

/**
 * Stores information of the selected participant for participant calendar view.
 */
export interface ParticipantComplianceState {
  participantId: string;
  subjectId: string;
  deviceId: string;
  siteName?: string;
  scope?: ComplianceScope;
  returnRoute: string;
}

const initialState: ParticipantComplianceState = {
  participantId: "",
  subjectId: "",
  deviceId: "",
  returnRoute: AppRoutes.HOME,
};

const participantComplianceStateSlice = createSlice({
  name: "participantComplianceState",
  initialState,
  reducers: {
    setParticipantComplianceState: (
      state,
      action: PayloadAction<ParticipantComplianceState>
    ) => {
      state.participantId = action.payload.participantId;
      state.subjectId = action.payload.subjectId;
      state.deviceId = action.payload.deviceId;
      state.siteName = action.payload.siteName;
      state.scope = action.payload.scope;
      state.returnRoute = action.payload.returnRoute;
    },
  },
});

export const { setParticipantComplianceState } =
  participantComplianceStateSlice.actions;

export default participantComplianceStateSlice.reducer;
