import { Looker40SDK } from "@looker/sdk";
import { find, unionWith } from "lodash";
import {
  NonCompliantReason,
  NonCompliantRecord,
  ComplianceType,
  NonComplianceMap,
  ParticipantComplianceRecord,
  ParticipantRecord,
  participantsSyncComplianceCache,
  participantsWearComplianceCache,
  participantsMedLogDailyComplianceCache,
  participantsMedLogWeeklyComplianceCache,
} from "./QueryResultsCache";
import { LookerDashboardId } from "generated/studydata/studydata_pb";

/**
 * Queries Looker to get participant wear compliance records for a week and study.
 *
 * The function assumes that the Looker user attributes for week and study are
 * set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
async function getStudyWearComplianceRecords(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<ParticipantComplianceRecord>();
  let hasError = false;

  try {
    if (participantsWearComplianceCache.has(previousWeekOffset)) {
      return {
        complianceType: ComplianceType.Wear,
        records: participantsWearComplianceCache.get(previousWeekOffset)!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_STUDY_WEAR_COMPLIANCE
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        records.push({
          participantId:
            row["participants_study_wear_compliance.participant_id"],
          subjectId: row["participants_study_wear_compliance.subject_id"],
          deviceId: row["participants_study_wear_compliance.device_id"],
          siteId: row["participants_study_wear_compliance.site_id"],
          isCompliant:
            row["participants_study_wear_compliance.is_compliant"] === 1,
        });
      }

      // Store the results in cache.
      participantsWearComplianceCache.set(previousWeekOffset, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return {
    complianceType: ComplianceType.Wear,
    records: records,
    hasError: hasError,
  };
}

/**
 * Queries Looker to get participants sync compliance records for a week and study.
 *
 * The function assumes that the Looker user attributes for week and study are
 * set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
async function getStudySyncComplianceRecords(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<ParticipantComplianceRecord>();
  let hasError = false;

  try {
    if (participantsSyncComplianceCache.has(previousWeekOffset)) {
      return {
        complianceType: ComplianceType.Sync,
        records: participantsSyncComplianceCache.get(previousWeekOffset)!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_STUDY_SYNC_COMPLIANCE
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        records.push({
          participantId:
            row["participants_study_sync_compliance.participant_id"],
          subjectId: row["participants_study_sync_compliance.subject_id"],
          deviceId: row["participants_study_sync_compliance.device_id"],
          siteId: row["participants_study_sync_compliance.site_id"],
          isCompliant:
            row["participants_study_sync_compliance.is_compliant"] === 1,
        });
      }

      // Store the results in cache.
      participantsSyncComplianceCache.set(previousWeekOffset, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return {
    complianceType: ComplianceType.Sync,
    records: records,
    hasError: hasError,
  };
}

/**
 * Queries Looker to get participant medication logging daily compliance records for a week and study.
 *
 * The function assumes that the Looker user attributes for week and study are
 * set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
async function getStudyMedLogDailyComplianceRecords(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<ParticipantComplianceRecord>();
  let hasError = false;

  try {
    if (participantsMedLogDailyComplianceCache.has(previousWeekOffset)) {
      return {
        complianceType: ComplianceType.MedLogDaily,
        records:
          participantsMedLogDailyComplianceCache.get(previousWeekOffset)!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_STUDY_MED_LOGGING_DAILY_COMPLIANCE
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        records.push({
          participantId:
            row[
              "participants_study_med_logging_daily_compliance.participant_id"
            ],
          subjectId:
            row["participants_study_med_logging_daily_compliance.subject_id"],
          deviceId:
            row["participants_study_med_logging_daily_compliance.device_id"],
          siteId:
            row["participants_study_med_logging_daily_compliance.site_id"],
          isCompliant:
            row[
              "participants_study_med_logging_daily_compliance.is_compliant"
            ] === 1,
        });
      }

      // Store the results in cache.
      participantsMedLogDailyComplianceCache.set(previousWeekOffset, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return {
    complianceType: ComplianceType.MedLogDaily,
    records: records,
    hasError: hasError,
  };
}

/**
 * Queries Looker to get participant medication logging weekly compliance records for a week and study.
 *
 * The function assumes that the Looker user attributes for week and study are
 * set up properly.
 *
 * See instructions below about how to get the Looker query id and query field names
 * used in this function:
 *   go/verily-devices-looker-dashboards
 */
async function getStudyMedLogWeeklyComplianceRecords(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let records = new Array<ParticipantComplianceRecord>();
  let hasError = false;

  try {
    if (participantsMedLogWeeklyComplianceCache.has(previousWeekOffset)) {
      return {
        complianceType: ComplianceType.MedLogWeekly,
        records:
          participantsMedLogWeeklyComplianceCache.get(previousWeekOffset)!,
        hasError: false,
      };
    }

    const results = await sdk.ok(
      sdk.run_query({
        query_id: lookerQueryIdsMap.get(
          LookerDashboardId.PARTICIPANTS_STUDY_MED_LOGGING_WEEKLY_COMPLIANCE
        )!,
        result_format: "json",
      })
    );

    if (results && Array.isArray(results)) {
      for (let row of results) {
        records.push({
          participantId:
            row[
              "participants_study_med_logging_weekly_compliance.participant_id"
            ],
          subjectId:
            row["participants_study_med_logging_weekly_compliance.subject_id"],
          deviceId:
            row["participants_study_med_logging_weekly_compliance.device_id"],
          siteId:
            row["participants_study_med_logging_weekly_compliance.site_id"],
          isCompliant:
            row[
              "participants_study_med_logging_weekly_compliance.is_compliant"
            ] === 1,
        });
      }

      // Store the results in cache.
      participantsMedLogWeeklyComplianceCache.set(previousWeekOffset, records);
    } else {
      hasError = true;
    }
  } catch (error) {
    hasError = true;
  }

  return {
    complianceType: ComplianceType.MedLogWeekly,
    records: records,
    hasError: hasError,
  };
}

/**
 * Queries Looker to get participants compliance records.
 *
 * The function assumes that the Looker user attributes for week and study are
 * set up properly.
 */
export async function getStudyParticipantsComplianceRecords(
  sdk: Looker40SDK,
  previousWeekOffset: number,
  optionalComplianceTypes: ComplianceType[] = [],
  lookerQueryIdsMap: Map<LookerDashboardId, string>
) {
  let activeParticipants = 0;
  const compliantRecords = new Array<ParticipantRecord>();
  const noncompliantRecords = new Array<NonCompliantRecord>();

  try {
    // Get array of required compliance records
    const getComplianceRecords = [
      getStudyWearComplianceRecords(sdk, previousWeekOffset, lookerQueryIdsMap),
      getStudySyncComplianceRecords(sdk, previousWeekOffset, lookerQueryIdsMap),
    ];

    // Get compliance records for optional compliance metrics
    for (let opt of optionalComplianceTypes) {
      switch (opt) {
        case ComplianceType.MedLogDaily:
          getComplianceRecords.push(
            getStudyMedLogDailyComplianceRecords(
              sdk,
              previousWeekOffset,
              lookerQueryIdsMap
            )
          );
          break;
        case ComplianceType.MedLogWeekly:
          getComplianceRecords.push(
            getStudyMedLogWeeklyComplianceRecords(
              sdk,
              previousWeekOffset,
              lookerQueryIdsMap
            )
          );
          break;
      }
    }

    // Start async functions simultaneously
    const results = await Promise.all(getComplianceRecords);
    const hasError = results.some((result) => {
      return result.hasError;
    });
    if (hasError) {
      return {
        activeParticipantsCount: 0,
        compliantRecords: new Array<ParticipantRecord>(),
        noncompliantRecords: new Array<NonCompliantRecord>(),
        hasError: true,
      };
    }

    const keys = results
      .map((result) => result.records)
      .reduce((acc, records) => {
        return unionWith(acc, records, (obj1, obj2) => {
          return (
            obj1.participantId === obj2.participantId &&
            obj1.subjectId === obj2.subjectId &&
            obj1.deviceId === obj2.deviceId &&
            obj1.siteId === obj2.siteId
          );
        });
      });

    activeParticipants = keys.length;

    for (let key of keys) {
      const lookupKey = {
        participantId: key.participantId,
        subjectId: key.subjectId,
        deviceId: key.deviceId,
        siteId: key.siteId,
      };

      const reasons = new Set<NonCompliantReason>();

      results.forEach((result) => {
        let records = result.records;
        let record = find<ParticipantComplianceRecord>(records, lookupKey);

        if (record === undefined || record.isCompliant === false) {
          reasons.add(NonComplianceMap.get(result.complianceType)!);
        }
      });

      if (reasons.size > 0) {
        noncompliantRecords.push({
          ...key,
          reasons: reasons,
        });
      } else {
        compliantRecords.push(key);
      }
    }

    return {
      activeParticipantsCount: activeParticipants,
      compliantRecords: compliantRecords,
      noncompliantRecords: noncompliantRecords,
      hasError: false,
    };
  } catch (error) {
    return {
      activeParticipantsCount: 0,
      compliantRecords: new Array<ParticipantRecord>(),
      noncompliantRecords: new Array<NonCompliantRecord>(),
      hasError: true,
    };
  }
}
