// source: studydetail/studydetail.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_api_client_pb = require('../google/api/client_pb.js');
goog.object.extend(proto, google_api_client_pb);
var google_api_field_behavior_pb = require('../google/api/field_behavior_pb.js');
goog.object.extend(proto, google_api_field_behavior_pb);
var google_api_resource_pb = require('../google/api/resource_pb.js');
goog.object.extend(proto, google_api_resource_pb);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest', null, global);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse', null, global);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.StudyDetail', null, global);
goog.exportSymbol('proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.StudyDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail.displayName = 'proto.verily.sdp.studydetail.v1alpha1.StudyDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.displayName = 'proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.displayName = 'proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.displayName = 'proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.displayName = 'proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.displayName = 'proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.StudyDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    studyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sponsorName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    studyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    verilyCdm: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.StudyDetail;
  return proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsorName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerilyCdm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.StudyDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStudyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSponsorName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStudyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVerilyCdm();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string study_name = 2;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.getStudyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.setStudyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sponsor_name = 3;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.getSponsorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.setSponsorName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string study_id = 4;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.getStudyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.setStudyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string verily_cdm = 5;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.getVerilyCdm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.StudyDetail.prototype.setVerilyCdm = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyDetail: (f = msg.getStudyDetail()) && proto.verily.sdp.studydetail.v1alpha1.StudyDetail.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest;
  return proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studydetail.v1alpha1.StudyDetail;
      reader.readMessage(value,proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinaryFromReader);
      msg.setStudyDetail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studydetail.v1alpha1.StudyDetail.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StudyDetail study_detail = 1;
 * @return {?proto.verily.sdp.studydetail.v1alpha1.StudyDetail}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.getStudyDetail = function() {
  return /** @type{?proto.verily.sdp.studydetail.v1alpha1.StudyDetail} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studydetail.v1alpha1.StudyDetail, 1));
};


/**
 * @param {?proto.verily.sdp.studydetail.v1alpha1.StudyDetail|undefined} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} returns this
*/
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.setStudyDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.clearStudyDetail = function() {
  return this.setStudyDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.hasStudyDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.CreateStudyDetailRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyDetail: (f = msg.getStudyDetail()) && proto.verily.sdp.studydetail.v1alpha1.StudyDetail.toObject(includeInstance, f),
    updateReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest;
  return proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studydetail.v1alpha1.StudyDetail;
      reader.readMessage(value,proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinaryFromReader);
      msg.setStudyDetail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.verily.sdp.studydetail.v1alpha1.StudyDetail.serializeBinaryToWriter
    );
  }
  f = message.getUpdateReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional StudyDetail study_detail = 1;
 * @return {?proto.verily.sdp.studydetail.v1alpha1.StudyDetail}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.getStudyDetail = function() {
  return /** @type{?proto.verily.sdp.studydetail.v1alpha1.StudyDetail} */ (
    jspb.Message.getWrapperField(this, proto.verily.sdp.studydetail.v1alpha1.StudyDetail, 1));
};


/**
 * @param {?proto.verily.sdp.studydetail.v1alpha1.StudyDetail|undefined} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} returns this
*/
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.setStudyDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.clearStudyDetail = function() {
  return this.setStudyDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.hasStudyDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string update_reason = 2;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.getUpdateReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.UpdateStudyDetailRequest.prototype.setUpdateReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest;
  return proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.GetStudyDetailRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest;
  return proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsRequest.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    studyDetailsList: jspb.Message.toObjectList(msg.getStudyDetailsList(),
    proto.verily.sdp.studydetail.v1alpha1.StudyDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse;
  return proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.verily.sdp.studydetail.v1alpha1.StudyDetail;
      reader.readMessage(value,proto.verily.sdp.studydetail.v1alpha1.StudyDetail.deserializeBinaryFromReader);
      msg.addStudyDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudyDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.verily.sdp.studydetail.v1alpha1.StudyDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StudyDetail study_details = 1;
 * @return {!Array<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.getStudyDetailsList = function() {
  return /** @type{!Array<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.verily.sdp.studydetail.v1alpha1.StudyDetail, 1));
};


/**
 * @param {!Array<!proto.verily.sdp.studydetail.v1alpha1.StudyDetail>} value
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse} returns this
*/
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.setStudyDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.verily.sdp.studydetail.v1alpha1.StudyDetail}
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.addStudyDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.verily.sdp.studydetail.v1alpha1.StudyDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse} returns this
 */
proto.verily.sdp.studydetail.v1alpha1.ListStudyDetailsResponse.prototype.clearStudyDetailsList = function() {
  return this.setStudyDetailsList([]);
};


goog.object.extend(exports, proto.verily.sdp.studydetail.v1alpha1);
