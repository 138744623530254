import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TestConfig {
  showLoadUserError: boolean;
  showInviteUserError: boolean;
  showEditUserError: boolean;
  showDeleteUserError: boolean;
  showSaveComplianceSettingError: boolean;
}

const initialState: TestConfig = {
  showLoadUserError: false,
  showInviteUserError: false,
  showEditUserError: false,
  showDeleteUserError: false,
  showSaveComplianceSettingError: false,
};

const testConfigSlice = createSlice({
  name: "testConfig",
  initialState,
  reducers: {
    setShowLoadUserError: (state, action: PayloadAction<boolean>) => {
      state.showLoadUserError = action.payload;
    },
    setShowInviteUserError: (state, action: PayloadAction<boolean>) => {
      state.showInviteUserError = action.payload;
    },
    setShowEditUserError: (state, action: PayloadAction<boolean>) => {
      state.showEditUserError = action.payload;
    },
    setShowDeleteUserError: (state, action: PayloadAction<boolean>) => {
      state.showDeleteUserError = action.payload;
    },
    setShowSaveComplianceSettingError: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showSaveComplianceSettingError = action.payload;
    },
  },
});

export const {
  setShowLoadUserError,
  setShowInviteUserError,
  setShowEditUserError,
  setShowDeleteUserError,
  setShowSaveComplianceSettingError,
} = testConfigSlice.actions;
export default testConfigSlice.reducer;
