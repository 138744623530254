import React, { FC, useMemo, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  useTheme,
  Link,
} from "@mui/material";
import { ReactComponent as KeyboardArrowRightIcon } from "assets/keyboard-arrow-right.svg";
import { ReactComponent as MultipleRequirementIcon } from "assets/multiple-requirement-icon.svg";
import { ReactComponent as WatchIcon } from "assets/watch-icon-black-white.svg";
import { ReactComponent as SyncIcon } from "assets/sync-icon-black-white.svg";
import { ReactComponent as MedicationPillIcon } from "assets/medication-pill-icon-black-white.svg";
import { ReactComponent as NotEnoughDataIllustration } from "assets/not-enough-data-illustration.svg";
import { ReactComponent as AllCompliantIllustration } from "assets/all-compliant-illustration.svg";
import SortableTableHeaderCell, {
  Order,
  orderByNumberAriaDescription,
  orderByTextAriaDescription,
} from "components/SortableTableHeaderCell";
import { NonCompliantReason } from "looker/QueryResultsCache";
import {
  NonCompliantSummaryRecord,
  NonCompliantSummary,
} from "./NonCompliantTabDataLoader";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ComplianceSettingPanel from "./ComplianceSettingPanel";
import WeekNavigation from "core/WeekNavigation";
import {
  resetNonCompliantTabState,
  setNonCompliantTabOrderBy,
  setNonCompliantTabSortOrder,
} from "./NonCompliantTabStateSlice";
import { AppRoutes } from "core/AppRoutes";
import {
  setNonCompliantReason,
  setNonCompliantScope,
} from "./NonCompliantDetailStateSlice";
import { useNavigate } from "react-router-dom";
import { setPreviousWeekOffset } from "core/WeekNavigationStateSlice";
import SlowLoadingIndicator from "components/SlowLoadingIndicator";
import { isActivationKey } from "common/Accessibility";
import { ViewDetailsTableHeaderCell } from "components/ViewDetailsTableHeaderCell";
import useNonCompliantDataLoader, {
  ComplianceScope,
} from "./NonCompliantTabDataLoader";
import { Tooltip } from "@verily-src/react-design-system";
import A11yStatusMessage from "components/A11yStatusMessage";

interface NoCompliantTableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (orderBy: string) => void;
}

const NonCompliantTableHeader: React.FC<NoCompliantTableHeaderProps> = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell aria-hidden="true" width="96px" />
        <SortableTableHeaderCell
          columnName="unmet requirements"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Unmet requirements"
          orderByAriaDescription={orderByTextAriaDescription}
          onRequestSort={onRequestSort}
          width="50%"
        >
          <Typography variant="body2em">Unmet requirements</Typography>
        </SortableTableHeaderCell>
        <SortableTableHeaderCell
          columnName="number of participants"
          order={order}
          orderBy={orderBy}
          orderByAriaLabel="Number of participants"
          orderByAriaDescription={orderByNumberAriaDescription}
          onRequestSort={onRequestSort}
          width="50%"
        >
          <Typography variant="body2em">Number of participants</Typography>
        </SortableTableHeaderCell>
        <ViewDetailsTableHeaderCell />
      </TableRow>
    </TableHead>
  );
};

function getNonCompliantIcon(reason: NonCompliantReason) {
  switch (reason) {
    case NonCompliantReason.Multiple:
      return <MultipleRequirementIcon />;
    case NonCompliantReason.WearTime:
      return <WatchIcon />;
    case NonCompliantReason.SyncTime:
      return <SyncIcon />;
    case NonCompliantReason.MedLog:
      return <MedicationPillIcon />;
  }
}

function getNonCompliantReasonString(reason: NonCompliantReason) {
  switch (reason) {
    case NonCompliantReason.Multiple:
      return "More than one";
    case NonCompliantReason.WearTime:
      return "Only watch wear time";
    case NonCompliantReason.SyncTime:
      return "Only watch sync";
    case NonCompliantReason.MedLog:
      return "Only medication logs";
  }
}

function getViewNonCompliantArialLabel(record: NonCompliantSummaryRecord) {
  return `View ${getNonCompliantReasonString(
    record.reason
  )} unmet requirement, Number of participants ${record.numberOfParticipants}`;
}

// Define a component to render non-compliant record
interface NonCompliantRowProps {
  record: NonCompliantSummaryRecord;
  onClickNextButton(record: NonCompliantSummaryRecord): void;
}

const NonCompliantRow: React.FC<NonCompliantRowProps> = ({
  record,
  onClickNextButton,
}) => {
  return (
    <TableRow
      hover
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClickNextButton(record);
      }}
    >
      <TableCell aria-hidden="true" width="96px">
        {getNonCompliantIcon(record.reason)}
      </TableCell>
      <TableCell width="50%">
        {getNonCompliantReasonString(record.reason)}
      </TableCell>
      <TableCell width="50%">{record.numberOfParticipants}</TableCell>
      <TableCell width="48px" sx={{ paddingRight: "12px" }}>
        <Tooltip title="View more">
          <Link
            tabIndex={0}
            onClick={() => {
              onClickNextButton(record);
            }}
            onKeyDown={(event) => {
              if (isActivationKey(event)) {
                onClickNextButton(record);
              }
            }}
          >
            <KeyboardArrowRightIcon
              title={getViewNonCompliantArialLabel(record)}
            />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface NonCompliantTableContentProps {
  records: Array<NonCompliantSummaryRecord>;
  onClickNextButton(record: NonCompliantSummaryRecord): void;
}

const NonCompliantTableContent: React.FC<NonCompliantTableContentProps> = ({
  records,
  onClickNextButton,
}) => {
  return (
    <TableBody>
      {records.map((record) => {
        return (
          <NonCompliantRow
            key={record.reason}
            record={record}
            onClickNextButton={onClickNextButton}
          />
        );
      })}
    </TableBody>
  );
};

function getNonCompliantNumber(summary: NonCompliantSummary) {
  if (summary.total <= 0) {
    return "--/--";
  } else {
    return `${summary.nonCompliant}/${summary.total}`;
  }
}

const NotEnoughDataView: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NotEnoughDataIllustration />
      <Typography variant="body1em" sx={{ fontWeight: 600 }}>
        Not enough participant data to show non-compliance
      </Typography>
    </Box>
  );
};

const AllCompliantView: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AllCompliantIllustration />
      <Typography variant="body1em" sx={{ fontWeight: 600 }}>
        All participants met their compliance requirements.
      </Typography>
    </Box>
  );
};

interface NonCompliantTabProps {
  scope: ComplianceScope;
}

const NonCompliantTab: FC<NonCompliantTabProps> = ({ scope }) => {
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );
  const previousWeekOffset = useAppSelector(
    (state) => state.weekNavigationState.previousWeekOffset
  );
  const dispatch = useAppDispatch();

  const { isLoading, hasError, summary, loadNonCompliantData } =
    useNonCompliantDataLoader(scope);

  // Reload data if currently selected study or week changes.
  useEffect(() => {
    loadNonCompliantData(currentRegistryId, previousWeekOffset);
  }, [currentRegistryId, previousWeekOffset, loadNonCompliantData]);

  const {
    nonCompliantTabSortOrder: sortOrder,
    nonCompliantTabOrderBy: orderBy,
  } = useAppSelector((state) => state.nonCompliantTabState);

  // Status message about the direction of sort for a11y support.
  const [sortDirectiontMessage, setSortDirectionMessage] = useState("");

  const handleRequestSort = (orderByColumn: string) => {
    const isAsc = orderBy === orderByColumn && sortOrder === "asc";
    const newSortOrder = isAsc ? "desc" : "asc";
    dispatch(setNonCompliantTabSortOrder(newSortOrder));
    dispatch(setNonCompliantTabOrderBy(orderByColumn));
    setSortDirectionMessage(
      (isAsc ? "Sort descending on " : "Sort ascending on ") + orderByColumn
    );
  };

  const sortedRecords = useMemo(() => {
    return summary.records.sort((a, b) => {
      let result = 0;
      switch (orderBy) {
        case "unmet requirements":
          result = a.reason - b.reason;
          break;
        case "number of participants":
          result = a.numberOfParticipants - b.numberOfParticipants;
          break;
      }
      return sortOrder === "asc" ? result : -result;
    });
  }, [summary.records, orderBy, sortOrder]);

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        justifyContent: "start",
        alignItems: "start",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginRight: "40px",
          flexGrow: 1,
        }}
      >
        <WeekNavigation
          enabled={!isLoading}
          previousWeekOffset={previousWeekOffset}
          onClickPreviousWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset + 1));
            dispatch(resetNonCompliantTabState());
          }}
          onClickNextWeek={() => {
            dispatch(setPreviousWeekOffset(previousWeekOffset - 1));
            dispatch(resetNonCompliantTabState());
          }}
        />
        {isLoading && <SlowLoadingIndicator />}
        {!isLoading && (
          <>
            <Typography component="h2" variant="display6">
              {getNonCompliantNumber(summary)} enrolled participants with active
              watches were non-compliant
            </Typography>
            {!hasError && summary.nonCompliant > 0 && (
              <Box
                sx={{
                  width: "100%",
                  marginTop: "8px",
                  padding: "12px 24px",
                  borderRadius: "16px",
                  backgroundColor: theme.palette.background.canvas,
                }}
              >
                <Typography variant="body2">
                  Note: Participants only appear in one category.
                </Typography>
              </Box>
            )}
            {!hasError && summary.total === 0 && <NotEnoughDataView />}
            {!hasError && summary.total > 0 && summary.nonCompliant === 0 && (
              <AllCompliantView />
            )}
            {(hasError || (summary.total > 0 && summary.nonCompliant > 0)) && (
              <>
                <TableContainer>
                  <Table
                    sx={{
                      minWidth: 700,
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                    aria-label="Noncompliant"
                  >
                    <NonCompliantTableHeader
                      order={sortOrder}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    {!hasError && (
                      <NonCompliantTableContent
                        records={sortedRecords}
                        onClickNextButton={(record) => {
                          dispatch(setNonCompliantScope(scope));
                          dispatch(setNonCompliantReason(record.reason));
                          navigate(AppRoutes.NON_COMPLIANT_DETAIL);
                        }}
                      />
                    )}
                    {hasError && (
                      <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                        Failed to load non-compliant data
                      </Alert>
                    )}
                  </Table>
                  <A11yStatusMessage message={sortDirectiontMessage} />
                </TableContainer>
              </>
            )}
          </>
        )}
      </Box>
      <Box
        role="complementary"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
          marginTop: "8px",
          marginLeft: "24px",
        }}
      >
        <ComplianceSettingPanel registryId={currentRegistryId} />
      </Box>
    </Box>
  );
};

export default NonCompliantTab;
