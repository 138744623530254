import React, { useEffect, useRef, useState } from "react";
import { RpcError, StatusCode } from "grpc-web";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import {
  UpdateStudySiteRequest,
  StudySite,
} from "generated/studysite/studysite_pb";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { dialog_border_radius } from "styles/Dimensions";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import { toDateProto } from "common/Dates";
import A11yDialogTitle from "components/A11yDialogTitle";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";

interface ArchiveStudySiteDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studySite: StudySite;
  onClose(): void;
  onSuccess(): void;
}

// The confirmation dialog for deleting a study site.
const ArchiveStudySiteDialog: React.FC<ArchiveStudySiteDialogProps> = ({
  open,
  studySite,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [errorMessage, setErrorMessage] = useState("");
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  const handleClickConfirmButton = () => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (!updateReasonFormRef.current?.validateInput(true)) {
            return;
          }

          setDisableConfirmButton(true);
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudySiteServiceApiClient(
            auth0Config.audience!,
            token
          );

          studySite.setStatus(1 /* Archived */);
          studySite.setArchivedDate(toDateProto(new Date()));
          const request = new UpdateStudySiteRequest()
            .setStudySite(studySite)
            .setUpdateReason(updateReasonFormRef.current.getUpdateReason());

          await client.updateStudySite(request);
          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);

          let msg = "Failed to archive the study site. Please try again.";
          if (error instanceof RpcError) {
            if (error.code === StatusCode.FAILED_PRECONDITION) {
              msg = "Failed to archive the study site. It's already in use.";
            }
          }

          setErrorMessage(msg);
        }

        setDisableConfirmButton(false);
      })();
    } else {
      setErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
    }
  };

  // Set up initial state when the dialog is first opened.
  useEffect(() => {
    if (open) {
      setErrorMessage("");
      setDisableConfirmButton(false);
    }
  }, [open]);

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "444px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display6" sx={{ fontWeight: "bold" }}>
          Archive site
        </Typography>
      </A11yDialogTitle>
      {errorMessage.length > 0 && (
        <Alert severity="error" sx={{ paddingLeft: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent sx={{ padding: "24px" }}>
        <Typography variant="body1em">This will:</Typography>
        <List
          sx={{
            listStyleType: "disc",
            paddingLeft: "32px",
            marginBottom: "16px",
          }}
        >
          <Typography component="li" role="listitem" variant="body1">
            Exclude this site from future compliance calculations, starting
            today.
          </Typography>
          <Typography component="li" role="listitem" variant="body1">
            Remove access for users associated with this site.
          </Typography>
        </List>
        <Typography variant="body1em">This will not:</Typography>
        <List
          sx={{
            listStyleType: "disc",
            paddingLeft: "32px",
            marginBottom: "16px",
          }}
        >
          <Typography component="li" role="listitem" variant="body1">
            Delete existing watch or compliance data associated with this site.
          </Typography>
        </List>
        <Typography
          variant="body1em"
          sx={{
            color: theme.palette.warning.main,
          }}
        >
          You can't undo this action.
        </Typography>
        <Box sx={{ marginTop: "16px" }}>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={["Site closure"]}
            reasonSelectionLabel="Reason for deletion (required)"
            reasonRequiredMessage="Reason for deletion required"
            otherReasonHint="Please provide reason"
            otherReasonRequiredMessage="Other reason for deletion required"
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          disabled={disableConfirmButton}
          onClick={handleClickConfirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveStudySiteDialog;
