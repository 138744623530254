import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Dialog as MUIDialog,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import {
  Button,
  Snackbar,
  ClearIcon,
  EditIcon,
  PersonIcon,
  WatchIcon,
} from "@verily-src/react-design-system";
import EditParticipantIdDialog from "./EditParticipantIdDialog";
import EditEnrollmentDateDialog from "./EditEnrollmentDateDialog";
import EditExitStudyDateDialog from "./EditExitStudyDateDialog";
import UpdateParticipantStatusDialog, {
  GetParticipantStatusById,
} from "./UpdateParticipantStatusDialog";
import ManageWatchDialog from "./ManageWatchDialog";
import AssignWatchDialog from "./AssignWatchDialog";
import RemoveStudyParticipantDialog from "./RemoveStudyParticipantDialog";
import InfoSection from "components/InfoSection";
import useStudyParticipantDataLoader from "./StudyParticipantDataLoader";
import { autohideTimeout } from "styles/Time";
import { AppRoutes } from "core/AppRoutes";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router";
import { setParticipantsViewState } from "./StudyParticipantsStateSlice";
import { setParticipantComplianceState } from "participantcompliance/ParticipantComplianceStateSlice";
import { clearCalendarViewCache } from "looker/CalendarViewQueryResultsCache";
import { ParseIdFromName, RemovePicardPrefix } from "common/ResourceName";
import { format } from "date-fns";
import { fromDateProto } from "common/Dates";
import { focusOnRef, sxFocusWithin, sxFullScreen } from "common/Accessibility";
import Loading from "components/Loading";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import SDPErrorSnackbar from "components/SDPErrorSnackbar";
import HeaderValuePair from "components/HeaderValuePair";

// Enum used for a11y support; to track the button that triggered the reload.
const enum ButtonSource {
  None,
  Exit,
  EditParticipantId,
  UpdateParticipantStatus,
  EditEnrollmentDate,
  EditCompletionDate,
  EditWithdrawalDate,
  ManageWatch,
}

interface StudyParticipantDetailDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipantName: string;
  onClose(): void;
  onSuccess(msg: string): void;
}

// The dialog box for viewing Participant details
const StudyParticipantDetailDialog: React.FC<
  StudyParticipantDetailDialogProps
> = ({ open, studyParticipantName, onClose, onSuccess }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const participantsViewState = useAppSelector(
    (state) => state.participantsViewState
  );

  const [hasAssignedDevice, setHasAssignedDevice] = useState(false);

  const [isEditParticipantIdDialogOpen, setIsEditParticipantIdDialogOpen] =
    useState(false);
  const [isEditEnrollmentDateDialogOpen, setIsEditEnrollmentDateDialogOpen] =
    useState(false);
  const [isUpdateStatusDialogOpen, setIsUpdateStatusDialogOpen] =
    useState(false);
  const [isUpdateExitStudyDateDialogOpen, setIsUpdateExitStudyDateDialogOpen] =
    useState(false);
  const [isAssignWatchDialogOpen, setIsAssignWatchDialogOpen] = useState(false);
  const [isManageWatchDialogOpen, setIsManageWatchDialogOpen] = useState(false);
  const [isRemoveParticipantDialogOpen, setIsRemoveParticipantDialogOpen] =
    useState(false);

  const [reloadData, setReloadData] = useState(true);
  const { isLoading, hasError, studyParticipant, participantSession } =
    useStudyParticipantDataLoader(studyParticipantName, reloadData);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  // Accessibility focus state for buttons
  const [fromButton, setFromButton] = useState<ButtonSource>(ButtonSource.None);

  const enrollmentDateRef = useRef<HTMLButtonElement>(null);
  const completionDateRef = useRef<HTMLButtonElement>(null);
  const withdrawalDateRef = useRef<HTMLButtonElement>(null);
  const exitButtonRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const [focusEditParticipantIdBtn, setFocusEditParticipantIdBtn] =
    useState(false);
  const [focusUpdateParticipantStatusBtn, setFocusUpdateParticipantStatusBtn] =
    useState(false);
  const [focusManageWatchButtonBtn, setFocusManageWatchButtonBtn] =
    useState(false);

  // Effects
  useEffect(() => {
    // Reset reload flag after loading is completed so that the flag
    // can be set to true to trigger a reload next time.
    if (!isLoading) {
      setReloadData(false);
    }
  }, [isLoading]);

  // Reload participants if currently selected study participant changes.
  useEffect(() => {
    setReloadData(true);
    setFromButton(ButtonSource.None);
  }, [studyParticipantName]);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      if (studyParticipant.getDeviceId() !== "") {
        setHasAssignedDevice(true);
      } else {
        setHasAssignedDevice(false);
      }
      setFocusEditParticipantIdBtn(false);
      setFocusUpdateParticipantStatusBtn(false);
      setFocusManageWatchButtonBtn(false);
      setIsSuccessSnackbarOpen(false);
      setShowErrorMessage(false);
    }
  }, [fromButton, open, studyParticipant]);

  // Set focus on the button that triggered the reload.
  useEffect(() => {
    if (!isLoading) {
      // Add delay to allow button to focus after loading is completed.
      const timeoutId = setTimeout(() => {
        switch (fromButton) {
          case ButtonSource.EditParticipantId:
            setFocusEditParticipantIdBtn(true);
            break;
          case ButtonSource.UpdateParticipantStatus:
            setFocusUpdateParticipantStatusBtn(true);
            break;
          case ButtonSource.ManageWatch:
            setFocusManageWatchButtonBtn(true);
            break;
          case ButtonSource.EditEnrollmentDate:
            focusOnRef(enrollmentDateRef);
            break;
          case ButtonSource.EditCompletionDate:
            focusOnRef(completionDateRef);
            break;
          case ButtonSource.EditWithdrawalDate:
            focusOnRef(withdrawalDateRef);
            break;
          case ButtonSource.Exit:
            focusOnRef(exitButtonRef);
            break;
          case ButtonSource.None:
            focusOnRef(titleRef);
        }

        if (showSuccessSnackbar) {
          setIsSuccessSnackbarOpen(true);
        }

        return () => clearTimeout(timeoutId);
      }, 500);
    }
  }, [isLoading, fromButton, showSuccessSnackbar]);

  const handleComplianceHistoryButton = () => {
    dispatch(
      setParticipantsViewState({
        ...participantsViewState,
        isParticipantDialogOpen: true,
        studyParticipantName: studyParticipantName,
      })
    );

    dispatch(
      setParticipantComplianceState({
        participantId: ParseIdFromName(studyParticipantName),
        subjectId: studyParticipant.getSponsorParticipantId(),
        deviceId: studyParticipant.getDeviceId(),
        returnRoute: AppRoutes.STUDY_PARTICIPANTS,
      })
    );
    clearCalendarViewCache();
    navigate(AppRoutes.PARTICIPANT_COMPLIANCE);
  };

  const Header = () => (
    <Box
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        padding: "24px 40px",
        background: theme.palette.background.canvas,
      }}
    >
      <Box
        sx={{
          display: "inherit",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginRight: "12px", ...sxFocusWithin }}>
          <IconButton onClick={onClose} aria-label="Exit" ref={exitButtonRef}>
            <ClearIcon />
          </IconButton>
        </Box>
        {!hasError && (
          <Typography
            component="h1"
            variant="display5"
            ref={titleRef}
            tabIndex={-1}
          >
            {`Participant ${studyParticipant.getSponsorParticipantId()}`}
          </Typography>
        )}
      </Box>
      <Button
        variant="filled"
        onClick={handleComplianceHistoryButton}
        role="link"
        aria-label="View compliance history"
      >
        View compliance history
      </Button>
    </Box>
  );

  function getWatchStatus(): string {
    if (!hasAssignedDevice) {
      return "No watch assigned";
    }

    // If the participant session EndTime has not been reached
    // and the participant is in the Enrolled state,
    // the device is considered active
    const endTime = participantSession.getInterval()?.getEndTime();
    if (
      (!endTime || endTime.toDate() > new Date()) &&
      studyParticipant.getStatus() ===
        StudyParticipant.ParticipantStatus.ENROLLED
    ) {
      return "Active";
    }

    return "Inactive";
  }

  function getWatchStartDate(): string {
    if (!hasAssignedDevice) {
      return "--";
    } else if (participantSession.getInterval()?.getStartTime()) {
      return format(
        participantSession.getInterval()?.getStartTime()?.toDate()!,
        "M/d/yyyy"
      );
    }

    return "--";
  }

  return (
    <>
      <SDPErrorSnackbar
        showSnackbar={showErrorMessage}
        setShowSnackbar={setShowErrorMessage}
        errorMessage={errorSnackbarMessage}
      />
      <MUIDialog open={open} fullScreen={true}>
        <Snackbar
          open={isSuccessSnackbarOpen}
          color="success"
          variant="tonal"
          withIcon={true}
          text={successSnackbarMessage}
          autoHideDuration={autohideTimeout}
          onClose={() => {
            setIsSuccessSnackbarOpen(false);
            setShowSuccessSnackbar(false);
          }}
          role="alert"
        ></Snackbar>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Header />
            <Box
              role="main"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                padding: "40px 0px",
                ...sxFullScreen,
              }}
            >
              {hasError && (
                <Alert severity="error" sx={{ paddingLeft: "16px" }}>
                  Failed to load study participant
                </Alert>
              )}
              {!hasError && (
                <>
                  {!hasAssignedDevice && (
                    <Box
                      sx={{
                        alignSelf: "center",
                        marginBottom: "24px",
                        width: "680px",
                      }}
                    >
                      <Alert severity="warning">
                        This participant will be excluded from further
                        compliance calculations until a new watch is assigned.
                      </Alert>
                    </Box>
                  )}
                  <InfoSection
                    title="Participant ID"
                    icon={<PersonIcon />}
                    buttonText="Edit"
                    value={studyParticipant.getSponsorParticipantId()}
                    onClick={() => setIsEditParticipantIdDialogOpen(true)}
                    btnFocus={focusEditParticipantIdBtn}
                    btnAriaLabel={`Edit Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                    children={undefined}
                  ></InfoSection>
                  <InfoSection
                    title="Participant status"
                    icon={<NotesIcon />}
                    buttonText="Change status"
                    onClick={() => setIsUpdateStatusDialogOpen(true)}
                    btnFocus={focusUpdateParticipantStatusBtn}
                    btnAriaLabel={`Change status for Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                  >
                    <Box marginBottom="24px">
                      <HeaderValuePair
                        component="h3"
                        title="Status"
                        value={
                          GetParticipantStatusById(studyParticipant.getStatus())
                            ?.label || ""
                        }
                      />
                    </Box>
                    <Box marginBottom="24px">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <HeaderValuePair
                          component="h3"
                          title="Enrollment date"
                          value={
                            studyParticipant.getEnrollmentStartTime()
                              ? format(
                                  studyParticipant
                                    .getEnrollmentStartTime()!
                                    .toDate(),
                                  "M/d/yyyy"
                                )
                              : "--"
                          }
                        />
                        <Box
                          sx={{
                            marginLeft: "8px",
                            marginTop: "-24px",
                            ...sxFocusWithin,
                          }}
                        >
                          <IconButton
                            ref={enrollmentDateRef}
                            size="small"
                            onClick={() =>
                              setIsEditEnrollmentDateDialogOpen(true)
                            }
                            aria-label={`Edit enrollment date for Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    <Box marginBottom="24px">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <HeaderValuePair
                          component="h3"
                          title="Completion date"
                          value={
                            studyParticipant.getStatus() === 2 &&
                            studyParticipant.getExitStudyDate()
                              ? format(
                                  fromDateProto(
                                    studyParticipant.getExitStudyDate()!
                                  ),
                                  "M/d/yyyy"
                                )
                              : "--"
                          }
                        />
                        {studyParticipant.getStatus() === 2 &&
                          studyParticipant.getExitStudyDate() !== undefined && (
                            <Box
                              sx={{
                                marginLeft: "8px",
                                marginTop: "-24px",
                                ...sxFocusWithin,
                              }}
                            >
                              <IconButton
                                ref={completionDateRef}
                                size="small"
                                onClick={() => {
                                  setIsUpdateExitStudyDateDialogOpen(true);
                                }}
                                aria-label={`Edit completion date for Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <HeaderValuePair
                          component="h3"
                          title="Withdrawal date"
                          value={
                            studyParticipant.getStatus() === 3 &&
                            studyParticipant.getExitStudyDate()
                              ? format(
                                  fromDateProto(
                                    studyParticipant.getExitStudyDate()!
                                  ),
                                  "M/d/yyyy"
                                )
                              : "--"
                          }
                        />
                        {studyParticipant.getStatus() === 3 &&
                          studyParticipant.getExitStudyDate() !== undefined && (
                            <Box
                              sx={{
                                marginLeft: "8px",
                                marginTop: "-24px",
                                ...sxFocusWithin,
                              }}
                            >
                              <IconButton
                                ref={withdrawalDateRef}
                                size="small"
                                onClick={() => {
                                  setIsUpdateExitStudyDateDialogOpen(true);
                                }}
                                aria-label={`Edit withdrawal date for Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </InfoSection>
                  <InfoSection
                    title="Watch details"
                    icon={<WatchIcon />}
                    buttonText={
                      hasAssignedDevice ? "Manage watch" : "Assign watch"
                    }
                    isButtonDisabled={studyParticipant.getStatus() !== 1}
                    onClick={() =>
                      hasAssignedDevice
                        ? setIsManageWatchDialogOpen(true)
                        : setIsAssignWatchDialogOpen(true)
                    }
                    btnFocus={focusManageWatchButtonBtn}
                    btnAriaLabel={`${
                      hasAssignedDevice ? "Manage" : "Assign"
                    } watch for Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                  >
                    <Box marginBottom="24px">
                      <HeaderValuePair
                        title="Device ID"
                        value={
                          hasAssignedDevice
                            ? RemovePicardPrefix(studyParticipant.getDeviceId())
                            : "--"
                        }
                        fontFamily="Roboto Mono"
                        component={"h3"}
                      />
                    </Box>
                    <Box marginBottom="24px">
                      <HeaderValuePair
                        component="h3"
                        title="Watch status"
                        value={getWatchStatus()}
                      />
                    </Box>
                    <Box>
                      <HeaderValuePair
                        component="h3"
                        title="Watch start date"
                        value={getWatchStartDate()}
                      />
                    </Box>
                  </InfoSection>
                  <Box
                    sx={{
                      alignSelf: "center",
                      width: "680px",
                    }}
                  >
                    <Button
                      color="destructive"
                      variant="filled"
                      onClick={() => setIsRemoveParticipantDialogOpen(true)}
                      aria-label={`Remove Participant ID ${studyParticipant.getSponsorParticipantId()}`}
                    >
                      Remove participant
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </MUIDialog>
      <EditParticipantIdDialog
        open={isEditParticipantIdDialogOpen}
        studyParticipant={studyParticipant}
        onClose={() => {
          setFromButton(ButtonSource.EditParticipantId);
          setIsEditParticipantIdDialogOpen(false);
        }}
        onSuccess={() => {
          setFromButton(ButtonSource.EditParticipantId);
          setSuccessSnackbarMessage("Participant ID updated");
          setShowSuccessSnackbar(true);
          setIsEditParticipantIdDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></EditParticipantIdDialog>
      <UpdateParticipantStatusDialog
        open={isUpdateStatusDialogOpen}
        studyParticipant={studyParticipant}
        participantSession={participantSession}
        onClose={() => {
          setIsUpdateStatusDialogOpen(false);
        }}
        onSuccess={() => {
          setFromButton(ButtonSource.UpdateParticipantStatus);
          setSuccessSnackbarMessage("Participant status updated");
          setShowSuccessSnackbar(true);
          setIsUpdateStatusDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></UpdateParticipantStatusDialog>
      <EditEnrollmentDateDialog
        open={isEditEnrollmentDateDialogOpen}
        studyParticipant={studyParticipant}
        participantSession={participantSession}
        onClose={() => {
          setIsEditEnrollmentDateDialogOpen(false);
        }}
        onSuccess={() => {
          setFromButton(ButtonSource.EditEnrollmentDate);
          setSuccessSnackbarMessage("Enrollment date updated");
          setShowSuccessSnackbar(true);
          setIsEditEnrollmentDateDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></EditEnrollmentDateDialog>
      <EditExitStudyDateDialog
        open={isUpdateExitStudyDateDialogOpen}
        studyParticipant={studyParticipant}
        onClose={() => {
          setIsUpdateExitStudyDateDialogOpen(false);
        }}
        onSuccess={() => {
          switch (studyParticipant.getStatus()) {
            case StudyParticipant.ParticipantStatus.EXIT_STUDY_COMPLETED:
              setFromButton(ButtonSource.EditCompletionDate);
              setSuccessSnackbarMessage("Completion date updated");
              break;
            case StudyParticipant.ParticipantStatus.EXIT_STUDY_WITHDRAWN:
              setFromButton(ButtonSource.EditWithdrawalDate);
              setSuccessSnackbarMessage("Withdrawal date updated");
              break;
          }
          setShowSuccessSnackbar(true);
          setFromButton(ButtonSource.EditCompletionDate);
          setIsUpdateExitStudyDateDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></EditExitStudyDateDialog>
      <ManageWatchDialog
        open={isManageWatchDialogOpen}
        studyParticipant={studyParticipant}
        participantSession={participantSession}
        onClose={() => {
          setIsManageWatchDialogOpen(false);
        }}
        onSuccess={(msg: string) => {
          setFromButton(ButtonSource.ManageWatch);
          setSuccessSnackbarMessage(msg);
          setShowSuccessSnackbar(true);
          setIsManageWatchDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></ManageWatchDialog>
      <AssignWatchDialog
        open={isAssignWatchDialogOpen}
        studyParticipant={studyParticipant}
        onClose={() => {
          setIsAssignWatchDialogOpen(false);
        }}
        onSuccess={() => {
          setFromButton(ButtonSource.ManageWatch);
          setSuccessSnackbarMessage("Watch assigned");
          setShowSuccessSnackbar(true);
          setIsAssignWatchDialogOpen(false);
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></AssignWatchDialog>
      <RemoveStudyParticipantDialog
        open={isRemoveParticipantDialogOpen}
        studyParticipant={studyParticipant}
        onClose={() => {
          setIsRemoveParticipantDialogOpen(false);
        }}
        onSuccess={() => {
          setIsRemoveParticipantDialogOpen(false);
          onSuccess("Participant removed");
          setReloadData(true);
        }}
        onError={(msg: string) => {
          setShowErrorMessage(true);
          setErrorSnackbarMessage(msg);
        }}
      ></RemoveStudyParticipantDialog>
    </>
  );
};

export default StudyParticipantDetailDialog;
