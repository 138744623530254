import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Environment =
  | "unset"
  | "local"
  | "dev-unstable"
  | "dev"
  | "staging"
  | "prod";

export interface AppConfig {
  environment: Environment;
  lookerInstanceUrl: string;
  lookerProxyUrl: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Url: string;
}

const initialState: AppConfig = {
  environment: "unset",
  lookerInstanceUrl: "",
  lookerProxyUrl: "",
  auth0Audience: "",
  auth0ClientId: "",
  auth0Url: "",
};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setAppConfig: (state, action: PayloadAction<AppConfig>) => {
      state.environment = action.payload.environment;
      state.lookerInstanceUrl = action.payload.lookerInstanceUrl;
      state.lookerProxyUrl = action.payload.lookerProxyUrl;
      state.auth0Audience = action.payload.auth0Audience;
      state.auth0ClientId = action.payload.auth0ClientId;
      state.auth0Url = action.payload.auth0Url;
    },
  },
});

export const { setAppConfig } = appConfigSlice.actions;
export default appConfigSlice.reducer;
