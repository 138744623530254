import { Box } from "@mui/material";
import { Button } from "@verily-src/react-design-system";

interface FooterProps {
  leftButtonText: string;
  isLeftButtonDisabled: boolean;
  handleClickLeftButton: () => void;
  rightButtonText: string;
  isRightButtonDisabled: boolean;
  isRightButtonLoading?: boolean;
  handleClickRightButton: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  leftButtonText,
  isLeftButtonDisabled,
  handleClickLeftButton,
  rightButtonText,
  isRightButtonDisabled,
  isRightButtonLoading,
  handleClickRightButton,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        alignContent: "bottom",
        justifyContent: "space-between",
        paddingTop: "24px",
        bottom: "48px",
        borderTop: "1px solid #9DA7B3",
        width: "inherit",
      }}
    >
      <Button
        label={leftButtonText}
        color="primary"
        variant="outlined"
        disabled={isLeftButtonDisabled}
        onClick={handleClickLeftButton}
        aria-label={leftButtonText}
      />
      <Button
        label={rightButtonText}
        variant="filled"
        disabled={isRightButtonDisabled}
        loading={isRightButtonLoading || false}
        onClick={handleClickRightButton}
        aria-label={rightButtonText}
      />
    </Box>
  );
};
