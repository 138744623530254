import { configureStore } from "@reduxjs/toolkit";
import appConfigReducer from "core/AppConfigSlice";
import appStateReducer from "core/AppStateSlice";
import auth0onfigReducer from "core/Auth0ConfigSlice";
import userConfigReducer from "core/UserConfigSlice";
import lookerConfigReducer from "looker/LookerConfigSlice";
import weekNavigationStateReducer from "core/WeekNavigationStateSlice";
import crcComplianceStateReducer from "home/CrcComplianceStateSlice";
import studyComplianceStateReducer from "home/StudyComplianceStateSlice";
import nonCompliantTabStateReducer from "home/NonCompliantTabStateSlice";
import nonCompliantDetailStateReducer from "home/NonCompliantDetailStateSlice";
import participantsViewStateReducer from "studyparticipant/StudyParticipantsStateSlice";
import participantStateReducer from "participantcompliance/ParticipantComplianceStateSlice";
import complianceSettingStateReducer from "compliancesetting/ComplianceSettingSlice";
import TestConfigReducer from "test_tools/TestConfigSlice";

export const store = configureStore({
  reducer: {
    appConfig: appConfigReducer,
    appState: appStateReducer,
    auth0Config: auth0onfigReducer,
    userConfig: userConfigReducer,
    lookerConfig: lookerConfigReducer,
    weekNavigationState: weekNavigationStateReducer,
    crcComplianceState: crcComplianceStateReducer,
    studyComplianceState: studyComplianceStateReducer,
    nonCompliantTabState: nonCompliantTabStateReducer,
    nonCompliantDetailState: nonCompliantDetailStateReducer,
    participantComplianceState: participantStateReducer,
    participantsViewState: participantsViewStateReducer,
    complianceSettingState: complianceSettingStateReducer,
    testConfig: TestConfigReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
