import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "generated/studyauth/studyauth_pb";
import { StudyInfo } from "../common/StudyInfo";

export interface StudyRoleInfo {
  role: Role;
  registryId: string;
  studySiteId: string;
}

export interface UserConfig {
  /** A list of all studies to which the user has access. */
  studies: StudyInfo[];

  /** User name in the format of users/{user_id} */
  userName: string;

  /** User first name */
  userFirstName: string;

  /** User's roles */
  userRoles: StudyRoleInfo[];

  /** Whether the user is a super admin */
  isSuperAdmin: boolean;

  /** The registry id of the selected study. */
  selectedRegistryId: string;
}

const initialState: UserConfig = {
  studies: [],
  userName: "",
  userFirstName: "",
  userRoles: [],
  isSuperAdmin: false,
  selectedRegistryId: "",
};

const userConfigSlice = createSlice({
  name: "userConfig",
  initialState,
  reducers: {
    setUserConfig: (state, action: PayloadAction<UserConfig>) => {
      state.studies = action.payload.studies;
      state.userName = action.payload.userName;
      state.userFirstName = action.payload.userFirstName;
      state.userRoles = action.payload.userRoles;
      state.isSuperAdmin = action.payload.isSuperAdmin;
      state.selectedRegistryId = action.payload.selectedRegistryId;
    },
    setIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
      state.isSuperAdmin = action.payload;
    },
    setSelectedRegistryId: (state, action: PayloadAction<string>) => {
      state.selectedRegistryId = action.payload;
    },
  },
});

export const { setUserConfig, setIsSuperAdmin, setSelectedRegistryId } =
  userConfigSlice.actions;

export default userConfigSlice.reducer;

/** Selector to find the user roles for the currently selected study. */
export const selectCurrentStudyRoles = createSelector(
  (userConfig: UserConfig) => userConfig.selectedRegistryId,
  (userConfig: UserConfig) => userConfig.userRoles,
  (registryId, userRoles) => {
    if (registryId === "") {
      return new Array<StudyRoleInfo>();
    }

    const filteredUserRoles = userRoles.filter((userRole) => {
      return userRole.registryId === registryId;
    });

    return filteredUserRoles;
  }
);

export const hasAnyRole = (
  currentStudyRoles: StudyRoleInfo[],
  ...requiredRoles: Role[]
) => {
  for (let userRole of currentStudyRoles) {
    if (requiredRoles.includes(userRole.role)) {
      return true;
    }
  }
  return false;
};

export const hasRoleInStudy = (
  currentStudyRoles: StudyRoleInfo[],
  registryId: string,
  role: Role
) => {
  for (let userRole of currentStudyRoles) {
    if (userRole.registryId === registryId && userRole.role === role) {
      return true;
    }
  }
  return false;
};
