import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { dialog_border_radius } from "styles/Dimensions";
import { Button } from "@verily-src/react-design-system";
import { InputTextField, InputTextFieldRef } from "components/InputText";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { StudySiteServiceApiClient } from "apiclient/StudySiteServiceApiClient";
import {
  CreateStudySiteRequest,
  StudySite,
} from "generated/studysite/studysite_pb";
import { countries, toCountryCode } from "common/CountryCode";
import { SingleSelectDropdown } from "@verily-src/react-design-system";
import A11yDialogTitle from "components/A11yDialogTitle";

interface CreateStudySiteDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  registryId: string;
  studySites: StudySite[];
  onClose(): void;
  onSuccess(): void;
}

export const duplicateSiteNameError =
  "This site name already exists in this study. Please use a different name.";
export const duplicateSiteNumberError =
  "This site number already exists in this study. Please use a different number.";

// Define an enum to specify which field should focus on when there are errors.
enum FormField {
  SiteName = 0,
  SiteNumber = 1,
}

// The dialog box to create a study site.
const CreateStudySiteDialog: React.FC<CreateStudySiteDialogProps> = ({
  open,
  registryId,
  studySites,
  onClose,
  onSuccess,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [errorMessage, setErrorMessage] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [siteName, setSiteName] = useState("");
  const [siteNumber, setSiteNumber] = useState("");
  const [principalInvestigator, setPrincipalInvestigator] = useState("");
  const [country, setCountry] = useState("");

  const [siteNameError, setSiteNameError] = useState("");
  const [siteNumberError, setSiteNumberError] = useState("");

  const dropdownOptions = useMemo<Array<{ label: string; value: string }>>(
    () =>
      countries.map((country) => ({
        label: country,
        value: country,
      })),
    []
  );

  // Hold the reference to form fields which could show a validation error
  // and need to be focused on.
  const formFields = useRef(new Array<InputTextFieldRef | null>());

  const validateInputs = useCallback(() => {
    let siteNameTrimmed = siteName.trim();
    let siteNumberTrimmed = siteNumber.trim();

    const errorFields = new Array<FormField>();
    if (siteNameTrimmed === "") {
      setSiteNameError("Site name required");
      errorFields.push(FormField.SiteName);
    }

    for (const studySite of studySites) {
      if (siteNameTrimmed === studySite.getSiteName()) {
        setSiteNameError(duplicateSiteNameError);
        errorFields.push(FormField.SiteName);
      }

      if (
        siteNumberTrimmed !== "" &&
        siteNumberTrimmed === studySite.getSiteNumber()
      ) {
        setSiteNumberError(duplicateSiteNumberError);
        errorFields.push(FormField.SiteNumber);
      }
    }

    // Focus on first error field if any
    if (errorFields.length > 0) {
      formFields.current[errorFields[0]]?.focus();
    }

    return errorFields.length === 0;
  }, [siteName, siteNumber, studySites]);

  const handleClickSubmitButton = useCallback(() => {
    if (isAuthenticated && auth0Config) {
      (async () => {
        try {
          if (!validateInputs()) {
            return;
          }

          setDisableSubmitButton(true);
          const token = await getAccessTokenSilently({
            audience: auth0Config.audience,
          });

          const client = new StudySiteServiceApiClient(
            auth0Config.audience!,
            token
          );

          const studySite = new StudySite()
            .setRegistryId(registryId)
            .setSiteName(siteName.trim())
            .setSiteNumber(siteNumber.trim())
            .setPrincipalInvestigator(principalInvestigator.trim())
            .setCountryCode(toCountryCode(country));

          const request = new CreateStudySiteRequest()
            .setStudySite(studySite)
            .setUpdateReason("Data Entry");
          await client.createStudySite(request);

          onSuccess();
        } catch (error) {
          console.log("error reason %s", error);
          setErrorMessage("Failed to add the site. Please try again.");
        }

        setDisableSubmitButton(false);
      })();
    } else {
      setErrorMessage(
        "User is not authenticated. Please logout and login again."
      );
    }
  }, [
    auth0Config,
    country,
    getAccessTokenSilently,
    isAuthenticated,
    onSuccess,
    principalInvestigator,
    registryId,
    siteName,
    siteNumber,
    validateInputs,
  ]);

  // Set initial state when the dialog is opened.
  useEffect(() => {
    if (open) {
      setSiteName("");
      setSiteNumber("");
      setPrincipalInvestigator("");
      setSiteNameError("");
      setSiteNumberError("");
      setErrorMessage("");
    }
  }, [open]);

  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: { width: "648px !important" },
      }}
    >
      <A11yDialogTitle sx={{ paddingTop: "24px" }}>
        <Typography variant="display5">Add site</Typography>
      </A11yDialogTitle>
      <DialogContent>
        {errorMessage.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: "12px" }}>
            <AlertTitle>
              <Typography
                variant="body1em"
                color={theme.palette.error.textOnBackground}
              >
                Add site failed
              </Typography>
            </AlertTitle>
            <Typography
              variant="body2"
              color={theme.palette.error.textOnBackground}
            >
              {errorMessage}
            </Typography>
          </Alert>
        )}
        <InputTextField
          id="siteName"
          label="Site name (required)"
          ref={(el) => (formFields.current[FormField.SiteName] = el)}
          value={siteName}
          placeholder="Site name"
          helperText={siteNameError}
          showError={siteNameError.length > 0}
          onChange={(newValue) => {
            setSiteNameError("");
            setSiteName(newValue);
          }}
        />
        <InputTextField
          id="siteNumber"
          label="Site number (optional)"
          ref={(el) => (formFields.current[FormField.SiteNumber] = el)}
          placeholder="Site number"
          value={siteNumber}
          helperText={siteNumberError}
          showError={siteNumberError.length > 0}
          onChange={(newValue) => {
            setSiteNumberError("");
            setSiteNumber(newValue);
          }}
          sx={{ marginTop: "30px" }}
        />
        <InputTextField
          sx={{ marginTop: "30px", marginBottom: "30px" }}
          id="primaryInvestigatorName"
          label="Primary Investigator name (optional)"
          placeholder="Name"
          value={principalInvestigator}
          onChange={(newValue) => {
            setPrincipalInvestigator(newValue);
          }}
        />
        <SingleSelectDropdown
          sx={{ width: "600px" }}
          value={country}
          options={dropdownOptions}
          label="Country (optional)"
          labelId="country"
          placeholder="Country"
          helperText=""
          onChange={(event) => {
            setCountry(event.target.value as string);
          }}
        />
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "16px 24px 24px 24px" }}
      >
        <Button
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          disabled={disableSubmitButton}
          loading={disableSubmitButton}
          onClick={handleClickSubmitButton}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStudySiteDialog;
