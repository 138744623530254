import React from "react";
import { TextField } from "@verily-src/react-design-system";

// Define a common number input field for edit compliance setting screen.
interface NumberInputProps {
  id: string;
  value: number;
  label: string;
  placeholderText?: string;
  helperText: string;
  showError: boolean;
  onChange(newValue: number): void;
  onMouseLeave(): void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  id,
  value,
  label,
  placeholderText,
  helperText,
  showError,
  onChange,
  onMouseLeave,
}) => {
  const handleInputChange = (newValue: string) => {
    const numberValue = parseInt(newValue === "" ? "0" : newValue);
    onChange(isNaN(numberValue) ? 0 : numberValue);
  };

  return (
    <TextField
      id={id}
      autoComplete="off"
      inputProps={{
        style: {
          fontSize: "16px",
        },
      }}
      value={value === 0 ? "" : value.toString()}
      label={label}
      helperText={helperText}
      placeholder={placeholderText}
      error={showError}
      onChange={(event) => {
        handleInputChange(event.target.value as string);
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      sx={{
        width: "240px",
        margin: "0px 8px",
      }}
    />
  );
};
