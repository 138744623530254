import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookerSdk } from "looker/LookerSdk";
import { ComplianceType } from "looker/QueryResultsCache";
import { updateReportTimePeriod } from "looker/UpdateReportTimePeriod";
import { getDefaultComplianceSetting } from "apiclient/ComplianceSettingServiceApiClient";
import { getComplianceSetting } from "compliancesetting/GetComplianceSetting";
import { getWeekEndDate, getWeekStartDate } from "core/WeekNavigation";
import { getStudyParticipants } from "looker/GetStudyParticipants";
import { getStudyParticipantsComplianceRecords } from "looker/GetStudyParticipantsComplianceRecords";
import { find } from "lodash";
import { selectLookerQueryIdsMap } from "looker/LookerConfigSlice";

export interface StudySummaryRecord {
  totalEnrolled: number;
  totalActive: number;
  totalCompliant: number;
  totalNoData: number;
  totalNoDevice: number;
}

// Defines a custom effect to load study summary data.
export function useStudySummaryTabDataLoader() {
  const appConfig = useAppSelector((state) => state.appConfig);
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const lookerQueryIdsMap = useAppSelector((state) =>
    selectLookerQueryIdsMap(state.lookerConfig)
  );

  const sdk = useMemo(() => {
    return getLookerSdk(appConfig);
  }, [appConfig]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [summary, setSummary] = useState<StudySummaryRecord>({
    totalEnrolled: 0,
    totalActive: 0,
    totalCompliant: 0,
    totalNoData: 0,
    totalNoDevice: 0,
  });

  const loadStudySummary = useCallback(
    (currentRegistryId: string, previousWeekOffset: number) => {
      if (isAuthenticated && auth0Config) {
        (async () => {
          try {
            setIsLoading(true);
            setHasError(false);

            const weekStart = getWeekStartDate(previousWeekOffset);
            const weekEnd = getWeekEndDate(previousWeekOffset);

            // Set report time period.
            const token = await getAccessTokenSilently({
              audience: auth0Config.audience,
            });

            await updateReportTimePeriod(
              auth0Config.audience!,
              token,
              weekStart,
              weekEnd,
              previousWeekOffset
            );

            let complianceSetting = await getComplianceSetting(
              auth0Config.audience!,
              token,
              currentRegistryId
            );
            if (complianceSetting === null) {
              complianceSetting = getDefaultComplianceSetting();
            }

            // Add optional compliance settings
            const optionalComplianceTypes: ComplianceType[] = [];

            if (complianceSetting.getMedsLoggedPerDay() > 0) {
              optionalComplianceTypes.push(ComplianceType.MedLogDaily);
            } else if (complianceSetting.getMedsLoggedPerWeek() > 0) {
              optionalComplianceTypes.push(ComplianceType.MedLogWeekly);
            }

            // Get all participants and compliance records in parallel.
            const [participants, complianceRecords] = await Promise.all([
              getStudyParticipants(sdk, previousWeekOffset, lookerQueryIdsMap),
              getStudyParticipantsComplianceRecords(
                sdk,
                previousWeekOffset,
                optionalComplianceTypes,
                lookerQueryIdsMap
              ),
            ]);

            if (participants.hasError || complianceRecords.hasError) {
              setHasError(true);
              setIsLoading(false);
              return;
            }

            let totalNoData = 0;
            let totalNoDevice = 0;
            for (let record of participants.records) {
              if (record.deviceId === null) {
                totalNoDevice++;
                continue;
              }

              const compliantRecord = find(
                complianceRecords.compliantRecords,
                record
              );

              const nonCompliantRecord = find(
                complianceRecords.noncompliantRecords,
                record
              );

              if (!compliantRecord && !nonCompliantRecord) {
                totalNoData++;
              }
            }

            let summary = {
              totalEnrolled: participants.records.length,
              totalActive: complianceRecords.activeParticipantsCount,
              totalCompliant: complianceRecords.compliantRecords.length,
              totalNoData: totalNoData,
              totalNoDevice: totalNoDevice,
            };

            setSummary(summary);
          } catch (error) {
            console.log("error reason %s", error);
            setHasError(true);
          }

          setIsLoading(false);
        })();
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
    [
      auth0Config,
      getAccessTokenSilently,
      isAuthenticated,
      lookerQueryIdsMap,
      sdk,
    ]
  );

  return {
    isLoading,
    hasError,
    summary,
    loadStudySummary,
  };
}
