import { Duration, format } from "date-fns";

export function getDateAriaLabel(date: Date) {
  return format(date, "MMMM, yyyy, EEEE, do");
}

export function parseDuration(totalHours: number): Duration {
  const totalMinutes = totalHours * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.round(totalMinutes % 60);
  return { hours, minutes };
}
