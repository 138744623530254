import React, { useState, useEffect } from "react";
import pluralize from "pluralize";
import { ComplianceSetting } from "generated/compliancesetting/compliancesetting_pb";
import { WatchData } from "./CalendarTypes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMoreIcon } from "@verily-src/react-design-system";
import { addDays, format } from "date-fns";
import { parseISO, startOfToday, lastDayOfWeek } from "date-fns";
import { useAppSelector } from "redux/hooks";
import { featureFlagProvider } from "core/FeatureFlagProvider";
import { earliestSyncDate } from "./CalendarTypes";
import { ComplianceScope } from "home/NonCompliantTabDataLoader";
import { a11yOutlineBorder } from "common/Accessibility";

interface SummaryPanelProps {
  watchData: WatchData[];
  complianceSetting: ComplianceSetting;
  latestSync: Date;
  calendarFirstDay: Date;
  calendarLastDay: Date;
  scope?: ComplianceScope;
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({
  watchData,
  complianceSetting,
  latestSync,
  calendarFirstDay,
  calendarLastDay,
  scope,
}) => {
  const theme = useTheme();

  const daysInAWeek = 7;
  const accordions = [];
  const today = startOfToday();
  const numWeeks = watchData.length / daysInAWeek;

  let firstDayOfWeek = calendarFirstDay;
  let i = 0;
  for (; i < numWeeks; i++) {
    firstDayOfWeek = parseISO(watchData[i * daysInAWeek].date);
    const formattedFirstDayOfWeek = format(firstDayOfWeek, "M/d/yyyy");
    const lastDay = lastDayOfWeek(firstDayOfWeek);
    const weeklyWatchData = watchData.slice(
      i * daysInAWeek,
      (i + 1) * daysInAWeek
    );
    accordions.push(
      <WeekAccordion
        key={i}
        summary={`Week of ${formattedFirstDayOfWeek}`}
        isDisabled={false}
        isExpanded={today >= firstDayOfWeek && today <= lastDay}
        weeklyWatchData={weeklyWatchData}
        complianceSetting={complianceSetting}
      />
    );
  }

  // Add remaining empty weeks.
  firstDayOfWeek = addDays(firstDayOfWeek, daysInAWeek);
  while (firstDayOfWeek < calendarLastDay) {
    i++;
    const formattedFirstDayOfWeek = format(firstDayOfWeek, "M/d/yyyy");
    accordions.push(
      <WeekAccordion
        key={i}
        summary={`Week of ${formattedFirstDayOfWeek}`}
        isDisabled={true}
        isExpanded={false}
        weeklyWatchData={new Array<WatchData>()}
        complianceSetting={complianceSetting}
      />
    );

    firstDayOfWeek = addDays(firstDayOfWeek, daysInAWeek);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        background: theme.palette.background.canvas,
        borderRadius: "16px",
        width: "350px",
        minHeight: "500px",
        height:
          scope === ComplianceScope.Study
            ? "calc(100vh - 160px)"
            : "calc(100vh - 136px)",
        overflowY: "auto",
      }}
    >
      <Box sx={{ marginBottom: theme.spacing(3) }}>
        <Typography component="h2" variant="display6">
          Summary
        </Typography>
        <Box
          sx={{
            bgcolor: theme.palette.primary.background,
            borderRadius: "4px",
            display: "block",
            width: "fit-content",
            marginTop: "8px",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.textOnBackground,
              fontSize: 12,
              fontWeight: 600,
              paddingX: "8px",
              paddingY: "3px",
            }}
          >
            {latestSync === earliestSyncDate
              ? "Waiting to sync"
              : "Watch last synced " + format(latestSync, "MM/dd/yyyy")}
          </Typography>
        </Box>
      </Box>
      {accordions}
    </Box>
  );
};

interface WeekAccordionProps {
  summary: string;
  isDisabled: boolean;
  isExpanded: boolean;
  weeklyWatchData: WatchData[];
  complianceSetting: ComplianceSetting;
}

const WeekAccordion: React.FC<WeekAccordionProps> = ({
  summary,
  isDisabled,
  isExpanded,
  weeklyWatchData,
  complianceSetting,
}) => {
  const theme = useTheme();
  const accordionDetails = (
    <WeekAccordionDetails
      weeklyWatchData={weeklyWatchData}
      complianceSetting={complianceSetting}
    />
  );
  const [expanded, setExpanded] = useState(false);
  useEffect(() => setExpanded(isExpanded), [isExpanded, weeklyWatchData]);
  return (
    <Accordion
      disabled={isDisabled}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={{
        background: theme.palette.background.canvas,
        boxShadow: "unset",
        "&::before": {
          display: "none",
        },
        "&.Mui-disabled": {
          background: "unset",
        },
      }}
    >
      <Typography component="h3" variant="body1em">
        <AccordionSummary
          expandIcon={isDisabled ? undefined : <ExpandMoreIcon />}
          sx={{
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            borderTop: "2px solid",
            borderColor: theme.palette.background.separator,
            marginBottom: "2px",
            "&:focus-within": {
              outline: a11yOutlineBorder,
            },
            "&:focus-visible": {
              backgroundColor: theme.palette.background.canvas,
            },
          }}
        >
          {summary}
        </AccordionSummary>
      </Typography>
      {accordionDetails}
    </Accordion>
  );
};

function formatDate(date: string): string {
  const dateFormat = "EEEE, M/d/yy";
  return format(parseISO(date), dateFormat);
}

interface WeekAccordionDetailsProps {
  weeklyWatchData: WatchData[];
  complianceSetting: ComplianceSetting;
}

const WeekAccordionDetails: React.FC<WeekAccordionDetailsProps> = ({
  weeklyWatchData,
  complianceSetting,
}) => {
  const appConfig = useAppSelector((state) => state.appConfig);
  if (weeklyWatchData.length === 0) {
    return (
      <AccordionDetails>
        Waiting for data. Participant has not synced their watch.
      </AccordionDetails>
    );
  }

  const wearHrs = weeklyWatchData
    .filter((watchData) => {
      return watchData.wearHrs !== undefined;
    })
    .map((watchData) => {
      const wearHrs = watchData.wearHrs!!;
      return (
        <li>
          {wearHrs.toFixed(1)} hours on {formatDate(watchData.date)}
        </li>
      );
    });
  const emptyWearHrs = <li>0 hours 0 minutes</li>;

  const syncs = weeklyWatchData
    .filter((watchData) => {
      return watchData.syncs !== undefined;
    })
    .map((watchData) => {
      return <li>Synced {formatDate(watchData.date)}</li>;
    });
  const emptySyncs = <li>0 completed a week</li>;

  let surveysAnswered = 0;
  let surveysPrompted = 0;
  for (let i = 0; i < weeklyWatchData.length; i++) {
    surveysAnswered += weeklyWatchData[i].surveysAnswered || 0;
    surveysPrompted += weeklyWatchData[i].surveysPrompted || 0;
  }
  if (surveysPrompted === 0) {
    surveysPrompted = 1;
  }
  const surveysPercents = (
    <li>
      {(surveysAnswered / surveysPrompted) * 100}% of prompted surveys a week
    </li>
  );

  const showMedicationLogData =
    complianceSetting.getMedsLoggedPerDay() > 0 ||
    complianceSetting.getMedsLoggedPerWeek() > 0;

  const medicationTags = weeklyWatchData
    .filter((watchData) => {
      return watchData.medicationTags !== undefined;
    })
    .map((watchData) => {
      let medicationTagCount = watchData.medicationTags;
      return (
        medicationTagCount && (
          <li>
            {medicationTagCount} logged on {formatDate(watchData.date)}
          </li>
        )
      );
    });
  const emptyMedicationTags = <li>0 time(s) a week</li>;

  return (
    <AccordionDetails>
      <Box>
        <Box>
          <Typography component="h4" variant="body2em">
            Watch wear
          </Typography>
          <Typography variant="body2">
            Required:
            <ul>
              <li>
                At least{" "}
                {pluralize(
                  "hour",
                  complianceSetting.getWearTimeHoursPerDay(),
                  true
                )}{" "}
                a day
              </li>
              <li>
                At least{" "}
                {pluralize(
                  "day",
                  complianceSetting.getWearTimeDaysPerWeek(),
                  true
                )}{" "}
                a week (any) (Sunday through Saturday)
              </li>
            </ul>
            Actual:
            <ul>{wearHrs.length === 0 ? emptyWearHrs : wearHrs}</ul>
          </Typography>
        </Box>
        <Box>
          <Typography component="h4" variant="body2em">
            Watch sync
          </Typography>
          <Typography variant="body2">
            Required:
            <ul>
              <li>
                At least{" "}
                {pluralize("day", complianceSetting.getSyncDaysPerWeek(), true)}{" "}
                per week (Sunday through Saturday)
              </li>
            </ul>
            Actual:
            <ul>{syncs.length === 0 ? emptySyncs : syncs}</ul>
          </Typography>
        </Box>
        {showMedicationLogData && (
          <Box>
            <Typography component="h4" variant="body2em">
              Medication logging
            </Typography>
            <Typography variant="body2">
              Required:
              <ul>
                <li>
                  {(() => {
                    const daily = complianceSetting.getMedsLoggedPerDay();
                    const weekly = complianceSetting.getMedsLoggedPerWeek();

                    let medLogFrequencyText = "";
                    if (daily > 0) {
                      medLogFrequencyText =
                        pluralize("time", daily, true) + " per day";
                    } else if (weekly > 0) {
                      medLogFrequencyText = pluralize("time", weekly, true);
                    }

                    return `At least ${medLogFrequencyText} a week`;
                  })()}
                </li>
              </ul>
              Actual:
              <ul>
                {medicationTags.length === 0
                  ? emptyMedicationTags
                  : medicationTags}
              </ul>
            </Typography>
          </Box>
        )}
        {featureFlagProvider.isSurveyEnabled(appConfig.environment) && (
          <Box>
            <Typography component="h4" variant="body2em">
              Surveys
            </Typography>
            <Typography variant="body2">
              Required:
              <ul>
                <li>
                  {complianceSetting.getSurveySubmissionPercentage()}% of
                  prompted surveys a week
                </li>
              </ul>
              Actual:
              <ul>{surveysPercents}</ul>
            </Typography>
          </Box>
        )}
      </Box>
    </AccordionDetails>
  );
};

export default SummaryPanel;
