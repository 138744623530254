import React, { SyntheticEvent } from "react";
import { Box, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ErrorIcon from "@mui/icons-material/Error";
import BusinessIcon from "@mui/icons-material/Business";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@verily-src/react-design-system";
import { TabPanel, tabA11yProps } from "components/TabPanel";
import { usePageTitle } from "components/PageTitle";
import StudySummaryTab from "./StudySummaryTab";
import NonCompliantTab from "./NonCompliantTab";
import { ComplianceScope } from "./NonCompliantTabDataLoader";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setSelectedStudyComplianceTab,
  StudyComplianceTabIndex,
} from "./StudyComplianceStateSlice";
import StudySiteComplianceTab from "./StudySiteComplianceTab";

const StudyAdminCompliance: React.FC = () => {
  const selectedTabIndex = useAppSelector(
    (state) => state.studyComplianceState.selectedTab
  );
  const dispatch = useAppDispatch();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setSelectedStudyComplianceTab(newValue));
  };

  // Set page title.
  usePageTitle("Monitor compliance");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "24px 40px 24px 40px",
      }}
    >
      <Box>
        <Typography component="h1" variant="display5">
          Monitor Compliance
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingTop: "24px",
          flexGrow: 1,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTabIndex} onChange={handleChange}>
            <Tab
              label="Study summary"
              icon={<DashboardIcon />}
              iconPosition="start"
              value={StudyComplianceTabIndex.StudySummary}
              {...tabA11yProps(StudyComplianceTabIndex.StudySummary)}
            />
            <Tab
              label="Non-compliant participants"
              icon={<ErrorIcon />}
              iconPosition="start"
              value={StudyComplianceTabIndex.NonCompliantParticipants}
              {...tabA11yProps(
                StudyComplianceTabIndex.NonCompliantParticipants
              )}
            />
            <Tab
              label="Site compliance"
              icon={<BusinessIcon />}
              iconPosition="start"
              value={StudyComplianceTabIndex.SiteCompliance}
              {...tabA11yProps(StudyComplianceTabIndex.SiteCompliance)}
            />
          </Tabs>
        </Box>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={StudyComplianceTabIndex.StudySummary}
        >
          <StudySummaryTab />
        </TabPanel>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={StudyComplianceTabIndex.NonCompliantParticipants}
        >
          <NonCompliantTab scope={ComplianceScope.Study} />
        </TabPanel>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={StudyComplianceTabIndex.SiteCompliance}
        >
          <StudySiteComplianceTab />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default StudyAdminCompliance;
