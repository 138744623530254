import { FC, SyntheticEvent } from "react";
import { Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import GroupsIcon from "@mui/icons-material/Groups";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@verily-src/react-design-system";
import { TabPanel, tabA11yProps } from "components/TabPanel";
import CrcComplianceTab from "./CrcCompliantTab";
import CrcAllParticipantsTab from "./CrcAllParticipantsTab";
import {
  CrcComplianceTabIndex,
  setSelectedComplianceTab,
} from "./CrcComplianceStateSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { usePageTitle } from "components/PageTitle";
import { ComplianceScope } from "./NonCompliantTabDataLoader";
import NonCompliantTab from "./NonCompliantTab";

const CrcCompliance: FC = () => {
  const selectedTabIndex = useAppSelector(
    (state) => state.crcComplianceState.selectedTab
  );
  const dispatch = useAppDispatch();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setSelectedComplianceTab(newValue));
  };

  // Set page title.
  usePageTitle("Monitor Compliance");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "24px 40px 24px 40px",
      }}
    >
      <Box>
        <Typography component="h1" variant="display5">
          Monitor Compliance
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingTop: "24px",
          flexGrow: 1,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTabIndex} onChange={handleChange}>
            <Tab
              label="Non-compliant"
              icon={<ErrorIcon />}
              iconPosition="start"
              value={CrcComplianceTabIndex.NonCompliant}
              {...tabA11yProps(CrcComplianceTabIndex.NonCompliant)}
            />
            <Tab
              label="Compliant"
              icon={<CheckIcon />}
              iconPosition="start"
              value={CrcComplianceTabIndex.Compliant}
              {...tabA11yProps(CrcComplianceTabIndex.Compliant)}
            />
            <Tab
              label="All enrolled participants"
              icon={<GroupsIcon />}
              iconPosition="start"
              value={CrcComplianceTabIndex.Participants}
              {...tabA11yProps(CrcComplianceTabIndex.Participants)}
            />
          </Tabs>
        </Box>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={CrcComplianceTabIndex.NonCompliant}
        >
          <NonCompliantTab scope={ComplianceScope.Site} />
        </TabPanel>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={CrcComplianceTabIndex.Compliant}
        >
          <CrcComplianceTab />
        </TabPanel>
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          tabIndex={CrcComplianceTabIndex.Participants}
        >
          <CrcAllParticipantsTab />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default CrcCompliance;
