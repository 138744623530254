import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RpcError, StatusCode } from "grpc-web";
import { ParticipantSessionServiceApiClient } from "apiclient/ParticipantSessionServiceApiClient";
import { SwapParticipantSessionsRequest } from "generated/participantsession/participantsession_pb";
import { StudyParticipant } from "generated/studyparticipant/studyparticipant_pb";
import { ParseIdFromName, RemovePicardPrefix } from "common/ResourceName";
import { selectCurrentStudyRoles } from "core/UserConfigSlice";
import { Role } from "generated/studyauth/studyauth_pb";
import useStudyParticipantsDataLoader from "studyparticipant/StudyParticipantsDataLoader";
import Loading from "./Loading";

export interface WatchSwapperConfirmRef {
  swapWatches: () => void;
  focusOnTitle: () => void;
}

interface ConfirmSwapEntry {
  participantId: string;
  currentDeviceId: string;
  newDeviceId: string;
}

interface WatchSwapperConfirmProps {
  swaps: Map<string, string>;
  onSuccess: () => void;
  onError: (msg: string) => void;
}

// The dialog for updating a participant status
const WatchSwapperConfirm = forwardRef<
  WatchSwapperConfirmRef,
  WatchSwapperConfirmProps
>(({ swaps, onSuccess, onError }, ref) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  // Get RegistryId
  const currentRegistryId = useAppSelector(
    (state) => state.userConfig.selectedRegistryId
  );

  // Get StudySiteId
  const selectedRoles = useAppSelector((state) =>
    selectCurrentStudyRoles(state.userConfig)
  );

  // Get login user's study site.
  // Note that CRCs can only manage one site per study.
  const currentStudySiteId = useMemo(() => {
    for (let userRole of selectedRoles) {
      if (userRole.role === Role.CRC) {
        return userRole.studySiteId;
      }
    }

    return "";
  }, [selectedRoles]);

  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const participantsViewState = useAppSelector((state) => {
    return state.participantsViewState;
  });

  const [participantsMap, setParticipantsMap] = useState<
    Map<string, StudyParticipant>
  >(new Map<string, StudyParticipant>());

  const [confirmSwapEntries, setConfirmSwapEntries] = useState<
    ConfirmSwapEntry[]
  >([]);

  const swapWatches = useCallback(async () => {
    if (!isAuthenticated || !auth0Config) {
      onError("User not authenticated. Please logout and login again.");
      return;
    }

    await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const client = new ParticipantSessionServiceApiClient(
          auth0Config.audience!,
          token
        );

        let req = new SwapParticipantSessionsRequest()
          .setRegistryId(currentRegistryId)
          .setStudySiteId(currentStudySiteId)
          .setUpdateReason(
            "Fix a watch association error. Watch assignments need to be swapped between participants."
          );

        // Populate swap map
        let swapMap = req.getSwapMapMap();
        swaps.forEach((swapParticipantId, participantId) => {
          const swapParticipant = participantsMap.get(swapParticipantId);
          const participant = participantsMap.get(participantId);

          if (!swapParticipant || !participant) {
            onError(
              "Participant to swap not found. Please refresh the page and try again."
            );
            return;
          }

          swapMap.set(
            ParseIdFromName(participant.getName()),
            ParseIdFromName(swapParticipant.getName())
          );
        });

        await client.swapParticipantSessions(req);

        onSuccess();
      } catch (error) {
        let errMsg = "Failed to swap watches. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to manage watch";
        } else if (
          error instanceof RpcError &&
          error.code === StatusCode.NOT_FOUND
        ) {
          errMsg = "Watch already unassigned";
        }

        onError(errMsg);
      }
    })();
  }, [
    auth0Config,
    getAccessTokenSilently,
    isAuthenticated,
    currentRegistryId,
    currentStudySiteId,
    participantsMap,
    swaps,
    onSuccess,
    onError,
  ]);

  const { hasError, isLoading, studyParticipants, loadStudyParticipants } =
    useStudyParticipantsDataLoader();

  useEffect(() => {
    if (currentStudySiteId) {
      loadStudyParticipants(currentStudySiteId);
    }
  }, [currentStudySiteId, loadStudyParticipants]);
  // Update participant map
  useEffect(() => {
    studyParticipants.forEach((participant: StudyParticipant) => {
      setParticipantsMap(
        participantsMap.set(participant.getSponsorParticipantId(), participant)
      );
    });
  }, [participantsViewState, participantsMap, studyParticipants]);

  useEffect(() => {
    const confirmSwapEntries: ConfirmSwapEntry[] = [];
    swaps.forEach((swapParticipantId, participantId) => {
      const participant = participantsMap.get(participantId);
      const swapParticipant = participantsMap.get(swapParticipantId);
      if (participant && swapParticipant) {
        confirmSwapEntries.push({
          participantId: participantId,
          currentDeviceId: participant.getDeviceId(),
          newDeviceId: swapParticipant.getDeviceId(),
        });
      }
    });
    setConfirmSwapEntries(confirmSwapEntries);
  }, [participantsMap, studyParticipants, swaps]);

  const focusOnTitle = useCallback(() => {
    titleRef.current?.focus();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return { swapWatches, focusOnTitle };
    },
    [swapWatches, focusOnTitle]
  );
  if (isLoading) {
    return <Loading />;
  }

  if (hasError) {
    return (
      <Alert severity="error" sx={{ marginTop: "24px", paddingLeft: "16px" }}>
        Failed to confirm watch swap. Please try again.
      </Alert>
    );
  }

  return (
    <>
      <Box ref={inputRef} sx={{ paddingBottom: "24px" }}>
        <Box>
          <Typography
            ref={titleRef}
            tabIndex={-1}
            component="h2"
            variant="display6"
          >
            Confirm watch swap
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            justifySelf: "center",
          }}
        >
          <Paper
            sx={{
              marginTop: "24px",
              padding: "16px 50px",
              background: theme.palette.background.canvas,
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                aria-label="swap-watches-confirmation-table"
              >
                <TableHead>
                  <TableRow sx={{ marginLeft: "60px" }}>
                    <TableCell>Participant ID</TableCell>
                    <TableCell>Current device</TableCell>
                    <TableCell>Updated device</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {confirmSwapEntries.map(
                    (entry: ConfirmSwapEntry, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                            {entry.participantId}
                          </TableCell>
                          <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                            {RemovePicardPrefix(entry.currentDeviceId)}
                          </TableCell>
                          <TableCell sx={{ fontFamily: "Roboto Mono" }}>
                            {RemovePicardPrefix(entry.newDeviceId)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
      <Box>
        <Typography
          component="h2"
          variant="display6"
          sx={{ paddingBottom: "12px" }}
        >
          Reason
        </Typography>
        <Typography variant="body1">
          Fix a watch association error. Watch assignments need to be swapped
          between participants.
        </Typography>
      </Box>
    </>
  );
});

export default WatchSwapperConfirm;
