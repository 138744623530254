import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, DialogTitle, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "@verily-src/react-design-system";
import { StudyParticipantServiceApiClient } from "apiclient/StudyParticipantServiceApiClient";
import {
  isParticipantSessionNullOrEmpty,
  ParticipantSessionServiceApiClient,
} from "apiclient/ParticipantSessionServiceApiClient";
import {
  UpdateStudyParticipantRequest,
  StudyParticipant,
} from "generated/studyparticipant/studyparticipant_pb";
import { format } from "date-fns";
import { fromDateProto, toDateProto, parseDate } from "common/Dates";
import { SDPDatePicker } from "components/SDPDatePicker";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { RpcError, StatusCode } from "grpc-web";
import { isValid } from "date-fns";
import {
  ListParticipantSessionsRequest,
  ParticipantSession,
} from "generated/participantsession/participantsession_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { StepInfo, Steps, EditEnrollmentDateSteps } from "common/Steps";
import UpdateReasonForm, {
  UpdateReasonFormRef,
} from "components/UpdateReasonForm";
import { dialog_border_radius } from "styles/Dimensions";
import HeaderValuePair from "components/HeaderValuePair";
import { ParseIdFromName } from "common/ResourceName";

interface EditEnrollmentDateDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  studyParticipant: StudyParticipant;
  participantSession: ParticipantSession;
  onClose(): void;
  onSuccess(): void;
  onError(msg: string): void;
}

// The dialog for updating a participant status
const EditEnrollmentDateDialog: React.FC<EditEnrollmentDateDialogProps> = ({
  open,
  studyParticipant,
  participantSession,
  onClose,
  onSuccess,
  onError,
}) => {
  const auth0Config = useAppSelector((state) => state.auth0Config);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [enrollmentDate, setEnrollmentDate] = useState<Date>(
    studyParticipant.getEnrollmentStartTime()
      ? studyParticipant.getEnrollmentStartTime()!.toDate()
      : parseDate(new Date())
  );
  const [firstWatchAssociation, setFirstWatchAssociation] = useState<
    ParticipantSession | undefined
  >(undefined);
  const [datePickerValue, setDatePickerValue] = useState<
    Date | null | undefined
  >(
    studyParticipant.getEnrollmentStartTime()
      ? studyParticipant.getEnrollmentStartTime()!.toDate()
      : parseDate(new Date())
  );
  const [datePickerError, setDatePickerError] = useState<boolean>(false);
  const [datePickerErrorText, setDatePickerErrorText] = useState<string>("");

  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [updateReason, setUpdateReason] = useState<string>("");

  const [stepId, setStepId] = useState<number>(
    EditEnrollmentDateSteps.NewDateEntry
  );
  const updateReasonFormRef = useRef<UpdateReasonFormRef>(null);

  useEffect(() => {
    const getFirstAssociation = async () => {
      setFirstWatchAssociation(undefined);
      if (!isAuthenticated || !auth0Config) {
        onError("User not authenticated. Please logout and login again.");
      }

      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const participantSessionApiClient =
          new ParticipantSessionServiceApiClient(auth0Config.audience!, token);

        const studyParticipantId = ParseIdFromName(studyParticipant.getName());
        const response =
          await participantSessionApiClient.listParticipantSessionHistory(
            new ListParticipantSessionsRequest().setParent(
              `studyParticipants/${studyParticipantId}`
            )
          );

        const participantSessionHistory = response.getSessionsList();

        // First association should be last entry
        // List is sorted by start time in descending order
        if (
          participantSessionHistory.length > 0 &&
          participantSessionHistory[0].getDeviceId() ===
            studyParticipant.getDeviceId()
        ) {
          setFirstWatchAssociation(
            participantSessionHistory[participantSessionHistory.length - 1]
          );
        }
      } catch (error) {
        console.log("error reason %s", error);
        let errMsg = "Failed to assign watch. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg =
            "User is not authorized to assign a watch to this participant.";
        }

        onError(errMsg);
      }
    };

    if (open) {
      setStepId(EditEnrollmentDateSteps.NewDateEntry);
      setEnrollmentDate(
        studyParticipant.getEnrollmentStartTime()
          ? studyParticipant.getEnrollmentStartTime()!.toDate()
          : parseDate(new Date())
      );
      setDatePickerValue(
        studyParticipant.getEnrollmentStartTime()
          ? studyParticipant.getEnrollmentStartTime()!.toDate()
          : parseDate(new Date())
      );
      setDatePickerError(false);
      setDatePickerErrorText("");
      setEditLoading(false);

      getFirstAssociation();
    }
  }, [
    open,
    studyParticipant,
    auth0Config,
    isAuthenticated,
    getAccessTokenSilently,
    onError,
  ]);

  // Set up ref to focus on dialog title when the dialog is opened
  const dialogTitleRef = useRef<any>(null);
  const focusOnDialogTitle = useCallback(() => {
    if (dialogTitleRef.current) {
      dialogTitleRef.current.focus();
    }
  }, []);

  const handleDialogTitleRef = useCallback(
    (node) => {
      dialogTitleRef.current = node;
      focusOnDialogTitle();
    },
    [focusOnDialogTitle]
  );

  const [enableAlert, setEnableAlert] = useState(false);

  const validateDatePickerField = useCallback(() => {
    // Reset error fields
    setDatePickerError(false);
    setDatePickerErrorText("");

    // If selected date is invalid, throw an error
    if (!datePickerValue || !isValid(datePickerValue)) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a valid date.");
      return false;
    }

    // If selected date is after the participant's first watch start date, change to error message
    if (
      !isParticipantSessionNullOrEmpty(firstWatchAssociation) &&
      datePickerValue >
        firstWatchAssociation!.getInterval()!.getStartTime()!.toDate()
    ) {
      setStepId(EditEnrollmentDateSteps.EnrollmentDateError);
      focusOnDialogTitle();
      return false;
    }

    // If selected date is in the future, throw an error
    const currentDate = parseDate(new Date());
    if (parseDate(datePickerValue!) > currentDate) {
      setDatePickerError(true);
      setDatePickerErrorText("Please select a date that is today or earlier.");
      return false;
    }

    return true;
  }, [datePickerValue, focusOnDialogTitle, firstWatchAssociation]);

  const editEnrollmentDate = useCallback(async () => {
    if (!isAuthenticated || !auth0Config) {
      onError("User is not authenticated. Please logout and login again.");
      return;
    }

    await (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0Config.audience,
        });

        const studyParticipantApiClient = new StudyParticipantServiceApiClient(
          auth0Config.audience!,
          token
        );

        await studyParticipantApiClient.updateStudyParticipant(
          new UpdateStudyParticipantRequest()
            .setStudyParticipant(
              new StudyParticipant()
                .setName(studyParticipant.getName())
                .setEnrollmentStartTime(Timestamp.fromDate(enrollmentDate))
                .setEnrollmentStartDate(toDateProto(enrollmentDate))
            )
            .setUpdateMask(
              new FieldMask().setPathsList(["enrollment_start_time"])
            )
            .setUpdateReason(updateReason)
        );

        onSuccess();
      } catch (error) {
        let errMsg = "Failed to update enrollment date. Please try again.";
        if (
          error instanceof RpcError &&
          error.code === StatusCode.PERMISSION_DENIED
        ) {
          errMsg = "User is not authorized to update enrollment date.";
        }

        onError(errMsg);
      }
    })();
  }, [
    isAuthenticated,
    auth0Config,
    onError,
    getAccessTokenSilently,
    studyParticipant,
    enrollmentDate,
    updateReason,
    onSuccess,
  ]);

  const stepInfo: Array<StepInfo> = [
    {
      id: EditEnrollmentDateSteps.NewDateEntry,
      title: "Edit enrollment date",
      page: (
        <>
          <Box>
            <HeaderValuePair
              component="h2"
              title="Current enrollment date"
              value={
                studyParticipant?.getEnrollmentStartDate()
                  ? format(
                      fromDateProto(studyParticipant.getEnrollmentStartDate()!),
                      "M/d/yyyy"
                    )
                  : "n/a"
              }
            />
          </Box>
          <SDPDatePicker
            label="Updated enrollment date (required)"
            value={datePickerValue}
            disableFuture={true}
            error={datePickerError}
            errorText={datePickerErrorText}
            onChange={(selectedDate) => {
              setDatePickerError(false);
              setDatePickerErrorText("");

              setDatePickerValue(selectedDate);
            }}
            sx={{
              margin: "32px 0px 38px",
            }}
          ></SDPDatePicker>
          <UpdateReasonForm
            ref={updateReasonFormRef}
            predefinedReasons={["Error correction", "Other"]}
            reasonSelectionLabel="Reason for updating enrollment date (required)"
            reasonRequiredMessage="Reason for updating enrollment date required"
            otherReasonAllowed={true}
          />
        </>
      ),
      previousButtonText: "Cancel",
      isPreviousDisabled: false,
      onPrevious: () => {
        onClose();
      },
      nextButtonText: "Save",
      isNextButtonDisabled: false,
      onNext: () => {
        if (
          updateReasonFormRef.current?.validateInput() &&
          validateDatePickerField()
        ) {
          setEnrollmentDate(parseDate(datePickerValue!));

          // Enable alert with a 500 ms delay so that dialog title is announced properly
          setEnableAlert(false);
          setTimeout(() => {
            setEnableAlert(true);
          }, 500);

          setStepId(EditEnrollmentDateSteps.ConfirmDate);
          setUpdateReason(updateReasonFormRef.current!.getUpdateReason());
          focusOnDialogTitle();
        }
      },
    },
    {
      id: EditEnrollmentDateSteps.ConfirmDate,
      title: "Confirm enrollment date updates",
      page: (
        <>
          <Alert
            aria-hidden={enableAlert ? "false" : "true"}
            icon={false}
            severity="warning"
          >
            Review and confirm the details below.
          </Alert>
          <Box sx={{ paddingTop: "16px" }}>
            <HeaderValuePair
              component="h2"
              title="Participant ID"
              fontFamily="Roboto Mono"
              value={studyParticipant.getSponsorParticipantId()}
              paragraph={true}
            />
            <HeaderValuePair
              title="Current enrollment date"
              value={
                studyParticipant?.getEnrollmentStartDate()
                  ? format(
                      fromDateProto(studyParticipant.getEnrollmentStartDate()!),
                      "M/d/yyyy"
                    )
                  : "n/a"
              }
              paragraph={true}
              component={"h2"}
            />
            <HeaderValuePair
              title="Updated enrollment date"
              value={
                studyParticipant?.getEnrollmentStartDate()
                  ? format(enrollmentDate, "M/d/yyyy")
                  : "n/a"
              }
              paragraph={true}
              component={"h2"}
            />
            <HeaderValuePair
              title="Reason for change"
              value={updateReason}
              paragraph={true}
              component={"h2"}
            />
          </Box>
        </>
      ),
      previousButtonText: "Go back",
      isPreviousDisabled: false,
      onPrevious: () => {
        setStepId(EditEnrollmentDateSteps.NewDateEntry);
        focusOnDialogTitle();
      },
      nextButtonText: "Confirm",
      isNextButtonDisabled: false,
      isNextButtonLoading: editLoading,
      onNext: async () => {
        setEditLoading(true);
        await editEnrollmentDate();
        setEditLoading(false);
      },
    },
    {
      id: EditEnrollmentDateSteps.EnrollmentDateError,
      title: "Enrollment date must come before watch start date",
      page: (
        <Typography variant="body1">
          Select a date that comes before the participant received their first
          watch
          {!isParticipantSessionNullOrEmpty(firstWatchAssociation)
            ? format(
                firstWatchAssociation!.getInterval()!.getStartTime()!.toDate(),
                " (M/d/yyyy)"
              )
            : ""}
          .<br />
          <br />
          <b>Need help?</b> <br />
          Email <b>numetric-portal@verily.com</b>
        </Typography>
      ),
      previousButtonText: "Go back",
      isPreviousDisabled: false,
      onPrevious: () => {
        setStepId(0);
        focusOnDialogTitle();
      },
      nextButtonText: "Close",
      isNextButtonDisabled: false,
      onNext: () => {
        onClose();
      },
    },
  ];

  const steps: Steps = new Steps(
    stepInfo,
    EditEnrollmentDateSteps.NewDateEntry
  );

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <DialogTitle
        ref={handleDialogTitleRef}
        component="h1"
        tabIndex={-1}
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">{steps.get(stepId)!.title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        {steps.get(stepId)!.page}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={() => steps.get(stepId)!.onPrevious()}
          sx={{
            marginRight: "8px",
          }}
          disabled={steps.get(stepId)!.isPreviousDisabled}
        >
          {steps.get(stepId)!.previousButtonText ?? "Previous"}
        </Button>
        <Button
          variant="filled"
          onClick={() => steps.get(stepId)!.onNext()}
          disabled={steps.get(stepId)!.isNextButtonDisabled}
        >
          {steps.get(stepId)!.nextButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEnrollmentDateDialog;
