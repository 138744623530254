import React, {
  createRef,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button, RadioGroup, Radio } from "@verily-src/react-design-system";
import { Device } from "generated/device/device_pb";
import { ParseIdFromName, RemovePicardPrefix } from "common/ResourceName";
import { focusOnRef } from "common/Accessibility";
import { dialog_border_radius } from "styles/Dimensions";
import A11yDialogTitle from "components/A11yDialogTitle";

interface MultipleDevicesFoundDialogProps {
  /** Whether to open or close the dialog */
  open: boolean;
  devices: Device[];
  selectedDeviceId: string;
  setSelectedDeviceId: React.Dispatch<React.SetStateAction<string>>;
  onClose(): void;
  onSuccess(): void;
}

const formatDeviceId = (deviceId: string) => {
  let deviceIdLength = deviceId.length;
  if (deviceIdLength < 8) {
    return deviceId;
  }

  const prefix = deviceId.slice(0, 4);
  const between = deviceId.slice(4, deviceIdLength - 4);
  const last4digits = deviceId.slice(deviceIdLength - 4);

  return (
    <Typography>
      {prefix} <b>{between}</b> {last4digits}
    </Typography>
  );
};

// The confirmation dialog for adding a study participant.
const MultipleDevicesFoundDialog: React.FC<MultipleDevicesFoundDialogProps> = ({
  open,
  devices,
  selectedDeviceId,
  setSelectedDeviceId,
  onClose,
  onSuccess,
}) => {
  const [focusIndex, setFocusIndex] = useState(-1);
  const optionsRef = useRef(devices.map(() => createRef<HTMLInputElement>()));

  const createRefs = useCallback(() => {
    devices.forEach((_, index) => {
      optionsRef.current[index] = createRef<HTMLInputElement>();
    });
  }, [devices]);

  useEffect(() => {
    setFocusIndex(-1);
    createRefs();
  }, [open, createRefs]);

  useEffect(() => {
    if (focusIndex > -1) {
      focusOnRef(optionsRef.current[focusIndex]);
    }
  }, [focusIndex]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: { borderRadius: dialog_border_radius },
        sx: {
          width: "448px !important",
        },
      }}
    >
      <A11yDialogTitle
        component="h1"
        sx={{ paddingTop: "24px", paddingBottom: "12px" }}
      >
        <Typography variant="display6">Duplicate devices found</Typography>
      </A11yDialogTitle>
      <DialogContent sx={{ paddingTop: "0px" }}>
        <Box>
          <Typography variant="body1">
            There are multiple devices that share the same last 4 digits. Please
            select the device you are trying to assign below:
          </Typography>
        </Box>
        <Box sx={{ paddingTop: "16px" }}>
          <RadioGroup>
            {devices.map((device, index) => {
              let deviceId: string = ParseIdFromName(device.getName());
              return (
                <Radio
                  inputRef={optionsRef.current[index]}
                  key={index}
                  label={formatDeviceId(RemovePicardPrefix(deviceId))}
                  value={deviceId}
                  checked={selectedDeviceId === deviceId}
                  onChange={(event: any) => {
                    setSelectedDeviceId(event.target.value);
                    setFocusIndex(index);
                  }}
                />
              );
            })}
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "right" }}
        sx={{ padding: "0px 24px 24px 24px" }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          onClick={onSuccess}
          disabled={selectedDeviceId.length === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleDevicesFoundDialog;
